import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let {DemographicValues, DemographicValuesObj,XAxis,AnswerObjOverall,optionList,
        
        
        currFilterValue, currFilterName, getDistributionIndexData,getIndexDriverData, GetParameterLabel }= this.props;

    console.log('AnswerObjOverall: ',AnswerObjOverall)     
    console.log('ssssssssDemographicValuesObj: ',DemographicValuesObj)     


    function GetPerc(ValuesObj,item,type){
      if(item==="OVERALL"){
        // let Yes =  ValuesObj["Yes"];
        // let No = ValuesObj["No"];

        let sum = 0;
        if(optionList && optionList.length>0){
          optionList.forEach((opt)=>{
            sum += ValuesObj[opt];
          })
        }


        let total = sum;
        let score = (((ValuesObj[type])*100)/total)
        return score?score.toFixed(2):0
      }
      else{
        let sum = 0;
        if(optionList && optionList.length>0){
          optionList.forEach((opt)=>{
            sum += ValuesObj[item][opt]
          });
        }
        let total = sum;

        // let Yes =  ValuesObj[item]["Yes"];
        // let No = ValuesObj[item]["No"];
        // let total = Yes + No;
        let score = (((ValuesObj[item][type])*100)/total)
        return score?score.toFixed(2):0
      }

    }


    return (
      <>

      <div className="py-1  whitespace-nowrap overflow-x-auto pb-6 ">
      <table className="border-collapse w-full">
        <thead>

          <tr className="text-xs">
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 ">{XAxis}</th>
            {optionList && optionList.length>0?
            optionList.map((item)=>
              <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 px-10">{item+"(%)"}</th>
            ):null}
            {/* <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300 px-10">{"YES (%)"}</th>
            <th className="p-3 font-bold bg-gray-50 text-gray-800 border border-gray-300">{"NO (%)"}</th> */}
          </tr>

        </thead>
        <tbody>

            {/* <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left" >    
                  {"Overall"}
                </td>
                <td className="p-3 border border-b px-6" >    
                  {GetNPSScore(DemographicValuesObj,item)}
                </td>
                <td className="p-3 border border-b px-6" >      
                    {DemographicValuesObj[item].Promoters}
                </td>
                <td className="p-3 border border-b px-6" >      
                    {DemographicValuesObj[item].Passsives}
                </td>
                <td className="p-3 border border-b px-6" >      
                    {DemographicValuesObj[item].Distractors}
                </td>
              </tr> */}

              <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left" >    
                  {"OVERALL"}
                </td>
                {optionList && optionList.length>0?
                optionList.map((item)=>
                <td className="p-3 border border-b px-6 bg-green-300" >      
                    {GetPerc(AnswerObjOverall,"OVERALL",item)}
                </td>
                 ):null}
                {/* <td className="p-3 border border-b px-6 bg-yellow-300" >      
                    {GetPerc(AnswerObjOverall,"OVERALL","No")}
                </td> */}
              </tr>


          {DemographicValues && DemographicValues.length>0?
            DemographicValues.map((item,index)=>
              <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                <td className="p-3 border border-b text-left" >    
                  {item}
                </td>
                {optionList && optionList.length>0?
                optionList.map((Opt)=>
                <td className="p-3 border border-b px-6 bg-green-300" >      
                    {GetPerc(DemographicValuesObj,item,Opt)}
                </td>
                ):null}
                {/* <td className="p-3 border border-b px-6 bg-yellow-300" >      
                    {GetPerc(DemographicValuesObj,item,"No")}
                </td> */}
              </tr>

            ):null}



        </tbody>
      </table>
    </div>

</>
    
    );
  }
}
function mapStateToProps(state) {
  // //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
