
import { authHeader } from '../_helpers';
import { CONST } from '../_config';
let backendUrl = JSON.parse(localStorage.getItem('backendUrl'));

export const questionsService = {
    disableQuestions,
    deleteQuestion,
    logout,
    createQuestion,
    updateQuestion,
    getAllQuestions,
    getQuestionListBySurveyID,
    surveySubmit,
    deleteQuestionOption,
    updateQuestionRenk,
    copyFromQuestionLibrary,
    translateQuestion,
    translateManyQuestions,
    translateSurvey


    // getParameterListbyParentId
    
};
async function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
    //window.location.reload();

}
async function getAllQuestions(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionsList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let questionsObj = {
                listOfQuestionss: data.data
            }
            console.log();
            return questionsObj;
        });
}
async function createQuestion(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/createQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questionsObj = {
                createQuestion: data.data
            }
            console.log();

            return questionsObj;
        });
}
async function updateQuestion(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                questionsList: data.data
            }
            console.log(questionsObj);

            return questionsObj;
        });
}

async function deleteQuestion(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });


    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questionsObj = {
                deleteQuestion: data.data
            }
            console.log(questionsObj);

            return questionsObj;
        });
}
async function disableQuestions(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateQuestionsStatus`, requestOptions)
        .then(handleResponse)
        .then(data => {

            let questionsObj = {
                deleteQuestions: data.data
            }
            console.log();

            return questionsObj;
        });
}
async function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

// async function getQuestionListBySurveyID(data,employeeId) {
//     let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    // let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionListBySurveyID`, requestOptions)
//         .then(handleResponse)
//         .then(data => {
//             var QuesAns=[];
            
//             data.data.map((quesData,quesDataIndex)=>{
//                 if(quesData.templateId===2){
//                     QuesAns.push({
//                         questionId:quesData.id,
//                         answer:"",
//                         id:"",
//                         isMandatory:quesData.isMandatory,
//                         selected:""
//                     });

//                     return QuesAns;
//                 }
//                 else{
//                     return QuesAns.push({
//                         questionId:quesData.id,
//                         answer:"",
//                         id:"",
//                         isMandatory:quesData.isMandatory,
//                         selected:""
//                     })
//             }
                
//             })
//             let questionObj = {
//                 questionsList: data.data,
//                 answersList:QuesAns
//             }
//             console.log("questionObj  ", questionObj);

//             return questionObj;
//         });
// }




async function surveySubmit(data,quesList) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/saveAnswers`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data.data;
        });
}


async function deleteQuestionOption(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/deleteQuestionOption`, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data.data;
        });
}



async function getQuestionListBySurveyID(data,employeeId) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestionListBySurveyID`, requestOptions)
        .then(handleResponse)
        .then(data => {
            var QuesAns=[];
            console.log("data data: ",data)
            
            let QuestionMain = [];
            let MatrixQuestion = [];
            let GroupQuestion = [];

            if(data && data.data && data.data.questionList){
                data.data.questionList.forEach((ques)=>{
                    
                    if(ques.templateId===13){
                        if(ques.parentId){
                            MatrixQuestion.push(ques);
                        }
                        else{
                            QuestionMain.push({
                                ...ques,
                                SubQuestionList:[]
                            });
                        }
                    }


                    // else if(ques.templateId === 14){
                    //     QuestionMain.push({
                    //         ...ques,
                    //         SubQuestionList:[]
                    //     });
                    // }


                    else if(ques.templateId !==13 && ques.templateId !==14 ){
                        if(ques.parentId){
                            GroupQuestion.push(ques);
                        }
                        else{
                            QuestionMain.push(ques);
                        }
                    }


                    else{
                        QuestionMain.push(ques);
                    }
                    
                });
            }

            if(QuestionMain && QuestionMain.length>0){
                QuestionMain.map((quesData,quesDataIndex)=>{


                    if(quesData.templateId===2){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            questionOptionId:""
                        });
                        return QuesAns;
                    }
                    else if(quesData.templateId===13){
                        QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            multiAnswer:[],
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory

                        });
                        return QuesAns;
                    }
                    else{
                        return QuesAns.push({
                            questionId:quesData.id,
                            answer:"",
                            questionOptionId:"",
                            isMandatory:quesData.isMandatory

                        })
                    }


                    
                })
            }


            console.log('---->------------>MatrixQuestion',MatrixQuestion)

            if(MatrixQuestion && MatrixQuestion.length>0){
                MatrixQuestion.forEach((ques)=>{
                    let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
                    if(getIndex!==-1){
                        QuestionMain[getIndex]["SubQuestionList"].push(ques);

                    }
                });
            }

            let QuestionFinal = []
            if(QuestionMain && QuestionMain.length>0){
                QuestionMain.forEach((Qmain)=>{

                    if(Qmain.templateId===14){
                        QuestionFinal.push(Qmain);

                        if(GroupQuestion && GroupQuestion.length>0){
                            GroupQuestion.forEach((ques,index)=>{
                                if(ques.parentId === Qmain.id){
                                    let temp = ques;
                                    temp["rank"] = Qmain.rank
                                    QuestionFinal.push(temp);
                                }
                            });
                        }


                    }else{
                        QuestionFinal.push(Qmain);
                    }

                });
            }


            // if(GroupQuestion && GroupQuestion.length>0){
            //     GroupQuestion.forEach((ques)=>{
            //         let getIndex = QuestionMain.findIndex(prev=>prev.id===ques.parentId);
            //         if(getIndex!==-1){
            //             QuestionMain[getIndex]["SubQuestionList"].push(ques);
            //         }
            //     });
            // }

            
            console.log('---->------------>QuestionMain',QuestionMain)

            let questionObj = {
                questionsList: QuestionFinal,
                answersList:QuesAns,
                surveyScreenData :data.data.survey
            }
            console.log("questionObj  ", questionObj);

            return questionObj;
        });
}

async function updateQuestionRenk(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/updateQuestionRenk`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                updatequestionData: data.data
            }
            console.log(questionsObj);

            return questionsObj;
        });
}


async function copyFromQuestionLibrary(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/copyFromQuestionLibrary`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                copyFromQuestionLibraryData: data.data
            }
            console.log(questionsObj);

            return questionsObj;
        });
}


async function translateQuestion(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/translateQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                translateQuestionData: data.data
            }
            console.log(questionsObj);

            return questionsObj;
        });
}

async function translateManyQuestions(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/translateManyQuestions`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                translateManyQuestionData: data.data
            }
            console.log(questionsObj);

            return questionsObj;
        });
}


async function translateSurvey(data) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(data)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/translateSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
         
            let questionsObj = {
                translateSurveyData: data.data
            }
            console.log(questionsObj);

            return questionsObj;
        });
}

// async function getParameterListbyParentId(data) {
//     let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    // let header = new Headers({
//         'Content-Type': 'application/json',
//         "Authorization": authHeader().Authorization
//     });
//     const requestOptions = {
//         method: "POST",
//         headers: header,
//         body: JSON.stringify(data)
//     }
//     return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getParameterListbyParentId`, requestOptions)
//         .then(handleResponse)
//         .then(data => {

//             let parameterObj = {
//                 getParameterListbyParentId: data.data
//             }

//             return parameterObj;
//         });
// }
