import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select2  from 'react-select';
import { SketchPicker } from 'react-color';

class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {
  }





 
  render() {
    let { SurveyTemplateId,welcomeFormData,imageWebLinkLogo,imageWebLinkLogo2,imageWebLink, handleSurveyTemplate,
    parameterListOptions, parameterDefault, orgListOptions, orgDefault,
    handleWelcomeTextInput,handleInstructionTextInput,handleThankyouTextInput,
    handleSurveyParameterList,handleSurveyorgList,handleChangetextColor,handleChangebgColor,handlebgColorImage,
    handleFile, handleUpload, handleImageRemove, handleFileLogo,handleFileLogo2, handleUploadLogo, handleLogoRemove,handlebgType,
    saveWelcomePage,handleChangeColorValue,handleInstructionCheck, instructionCheck,

    handleColorCode, ColorCodeList, selectColorType
    } = this.props;


    console.log('welcomeFormData.logo: ',imageWebLinkLogo, welcomeFormData.logo)
    console.log('welcomeFormData.logo2: ',imageWebLinkLogo2, welcomeFormData.logo2)

    
    let templateList = [
      {
        "name":"Form View (v1)",
        "designTemplate":"v1"
      },
      {
        "name":"Slide View (v2)",
        "designTemplate":"v2"
      },
      {
        "name":"Card View (v3)",
        "designTemplate":"v3"
      },
      {
        "name":"Wide View (v4)",
        "designTemplate":"v4"
      },
      {
        "name":"OnePage View (v5)",
        "designTemplate":"v5"
      },
      {
        "name":"New Theme (v6)",
        "designTemplate":"v6"
      }
    ]



    return (
      <>
<main className="xl:px-6 lg:px-6 md:px-6 px-2 bg-gray-100">
  {/* <div className="cursor-pointer item-end" onClick={()=>saveWelcomePage()}>save</div> */}
    <div className="container py-12 xl:w-2/3 xl:px-12 lg:w-2/3 lg:px-8  md:w-2/3 md:px-0 px-2 w-full mx-auto">
      {/*  */}
      
      <div>
      <label htmlFor="logo">
      <div className="border border-gray-300 flex justify-center py-4 bg-gray-300 cursor-pointer">
        <div className="text-center text-gray-700">
          {/* <i className="fas fa-image text-3xl " /> */}
          <img src={(imageWebLinkLogo?imageWebLinkLogo:welcomeFormData.logo)} style={{width:'70px', marginLeft:"20%", marginBottom:"5%"}}/>
          <p className="text-sm text-gray-700" >Click to add Logo</p>

       
        </div>
      </div>

      </label>
      {imageWebLinkLogo || welcomeFormData.logo?
            <p onClick={()=>handleLogoRemove(1)} className="text-sm text-red-700 cursor-pointer" >Remove Logo</p>
       :null}

              <input
                style={{ display: 'none' }}
                id="logo"
                name="logo"
                type="file"
                onChange={handleFileLogo}
              />  

      <div className="my-2">
        {/* <input type="text" name placeholder="Title here.." className="py-4 px-4 w-full border border-gray-300 outline-none" /> */}
      </div>
      </div>


      <div className="my-10"/>


        {/* ------------------------------------------------------------------------------------------- */}
      {/* logo2 */}
      <div>
      <label htmlFor="logo2">
      <div className="border border-gray-300 flex justify-center py-4 bg-gray-300 cursor-pointer">
        <div className="text-center text-gray-700">
          {/* <i className="fas fa-image text-3xl " /> */}
          <img src={(imageWebLinkLogo2?imageWebLinkLogo2:welcomeFormData.logo2)} style={{width:'70px', marginLeft:"20%", marginBottom:"5%"}}/>
          <p className="text-sm text-gray-700" >Click to add Secondary Logo</p>

       
        </div>
      </div>

      </label>
      {imageWebLinkLogo2 || welcomeFormData.logo2?
            <p onClick={()=>handleLogoRemove(2)} className="text-sm text-red-700 cursor-pointer" >Remove Secondary Logo</p>
       :null}

              <input
                style={{ display: 'none' }}
                id="logo2"
                name="logo2"
                type="file"
                onChange={handleFileLogo2}
              />  

      <div className="my-2">
        {/* <input type="text" name placeholder="Title here.." className="py-4 px-4 w-full border border-gray-300 outline-none" /> */}
      </div>
      </div>

      <div className="my-10"/>




 


      <div className="mt-4 text-black font-semibold text-sm">Survey Visual Design</div>
      <div className="bg-gray-200">

      <div className="flex">

          <div className=" py-2 mx-auto">
              {ColorCodeList && ColorCodeList.length>0?
              ColorCodeList.map((color,index)=>
              <div  className="flex">
              <div onClick={()=>handleColorCode(color)}
              style={{width:'230px'}}
              className={color.value===selectColorType?"cursor-pointer bg-gray-700  text-white p-2 m-2":"cursor-pointer  bg-white p-2 m-2"} keys={index}
              
              >
                {color.name}
              </div>
              
              <div style={{background:welcomeFormData[color.value],width:'40px', height:'40px'}} className=" p-2 m-2"></div>
              </div>
              )
              :null}
              
              
          </div>



        <div className="my-4 mx-2 md:w-4/12 mx-6">
                        <div className="text-black font-semibold text-sm">Select Color / Enter Color Code:</div>
                        <SketchPicker
                              color={ welcomeFormData[selectColorType] }
                              onChangeComplete={ handleChangeColorValue }
                            />
        </div>


        





          </div>








      </div>



      <div className="my-10"/>


      <div className="">
              <label htmlFor="image">
            <div className="border border-gray-300 flex justify-center py-4 bg-gray-300 cursor-pointer">
              <div className="text-center text-gray-700">
                {/* <i className="fas fa-image text-3xl " /> */}
                <img src={(imageWebLink?imageWebLink:welcomeFormData.bgImage)} style={{width:'500px', marginBottom:"5%"}}/>

                <p className="text-sm text-gray-700" >Click to add Background Image</p>
            
              </div>
            </div>
            </label>
            {imageWebLink || welcomeFormData.bgImage?
            <p onClick={()=>handleImageRemove()} className="text-sm text-red-700 cursor-pointer" >Remove Background Image</p>
            :null}
                    <input
                      style={{ display: 'none' }}
                      id="image"
                      name="image"
                      type="file"
                      onChange={handleFile}
                    />  
      </div>



      <div className="py-6 flex ">
          
          {templateList && templateList.length>0?
          templateList.map((item)=>
            <div className="flex mx-4" onClick={()=>handleSurveyTemplate(item.designTemplate)}>
              <div className="py-1 pr-2"><input type="checkbox" checked={item.designTemplate===welcomeFormData.designTemplate} className="cursor-pointer" /></div>
              <div className="py-1 text-gray-700">{item.name}</div>
            </div>
          ):null}


      </div>


    </div>
  </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey } = state;
  return {
    loggingIn,
    survey
  };
}
export default connect(mapStateToProps)(WelcomePage);
