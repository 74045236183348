import React from 'react';
import ReactSpeedometer from "react-d3-speedometer";


export default function GaugeScore(props) {
    
    let { item, Benchmark } = props;





    return (
      <>	
      <div className="pt-1 pl-4" style={{height:Benchmark && Benchmark.score?null:"140px"}}>

      <ReactSpeedometer className="meter"
                                value={item.score}
                                minValue={0}
                                maxValue={100}
                                needleColor="#4db8ff"
                                segments={1}
                                segmentColors={[
                                "#f5b342"
                                ]}
                                ringWidth={35}
                                customSegmentStops={[0, 100]}
                                // fluidWidth={true}
                                needleTransitionDuration={3333}
                                needleTransition="easeElastic"
                              />  
      {Benchmark && Benchmark.score || true?
      <div className="gauge-outer">
          <div className="gauge-inner" style={{transform: 'rotate(calc(1deg * (-45 + '+0+' * 1.8)))'}}></div>
        </div>
        :null}
      
      
      </div>

      </>
    );
  }
