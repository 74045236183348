import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment, { max } from 'moment';
import IndexKPI from './Components/IndexKPI';
import Filter from './Components/Filter';
import DonutChart from './Components/DonutChart';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDropdown:"",
      showTaskDropdown:[],
      showMAPView:true,

      FilterValues: [],
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }


  openDropdown=(emp)=>{
    if(this.state.showDropdown === emp.emp_id){
      this.setState({showDropdown:""});
    }
    else{
      this.setState({showDropdown:emp.emp_id});
    }
  }


  openTaskDropdown=(act)=>{
    let { showTaskDropdown } = this.state;

    let getIndex = showTaskDropdown.findIndex(prev=>prev===act.id);
    if(getIndex===-1){
      showTaskDropdown.push(act.id);
    }
    else{
      showTaskDropdown.splice(getIndex,1);
    }
    this.setState({showTaskDropdown});
  }

  handleMAPView=(val)=>{
    this.setState({showMAPView:val});
  }


  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleMultiFilterGetQuestion } = this.props;
    this.setState({ FilterValues: finalFilter });

    console.log('========s===================>',finalFilter)
    // handleMultiFilterGetQuestion(finalFilter);

  }
  render() {
    let { getAllManagerActionPlanData, getDemographicIndexForManagerData ,GetParameterLabel, 
      EmpFilterData, getIndexFilterData} = this.props;

    let { showMAPView, showDropdown, showTaskDropdown , FilterValues} = this.state;



//-------------------------------------------------------------------------------------------


    let DmgForMngrData = [];
    console.log('x FilterValues: ',FilterValues);

    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      getDemographicIndexForManagerData.forEach((Emp)=>{

        let isCheck = 0;
        if(FilterValues && FilterValues.length>0){
          FilterValues.forEach((filter)=>{
            if(filter.value !== "All"){
              if(!(Emp[filter.level] === filter.value)){
                isCheck++
              }
            }

            console.log('x 1: ',Emp[filter.level]);
            console.log('x 2: ',filter.value);

          });
        }
        // console.log('x Emp: ',Emp);
        // console.log('x isCheck: ',isCheck);

        if(isCheck===0){
          DmgForMngrData.push(Emp)
        }



      
      });
      
    }



    let AllMngrAPlanData = [];

    if(getAllManagerActionPlanData && getAllManagerActionPlanData.length>0){
      getAllManagerActionPlanData.forEach((action)=>{
        let getIndex = DmgForMngrData.findIndex(prev=>prev.emp_id===action.emp_id);
        if(getIndex!==-1){
          AllMngrAPlanData.push(action)
        }
        
      });
    }


    //-------------------------------------------------------------------------------










    // //console.log('AllMngrAPlanData: ',AllMngrAPlanData);
    // //console.log('DmgForMngrData: ',DmgForMngrData);

    let EmployeeList = [];
    let actionTotalCount = 0;
    let actionCompleteCount = 0;
    let totalTasks = 0;
    let completedTasks = 0;
    let StartedTasks = 0;

    if(AllMngrAPlanData && AllMngrAPlanData.length>0){
      AllMngrAPlanData.forEach((action)=>{

        if(action && action.employeeTaskList && action.employeeTaskList.length>0){
          // //console.log('action.employeeTaskList: ',action.employeeTaskList);

          totalTasks +=action.employeeTaskList.length;
          completedTasks += action.employeeTaskList.filter(x => x.isComplete == true).length;

          StartedTasks += action.employeeTaskList.filter(x => x.isStarted == true).length;
        }


        if(action.isComplete){
          actionCompleteCount++;
        }
        actionTotalCount++;
        let getIndex = EmployeeList.findIndex(prev=>prev.emp_id === action.emp_id);
        if(getIndex===-1){
          EmployeeList.push({
            "emp_id":action.emp_id
          });
        }
      });
    }

    let EmployeeActionList = [];
    if(EmployeeList && EmployeeList.length>0){
      EmployeeList.forEach((emp)=>{
        let actionFilteredList = AllMngrAPlanData.filter(x => x.emp_id == emp.emp_id);
        let completedActionCount = actionFilteredList.filter(action => action.isComplete == true).length;
        let totalActionCount = actionFilteredList && actionFilteredList.length>0?actionFilteredList.length:0;
        ////console.log('actionFilteredList: ',actionFilteredList);
        let startedActionCount = actionFilteredList.filter(action => action.isStarted == true).length;


        let actionFilteredStartDate = AllMngrAPlanData.filter(x => x.isStarted == true);
        let actionFilteredEndDate = AllMngrAPlanData.filter(x => x.isComplete == true);
        //console.log('actionFilteredStartDate: ',actionFilteredStartDate);
        ////console.log('actionFilteredEndDate: ',actionFilteredEndDate);


        let startDateList = [];
        if(actionFilteredStartDate && actionFilteredStartDate.length>0){
          actionFilteredStartDate.forEach((item)=>{
            startDateList.push(item.startDate);
          });
        }
        let endDateList = [];
        if(actionFilteredEndDate && actionFilteredEndDate.length>0){
          actionFilteredEndDate.forEach((item)=>{
            endDateList.push(item.startDate);
          });
        }
        //console.log('startDateList: emp',emp,startDateList);

        // let min = 0
        // let max = 0
        // if(startDateList && startDateList.length>0){
        //   min = Math.min(...startDateList);
        // }
        // if(endDateList && endDateList.length>0){
        //   max = Math.max(...endDateList);
        // }

        ////console.log('minimum: ',min);
        ////console.log('maximum: ',max);


        let ManagerList = [];
        if(DmgForMngrData && DmgForMngrData.length>0){
          ManagerList = DmgForMngrData;
        }
        let getIndex = ManagerList.findIndex(prev=>prev.emp_id===emp.emp_id);
        let AllStartDates = [];
        let AllEndDates = [];
        if(actionFilteredList && actionFilteredList.length>0){
          actionFilteredList.forEach((item)=>{
            if(true){
                //console.log('action---item: ',item);

                if(item.isStarted){
                  AllStartDates.push(item.startDate)
                }
                if(item.isComplete){
                  AllEndDates.push(item.endDate)
                }

            }
          });
        }
        //console.log('AllStartDates: ',AllStartDates);
        //console.log('AllEndDates: ',AllEndDates);

        

        let tempList = {
          "emp_id":emp.emp_id,
          "emp_details":(getIndex!==-1)?DmgForMngrData[getIndex]:"",
          "action_list":actionFilteredList,
          "startDate":AllStartDates && AllStartDates.length>0 ? Math.min(...AllStartDates):0,
          "endDate":AllEndDates && AllEndDates.length>0 ? Math.max(...AllEndDates):0,
          "isStarted":startedActionCount?true:false,
          "isComplete":(completedActionCount === totalActionCount)?true:false,
        }
        EmployeeActionList.push(tempList);

      });
    }
    //console.log('EmployeeActionList: ',EmployeeActionList);



 

















    // let ActionType2List = [];
    // if(AllMngrAPlanData && AllMngrAPlanData.length>0){
    //   AllMngrAPlanData.forEach((item)=>{
    //     let getIndex = ActionType2List.findIndex(prev=>prev.actionId.id===item.actionId.id);
    //     if(getIndex===-1){
    //       let temp= {
    //         actionId:item.actionId,
    //         empList:[]
    //       }
    //       if(item.emp_id){
    //         temp["empList"].push({
    //           "createdAt":item.createdAt,
    //           "emp_id":item.emp_id
    //         });
    //         ActionType2List.push(temp);
    //       }
    //     }
    //     else{
    //       if(item.emp_id){
    //         ActionType2List[getIndex]["empList"].push({
    //           "createdAt":item.createdAt,
    //           "emp_id":item.emp_id
    //         });
    //       }
    //     }
    //   });
    // }

    console.log('AllMngrAPlanData: ',AllMngrAPlanData)


    let TaskList = [];

    if(AllMngrAPlanData && AllMngrAPlanData.length>0){
      AllMngrAPlanData.forEach((action)=>{
        if(action && action.employeeTaskList && action.employeeTaskList.length>0){
          action.employeeTaskList.forEach((task)=>{

            let getIndex = TaskList.findIndex(prev=>prev.taskId.id===task.taskId.id);
            if(getIndex === -1){
              TaskList.push({
                ...task,
                managerList:[{"managerId":task.emp_id,"isComplete":task.isComplete}],
                taskCount:1,
                actionName:action.actionId.name,
                parameterName:action.actionId.parameterName
              });
            }
            else{
              TaskList[getIndex]["managerList"].push({"managerId":task.emp_id,"isComplete":task.isComplete});
              TaskList[getIndex]["taskCount"] += 1;
            }


            
            // let getIndex = TaskList.findIndex(prev=>prev.id===task.id);
            // if(getIndex === -1){
            //   TaskList.push({
            //     ...task,
            //     completeList:[task.isComplete],
            //     taskCount:1,
            //     actionName:action.actionId.name,
            //     parameterName:action.actionId.parameterName
            //   });
            // }
            // else{
            //   TaskList[getIndex]["completeList"].push(task.isComplete);
            //   TaskList[getIndex]["taskCount"] += 1;
            // }



          });
        }
      });
    }


    console.log('TaskList:--------> ',TaskList)


    // let PendingTaskList = [];
    // let CompletedTaskList = [];

    // if(TaskList && TaskList.length>0){
    //   TaskList.forEach((item)=>{

    //     let booleanCount = 0;
    //     if(item && item.completeList && item.completeList.length>0){
    //       booleanCount = item.completeList.filter(x=>x === true).length;
    //     }

    //     if(booleanCount === item.taskCount){
    //       CompletedTaskList.push(item);
    //     }
    //     else{
    //       PendingTaskList.push(item);
    //     }

    //   });
    // }


    // if(TaskList && TaskList.length>0){
    //   TaskList.forEach((item)=>{

    //     if(item.isComplete){
    //       CompletedTaskList.push(item);
    //     }
    //     else{
    //       PendingTaskList.push(item);
    //     }

    //   });
    // }


    // console.log('PendingTaskList:--------> ',PendingTaskList)
    // console.log('CompletedTaskList:--------> ',CompletedTaskList)




    let ResponseAction = {
      "assigned":totalTasks,
      "pending":totalTasks-completedTasks,
      "completed":completedTasks
    }



    // let ResponseAction = {
    //   "assigned":TaskList && TaskList.length>0?TaskList.length:0,
    //   "pending":PendingTaskList && PendingTaskList.length>0?PendingTaskList.length:0,
    //   "completed":CompletedTaskList && CompletedTaskList.length>0?CompletedTaskList.length:0
    // }



    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    function ManagerStatus (task) {
      let ResponseAction = {
        "assigned":task && task.managerList.length>0? task.managerList.length:0,
        "pending":task && task.managerList.length>0? task.managerList.filter(x=>x.isComplete===true).length:0,
        "completed":task && task.managerList.length>0? task.managerList.filter(x=>x.isComplete===false).length:0
      }
      return ResponseAction
    }


    //console.log('showTaskDropdown :',showTaskDropdown)
    return (
      <> 


        <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">

        <Filter
              showFilter={this.state.showFilter}
              showValue={this.state.showValue}
              closeFilter={this.closeFilter}

              EmpFilterData2={EmpFilterData2}
              getIndexFilterData={getIndexFilterData}
              handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
            />

        </div>
        {FilterValues && FilterValues.length > 0 ?
        <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
          {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
            <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
          ) : null}</div>
        : null}







      <div className="px-2">
        <IndexKPI ResponseAction={ResponseAction} />
      </div>



      <div className="flex flex-col mx-2 bg-gray-100 px-2  pb-4 ">
      <div className="font-semibold text-lg py-3">Manager Action Plan (Detail View)</div>
      <div className="pb-2 overflow-x-auto whitespace-nowrap w-full ">
      
      
    <div className="flex mb-4 -ml-2">
      <div 
        onClick={()=>this.handleMAPView(true)} 
        className={showMAPView?
          "cursor-pointer  py-1 block px-4 text-blue-600 border-b-2 border-blue-500":
          "cursor-pointer  py-1 block px-4"}
        >{"Manager Wise"}</div>
      <div 
        onClick={()=>this.handleMAPView(false)} 
        className={!showMAPView?
          "cursor-pointer  py-1 block px-4 text-blue-600 border-b-2 border-blue-500":
          "cursor-pointer  py-1 block px-4"}
        >{"Task View"}</div>
    </div>




      {!showMAPView?
      <>
      <div className="grid grid-cols-3 gap-2">

          {TaskList && TaskList.length>0?
            TaskList.map((task, index)=>   
                <>
                  <div className=" bg-white mb-4 rounded-md capitalize shadow-lg m-2">
                    <div className="md:flex justify-between items-center text-sm text-gray-500 relative p-3 ">

                      <div className="w-full">
                        <div className="" >

                        <div className="" >
                          <div className="text-gray-700 mx-2"><span className="text-sm text-red-500">ACTION:</span> {task.actionName}  ({task.parameterName})</div>
                          <h1 className="font-medium text-gray-800 whitespace-pre-line m-2"><span className="text-sm text-blue-500">TASK:</span> {task && task.taskId && task.taskId.name?task.taskId.name:""}</h1>
                          
                          <div className="grid grid-cols-2 ">
                            <div title="Employee id" className="text-gray-500 pt-1 mx-2">
                              <div className="text-gray-500"><span className="pb-2">Assigned Managers:</span> <span className="text-sm text-blue-500">{ManagerStatus(task).assigned}</span></div>
                              <div className="text-gray-500"><span className="pb-2">Pending Managers:</span> <span className="text-sm text-yellow-500">{ManagerStatus(task).pending}</span></div>
                              <div className="text-gray-500"><span className="pb-2">Completed Managers:</span> <span className="text-sm text-green-500">{ManagerStatus(task).completed}</span></div>
                            </div>
                            <div className="">
                              <DonutChart managerStatus={ManagerStatus(task)} />
                            </div>
                          </div>

                        </div>


                        </div>
                      </div>
                      
                    </div>

                  </div>
              </>
            ):null}
          </div>

        </>

        :
        <>

{EmployeeActionList && EmployeeActionList.length>0?
          EmployeeActionList.map((empAct, index)=>   
          <>
        <div className=" bg-white mb-4 rounded-md capitalize shadow-lg ">
          <div className="md:flex justify-between items-center text-sm text-gray-500 relative p-3 ">


            <div className="w-60">
              <div className="flex align-items-center" onClick={()=>this.openDropdown(empAct)}>
                <div className="rounded-full py-2 px-3 bg-gray-100">
                  <span className="material-icons" style={{fontSize: '2rem'}}>person</span>
                </div>
                <div className="md:ml-3 ml-0" >
                  <h1 className="font-medium text-gray-800 truncate">{empAct && empAct.emp_details && empAct.emp_details.name?empAct.emp_details.name:"NaN"}</h1>
                  <div title="Employee id" className="text-gray-500 flex items-stretch pt-1">

                    <span className="material-icons mr-2" style={{fontSize: '18px'}}>verified_user</span> 
                    {empAct && empAct.emp_id?"EmpId: "+empAct.emp_id:""}
                    
                    {showDropdown === empAct.emp_id?
                      <> 
                      <span className="px-2"></span>
                      <span className="material-icons mr-2" style={{fontSize: '18px'}}>email</span> 
                      {empAct && empAct.emp_details && empAct.emp_details.email?"Email: "+ empAct.emp_details.email:""} 
                      </>
                    :null}

                  </div>
                </div>
              </div>
            </div>

            <div className="flex md:text-sm text-xs md:py-0 py-3 w-40 " onClick={()=>this.openDropdown(empAct)}> 
              <p className="lg:mr-20 mr-6">{empAct && empAct.startDate? "Start: "+moment.unix(empAct.startDate).format("DD MMM, yyyy"):""}</p> 
              <p className>{(actionTotalCount === actionCompleteCount)?empAct && empAct.endDate?"End: "+ moment.unix(empAct.endDate).format("DD MMM, yyyy"):"":""}</p>
            </div>

            <div className="md:text-left" onClick={()=>this.openDropdown(empAct)}> 
              {empAct.isComplete?
                <span title="Status" className="bg-green-400 bg-white text-gray-50  rounded-md px-2 text-xs py-0.5">Completed</span>
                :
                <span title="Status" className="bg-yellow-400 bg-white text-gray-50  rounded-md px-2 text-xs py-0.5">Pending</span>
              }
            </div>  

          <span onClick={()=>this.openDropdown(empAct)} title="More Details" 
            className="material-icons p-1 rounded-full bg-gray-50 font-bold mr-2 md:relative absolute md:top-0 top-2 right-0 cursor-pointer" 
            style={{fontSize: '18px',background:showDropdown === empAct.emp_id?"#e1f4fc":"#f2f2f2"}}>{showDropdown === empAct.emp_id?"expand_less":"expand_more"}</span>

          </div>
          {showDropdown === empAct.emp_id?
          <>
          {empAct && empAct.action_list && empAct.action_list.length>0?
            empAct.action_list.map((action)=>
          <div className="p-3 w-full  items-center cursor-pointer mx-6" onClick={()=>this.openTaskDropdown(action)}>

            <div className="flex">
            
                  <div className="flex items-start font-semibold text-base "> 

                    <span className={action.isComplete?"material-icons text-green-400 mx-2 mt-1 items-start":"material-icons text-yellow-400 mx-2 mt-1 items-start"} style={{fontSize: '1.3rem'}}>
                      {action.isComplete?"check_circle":"pending"}</span>  

                    <h1 className>{(action && action.actionId && action.actionId.name)?action.actionId.name:""}</h1>

                  </div>
            
                <div className="flex items-end mx-4">
                  
                  {action && action.isStarted?
                  <span title="Satrt date" className="flex items-center py-1.5 text-xs text-gray-500 "> 
                    <span className="material-icons mr-2" style={{fontSize: '1rem'}}>today</span>
                    {action && action.startDate?"Start: "+moment.unix(action.startDate).format("DD MMM, yyyy"):""}
                  </span>
                  :null}

                  {action && action.isComplete?
                  <span title="End date" className="flex items-center py-1.5 text-xs text-gray-500 ml-4"> 
                    <span className="material-icons mr-2" style={{fontSize: '1rem'}}>event</span>
                    {action && action.endDate?"End: "+moment.unix(action.endDate).format("DD MMM, yyyy"):""}
                  </span>
                  :null}

                  </div>
            </div>



            {showTaskDropdown.findIndex(prev=>prev===action.id) !==-1?
            <div className="ml-4 mt-4">
              {(action && action.employeeTaskList && action.employeeTaskList.length>0)?
                action.employeeTaskList.map((task)=>
                <div className="pb-2">
                  <div className="flex items-start"> 
                  <span className={task.isComplete?"material-icons text-green-400 mr-2 items-start":"material-icons text-yellow-400 mr-2 items-start"} style={{fontSize: '1.3rem'}}>
                    {task.isComplete?"done":"more_horiz"}</span> 
                  <p className="text-gray-800 font-medium text-sm">{(task && task.taskId && task.taskId.name)?(task.taskId.name.length>150?task.taskId.name.slice(0,150)+"...":task.taskId.name):""}</p> 
                  </div>
                  
                  <div className="flex">
                  
                  {task.isStarted?
                  <span title="Completion Date " className="flex items-center text-xs ml-8 py-1.5 text-gray-500">
                    <span className="material-icons mr-2" style={{fontSize: '1rem'}}>event_available</span> 
                    {task && task.initdate?"Start: "+moment.unix(task.initdate).format("DD MMM yyyy"):""}
                  </span>
                  :null}

                  {task.isComplete?
                  <span title="Completion Date " className="flex items-center text-xs ml-8 py-1.5 text-gray-500">
                    <span className="material-icons mr-2" style={{fontSize: '1rem'}}>event_available</span> 
                    {task && task.date?"End: "+moment.unix(task.date).format("DD MMM yyyy"):""}
                  </span>
                  :null}

                  {task.isStarted && !task.isComplete?
                  <span title="Completion Date " className="flex items-center text-xs ml-8 py-1.5 text-gray-500">
                    <span className="material-icons mr-2" style={{fontSize: '1rem'}}>event_available</span> 
                    {"Pending"}
                  </span>
                  :null}

                  {!task.isStarted && !task.isComplete?
                  <span title="Completion Date " className="flex items-center text-xs ml-8 py-1.5 text-gray-500">
                    <span className="material-icons mr-2" style={{fontSize: '1rem'}}>event_available</span> 
                    {"Not Started"}
                  </span>
                  :null}


                  </div>


                </div>
              ):null}


              {/* <div className="pb-2">
                <div className="flex items-start"> <span className="material-icons text-green-400 mr-2 items-start" style={{fontSize: '1.3rem'}}>check_circle</span> <p className="text-gray-800 font-medium text-sm">managing shit  and wellness workshop</p> </div>
                <span title="Completion Date " className="flex items-center text-xs ml-8 py-1.5 text-gray-500"><span className="material-icons mr-2" style={{fontSize: '1rem'}}>event_available</span> dec 31, 2021</span>
              </div> */}

            </div>
            :null}
          </div>
            ):null}
          </>
          :null}
        </div>
        </>
          ):null}
        </>


        

      }






        
        </div>
      </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  //console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
