import React, { Component } from 'react';
import { connect } from 'react-redux';

class IndexMeanOverall extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }


  render() {
    let { getMeanScoreQwiseData, currFilterName, ItemSaveData, cardType} = this.props;

    // console.log('OverviewList: ',OverviewList);
    let indexName = '';
    if(ItemSaveData && ItemSaveData.name){
      indexName = ItemSaveData.name;
    }


    let IndexList = [];
    let IndexNameList = [];
    let dmgNameList = [];
    let OverviewList = [];
    if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
      dmgNameList.push("overall");
      getMeanScoreQwiseData.forEach((ques,index2)=>{

        let getIndex4 = dmgNameList.findIndex(prev=>prev===ques.dmgName);
        if(getIndex4===-1){
          dmgNameList.push(ques.dmgName);
        }

        let getIndex1 = IndexList.findIndex(prev=>prev.index===ques.parameterDimensionName);
        if(getIndex1===-1){

          
          let tempDriver = {
            "name":ques && ques.parameterDimensionName?ques.parameterDimensionName:"Driver "+index2.toString(),
            "mean":ques && ques.score?ques.score:0
          }
          OverviewList.push(tempDriver);
        


          IndexNameList.push(ques.parameterDimensionName);
          let temp = {
            "index":ques.parameterDimensionName,
            "QuestionList":[]
          }
          temp["QuestionList"].push(ques);
          IndexList.push(temp)
        }
        else{
          IndexList[getIndex1]["QuestionList"].push(ques)
        }
      });
    }

    console.log("-------------------------------------------------------------------------------------------");

    console.log("IndexList",IndexList);
    // console.log("IndexList",IndexList);

    console.log("-------------------------------------------------------------------------------------------");

    let IndexObj = {}
    if(IndexList && IndexList.length>0){
      IndexList.forEach((item)=>{
        IndexObj[item.index]=[];
        if(item && item.QuestionList && item.QuestionList.length>0){
          item.QuestionList.forEach((que, queIndex)=>{
            let getIndex3 = IndexObj[item.index].findIndex(prev=>prev.dmgName===que.dmgName);
            if(getIndex3===-1){
              let temp={
                "dmgName":que.dmgName,
                "score":que.score,
                "total":1
              }
              IndexObj[item.index].push(temp);
            }
            else{
              IndexObj[item.index][getIndex3]["score"]+=que.score
              IndexObj[item.index][getIndex3]["total"]++;
            }

          });

        }
      });
    }

    console.log('aaa OverviewList: ',OverviewList);

    // console.log('aaa IndexObj: ',IndexObj);


    var NewIndexData = Object.keys(IndexObj).map((key) => [key, IndexObj[key]]);


    let NeWdATA = []
    if(NewIndexData && NewIndexData.length>0){
      NewIndexData.forEach((item)=>{
        let temp2={
          "name":item[0]
        }

        let getIndex6 = OverviewList.findIndex(prev=>prev.name===temp2.name);
        temp2["overall"]=parseFloat((getIndex6!==-1)?OverviewList && OverviewList[getIndex6] && OverviewList[getIndex6].mean?OverviewList[getIndex6].mean:0:0);

        console.log('aaa temp2["Overall"]: ',temp2["Overall"]);

        let list =  item[1];
        if(list && list.length>0){
            list.forEach((item2)=>{
            temp2[item2.dmgName]=(item2.score/item2.total)
          });
        }
        NeWdATA.push(temp2);
      });
    }
    // console.log('aaa dmgNameList: ',dmgNameList);
    // console.log('aaa NeWdATA: ',NeWdATA);

    let NewMeanIndexData = {}
    NewMeanIndexData["name"]="Overall";
    if(dmgNameList && dmgNameList.length>0){
      dmgNameList.forEach((dmg)=>{
    if(NeWdATA && NeWdATA.length>0){
      let sumScore = 0;
      NeWdATA.forEach((item)=>{
      sumScore+=parseFloat(item[dmg]);

      });
      sumScore/=NeWdATA.length;
      NewMeanIndexData[dmg]=sumScore;

    }
  });
}

let QuestionMeanDataList = []
QuestionMeanDataList.push(NewMeanIndexData);
if(NeWdATA && NeWdATA.length>0){
  NeWdATA.forEach((item)=>{
    QuestionMeanDataList.push(item)
  });
}


    // console.log('OverviewList: ',OverviewList);
    // console.log('NewMeanIndexData: ',NewMeanIndexData);
    // console.log('QuestionMeanDataList: ',QuestionMeanDataList);


    function getColor(data,score) {
      // console.log('data==>',data)

      if(data.name==="overall"){
        return {Color:'#ffffff',diffTextColor:'#ffffff'}

      }
      else{
        // let getIndex5 = OverviewList.findIndex(prev=>prev.name===name);
        // console.log('getIndex5: ',getIndex5);
        // console.log('OverviewList[getIndex5]): ',OverviewList[getIndex5].mean);
        // let overallMean = OverviewList && OverviewList[getIndex5] && OverviewList[getIndex5].mean?OverviewList[getIndex5].mean:0
        let diffNo = parseInt(Math.abs(parseFloat(data["overall"]) - parseFloat(score)));
        if(true){

        if(parseFloat(data["overall"]) <= parseFloat(score)){ //greater (blue)
          if(diffNo>=0 && diffNo<=2){
            return {Color:'#2a74d5',diffTextColor:'#ffffff'}

          }
          else if(diffNo>=2 && diffNo<=4){
            return {Color:'#4b9ee7',diffTextColor:'#00000'}

          }
          else{
            return {Color:'#b8d7f2',diffTextColor:'#00000'}
          }

        }
        else{ //lesse (red)
          if(diffNo>=0 && diffNo<=2){
            return {Color:'#f5babd',diffTextColor:'#000000'}

          }
          else if(diffNo>=2 && diffNo<=4){
            return {Color:'#ea95a1',diffTextColor:'#000000'}

          }
          else{
            return {Color:'#d03e4d',diffTextColor:'#ffffff'}

          }
          
        }
        }else{
          return {Color:'#ffffff',diffTextColor:'#00000'}
        }


      }

    }


    return (
      <>
      
      <div className="bg-white rounded w-full shadow-lg px-4 justify-center">
        <div className="px-1 py-4 text-base text-gray-700 font-semibold flex justify-between items-center">
          <h1 className="py-4">
          <p className="text-base text-gray-700 font-semibold">Index Mean Score <span className="text-blue-500">{currFilterName==="none"?"":"( "+currFilterName+" Wise )"}</span></p> 
          </h1> 
        </div>

        <div className="overflow-x-auto whitespace-nowrap   overflow-hidden  pb-5">
        {dmgNameList && dmgNameList.length>0?
        <table className=" border w-full">
          <thead>
                <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                  <th className="py-2 px-4 border-r font-bold">
                    <div class="flex items-center justify-left">{cardType==="overall"?"Index":(indexName==="OrganizationCore"?"Driver":"Dimension")}</div>
                  </th>
                {dmgNameList && dmgNameList.length>0?
                  dmgNameList.map((item)=>
                  <th className="py-2 px-4 border-r font-bold">
                    <div class="flex items-center justify-center">{item}</div>
                  </th>
                ):null}

                </tr>
          </thead>
          <tbody>

          {QuestionMeanDataList && QuestionMeanDataList.length>0?
            QuestionMeanDataList.map((data,index)=>
            <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
              <td className="p-3 border border-b text-left" style={{fontWeight:'bold'}}>    
                {data.name}
              </td>
              {dmgNameList && dmgNameList.length>0?dmgNameList.map((item,index1)=>
                
                <td className="p-3 border border-b text-center" style={{background:getColor(data,parseFloat(data[item])).Color,color:getColor(data,data[item]).diffTextColor}}> 
                  {data && data[item]?data[item].toFixed(2):"0"}
                </td>
                ):null}


            </tr>
            ):null}

          </tbody>
        </table>
         :<div className="text-center py-4 pb-6 text-lg text-gray-500 font-semibold">No Data Available</div>}
    </div>
                    

                    </div>
     
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexMeanOverall);
