import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuadrantChart from './Charts/QuadrantChart';
import Commentary from '../../../Index/Components/Commentary/Commentary';
import Filter from './Filter/Filter';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName:'none',
      viewScore:true,
      showDropDown1:false,
      showDropDown2:false,
      showFilter:false,
      showValue:'',
      DimensionList:[
        {
          index:1,
          name:'Happiness',
          cutOff:59,
          range:72,
          type:""
        },
        {
          index:2,
          name:'Engagement',
          cutOff:78,
          range:100,
          type:"%"

        },
        {
          index:4,
          name:'Stress',
          cutOff:28,
          range:60,
          type:"%"

        },
        {
          index:5,
          name:'Manager',
          cutOff:59,
          range:100,
          type:"%"

        },
        {
          index:6,
          name:'Leader',
          cutOff:50,
          range:100,
          type:"%"

        },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],

      selectedItem1:'',
      selectedItem2:'',

      currentSelectValue:'',
      FilterValues:[]


    }
  }
  componentDidMount() {

  }

  handleViewScore=(data)=>{
    this.setState({viewScore:data})
  }




  handleDropdown1=(check)=>{
    this.setState({showDropDown1:!check, showDropDown2:false})
  }
  handleDropdown2=(check)=>{
    this.setState({showDropDown2:!check, showDropDown1:false})
  }

  changeItem1=(item)=>{
    this.setState({selectedItem1:item})
    this.setState({showDropDown1:false, showDropDown2:false})

  }

  changeItem2=(item)=>{
    this.setState({selectedItem2:item})
    this.setState({showDropDown1:false, showDropDown2:false})

  }
  

  handleMultifilterIndexScore2=(finalFilter, name)=>{
    let {handleMultiFilterAdvancScatter}= this.props;
    this.setState({FilterValues:finalFilter});
    handleMultiFilterAdvancScatter(finalFilter,true, name);

  }


  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }


  render() {
    let { getIndexByFilterDMGData, CountRestrict, validateList,indexType,optionType,getCommentData,loading, getIndexData, getDemographicIndexData, EmpFilterData, getIndexFilterData, getDemographicIndexScatterData } = this.props;

    
    let { showDropDown1, showDropDown2, DimensionList, selectedItem1, selectedItem2, currentSelectValue, FilterValues  } = this.state;

    // let valueList=[]
    // if(getIndexData){
    //   valueList = Object.keys(getIndexData).map((key) => [ key, getIndexData[key] ]);
    //   console.log('valueList: ',valueList)
    // }


            
  // let { DimensionList} = this.state;

  // let { validateIndex } = this.props;

  // let validateList = [];
  // let validObj = Object.keys(validateIndex).map((key)=>[key,validateIndex[key]])
  // console.log('validObj: ',validObj);
  // if(validObj && validObj.length>0){
  //   validObj.forEach((item)=>{
  //     if(item[1]){
  //       let getIndex = DimensionList.findIndex(prev=>prev.index===parseInt(item[0]));
  //       if(getIndex!==-1?DimensionList[getIndex].name:""){
  //         validateList.push({
  //           "name":getIndex!==-1?DimensionList[getIndex].name:"",
  //           "template":parseInt(item[0])
  //         });
  //       }

  //     }
  //   })
  // }
  // console.log('validateList: ',validateList);


  

  let DimensionIndex = []
  if(validateList && validateList.length>0){
    validateList.forEach((valid)=>{
      if(DimensionList&&DimensionList.length>0){
        DimensionList.forEach((dimen)=>{
          if(valid.template === dimen.index){
            DimensionIndex.push(dimen);
          } 
        })
      }
    })
  }


  console.log('DimensionIndex: ',DimensionIndex)
  console.log('validateList: ',validateList)

  let Item1 = '';
  let Item2 = '';
  if(selectedItem1){
    Item1= selectedItem1
  }
  else{
    let getIndex1 = DimensionIndex.findIndex(prev=>prev.template===selectedItem1.index)
    if(getIndex1===-1){
      Item1 = DimensionIndex[0];
    }
    else{
      Item1 = DimensionIndex[getIndex1];
    }
  }


  if(selectedItem2){
    Item2= selectedItem2
  }
  else{
  let getIndex2 = DimensionIndex.findIndex(prev=>prev.template===selectedItem2.index)
  if(getIndex2===-1){
    Item2 = DimensionIndex[0];
  }
  else{
    Item2 = DimensionIndex[getIndex2];
  }

  if(Item1===Item2){

        if(getIndex2===-1){
          Item2 = DimensionIndex[1];
        }
        else{
          Item2 = DimensionIndex[getIndex2+1];
        }
  
  }
}






    console.log('---------------------------------getDemographicIndexScatterData---->',getDemographicIndexScatterData)


    
    let EmpFilterData2 = []
    if(EmpFilterData && EmpFilterData.length>0){
     EmpFilterData.forEach((emp)=>{
       if(emp.name !=='ManagerPID'){
         EmpFilterData2.push(emp);
       }
     })
    }
 
    let isDisable  = true;
    // let DistributionIndexData = [];
   //  let DemographicDataDistribution = [];
 
    if(FilterValues && FilterValues.length>0){
 
      if(isDisable){
         FilterValues.forEach((item)=>{
             if(item.value==='All'){
               isDisable = false
             }
         });
      }
 
 
     //  DistributionIndexDataScore=getIndexDriverData;
     //  DemographicDataDistribution=getIndexDistributionData;
    //  DistributionIndexData = getDistributionIndexData
 
     // getDistributionIndexData && getIndexDriverData
 
    }
 



    return (
      <>
      <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
        <Filter
          showFilter={this.state.showFilter}
          showValue={this.state.showValue}
          closeFilter={this.closeFilter}

          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexByFilterDMGData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        />
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

      </div>


      {true?
      <>
 
        <div className="xl:flex lg:flex md:flex justify-start items-center">

        {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



      </div>


      {FilterValues && FilterValues.length > 0 ?
          <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null}


    <div onClick={()=>this.closeFilter(false)}  className=" " >
      <div className="flex w-full" >

      {Item2 && Item2.name?
      <div className="bg-white rounded w-full shadow-lg px-4 m-4">
      <div className="text-lg font-semibold py-4">Scatter Plot</div>

              {Item1 && Item1.name?
              <>
              <div className="dropdown inline-block relative py-2 pl-2">
                <div onClick={()=>this.handleDropdown1(showDropDown1)} className="px-2 font-semibold text-xl py-4 cursor-pointer">{Item1.name}</div>

                {showDropDown1?
                  <ul style={{marginTop:'-50px'}}  className="dropdown-content absolute visible z-10 text-gray-700 w-full pt-1">
                    {DimensionIndex && DimensionIndex.length>0?
                      DimensionIndex.map((item)=>
                      (selectedItem2.index!==item.index)?
                      <li onClick={()=>this.changeItem1(item)} className="border border-gray-100 cursor-pointer rounded-t bg-white hover:bg-gray-100 py-2 px-4 block text-xs font-semibold ">{item.name}</li>
                      :null
                      )
                    :null}
                  </ul>
                :null}
              </div>
              <div className="dropdown inline-block relative px-1"><i className="fa fa-pencil"></i></div>
              </>:null}

              {Item2 && Item2.name?
              <>
              <div className="dropdown inline-block relative py-2 pl-2">VS</div>
              <div className="dropdown inline-block relative py-4 pl-2">
                <div onClick={()=>this.handleDropdown2(showDropDown2)} className="px-2 font-semibold text-xl py-4 cursor-pointer">{Item2.name}</div>

                {showDropDown2?
                  <ul style={{marginTop:'-50px'}} className="dropdown-content absolute visible z-10 text-gray-700 w-full pt-1">
                  {DimensionIndex && DimensionIndex.length>0?
                    DimensionIndex.map((item)=>
                    (selectedItem1.index!==item.index)?
                    <li onClick={()=>this.changeItem2(item)} className="border border-gray-100 cursor-pointer rounded-t bg-white hover:bg-gray-100 py-2 px-4 block text-xs font-semibold ">{item.name}</li>
                    :null
                    )
                  :null}
                </ul>
                :null}
              </div>
              

              <div className="dropdown inline-block relative px-1"><i className="fa fa-pencil"></i></div>
              </>
              :null}



                <div className="h-screen py-10">
                  <QuadrantChart CountRestrict={CountRestrict} getDemographicIndexData={getDemographicIndexScatterData} selectedItem1={Item1} selectedItem2={Item2} />
                  {/* <div className="px-2 py-4 text-center"><b>{"X-Axis: "}</b>{Item1.name}{", "}<b>{"Y-Axis: "}</b>{Item2.name}</div> */}
                  {Item1 && Item1.name && Item2 && Item2.name?
                    <div className="px-2 py-4 text-center"><b>{"X-Axis: "}</b>{Item1.name+"(0-"+Item1.range+Item1.type+")"}{", "}<b>{"Y-Axis: "}</b>{Item2.name+"(0-"+Item2.range+Item2.type+")"}</div>
                  :null}

                </div>

              </div>
              :
              <>
      <div className="bg-white rounded w-full shadow-lg px-4 m-4">
      <div className="text-lg font-semibold py-4">Scatter Plot</div>

        <div className='text-center text-3xl text-gray-400 py-40'>
          Not Calculated , Only one index available!  
        </div>

      </div>
      </>
      }






          </div>
        </div>
</>
:
<>
{!loading?
<div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
:null}
</>
}
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
