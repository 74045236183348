import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionScore from './Charts/QuestionScore';
import Filter from './Filter';
import Commentary from '../../../Index/Components/Commentary/Commentary';

class Strength extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none'
    }
  }
  componentDidMount() {

  }
  handleQuestionFilterValueSave = (val) => {
    this.setState({ currFilterValue: val })
  }
  handleQuestionFilterNameSave = (val) => {
    this.setState({ currFilterName: val })
    if (val === 'none') {
      this.setState({ currFilterValue: 'none' })
    }

  }
  render() {
    let { GetParameterLabel, selectedFilterValue, indexType, optionType, getCommentData, loading,

      getQuestionData, getQuestionFavorabilityData, getQuestionFilterData, getRespondantDetailsData, handleQuestionFilter, EmpFilterData, getIndexFilterData

    } = this.props;

    let QuestionData = []

    if (this.state.currFilterValue === 'none') {
      QuestionData = getQuestionData
    }
    else {
      QuestionData = getQuestionFilterData
    }


    let totalCount = 0;
    if (getRespondantDetailsData && getRespondantDetailsData.length > 0) {
      if (getRespondantDetailsData && getRespondantDetailsData[0] && getRespondantDetailsData[0].completed) {
        totalCount = getRespondantDetailsData[0].completed
      }
    }

    console.log('QuestionData--->', QuestionData)
    console.log('getQuestionData--->', getQuestionData)
    console.log('getQuestionFilterData--->', getQuestionFilterData)


    let paraList = []

    let count = 0;
    let rowSpan = 0;
    let NewParaList = []

    if (QuestionData && QuestionData.length > 0) {

      QuestionData.forEach((ques) => {
        if (paraList.findIndex(prev => prev === ques.parameterDimensionName) === -1) {
          paraList.push(ques.parameterDimensionName);
          count += 1
        }
        else {
        }
      })

      let obj = {}
      paraList.forEach((para) => {
        obj[para] = 0
        QuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1
          }
        })
      })


      NewParaList = Object.keys(obj).map((key) => [key, obj[key]])
      //   console.log('data NewParaList: ',NewParaList)

      rowSpan = (QuestionData.length / count)

      // console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,QuestionData.length)

    }
    // console.log('ORG ==== NewParaList ===== > : ',NewParaList)
    // console.log('ORG ==== QuestionData ===== > : ',QuestionData)

    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total = ques['1'] + ques['2'] + ques['3'] + ques['4'] + ques['5'] + ques['6'];
        NewQuesList.push({
          ...ques,
          score: parseFloat(((ques['5'] + ques['6']) * 100 / total).toFixed(2))
        })
      })
    }


    NewQuesList.sort((a, b) => b.score - a.score);


    // console.log('ORG ==== NewQuesList ===== > : ',NewQuesList)


    return (
      <>

        {getQuestionFavorabilityData && getQuestionFavorabilityData.length > 0 ?
          <>
            <div className=" md:flex justify-end items-center pt-3 pr-4">
              <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />
            </div>

            {/* <Filter 
        EmpFilterData = {EmpFilterData}
        getIndexFilterData = {getIndexFilterData}
        handleQuestionFilter= {handleQuestionFilter}
        handleQuestionFilterValueSave={this.handleQuestionFilterValueSave}
        handleQuestionFilterNameSave={this.handleQuestionFilterNameSave}
      />      */}
            <div className="p-4">
              <div className="bg-white rounded w-full shadow-lg p-4">

                <div className="text-lg font-semibold py-4">Bottom 10 Questions with Least Favourability Score :-</div>
                <div class="overflow-x-hidden whitespace-nowrap w-full overflow-hidden  mb-4">
                  <table class="w-full border ">
                    <thead>
                      <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                        <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Dimension</div></th>
                        <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Question</div></th>
                        <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-left">Favorability Score</div></th>
                      </tr>
                    </thead>
                    <tbody>
                      {getQuestionFavorabilityData && getQuestionFavorabilityData.length > 0 ?
                        getQuestionFavorabilityData.map((question, index) =>
                          <tr key={index} className="bg-white  mb-10 lg:mb-0 text-xs text-gray-800">
                            <td className="px-2 border border-b w-2/12 text-center">
                              <div style={{ width: '20%' }}>{(question && question._id && question._id.dimension) ? GetParameterLabel(question._id.dimension) : '-'}</div>
                            </td>
                            <td className="tooltip-response-question1 px-2 border border-b w-2/12">
                              <div className="cursor-pointer whitespace-pre-line w-96">{(question && question._id && question._id.question) ? question._id.question : 'NaN'}</div>
                              {/* <div className="tooltip-response-question2">
                                {(question && question._id && question._id.question) ? question._id.question : 'NaN'}
                              </div> */}
                            </td>
                            <td className=" border border-b w-10/12 px-2">
                              <QuestionScore question={question} totalCount={totalCount} currFilterValue={this.state.currFilterValue} currFilterName={this.state.currFilterName} selectedFilterValue={selectedFilterValue} getQuestionFilterData={getQuestionFilterData} />
                            </td>
                          </tr>
                        )
                        : null}

                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </>
          :
          <>
            {!loading ?
              <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div> : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Strength);
