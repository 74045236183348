import { campaignConstants } from '../_constants';
import { campaignService } from '../_services';
import { alertActions } from '.';
export const campaignActions = {
    createNewCampaign,
    getCampaignListBySurveyID,
    getCampaignEmployeesByID,
    getCampaignCustomerByID,
    setExpireCampaign,
    resendCampain,
    responseAnalysisByCampaignId,
    customerResponseAnalysisByCampaignId,
    resendMailInBulk,
    getReminderHistoryByCamapaignAndCompanyId,


    getAllEmployees
};


function createNewCampaign(data) {
    return dispatch => {
        dispatch(request());
        campaignService.createNewCampaign(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                    dispatch(this.getCampaignListBySurveyID({surveyId:data.surveyId ,companyId:data.companyId }));


                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.ADD_CAMPAIGN_REQUEST } }
    function success(campaign) { return { type: campaignConstants.ADD_CAMPAIGN_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.ADD_CAMPAIGN_FAILURE, error } }
}
function setExpireCampaign(data) {
    return dispatch => {
        dispatch(request());
        campaignService.setExpireCampaign(data)
            .then(
                campaign => {
                    dispatch(success(campaign));


                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.EXPIRE_CAMPAIGN_REQUEST } }
    function success(campaign) { return { type: campaignConstants.EXPIRE_CAMPAIGN_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.EXPIRE_CAMPAIGN_FAILURE, error } }
}
function resendCampain(data) {
    return dispatch => {
        dispatch(request());
        campaignService.resendCampain(data)
            .then(
                campaign => {
                    dispatch(success(campaign));


                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.RESEND_CAMPAIGN_REQUEST } }
    function success(campaign) { return { type: campaignConstants.RESEND_CAMPAIGN_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.RESEND_CAMPAIGN_FAILURE, error } }
}
function getCampaignListBySurveyID(data) {
   console.log("-----------------------------------------------------------------------------------data getCampaignListBySurveyID: ",data)
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignListBySurveyID(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.CAMPAIGN_GETALL_REQUEST } }
    function success(campaign) { return { type: campaignConstants.CAMPAIGN_GETALL_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.CAMPAIGN_GETALL_FAILURE, error } }
}

function getCampaignEmployeesByID(data) {
     return dispatch => {
         dispatch(request());
         campaignService.getCampaignEmployeesByID(data)
             .then(
                 campaign => {
                     dispatch(success(campaign));
                 },
                 error => {
                     
                     dispatch(failure(error))
                 }
             );
     };
     function request() { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_REQUEST } }
     function success(campaign) { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_SUCCESS, campaign } }
     function failure(error) { return { type: campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_FAILURE, error } }
 }

 function getCampaignCustomerByID(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getCampaignCustomerByID(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.CAMPAIGN_CUSTOMER_GETALL_REQUEST } }
    function success(campaign) { return { type: campaignConstants.CAMPAIGN_CUSTOMER_GETALL_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.CAMPAIGN_CUSTOMER_GETALL_FAILURE, error } }
}



 function responseAnalysisByCampaignId(data) {
     return dispatch => {
         dispatch(request());
         campaignService.responseAnalysisByCampaignId(data)
             .then(
                 campaign =>{
                     dispatch(success(campaign));
                 },

                 error => {
                     
                     dispatch(failure(error))
                 }
             );
     };
     function request() { return { type: campaignConstants.GET_RESPONSE_ANALYTICS_REQUEST } }
     function success(campaign) { return { type: campaignConstants.GET_RESPONSE_ANALYTICS_SUCCESS, campaign } }
     function failure(error) { return { type: campaignConstants.GET_RESPONSE_ANALYTICS_FAILURE, error } }
 }



 function customerResponseAnalysisByCampaignId (data) {
    return dispatch => {
        dispatch(request());
        campaignService.customerResponseAnalysisByCampaignId(data)
            .then(
                campaign =>{
                    dispatch(success(campaign));
                },

                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_REQUEST } }
    function success(campaign) { return { type: campaignConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_FAILURE, error } }
}


 function resendMailInBulk(data) {
    return dispatch => {
        dispatch(request());
        campaignService.resendMailInBulk(data)
            .then(
                campaign =>{
                    dispatch(success(campaign));
                },

                error => {
                    
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.RESEND_MAIL_IN_BULK_REQUEST } }
    function success(campaign) { return { type: campaignConstants.RESEND_MAIL_IN_BULK_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.RESEND_MAIL_IN_BULK_FAILURE, error } }
}

function getReminderHistoryByCamapaignAndCompanyId(data) {
     return dispatch => {
         dispatch(request());
         campaignService.getReminderHistoryByCamapaignAndCompanyId(data)
             .then(
                 campaign => {
                     dispatch(success(campaign));
                 },
                 error => {
                     
                     dispatch(failure(error))
                 }
             );
     };
     function request() { return { type: campaignConstants.GET_REMINDER_HISTORY_REQUEST } }
     function success(campaign) { return { type: campaignConstants.GET_REMINDER_HISTORY_SUCCESS, campaign } }
     function failure(error) { return { type: campaignConstants.GET_REMINDER_HISTORY_FAILURE, error } }
 }



 function getAllEmployees(data) {
    return dispatch => {
        dispatch(request());
        campaignService.getAllEmployees(data)
            .then(
                campaign => {
                    dispatch(success(campaign));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: campaignConstants.ALLEMPLOYEE_GETALL_REQUEST } }
    function success(campaign) { return { type: campaignConstants.ALLEMPLOYEE_GETALL_SUCCESS, campaign } }
    function failure(error) { return { type: campaignConstants.ALLEMPLOYEE_GETALL_FAILURE, error } }
}
 