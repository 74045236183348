export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGIN_OTP_REQUEST: 'USERS_LOGIN_OTP_REQUEST',
    LOGIN_OTP_SUCCESS: 'USERS_LOGIN_OTP_SUCCESS',
    LOGIN_OTP_FAILURE: 'USERS_LOGIN_OTP_FAILURE',
    
    LOGOUT: 'USERS_LOGOUT',

    LOGIN_SUPER_ADMIN_REQUEST: 'USERS_LOGIN_SUPER_ADMIN_REQUEST',
    LOGIN_SUPER_ADMIN_SUCCESS: 'USERS_LOGIN_SUPER_ADMIN_SUCCESS',
    LOGIN_SUPER_ADMIN_FAILURE: 'USERS_LOGIN_SUPER_ADMIN_FAILURE',


    FILE_UPLOAD_STATUS_REQUEST: 'FILE_UPLOAD_STATUS_REQUEST',
    FILE_UPLOAD_STATUS_SUCCESS: 'FILE_UPLOAD_STATUS_SUCCESS',
    FILE_UPLOAD_STATUS_FAILURE: 'FILE_UPLOAD_STATUS_FAILURE',

    LOGO_FILE_UPLOAD_STATUS_REQUEST: 'LOGO_FILE_UPLOAD_STATUS_REQUEST',
    LOGO_FILE_UPLOAD_STATUS_SUCCESS: 'LOGO_FILE_UPLOAD_STATUS_SUCCESS',
    LOGO_FILE_UPLOAD_STATUS_FAILURE: 'LOGO_FILE_UPLOAD_STATUS_FAILURE',
    
    LOGO_FILE_UPLOAD_2_STATUS_REQUEST: 'LOGO_FILE_UPLOAD_2_STATUS_REQUEST',
    LOGO_FILE_UPLOAD_2_STATUS_SUCCESS: 'LOGO_FILE_UPLOAD_2_STATUS_SUCCESS',
    LOGO_FILE_UPLOAD_2_STATUS_FAILURE: 'LOGO_FILE_UPLOAD_2_STATUS_FAILURE',
};