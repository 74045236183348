import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dashboardActions, surveyActions } from '../../_actions';
import Header from '../SurveyMgmt/Header/Header';
import Sidebar from '../SurveyMgmt/Survey/Components/Sidebar/Sidebar';
import { accessModule } from '../../_helpers';

import { userActions } from '../../_actions';
import LoadingOverlay from 'react-loading-overlay';
import { SheetJSFT } from './types';
import XLSX from 'xlsx';
import { make_cols } from './MakeColumns';
import AddFilter from './Modal/AddFilter';
import AllFilters from './Modal/AllFilters';


class Employees extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveysize:10000,

      showSurveyList:true,
      showAddNewSurvey:false,
      showEditSurvey:false,
      confirmDeleteSurvey:false,
      CurrentSurveyId:'',
      CurrentSurveyName:'',
      showChooseSurveyModal:false,
      showSurveyLibrary:false,
      isPackageSelected:false,
      CurrentSurveyData:'',
      showQuestionLibrary:'',
      searchEmployee:'',

      showEmployeeModal:false,

      EmpIndexA:0,
      EmpIndexB:6,

      currentSurveyPage:1,

      savePackage:'',
      packageList:[],
      surveyValidation:{
        "name":true,
        "desc":true
      },


      surveyFormData:{
        "name":'',
        "desc":''
      },

      showSidebar:false,


      employeesize2:6,

      showCreateSurveyPopup:false,


      
      CurrentLanguage:'en',
      CurrentLanguageName:'English',
      
      showLanguageModal:false,

      openEmpFilterModal:false,
      openEmpFilterModal2:false,


      formDataFilter: {
        "name": "",
        "label": "",
        "rank":1,
        "isDisable": false
      },
      updateformDataFilter: {
        "id":"",
        "name": "",
        "label": "",
        "rank":1
      },

      showFilterNames:false,
      employeeData:"",
      showEditDetails:false,

      employeeData:"",
      tempEmpData:""


    }
  }
  componentDidMount() {
    let data = {
      "keyWord": "",
      // "companyId": "5f9aaf951be7e064d18f6e6a",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    this.props.dispatch(surveyActions.getSurveyList(data));
    this.props.dispatch(dashboardActions.getClientProfile());

    this.props.dispatch(surveyActions.getEmployeeFilterListByCompanyId());


    let datatempemployee = {
        "keyWord": "",
        "pageNo": 1,
        "companyId": this.props.match.params.id,
        "size": this.state.employeesize2
      }
  
    this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));



    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data2));
    this.props.dispatch(surveyActions.getLanguage(data2));
  }

  handleChange = (e) => {
    const files = e.target.files;
    // console.log("files; ",files)
    if (files && files[0]) this.setState({ file: files[0] });
  };
  handleFile = () => {
    /* Boilerplate to set up FileReader */
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA: true });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      let data = XLSX.utils.sheet_to_json(ws);
      // console.log("datadatadatadata ", data);
      data = data.map(v => ({ ...v, "companyId": this.props.match.params.id }))

      let flag = 0;
      let locList = [];
      if(data && data.length>0){
        for(var i = 0 ; i < data.length ; i++){
          let value = validateEmail(data[i].email);
          console.log("emails:x:",data[i].email);

          if(value){
            //all valid
          }
          else{
            flag = flag + 1;
            locList.push(i+1);
          }
        }
      }
      function validateEmail(email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
      }
      
      if(flag){
        this.setState({isNotMail:true,isNotMailPos:locList})
      }
      else{
          /* Update state */
          let datatemp0 = {
            "keyWord": this.state.keyWord,
            "pageNo": 1,
            "size": this.state.employeesize
          }
          let Temp1 = {
            "keyWord": '',
            "pageNo": 1,
            "size": 6
          }
          this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
            this.props.dispatch(surveyActions.saveEmployeeList(this.state.data, Temp1));
          this.setState({isNotMail:false,isNotMailPos:[], showEmployeeModal:false})

          });
      }



    };

    if (rABS) {
      reader.readAsBinaryString(this.state.file);
    } else {
      reader.readAsArrayBuffer(this.state.file);
    };
  }



  onDisableEmployee = (data) => {
    console.log("data Employee: ", data);
    let tempdata = {
      "id": data.id.toString(),
      "keyWord": this.state.keyWord,
      "pageNo": this.state.employeepage,
      "size": this.state.size

    }

    console.log("asdf :: ", tempdata);
    this.props.dispatch(surveyActions.updateEmployeeStatus(tempdata));
  }


  handleSearch = (event) => {
    event.preventDefault();
    let { value } = event.target;

    this.setState({searchEmployee:value})

    // console.log("search value:",value)
    // this.setState({ keyWord: value, offset: 0 });
    // let data = {
    //   "keyWord": value,
    //   "pageNo": 1,
    //   "companyId": this.props.match.params.id,
    //   "size": this.state.size
    // }
    // this.setState({employeeData:''});

    // this.props.dispatch(surveyActions.getEmployeeList(data));
  }


  handleSearchAPI = (event) => {
    event.preventDefault();
    let { searchEmployee } = this.state;
    

    this.setState({ keyWord: searchEmployee, offset: 0 });
    

    let data = {
      "keyWord": "",
      "pageNo": 1,
      "companyId": this.props.match.params.id,
      "size": this.state.employeesize2
    }
    if(searchEmployee){
      data = {
        "keyWord": searchEmployee,
        "pageNo": 1,
        "companyId": this.props.match.params.id,
        "size": this.state.size
      }
    }


    this.setState({employeeData:''});

    this.props.dispatch(surveyActions.getEmployeeList(data));
  }


  handleADDpackage=(survey)=>{
    this.setState({showQuestionLibrary:true})

    let { packageList } = this.state;
    if(packageList.findIndex(prev=>prev === survey.id)===-1){
      packageList.push(survey.id);
      this.setState({isPackageSelected:false})
    }
    else{
      let getIndex = packageList.findIndex(prev=>prev === survey.id);
      packageList.splice(getIndex,1);
      this.setState({isPackageSelected:true})
    }
    this.setState({ packageList });
  }


  onSurveyDelete=()=>{
    let data = {
      id:this.state.CurrentSurveyId
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }
    this.props.dispatch(surveyActions.deleteSurvey(data,datatempsurvey));
    this.setState({confirmDeleteSurvey:false, CurrentSurveyId:'',CurrentSurveyName:''})
  }

  onEditSurvey=(data)=>{
    this.props.history.push(`/app/question/${data.id}`)
  }
  
  onPreviewSurvey=()=>{
    
  }
  
  onReportSurvey=(data)=>{
    this.props.history.push(`/app/surveyreport/${data.id}`)
  }
  
  onPublishSurvey=(data)=>{
    this.props.history.push(`/app/publish/${data.id}`)
  }

  handleChooseSurveyPopup=()=>{
    this.setState({showCreateSurveyPopup:false,showChooseSurveyModal:true})
  }

  handleCreateSurveyPopup=()=>{
    this.handleChooseSurveyPopupClose();
    this.setState({showCreateSurveyPopup:true})
  }

  handleCreateSurveyFromLibrary=()=>{
    this.handleChooseSurveyPopupClose();
    this.setState({showSurveyLibrary:true});


    this.props.dispatch(surveyActions.getSurveyFromLib());
  }

  handleSurveyLibraryClose=(check)=>{
    if(check){
      this.setState({CurrentSurveyData:false});
    }
    else{
      this.setState({showSurveyLibrary:false});
    }
  }


  QuestionLibraryClose=()=>{
      this.setState({CurrentSurveyData:false, savePackage:'',showQuestionLibrary:false});
   }



  handleCreateSurveyPopupClose=()=>{
    this.handleChooseSurveyPopupClose();

    let validate = {
      "name":true,
      "desc":true
    }
    let tempData={
      "name":'',
      "desc":''
    }
    this.setState({showCreateSurveyPopup:false, surveyValidation:validate, surveyFormData:tempData})
  }

  handleChooseSurveyPopupClose=()=>{
    this.setState({showChooseSurveyModal:false})
  }

  onLogout = () =>{
    console.log('logout')
    this.props.dispatch(userActions.logout());
  }


  goToDashboard = () =>{
    
    this.props.history.push('/app/dashboard');

  }

  goToGeneralDashboard = () =>{
    let SurveyId = '5fa04d316ede0f0336066cce';

    this.props.history.push('/app/generaldashboard/' + SurveyId);

  }


  onDashboard=(survey) =>{
    console.log('survey: ',survey);

    // let SurveyId = '5fa04d316ede0f0336066cce';

    if(survey && survey.templateId===1){
      this.props.history.push('/app/dashboard/' + survey.id);
    }
    else if(survey && survey.templateId===2){
      this.props.history.push('/app/dashboard/' + survey.id);
    }
    else if(survey && survey.templateId===3){
      this.props.history.push('/app/generaldashboard/' + survey.id);
    }

  }



  handleSurveyLibQuestions=(survey)=>{
    this.setState({CurrentSurveyData:survey});
  }

  handleUseSurveyPage=()=>{
    let { CurrentSurveyData } = this.state;
    console.log('CurrentSurveyData: ',CurrentSurveyData)

    let data = {
      "surveyId": "605993ff4e91a52e9f001f80",
      "surveyLib": ["60598a67ade90e2a7fbf2830","6058a75c6ba424628f903463"]
    }
  }
  onLogout = () =>{
    console.log('logout')
    this.props.dispatch(userActions.logout());
  }
  handleSuveyPackageSubmit=()=>{
    let { SurveySubmitData, packageList } = this.state;
 

    let resData = {
      "name":SurveySubmitData.name,
      "desc":SurveySubmitData.desc,
      "templateId":2
    }
    let datatempsurvey = {
      "keyWord": "",
      "pageNo": 1,
      "size": this.state.surveysize
    }

    this.props.dispatch(surveyActions.createSurvey(resData, datatempsurvey,false, packageList));

    let validate = {
       "name":true,
       "desc":true
    }
    let tempData={
       "name":'',
       "desc":''
    }
    this.setState({showSurveyLibrary:false,showCreateSurveyPopup:false,showChooseSurveyModal:false, surveyValidation:validate, surveyFormData:tempData})

}

addEmployeeModal=(value)=>{
  this.setState({showEmployeeModal:value});
}

prevEmpPage=()=>{
  let { EmpIndexA, EmpIndexB } = this.state;
  if(EmpIndexA>0){
      this.setState({EmpIndexA:EmpIndexA-1, EmpIndexB:EmpIndexB-1});
  }
}
nextEmpPage=(IndexListIndex)=>{
  let { EmpIndexA, EmpIndexB } = this.state;
  if(IndexListIndex && IndexListIndex.length && (EmpIndexB<IndexListIndex.length)){
    this.setState({EmpIndexA:EmpIndexA+1, EmpIndexB:EmpIndexB+1});
  }
}

SelectEmpPage=(val,IndexListIndex)=>{

  if(val>0 && val < (IndexListIndex && IndexListIndex.length>0?IndexListIndex.length:0)){

    this.setState({currentSurveyPage:val});
    let datatempemployee = {
      "keyWord": "",
      "pageNo": val,
      "companyId": this.props.match.params.id,
      "size": this.state.employeesize2
    }
  
  this.props.dispatch(surveyActions.getEmployeeList(datatempemployee));
  }


}

DownloadEmployees =(employeesItem, isFilter)=>{
  let list = [];
  let keyList = []
  let keyNewList = []


  if(employeesItem && employeesItem.length>0 && employeesItem[0]){
    keyList = Object.keys(employeesItem[0])
  }

  if(keyList && keyList.length>0){
    keyList.forEach((key,index)=>{
      if(key != 'id' 
      // && key != 'role' 
      && key != 'companyId' && key != 'currentStage' && key != 'isDisable' && key != 'createdAt'){
        keyNewList.push(key);
      }
    })
  }

  if(employeesItem && employeesItem.length>0){

    employeesItem.forEach((emp,index)=>{

      let data = {}

      if(keyNewList && keyNewList.length>0){
        keyNewList.forEach((keY,indey)=>{
          data[keY] = emp[keY]
        })
      }

      list.push(data)

    })


  let finalList = [];
  if(isFilter){
      let listFiltered = []
      let {getEmployeeFilterListByCompanyIdData} = this.props.survey;

      console.log('asdad: ',getEmployeeFilterListByCompanyIdData)

      if(list && list.length>0){
      list.forEach((emp)=>{
        let tempObj ={}
        tempObj["email"]=emp["email"];
        tempObj["emp_id"]=emp["emp_id"];
        if(getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length>0){
          getEmployeeFilterListByCompanyIdData.forEach((filter)=>{
            if(!filter.isDisable){
              tempObj[filter.name] = emp[filter.name] 
            }
          });
        }
        finalList.push(tempObj);

      });
      }
    }

  else{
    finalList=list;
  }
  



  let binary_univers = finalList;
    console.log('binary_univers: ',binary_univers)

  let binaryWS = XLSX.utils.json_to_sheet(binary_univers); 

  // Create a new Workbook
  var wb = XLSX.utils.book_new() 

  // Name your sheet
  XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values') 

  // export your excel
  XLSX.writeFile(wb, 'employees.xlsx');


  }

}


handleViewDetails=(emp)=>{
  this.setState({employeeData:emp})
}


sidebarToggle = () =>{
  this.setState({showSidebar:!this.state.showSidebar});

  
  // setTimeout(()=>{
  //   this.setState({showSidebar:false});
  // },4000);

}

routeLink=(link)=>{
  this.props.history.push('/app/'+link);
  this.setState({CrrRoute:link});
}


handleEmpInput=(e)=>{
  let { employeeData } = this.state;
  employeeData[e.target.name] = e.target.value;
  this.setState({employeeData});
}


handleLanguage=(item)=>{
  this.setState({CurrentLanguage:item.shortName, CurrentLanguageName:item.name, showLanguageModal:false});
}


openLanguageModal=(check)=>{
  this.setState({showLanguageModal:!check});
  setTimeout(()=>{
    this.setState({showLanguageModal:false});
  },7000);
}


  onEmployeeFilter=(getEmployeeListData)=>{
    let { openEmpFilterModal } = this.state;
    this.props.dispatch(surveyActions.getEmployeeColumnName());
    this.setState({ openEmpFilterModal: !openEmpFilterModal, openEmpFilterModal2:false });

  }

  onAddFilter=(getEmployeeListData)=>{
    let { openEmpFilterModal2 } = this.state;
    this.props.dispatch(surveyActions.getEmployeeColumnName());
    this.setState({ openEmpFilterModal2: !openEmpFilterModal2, openEmpFilterModal:false });

  }

  closeEmployeeFilter=(check)=>{
    this.setState({ openEmpFilterModal: check });
  }

  closeEmployeeFilter2=(check)=>{
    this.setState({ openEmpFilterModal2: check });
  }

  handleFilterInput=(e)=>{
    let { formDataFilter } = this.state;
    formDataFilter["label"] = e.target.value;
    this.setState({formDataFilter});
  }
  handleFilterRank=(e)=>{
    let { formDataFilter } = this.state;
    formDataFilter["rank"] = e.target.value;
    this.setState({formDataFilter});
  }
  updatehandleFilterRank=(value,emp)=>{
    // let { updateformDataFilter } = this.state;
    // updateformDataFilter["rank"] = value;
    // this.setState({updateformDataFilter});
    this.props.dispatch(surveyActions.updateEmployeeFilterRank({ id: emp.id,rank:value}));
  }

  onEmployeeFilterDropdown=(check)=>{
    this.setState({showFilterNames:check});
  }


  handleEmployeeColumnSelect=(name)=>{
    let { formDataFilter } = this.state;
    formDataFilter["name"] = name;
    formDataFilter["label"] = name;
    this.setState({formDataFilter,showFilterNames:false});
  }

  handleFilterStatus=(value)=>{
    console.log("handleFilterStatus::", value);
    this.props.dispatch(surveyActions.updateEmployeeFilterStatus({ id: value }));
  }


  handleFilterCheck = () => {
    let { formDataFilter } = this.state;
    if (formDataFilter.isDisable) {
      formDataFilter['isDisable'] = false;
    }
    else {
      formDataFilter['isDisable'] = true;
    }
    this.setState({ formDataFilter });
  }

  createEmployeeFilter=(NameItem)=>{
    // let { formDataFilter } = this.state;


    let { getEmployeeFilterListByCompanyIdData } = this.props.survey;
    let Rank = (getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length>0)?getEmployeeFilterListByCompanyIdData.length+1:1
    // console.log('Rank: ',Rank)


    if(NameItem){
      let tempData ={
        "name": NameItem ,
        "label": NameItem,
        "rank": Rank,
        "isDisable": false
      }
      this.props.dispatch(surveyActions.createEmployeeFilter(tempData));
    }

    // let tempClear ={
    //   "name": "",
    //   "label": "",
    //   "isDisable": false
    // }
    // this.setState({formDataFilter:tempClear});

    this.setState({openEmpFilterModal2:false});

  }



  onClickQuesUp = (emp1, index) => {
    let { survey } = this.props;
    let { getEmployeeFilterListByCompanyIdData } = survey;
    // console.log("working:onClickUp: ",emp1,index,'==>',surveyId)

    let emp2 = ''
    if (getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length > 0 && index) {
      emp2 = getEmployeeFilterListByCompanyIdData[index - 1].id;
    }

    if (emp1 && emp2) {
      let data = {
        idA: emp1,
        idB: emp2
      }
      console.log('data idA, IdB: ', data)
      this.props.dispatch(surveyActions.updateEmployeeFilterRank(data));
    }

  }

  onClickQuesDown = (emp1, index) => {
    let { survey } = this.props;
    let { getEmployeeFilterListByCompanyIdData } = survey;

    let emp2 = ''
    if (getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length > 0 && (index !== getEmployeeFilterListByCompanyIdData.length - 1)) {
      emp2 = getEmployeeFilterListByCompanyIdData[index + 1].id;
    }


    if (emp1 && emp2) {
      let data = {
        idA: emp1,
        idB: emp2
      }
      console.log('data idA, IdB: ', data)

      this.props.dispatch(surveyActions.updateEmployeeFilterRank(data));
    }

  }




  downLoadSampleCSV=()=>{
  
  let binary_univers = [{
    "emp_id":"001",
    "email":"sample@mail.com"
  }];
  console.log('binary_univers: ',binary_univers)

  let binaryWS = XLSX.utils.json_to_sheet(binary_univers); 

  // Create a new Workbook
  var wb = XLSX.utils.book_new() 

  // Name your sheet
  XLSX.utils.book_append_sheet(wb, binaryWS, 'Binary values') 

  // export your excel
  XLSX.writeFile(wb, 'sample employee.xlsx');
  }



  onDeleteFilter=(empFilter)=>{
    let tempData ={
      id:empFilter.id
    }
    this.props.dispatch(surveyActions.deleteEmployeeFilter(tempData));
  }



  handleEmployeeDetails=(emp)=>{
    this.setState({employeeData:emp, tempEmpData:emp});
  }

  onEditDetails=(check)=>{
    this.setState({showEditDetails:check});
  }

  handleRoleInput=(value)=>{
    let { employeeData } = this.state;
    employeeData["role"] = value;
    this.setState({employeeData});
  }

  handleTestUserInput=(value)=>{
    let { employeeData } = this.state;
    employeeData["isTest"] = value;
    this.setState({employeeData});
  }

  handleUpdateEmployeeSubmit=()=>{
    let { employeeData } = this.state;

    let data = {
      "id": employeeData.id,
      "email" : employeeData.email,
      "mobile": employeeData.mobile,
      "emp_id": employeeData.emp_id,
      "role": employeeData.role,
      "isTest": employeeData.isTest,
   }
   let tempdata = {
    "keyWord": "",
    "pageNo": 1,
    "companyId": this.props.match.params.id,
    "size": this.state.employeesize2
  }
  console.log("employeeData final: ", data)
  this.setState({showEditDetails:false});
  this.props.dispatch(surveyActions.updateEmployee(data,tempdata));
  }

  render() {
    let { survey } = this.props;
    let { loading,getEmployeeColumnNameData, getEmployeeFilterListByCompanyIdData,  getLanguageLabelData,getLanguageData,getEmployeeListData,getEmployeeListTotal,createSurveyData, getSurveyFromLibData, getSurveyListData, getSurveyListTotal, error } = survey;
    
    let {  showEditDetails,showFilterNames,formDataFilter,updateformDataFilter,openEmpFilterModal2,openEmpFilterModal,showEmployeeModal, showLanguageModal, CurrentLanguageName, CurrentLanguage,  showSidebar,CrrRoute, employeeData,savePackage, isPackageSelected, showQuestionLibrary, packageList,CurrentSurveyData, showSurveyLibrary, showChooseSurveyModal, showSurveyList,CurrentSurveyName, confirmDeleteSurvey, surveyFormData, showCreateSurveyPopup, surveyValidation } = this.state;


 
    console.log('getEmployeeColumnNameData: ',getEmployeeColumnNameData)
    console.log('getEmployeeFilterListByCompanyIdData: ',getEmployeeFilterListByCompanyIdData)


    let EmployeeFilterCompanyList = []
    if(getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length>0){
      getEmployeeFilterListByCompanyIdData.forEach((filter)=>{
        if(!filter.isDisable){
          EmployeeFilterCompanyList.push(filter);
        }
      });
    }
       //--------------------------------------------------------------------------------

       let IndexList = []
       let pageCount = 7
       let IndexListIndex = []
   
       if(getEmployeeListTotal){
       
   
        if(getEmployeeListTotal && getSurveyListData && getSurveyListData.length>0){
         [...Array(getEmployeeListTotal).keys()].forEach((item,index)=>{
           if(index % pageCount === 0){
             IndexList.push(index)
           }
           if(index===(getSurveyListData.length-1)){
             IndexList.push(index)
           }
         })
        }
        
        console.log('IndexList: ',IndexList)
   
        if(IndexList && IndexList.length>0){
         IndexList.forEach((item,index)=>{
           if(index!==(IndexList.length-1)){
             IndexListIndex.push(index+1)
           }
         })
        }
        console.log('IndexListIndex: ',IndexListIndex)

   
     }
        //--------------------------------------------------------------------------------
 



  //ROLE MANAGEMENT ----------------------------------------------

  let moduleAccess = {
    "isAdmin":false,
    "access":{}
  };
  if(accessModule()){
    moduleAccess["access"] = accessModule();
  }
  console.log('moduleAccess: ',moduleAccess)


  let SHOW_ELEMENT = false;
  let clientData = this.props.dashboard.ClientProfileData;
  if(clientData && clientData.role){

      if(clientData.role === "ADMIN"){
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if(clientData.role === "MNGR"){
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if(clientData.role === "CROLE"){
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if(clientData.role === "EMP"){
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
  }
  function accessAllow (item){
    return (moduleAccess.isAdmin?true:(moduleAccess && moduleAccess.access && moduleAccess.access[item]?true:false))
  }

  //END -------------------------------------------------------

  
  let LanguageObj = {};
  if(getLanguageLabelData && getLanguageLabelData[CurrentLanguage]){
    LanguageObj =  getLanguageLabelData[CurrentLanguage];
  }


  let { ClientProfileData } = this.props.dashboard;
  if(ClientProfileData && ClientProfileData.role && ClientProfileData.role==="MNGR"){
      this.props.history.push(`/app/manageractionplan`)
  }


  let EmployeeListData = [];
  // if(getEmployeeListData && getEmployeeListData.length>0){
  //   EmployeeListData = Object.key(getEmployeeListData)
  // }
  // console.log('getEmployeeListData: ',getEmployeeListData)
  // console.log('EmployeeListData: ',EmployeeListData)

  let EmployeeFieldList = []
  if(getEmployeeListData && getEmployeeListData.length>0){
    EmployeeFieldList =  Object.keys(getEmployeeListData[0]);
  }
  console.log('EmployeeFieldList: ',EmployeeFieldList)

  let NewEmployeeFieldList = []
  if(EmployeeFieldList && EmployeeFieldList.length>0){
      EmployeeFieldList.forEach((item)=>{
        if(item != "isDisable" && item != "isTest" && item != "createdAt" &&  item != "id" && item!="actionPlanStage"&&
        item !="companyId" && item !="email"  && item !="emp_id" ){
          NewEmployeeFieldList.push(item);      
        }
      });
    }

  


  if(EmployeeFieldList){

  }
  console.log('NewEmployeeFieldList: ',NewEmployeeFieldList)

  console.log('employeeData: ',employeeData)



  console.log('---------> IndexListIndex: ',IndexListIndex)



    return (
      <>
          <LoadingOverlay
      active={loading}
      // active={false}

      spinner
      text='Loading Data...'
      className="loader"
      >


    
          <div>
  {/*this is side bar  */}
  <Sidebar sidebarToggle={this.sidebarToggle} accessAllow={accessAllow} showSidebar={showSidebar} routeLink={this.routeLink} CrrRoute={CrrRoute}
         
         LanguageLabel={this.LanguageLabel}

         LanguageObj={LanguageObj}
         CurrentLanguage={CurrentLanguage}
  
  />

 
  <main className="lg:ml-16 h-screen ">
    {/* component */}
    <Header  onLogout={this.onLogout} CurrentLanguageName={CurrentLanguageName}
        getLanguageData={getLanguageData} 
        handleLanguage={this.handleLanguage}
        openLanguageModal={this.openLanguageModal}
        showLanguageModal={showLanguageModal} 
    />



{accessAllow("uploadEmp")?
    <>


{employeeData?
    
    
    <div className="p-4 h-full overflow-hidden overflow-y-auto bg-gray-100">
      <div className="p-6 bg-white">
      <div className="lg:flex w-full ">
        {/* first */}
        <div className="flex justify-center text-center lg:w-8/12  ">
          <div className>
            <div className="relative"><img src="img/profile/user1.png" className="lg:w-40 w-36 h-36 lg:h-40 rounded-full my-4" /><span className="material-icons absolute bottom-0 right-0 p-2 bg-pink-500 text-white rounded-full" style={{fontSize: '18px'}}>photo_camera</span> </div>
            <h1 className="text-blue-500 font-medium">{employeeData && employeeData.name?employeeData.name:"Na"}</h1>
            <p className="text-xs text-gray-500">{employeeData && employeeData.role?employeeData.role:""}</p>
            <div onClick={()=>this.onEditDetails(true)} className="flex justify-center"><button className="bg-blue-50 text-blue-500 text-xs py-2 px-6 rounded-full flex items-center border border-blue-500 focus:outline-none my-4">Edit Details <span className="material-icons pl-2" style={{fontSize: '18px'}}>edit</span></button></div>
          </div>
        </div>
        

      {showEditDetails?
        <>
        <div className="w-full md:px-6 lg:pr-4 lg:pl-8 ">
          <div className="pb-4">
            <label className="block text-xs text-gray-500 pb-2">Email</label>
            <input type="text" name="email" value={employeeData.email} onChange={this.handleEmpInput} placeholder="employee01mailinator.com" className="border block text-sm p-1 text-black w-full placeholder-black" /> 
          </div>
          {/* 3 */}
          <div className="pb-4">
            <label className="block text-xs text-gray-500 pb-2">Employee Id</label>
            <input type="text" name="emp_id" value={employeeData.emp_id} onChange={this.handleEmpInput} placeholder={194808} className="border block text-sm p-1 text-black w-full placeholder-black" /> 
          </div>
          {/* 1 */}
            <div className="pb-4 ">
            <label className="block text-xs text-gray-500 pb-2">Mobile </label>
            <input type="text" name="mobile" value={employeeData.mobile} onChange={this.handleEmpInput} placeholder="+91 XXXXXXXXXX" className="border block text-sm p-1 text-black w-full placeholder-black" /> 
          </div>
        </div>
       
        <div className="w-full md:px-6 lg:px-4">

          {/* 2 */}
          <div className="pb-4">
            <label className="block text-xs text-gray-500 pb-2">Role</label>
            <div className="flex">
              <div className=""><input checked={employeeData && employeeData.role==="ADMIN"?true:false} type="radio" onClick={()=>this.handleRoleInput("ADMIN")} onChange={()=>{}}/></div><div className="px-2 text-gray-500">ADMIN</div>
              <div className="pl-2"><input checked={employeeData && employeeData.role==="CROLE"?true:false} type="radio" onClick={()=>this.handleRoleInput("CROLE")} onChange={()=>{}}/></div><div className="px-2 text-gray-500">CROLE</div>
              <div className="pl-2"><input checked={employeeData && employeeData.role==="MNGR"?true:false} type="radio" onClick={()=>this.handleRoleInput("MNGR")} onChange={()=>{}}/></div><div className="px-2 text-gray-500">MNGR</div>
              <div className="pl-2"><input checked={employeeData && employeeData.role==="EMP"?true:false} type="radio" onClick={()=>this.handleRoleInput("EMP")} onChange={()=>{}}/></div><div className="px-2 text-gray-500">EMP</div>
            </div>
          </div>
          {/* 3 */}
          <div className="pb-4 ">
            <label className="block text-xs text-gray-500 pb-2">Test User</label>
            <div className="flex">
              <div className=""><input type="radio" checked={employeeData && employeeData.isTest?true:false} onClick={()=>this.handleTestUserInput(true)} onChange={()=>{}}/></div><div className="px-2 text-gray-500">Yes</div>
              <div className="pl-2"><input type="radio" checked={employeeData && employeeData.isTest?false:true} onClick={()=>this.handleTestUserInput(false)} onChange={()=>{}}/></div><div className="px-2 text-gray-500">No</div>
            </div>          
          </div>
        </div>
        </>
        :
        <>
        
        <div className="w-full md:px-6 lg:pr-4 lg:pl-8 ">
          {/* 1 */}
          <div className="pb-4 ">
            <label className="block text-xs text-gray-500 pb-2">Employee Name:</label>
            <input  placeholder={employeeData && employeeData.name?employeeData.name:"NaN"} disabled={true} className="bg-white block text-sm p-1 text-black w-full placeholder-black" /> 
          </div>
          {/* 2 */}
          <div className="pb-4">
            <label className="block text-xs text-gray-500 pb-2">Email:</label>
            <input  placeholder={employeeData && employeeData.email?employeeData.email:"NaN"} disabled={true} className=" bg-white block text-sm p-1 text-black w-full placeholder-black" /> 
          </div>
          {/* 3 */}
          <div className="pb-4">
            <label className="block text-xs text-gray-500 pb-2">Employee Id:</label>
            <input  placeholder={employeeData && employeeData.emp_id?employeeData.emp_id:"NaN"} disabled={true} className=" bg-white block text-sm p-1 text-black w-full placeholder-black" /> 
          </div>
          {/* 4 */}
          <div className="pb-4">
            <label className="block text-xs text-gray-500 pb-2">Mobile:</label>
            <input  placeholder={employeeData && employeeData.mobile?employeeData.mobile:"NaN"} disabled={true} className=" bg-white block text-sm p-1 text-black w-full placeholder-black" /> 
          </div>
          {/* 5 */}
          <div className="pb-4">
            <label className="block text-xs text-gray-500 pb-2">Gender:</label>
            <input  placeholder={employeeData && employeeData.Gender?employeeData.Gender:"NaN"} disabled={true} className=" bg-white block text-sm p-1 text-black w-full placeholder-black" /> 
          </div>
        </div>
        <div className="w-full md:px-6 lg:px-4">
          {/* 2 */}
          <div className="pb-4">
            <label className="block text-xs text-gray-500 pb-2">Role:</label>
            <input  placeholder={employeeData && employeeData.role?employeeData.role:"NaN"} disabled={true} className="bg-white block text-sm p-1 text-black w-full placeholder-black" /> 
          </div>
          {/* 3 */}
          <div className="pb-4 ">
            <label className="block text-xs text-gray-500 pb-2">Test User:</label>
            <input  placeholder={employeeData && employeeData.isTest?"Yes":"No"} disabled={true} className="bg-white block text-sm p-1 text-black w-full placeholder-black" /> 
          </div>
        </div>
        </>
        
      }

      </div>
      
      <div className="flex lg:justify-end justify-center mt-4 md:px-4">
        {showEditDetails?<button onClick={()=>this.handleUpdateEmployeeSubmit()} className="bg-blue-500 text-white px-6 py-2 text-xs uppercase mr-1">update</button>:null}
        {showEditDetails?<button onClick={()=>this.onEditDetails(false)} className="bg-white text-red-500 px-6 py-2 text-xs uppercase ml-1 border-2 border-red-500 font-medium ">cancel</button>:
        <button onClick={()=>this.handleEmployeeDetails("")} className="bg-white text-gray-500 px-6 py-2 text-xs uppercase ml-1 border-2 font-medium ">close</button>}
      </div>
    </div>
    </div>
      :




<div className=" py-4   px-4 h-full overflow-hidden overflow-y-auto bg-gray-100" style={{height: 'calc(100% - 4rem)'}}>
  <div className="lg:flex justify-between">
    {/* left side */}
    <div className="flex items-center">
      <h1 className="text-lg font-medium text-gray-800 ">My Employees</h1>
      <div className="flex text-blue-500 text-xs items-center ml-2 border-l border-blue-500  pl-2 ">
        <p>Home</p> <span className="material-icons" style={{fontSize: '12px'}}>navigate_next</span>
        <p>My Survey</p>
      </div>
    </div>
    {/* right side */}
    <div className="md:flex items-center lg:mt-0 mt-4 ">
      <div className="flex h-full md:mb-0 mb-4"> </div>
      <div className="flex">
        {/* search box */}
        <div className="w-full flex justify-between bg-white rounded-full text-sm items-center border overflow-hidden">
          <input type="search" name="search" onChange={this.handleSearch} placeholder={LanguageObj && LanguageObj["search_by_email_text"+"_"+CurrentLanguage]? LanguageObj["search_by_email_text"+"_"+CurrentLanguage]:"Search by email..."} className="md:w-56 w-full focus:outline-none px-4 py-2" /> 
          
          <span onClick={this.handleSearchAPI} className="cursor-pointer material-icons text-blue-500 p-2  rounded-l-full" 
          style={{fontSize: '1.4rem'}}>search</span> 
          
          </div>
      </div>
    </div>
  </div>


  
  <div className="flex flex-col  bg-white rounded-lg overflow-hidden mt-4 border">
    {/* filter and btns*/}
    <div className="md:flex justify-between p-4 border-b">
      <div className="flex space-x-4">
        <div  
        className="flex items-center text-sm font-medium  text-gray-500 relative cursor-pointer  "> 
        
        <div className="flex items-center text-sm font-medium  text-gray-500 relative cursor-pointer  " 
        onClick={()=>this.onEmployeeFilter(getEmployeeListData)} >
          
          <span className="material-icons text-gray-800 mr-2">filter_list</span>
          <p className="text-gray-800">Filters</p>
          <p className="text-white bg-blue-500 rounded-full h-5 flex inline-flex items-center w-5 justify-center mx-2">{
          EmployeeFilterCompanyList && EmployeeFilterCompanyList.length>0?EmployeeFilterCompanyList.length:0
          }</p> <span className="material-icons">arrow_drop_down</span>


          </div>
          {/* drop down */}





        {openEmpFilterModal?
              <AllFilters
              closeEmployeeFilter={this.closeEmployeeFilter} 
              onEmployeeFilterDropdown={this.onEmployeeFilterDropdown} 
              handleEmployeeColumnSelect={this.handleEmployeeColumnSelect} 
              handleFilterCheck={this.handleFilterCheck} 
              createEmployeeFilter={this.createEmployeeFilter} 
              onDeleteFilter={this.onDeleteFilter} 
              handleFilterStatus={this.handleFilterStatus} 

              showFilterNames={showFilterNames}
              formDataFilter={formDataFilter}
              getEmployeeColumnNameData={getEmployeeColumnNameData}
              EmployeeFilterCompanyList={EmployeeFilterCompanyList}
              LanguageObj={LanguageObj}
              CurrentLanguage={CurrentLanguage}
              getEmployeeFilterListByCompanyIdData={getEmployeeFilterListByCompanyIdData}
              handleFilterRank={this.handleFilterRank}
              updateformDataFilter={updateformDataFilter}
              updatehandleFilterRank={this.updatehandleFilterRank}

              onClickQuesUp={this.onClickQuesUp}
              onClickQuesDown={this.onClickQuesDown}

              />
            :
            null}



          {/* end */}
        </div>






        
        <div className="flex"> 
          <div onClick={()=>this.onAddFilter(getEmployeeListData)} className="flex items-center text-sm font-medium  text-blue-500 relative cursor-pointer  bg-blue-100 rounded-md p-2"> 
            <span className="material-icons mr-2">add</span>
              <p className="  capitalize">Add new filter</p> <span className="material-icons">arrow_drop_down</span>
            </div>



          {/* drop down */}


          {openEmpFilterModal2?
              <AddFilter
              closeEmployeeFilter={this.closeEmployeeFilter2} 
              onEmployeeFilterDropdown={this.onEmployeeFilterDropdown} 
              handleEmployeeColumnSelect={this.handleEmployeeColumnSelect} 
              handleFilterCheck={this.handleFilterCheck} 
              createEmployeeFilter={this.createEmployeeFilter} 
              onDeleteFilter={this.onDeleteFilter} 
              handleFilterStatus={this.handleFilterStatus} 

              showFilterNames={showFilterNames}
              formDataFilter={formDataFilter}
              getEmployeeColumnNameData={getEmployeeColumnNameData}
              EmployeeFilterCompanyList={EmployeeFilterCompanyList}
              LanguageObj={LanguageObj}
              CurrentLanguage={CurrentLanguage}
              getEmployeeFilterListByCompanyIdData={getEmployeeFilterListByCompanyIdData}
              handleFilterRank={this.handleFilterRank}
              updateformDataFilter={updateformDataFilter}
              updatehandleFilterRank={this.updatehandleFilterRank}

              onClickQuesUp={this.onClickQuesUp}
              onClickQuesDown={this.onClickQuesDown}

              />
            :
            null}





          {/* end */}
        </div>


      </div>
      <div className="flex space-x-4">
        {/* btn */}<span  onClick={()=>this.DownloadEmployees(getEmployeeListData, false)} className="cursor-pointer text-sm text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-md capitalize flex items-center font-medium">
          <span className="material-icons-outlined mr-2">file_download</span> export CSV </span>
        {/* btn */}<span  onClick={()=>this.addEmployeeModal(true)} className="cursor-pointer text-sm text-white bg-blue-500 hover:bg-blue-600 px-4 py-2 rounded-md capitalize flex items-center font-medium">
          <span className="material-icons-outlined mr-2">add</span> add employee</span>
      </div>
    </div>
    {/* end */}



    
    <div className=" overflow-x-auto whitespace-nowrap w-full ">
      <div className="align-middle inline-block min-w-full overflow-hidden  ">


        <table className="min-w-full">
          <thead>
            <tr>

              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 tracking-wider">{LanguageObj && LanguageObj["email_address_label"+"_"+CurrentLanguage]? LanguageObj["email_address_label"+"_"+CurrentLanguage]:"Email Address"}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 tracking-wider"> {LanguageObj && LanguageObj["emp_id_label"+"_"+CurrentLanguage]? LanguageObj["emp_id_label"+"_"+CurrentLanguage]:"Emp ID"}</th>

              {NewEmployeeFieldList && NewEmployeeFieldList.length>0?
                NewEmployeeFieldList.map((item)=>
                  (!item.isDisable)?
                  <th style={{color:EmployeeFilterCompanyList.some(prev=>prev.name === item)?"#347deb":null}} 
                  className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium font-semibold text-gray-500 tracking-wider">
                    <div className="flex">
                    <span className="px-2 py-3">{item}</span>
                    {EmployeeFilterCompanyList.some(prev=>prev.name === item)?
                    <span className="material-icons py-2" style={{height:'5px',width:'5px'}}>filter_list</span>
                    :""}
                    </div>
                    </th>
                  :null)
                :null}

              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium font-semibold text-gray-500 tracking-wider">{LanguageObj && LanguageObj["status_label"+"_"+CurrentLanguage]? LanguageObj["status_label"+"_"+CurrentLanguage]:"Status"}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium font-semibold text-gray-500 tracking-wider">{LanguageObj && LanguageObj["action_label"+"_"+CurrentLanguage]? LanguageObj["action_label"+"_"+CurrentLanguage]:"Action"}</th>
            
            </tr>
          </thead>
          <tbody className="bg-white">
          {getEmployeeListData && getEmployeeListData.length>0?
              getEmployeeListData.map((emp, index)=>
            <tr>



              
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <div className="leading-5 font-semibold text-xs">{emp.email}</div>
              </td>


              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <div className="leading-5 font-semibold text-xs">{emp.emp_id}</div>
              </td>



              {NewEmployeeFieldList && NewEmployeeFieldList.length>0?
              NewEmployeeFieldList.map((item)=>
              (!item.isDisable)?
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <div className="leading-5 font-semibold text-xs">{emp && emp[item]?emp[item]:"------"}</div>
                </td>
              :null)
            :null}



            <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center">
              <span className="px-2 inline-flex text-xs leading-5 font-medium rounded-full bg-green-100 text-green-800">
                {emp.isDisable?"Inactive":"Active"}</span>
            </td>



              <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center">
                <span onClick={()=>this.handleEmployeeDetails(emp)} className="cursor-pointer material-icons hover:bg-blue-100 p-2 rounded-md hover:text-blue-500 text-gray-500" style={{fontSize: '16px'}}>edit</span>

              </td>


            </tr>
            ):null
          }







          </tbody>
        </table>
      </div>
    </div>
  </div>
  {/*end  */}
  {/* page no 1 2 3  */}



  <div className="bg-white h-16 w-full flex items-center justify-center mt-2">
    <div className="flex items-center"> 
    
    <span onClick={()=>this.SelectEmpPage(this.state.currentSurveyPage-1,IndexListIndex)} className="cursor-pointer material-icons bg-gray-100 hover:bg-blue-500 hover:text-white text-gray-500 p-2 rounded-full">chevron_left</span>
     
     <div className="flex items-center mx-4 text-gray-500 text-sm font-medium">
        <p>{this.state.currentSurveyPage}</p>
        {/* <p className="px-0.5">-</p>
        <p>10</p> */}
        <p className="px-1">of</p>
        <p>{IndexListIndex && IndexListIndex.length>0? IndexListIndex.length:0}</p>
      </div> 


    <span onClick={()=>this.SelectEmpPage(this.state.currentSurveyPage+1,IndexListIndex) } className="cursor-pointer material-icons bg-gray-100 hover:bg-blue-500 hover:text-white text-gray-500 p-2 rounded-full">chevron_right</span>
      
      </div>
  </div>



  {/* end */}
</div>


}







{showEmployeeModal?
    <div className="top-0 xl:h-screen h-full  w-full flex justify-center items-center z-40 fixed" style={{background: '#0000005c'}}>
        <div className="bg-white rounded-lg  shadow-sm  xl:w-5/12  lg:w-6/12 md:w-8/12 w-11/12">
          <div className="flex justify-between p-4 border-b"> <h2 className="text-base font-semibold text-center test-gray-800 ">Upload Employees</h2> <span onClick={()=>this.addEmployeeModal(false)} className="cursor-pointer text-sm font-medium text-red-500">Close</span></div>
          <div className="m-4 mt-2">
            <div className="flex pb-2">
              <div className="text-gray-500">
                <span className="font-semibold cursor-pointer">Mandatory fields* : </span>
                <span className="text-red-500">emp_id, email</span>
              </div>
            </div>

            <div className="bg-gray-50 border-dotted border-2 text-center py-4 text-xs text-gray-500 rounded-md"> <span className="material-icons text-blue-500" style={{fontSize: '42px'}}>cloud_upload</span>

              <div className="">
                <input type="file" className="ml-12 py-1.5 rounded-md focus:outline-none font-medium"
                 id="file" accept={SheetJSFT} onChange={this.handleChange} />
              </div>
            </div>
          </div>
          {/* progrees line */}
          {/* <div className="mx-4 h-1 rounded-full w-2/6 bg-blue-500" /> */}
          {/* upload details */}
          <div className="flex justify-between py-4 pr-4">
            <div className="text-xs ">
              {/* <p className="font-semibold text-gray-800">Tata Employees.pdf</p>
              <div className="flex"><p>2.5 MB</p> <p>/ 1.10 MB</p></div> */}
            </div>
            <button onClick={()=>this.downLoadSampleCSV()} className="mr-72 border border-green-500 rounded-md px-6 py-1.5 text-xs text-green-500 focus:outline-none" >Sample CSV</button>

            <button  onClick={this.handleFile} className="ml-22 border border-blue-500 rounded-md px-6 py-1.5 text-xs text-blue-500 focus:outline-none">Continue</button>
          </div>
          {/*  */}
        </div>
    </div>
:
null}



{/* EMPLOYEE FILTER */}
{/* {openEmpFilterModal?
  <AddFilter
  closeEmployeeFilter={this.closeEmployeeFilter} 
  onEmployeeFilterDropdown={this.onEmployeeFilterDropdown} 
  handleEmployeeColumnSelect={this.handleEmployeeColumnSelect} 
  handleFilterCheck={this.handleFilterCheck} 
  createEmployeeFilter={this.createEmployeeFilter} 
  onDeleteFilter={this.onDeleteFilter} 
  handleFilterStatus={this.handleFilterStatus} 

  showFilterNames={showFilterNames}
  formDataFilter={formDataFilter}
  getEmployeeColumnNameData={getEmployeeColumnNameData}
  EmployeeFilterCompanyList={EmployeeFilterCompanyList}
  LanguageObj={LanguageObj}
  CurrentLanguage={CurrentLanguage}
  getEmployeeFilterListByCompanyIdData={getEmployeeFilterListByCompanyIdData}
  handleFilterRank={this.handleFilterRank}
  updateformDataFilter={updateformDataFilter}
  updatehandleFilterRank={this.updatehandleFilterRank}

  onClickQuesUp={this.onClickQuesUp}
  onClickQuesDown={this.onClickQuesDown}

  />
:
null} */}

</>
:
<>
{!loading?

<div className="container py-12 mx-auto px-4 h-full overflow-hidden overflow-y-auto" style={{height: 'calc(100% - 4rem)'}}>
<div className="w-full h-full flex justify-center items-center">
  {/*  text start*/}
  <div className="text-center">
    <img src="img/empty_survey.png" className="mb-4" />
    <h1 className="text-gray-500 text-xl  font-semibold cursor-default">You have no access <br/> to this module!</h1>
  </div>
</div>
</div>
:null}
</>
}





  </main>
</div>

    </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, dashboard } = state;
  return {
    loggingIn,
    survey,
    dashboard
  };
}
export default connect(mapStateToProps)(Employees);
