import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class HeatMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {

    let { CountRestrict, currFilterValue, currFilterName, getDistributionIndexData, getIndexDriverData, GetParameterLabel } = this.props;

    console.log('getDistributionIndexData: ', getDistributionIndexData)

    let YAxis = []
    let XAxis = ['Time', 'Being', 'Relationship', 'Worklife', 'Achievement', 'Meaning']
    let XAxisName = ['TimeAvg', 'BeingAvg', 'RelationshipAvg', 'WorkLifeAvg', 'AchivementAvg', 'MeeningfullAvg']
    let DataValues = []
    let list = []
    let overallData = {}


    if (getIndexDriverData) {
      overallData = {
        TimeAvg: getIndexDriverData.TimeCount,
        BeingAvg: getIndexDriverData.BeingCount,
        RelationshipAvg: getIndexDriverData.RelationshipCount,
        WorkLifeAvg: getIndexDriverData.WorkLifeCount,
        AchivementAvg: getIndexDriverData.AchivementCount,
        MeeningfullAvg: getIndexDriverData.MeeningfullCount,
        name: "OVERALL"
      }

      if (list.findIndex(prev => prev.name === "OVERALL") === -1) {
        list.push(overallData)
      }

    }



    if (getDistributionIndexData && getDistributionIndexData.length > 0) {

      getDistributionIndexData.forEach((hello) => {
        list.push(hello);
      });




      list.forEach((item, index) => {
        XAxisName.forEach((axis, axisIndex) => {

          if (index !== (list.length - 1)) {
            DataValues.push({
              value: [axisIndex, index, parseFloat(item[axis]).toFixed(2)],
              itemStyle: {
                color:
                  (parseFloat(overallData[axis]) > parseFloat(item[axis])) ?
                    (parseFloat(item[axis]) === 0) ? '#ffb3b3' : 'rgba(230, 0, 0,' + parseFloat(item[axis]) / 100 + ')'
                    :
                    (parseFloat(item[axis]) === 0) ? '#c2f0c2' : 'rgba(46, 184, 46,' + parseFloat(item[axis]) / 100 + ')'
              }
            })
          }
          else {
            DataValues.push({
              value: [axisIndex, index, parseFloat(item[axis]).toFixed(2)],
              itemStyle: { color: (parseFloat(item[axis]) === 0) ? '#b3ecff' : 'rgba(0, 172, 230,' + parseFloat(item[axis]) / 100 + ')' }
            })
          }

        })
      })



    }


    if (list && list.length > 0) {
      list.forEach((driver) => {
        YAxis.push(driver.name)
      })
    }

    // console.log('XAxis: ',XAxis)
    // console.log('YAxis: ',YAxis)
    // console.log('DataValues: ',DataValues)
    console.log('list: ', list)


    // list.reverse()


    // function getColor(data, index) {
    //   let Color = '';
    //   if(data.name==='OVERALL'){
    //     return Color = '#bdddf2'
    //   }
    //   else{
    //     if(overallData[index] <= data[index]){
    //       return Color = '#b2edc9'
    //     }
    //     else if(overallData[index] === data[index]){
    //       return Color = 'yellow'
    //     }
    //     else{
    //       return Color = '#f2bdbd'
    //     }
    //   }

    // }


    function showNaN (item,name){
      let dataScore = CountRestrict(item && item.count?item.count:"NaN", item.name==='OVERALL')?"NaN":( name && item && item[name]? (item[name]).toFixed(2):"NaN" );
      return dataScore
    }


    
    function getColor(data, index) {

      if(data.name==="OVERALL" || showNaN(data, index)==="NaN"){
        return { Color: '#f1f1f1', diffTextColor: '#000' }

      }
      else {

        let diffNo = parseInt(Math.abs(parseFloat(overallData[index]) - parseFloat(data[index])));
        // console.log("diffNo:", diffNo)
        if (parseFloat(overallData[index]) <= parseFloat(data[index])) { //greater (blue)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#33cc33', diffTextColor: '#ffffff' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#70db70', diffTextColor: '#00000' }

          }
          else {
            return { Color: '#adebad', diffTextColor: '#00000' }
          }

        }
        else { //lesse (red)
          if (diffNo >= 0 && diffNo <= 2) {
            return { Color: '#f5babd', diffTextColor: '#000000' }

          }
          else if (diffNo >= 2 && diffNo <= 12) {
            return { Color: '#ea95a1', diffTextColor: '#000000' }

          }
          else {
            return { Color: '#d03e4d', diffTextColor: '#ffffff' }

          }
        }
      }

    }



    let { FilterValues } = this.props;


    console.log('list=========>',list)






    return (
      <>
        {!(FilterValues && FilterValues.length > 0) ?
          <>
            {list && list.length > 0 ?
              <div className="py-1  whitespace-nowrap overflow-x-hidden py-10 w-full mx-2">
                <table className="border-collapse w-full ">
                  <thead>
                    <tr className="text-xs">
                      <th className="p-3 font-semibold  text-gray-800">{GetParameterLabel("Time")}</th>
                      <th className="p-3 font-semibold  text-gray-800">{GetParameterLabel("Being")}</th>
                      <th className="p-3 font-semibold  text-gray-800">{GetParameterLabel("Relationship")}</th>
                      <th className="p-3 font-semibold  text-gray-800">{GetParameterLabel("WorkLife")}</th>
                      <th className="p-3 font-semibold  text-gray-800">{GetParameterLabel("Achivement")}</th>
                      <th className="p-3 font-semibold  text-gray-800">{GetParameterLabel("Meeningfull")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {list && list.length > 0 ?
                      list.map((item, index) =>
                        index === 0 ?
                          <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">

                            {item.name !== 'OVERALL' ?
                              <td className="p-3 border border-b text-left" style={{ fontWeight: (item.name === 'OVERALL') ? 'bold' : null }}>
                                {(item.name) ? item.name : 'Unavailable'}
                              </td>
                              : null}

                            <td className="p-3 border border-b text-xl font-bold text-gray-700" style={{ background: getColor(item, 'TimeAvg') }}>
                              {(item.TimeAvg) ? item.TimeAvg.toFixed(2) : "NaN"}
                            </td>
                            <td className="p-3 border border-b text-xl font-bold text-gray-700" style={{ background: getColor(item, 'BeingAvg') }}>
                              <span className="py-1 px-3">{(item.BeingAvg) ?  item["BeingAvg"].toFixed(2) : 'NaN'}</span>
                            </td>
                            <td className="p-3 border border-b text-xl font-bold text-gray-700" style={{ background: getColor(item, 'RelationshipAvg') }}>
                              <span className="py-1 px-3">{(item.RelationshipAvg) ? item.RelationshipAvg.toFixed(2) : 'NaN'}</span>
                            </td>
                            <td className="p-3 border border-b text-xl font-bold text-gray-700" style={{ background: getColor(item, 'WorkLifeAvg') }}>
                              <span className="py-1 px-3 ">{(item.WorkLifeAvg) ? item.WorkLifeAvg.toFixed(2) : 'NaN'}</span>
                            </td>
                            <td className="p-3 border border-b text-xl font-bold text-gray-700" style={{ background: getColor(item, 'AchivementAvg') }}>
                              <span className="py-1 px-3">{(item.AchivementAvg) ? item.AchivementAvg.toFixed(2) : 'NaN'}</span>
                            </td>
                            <td className="p-3 border border-b text-xl font-bold text-gray-700" style={{ background: getColor(item, 'MeeningfullAvg') }}>
                              <span className="py-1 px-3">{(item.MeeningfullAvg) ? item.MeeningfullAvg.toFixed(2) : 'NaN'}</span>
                            </td>
                          </tr>
                          : null
                      ) : null}

                  </tbody>
                </table>
              </div>
              : null}
          </>
          :
          <>
            {list && list.length > 0 ?
              <div className="overflow-x-auto whitespace-nowrap w-full overflow-hidden  ">
                <table className="w-full border ">
                  <thead>
                    {list && list.length > 0 ?
                      list.map((item, index) =>
                        (index === 0) ?
                          <tr className="bg-gray-100 border-b text-xs   text-gray-800">
                            <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-left">{currFilterName}</div></th>
                            <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{GetParameterLabel("Time")} </div></th>
                            <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{GetParameterLabel("Being")} </div></th>
                            <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{GetParameterLabel("Relationship")} </div></th>
                            <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{GetParameterLabel("WorkLife")} </div></th>
                            <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{GetParameterLabel("Achivement")} </div></th>
                            <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{GetParameterLabel("Meeningfull")} </div></th>
                          </tr>
                          : null
                      ) : null}
                  </thead>
                  <tbody>
                    {list && list.length > 0 ?
                      list.map((item, index) =>
                        index !== 0 || true ?
                          <tr className="bg-white  mb-10 lg:mb-0 text-xs text-center text-gray-800">
                            <td className="p-3 border border-b text-left" style={{ fontWeight: (item.name === 'OVERALL') ? 'bold' : null }}>
                              {(item.name) ? item.name : 'Unavailable'}
                            </td>
                            <td className="p-3 border border-b" style={{ background: getColor(item, 'TimeAvg').Color, color: getColor(item, 'TimeAvg').diffTextColor }}>
                              {(item.TimeAvg) ?showNaN(item,"TimeAvg") : "NaN"}
                            </td>
                            <td className="p-3 border border-b" style={{ background: getColor(item, 'BeingAvg').Color, color: getColor(item, 'BeingAvg').diffTextColor }}>
                              <span className="py-1 px-3">{(item.BeingAvg) ? showNaN(item,"BeingAvg") : 'NaN'}</span>
                            </td>
                            <td className="p-3 border border-b" style={{ background: getColor(item, 'RelationshipAvg').Color, color: getColor(item, 'RelationshipAvg').diffTextColor }}>
                              <span className="py-1 px-3">{(item.RelationshipAvg) ? showNaN(item,"RelationshipAvg") : 'NaN'}</span>
                            </td>
                            <td className="p-3 border border-b" style={{ background: getColor(item, 'WorkLifeAvg').Color, color: getColor(item, 'WorkLifeAvg').diffTextColor }}>
                              <span className="py-1 px-3 ">{(item.WorkLifeAvg) ? showNaN(item,"WorkLifeAvg") : 'NaN'}</span>
                            </td>
                            <td className="p-3 border border-b" style={{ background: getColor(item, 'AchivementAvg').Color, color: getColor(item, 'AchivementAvg').diffTextColor }}>
                              <span className="py-1 px-3">{(item.AchivementAvg) ? showNaN(item,"AchivementAvg") : 'NaN'}</span>
                            </td>
                            <td className="p-3 border border-b" style={{ background: getColor(item, 'MeeningfullAvg').Color, color: getColor(item, 'MeeningfullAvg').diffTextColor }}>
                              <span className="py-1 px-3">{(item.MeeningfullAvg) ? showNaN(item,"MeeningfullAvg")  : 'NaN'}</span>
                            </td>
                          </tr>
                          : null
                      ) : null}

                  </tbody>
                </table>
                <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>

              </div>
              : null}
          </>
        }

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(HeatMap);
