import React, { Component } from 'react';
import { connect } from 'react-redux';

class Filter extends Component {
  constructor(props) {
    super(props);
    this.state = {
        // showFilter:false,
        // showValue:'',
        currFilterList:[],
        finalFilter:[],
        searchName:''

    }
  }
  componentDidMount() {

  }


  handleParameterQuestion=()=>{

  }

  handleSelectFilter=(name,InitFilter)=>{
    // this.setState({showFilter:false});
    this.props.closeFilter(false);


    let {currFilterList,finalFilter}= this.state;
    let { handleMultifilterIndexScore } = this.props;
    let getIndex = currFilterList.findIndex(prev=>prev===name)
    if(getIndex===-1){
        currFilterList.push(name);
        finalFilter.push({
            "level":name,
            "value":"All"
        });  

        this.setState({currFilterList,finalFilter},()=>{
            handleMultifilterIndexScore(finalFilter,name); 
        });
    }
    else{
        currFilterList.splice(getIndex,1);
        let getIndex2 = finalFilter.findIndex(prev=>prev.level===name);
        finalFilter.splice(getIndex2,1);

        this.setState({currFilterList,finalFilter},()=>{
            if(finalFilter && finalFilter.length>0){
                handleMultifilterIndexScore(finalFilter,finalFilter[finalFilter.length-1].level); 
            }
            else{
                handleMultifilterIndexScore([]); 
                // this.setState({showFilter:false})
                this.props.closeFilter(false);

            }





            if(!(currFilterList && currFilterList.length>0)){
              handleMultifilterIndexScore([],InitFilter); 
            }





        });
    }




  }



  handleOpenFilter=(check)=>{
    // this.setState({showFilter:!check,showValue:''});
    this.props.closeFilter(!check,'');

    }


    handleOpenValue=(name)=>{
        if(this.state.showValue===name){
            // this.setState({showValue:'',showFilter:false})
            this.props.closeFilter(false,'');
        }
        else{
            // this.setState({showValue:name,showFilter:false})
            this.props.closeFilter(false,name);
        }
    }


    handleSelectValue=(value,name)=>{
        let { finalFilter, currFilterList } = this.state;

        let getIndex =  finalFilter.findIndex(prev=>prev.level===name);

        if(getIndex===-1){

          if(!(currFilterList && currFilterList.length>0)){
            currFilterList.push(name)
          }

            finalFilter.push({
                "level":name,
                "value":value
            });      

        }
        else{

            let getIndex2 =  finalFilter.findIndex(prev=>prev.value===value);
            if(getIndex2===-1){
                finalFilter[getIndex].value=value;
            }
            else if(value==="All"){
                finalFilter[getIndex].value="All";
            }

        }
        let { handleMultifilterIndexScore } = this.props;
        this.props.closeFilter(false,'');
        this.setState({finalFilter},()=>{
            handleMultifilterIndexScore(finalFilter,name);
        })
    }



    // handleFilterClear=(InitFilter)=>{
    // let { handleMultifilterIndexScore } = this.props;
    // this.setState({finalFilter:[],currFilterList:[]},()=>{
    //     handleMultifilterIndexScore([],InitFilter);
    // })
    // }




    
    handleFilterClear=(InitFilter)=>{
      let { currFilterList, finalFilter } = this.state;
    let { handleMultifilterIndexScore } = this.props;

    if(finalFilter && finalFilter.length>0){
      finalFilter.forEach((item,index)=>{
        if(item.value!=="All"){
          finalFilter[index].value="All";
        }
      });
    }


    this.setState({currFilterList,finalFilter},()=>{
      handleMultifilterIndexScore(finalFilter,finalFilter[finalFilter.length-1].level); 
    });

    }









    handleSelectAllFilter=()=>{

    }

    handleSearch=(e)=>{
      this.setState({searchName:e.target.value});
    }

  render() {

    const ref = React.createRef();
    let { EmpFilterData2, getIndexFilterData , showValue,showFilter} = this.props;
    let { searchName, currFilterList, finalFilter } = this.state;

    let DemographicValueList = {};
    if(getIndexFilterData){
        DemographicValueList = getIndexFilterData;
        // DemographicValueList.sort();
    }



    console.log('EmpFilterData2: ',EmpFilterData2)
    console.log('currFilterList: ',currFilterList)
    console.log('DemographicValueList: ',DemographicValueList)
    console.log('finalFilter: ',finalFilter)



    function selectedValue (item){
        let Value = '';
        let getIndex = finalFilter.findIndex(prev=>prev.level===item);
        if(getIndex!==-1){
            if(finalFilter && finalFilter[getIndex] && finalFilter[getIndex].value){
                Value = finalFilter[getIndex].value;
            }
        }
        return Value;
    }


    function SortList(list){
      let temp = [];
      if(list && list.length>0){
        temp= list.sort()
      }
      return temp;
    }


    let InitFilter = "";

    if(EmpFilterData2 && EmpFilterData2.length>0){
      
      if(EmpFilterData2 && EmpFilterData2[0] && EmpFilterData2[0].name){
        InitFilter = EmpFilterData2[0].name;
      }


    }



    let currFilterListNew = []
    if(currFilterList && currFilterList.length>0){
      currFilterList.forEach((item)=>{
        currFilterListNew.push(item);
      });
    }
    else{
      currFilterListNew.push(InitFilter);

    }
    


    console.log('EmpFilterData2: ',EmpFilterData2)




    return (
      <>
        <div className="flex ">
          {/* 1 */}
          <div className="cursor-pointer flex items-center text-xs font-medium mr-6 text-gray-500 relative">
            <span className="material-icons text-gray-400 mr-2" style={{fontSize: '18px'}}  onClick={()=>this.handleOpenFilter(showFilter)}>filter_list</span><p  onClick={()=>this.handleOpenFilter(showFilter)} className="text-gray-800">Filters</p> 
            <p  onClick={()=>this.handleOpenFilter(showFilter)} className="text-white bg-blue-500 rounded-full h-5 flex inline-flex items-center w-5 justify-center mx-2">
            {currFilterListNew && currFilterListNew.length>0?currFilterListNew.length:0}
            </p> <span  onClick={()=>this.handleOpenFilter(showFilter)} className="material-icons">arrow_drop_down</span>
            {showFilter?
            <div className="z-10 absolute left-0 top-8 bg-white shadow-lg p-3 font-normal font-xs w-48" id="chatbot" style={{overflowY:'auto',height:'400px'}}>
              {EmpFilterData2 && EmpFilterData2.length>0?
                EmpFilterData2.map((dmg,index)=>
                <div key={index}  className="py-2 m-1 flex items-center">
                    <input type="checkbox" checked={currFilterListNew.some(prev=>prev===dmg.name)?true:false} onClick={()=>this.handleSelectFilter(dmg.name,InitFilter)} 
                    onChange={()=>{}}/><label  onClick={()=>this.handleSelectFilter(dmg.name,InitFilter)} className="ml-2">{dmg.label}</label> 
                </div>
                ):null}
              </div>
             :null}
          </div>
          {/* 2 */}



        {currFilterListNew && currFilterListNew.length>0?
        currFilterListNew.map((item)=>
          <div className="cursor-pointer flex items-center text-xs font-medium mr-6 text-gray-500 relative">
            <p onClick={()=>this. handleOpenValue(item)} className="mr-2">{
            (selectedValue(item)?selectedValue(item)==="All"?item+" ("+selectedValue(item)+")":selectedValue(item):item+' (All)')
            }</p>   <span onClick={()=>this. handleOpenValue(item)} className="material-icons">arrow_drop_down</span>

            {showValue===item?
            <>
            <div className="z-10 absolute left-0 top-8 bg-white shadow-lg p-3 font-normal font-xs w-48" id="chatbot" style={{overflowY:'auto',height:'400px'}}>
              <input type="text" name="searchName" value={searchName} onChange={this.handleSearch} placeholder="Search" className="w-full border p-1.5 rounded-sm focus:outline-none text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1" />
              {/* <div className="py-2 m-1 flex items-center"><input type="checkbox"  checked={finalFilter.some(prev=>(prev.level === item)&&(prev.value === "All"))?true:false} onClick={()=>this.handleSelectValue('All',item)}  /><label className="ml-2">Select all</label> </div> */}
              <div className="py-2 m-1 flex items-center"><input type="checkbox" onClick={()=>this.handleSelectValue("All",item)} onChange={()=>{}}/>
                <label onClick={()=>this.handleSelectValue("All",item)} className="ml-2">{"All"}</label> 
              </div>
              {SortList(DemographicValueList[item]) &&  DemographicValueList && DemographicValueList[item] && DemographicValueList[item].length>0?
                SortList(DemographicValueList[item]).map((value,index)=>
                value && value.match(new RegExp(`${searchName}`, 'gi'))?
                <div className="py-2 m-1 flex items-center"><input type="checkbox" checked={finalFilter.some(prev=>prev.value === value)?true:false} onClick={()=>this.handleSelectValue(value,item)} onChange={()=>{}}/><label onClick={()=>this.handleSelectValue(value,item)} className="ml-2">{value}</label> </div>
                :null)
              :null}
            </div>
            </>
            :null}

          </div>
        ):null}


    {currFilterListNew && currFilterListNew.length>0 && finalFilter && finalFilter.length>0?
        <div className="cursor-pointer flex items-center text-xs font-medium mr-6 text-gray-500 relative">
          <p className="mr-2 text-white bg-green-400 px-2 py-1 rounded-sm" onClick={()=>this.handleFilterClear()} >{"Clear All"}</p>  
      </div>
    :null}


        </div>
        
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Filter);
