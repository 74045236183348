import React from "react";
import ParameterView from '../Parameter/ParameterView';

export default function AddSurvey(props) {
  let { GetParameterLabel, showIndexFilter,handleOpenIndexFilter,selectedIndexFilterValue,handleSelectIndexFilter,SubmitOfQuesLibList,onSelectQLQue,handleQuestionLibraryModalClose, getQuestionLibraryListData, QuestionLibraryFinalList } = props;


console.log('getQuestionLibraryListData: ',getQuestionLibraryListData)


let ParameterBucket = [];
if(getQuestionLibraryListData && getQuestionLibraryListData.length>0){
    getQuestionLibraryListData.forEach((ques)=>{
        if(ques && ques.parameterBucketId && ques.parameterBucketId.id){
            let getIndex = ParameterBucket.findIndex(prev=>prev.id===ques.parameterBucketId.id)
            if(getIndex===-1){
                ParameterBucket.push({
                    "name":ques.parameterBucketId.name,
                    "id":ques.parameterBucketId.id
                });
            }

        }
    });
}

console.log('ParameterBucket: ',ParameterBucket);




let QuestionList = []
if(getQuestionLibraryListData && getQuestionLibraryListData.length>0){
    if(selectedIndexFilterValue && selectedIndexFilterValue.id){
        getQuestionLibraryListData.forEach((ques)=>{
            if(ques && ques.parameterBucketId && ques.parameterBucketId.id && selectedIndexFilterValue && (selectedIndexFilterValue.id===ques.parameterBucketId.id)){
                QuestionList.push(ques);
            }
        });
    }
    else{
        QuestionList = getQuestionLibraryListData;
    }

}


function getBucketName(ques){
    if(ques && ques.parameterBucketId && ques.parameterBucketId.name){
        return ques.parameterBucketId.name
    }
    else return ""
}



  return (
   <>
        <div className="top-0 xl:h-screen h-full  w-full flex justify-center items-center z-40 fixed" style={{background: '#0000005c'}}>
  <div className="bg-white rounded-lg  shadow-sm  xl:w-7/12 md:w-9/12 w-11/12">
    <div className="flex justify-between p-4 border-b"> <h2 className="flex items-center text-base font-semibold text-center test-gray-800 capitalize">question library <span className="material-icons ml-2">help_outline</span> </h2> <span onClick={()=>handleQuestionLibraryModalClose()} className="cursor-pointer font-medium text-gray-500 material-icons hover:bg-red-500 rounded-full p-1 hover:text-white" style={{fontSize: '16px'}}>close</span></div>
    <div className="flex">
      <div className="capitalize md:text-sm text-xs font-medium   gap-2  md:w-56 w-48  h-96 overflow-y-auto overflow-hidden border-r quzlibrarysroll">
        
      <span onClick={()=>handleSelectIndexFilter("",true)} className={
            !(selectedIndexFilterValue && selectedIndexFilterValue.id)?
            "px-4 block py-3 cursor-pointer  hover:bg-blue-500 hover:bg-blue-500 hover:text-white bg-blue-500 text-white":
            "px-4 block py-3 cursor-pointer  hover:bg-blue-300 hover:text-white"
            }
            >
            {"All Questions"}
            </span>


      {ParameterBucket && ParameterBucket.length>0?
        ParameterBucket.map((para,index)=>
            <span onClick={()=>handleSelectIndexFilter(para,false)} className={
            selectedIndexFilterValue && selectedIndexFilterValue.id && selectedIndexFilterValue.id===para.id?
            "px-4 py-3 block cursor-pointer  hover:bg-blue-500 hover:bg-blue-500 hover:text-white bg-blue-500 text-white":
            "px-4 py-3 block cursor-pointer  hover:bg-blue-300 hover:text-white"
            }
            >
            {GetParameterLabel(para.name)}
            </span>
        ):null}
        
        {/* <span className="px-4 py-3   hover:bg-blue-500 hover:text-white">Engagement Index</span>
        <span className="px-4 py-3    hover:bg-blue-500 hover:text-white">Engagement Index</span>
        <span className="px-4 py-3  hover:bg-blue-500 hover:text-white">Engagement Index</span>
        <span className="px-4 py-3   hover:bg-blue-500 hover:text-white">Engagement Index</span>
        <span className="px-4 py-3   hover:bg-blue-500 hover:text-white">Engagement Index</span>
        <span className="px-4 py-3   hover:bg-blue-500 hover:text-white">Engagement Index</span>
        <span className="px-4 py-3   hover:bg-blue-500 hover:text-white">Engagement Index</span> */}
      </div>
      {/*quz */}
      <div className="p-4 overflow-y-auto h-96 text-xs w-full">
        
      {QuestionList && QuestionList.length>0?
        QuestionList.map((ques)=>
        <div className=" py-4 border-b mb-4 ">   <p className="text-sm font-semibold">
           {ques.name}
          </p>
          {/* options */}
          <div className="my-2 relative">
            {ques&&ques.optionsList&&ques.optionsList.length>0?
            ques.optionsList.map((opt)=>
            <div className="flex items-center py-2"><input type="checkbox" name 
            className="mr-2 rounded-full" /> <label>{opt.name}</label> </div>
            ):null}
            
            {QuestionLibraryFinalList.some(prev=>prev===ques.id)?
            <span onClick={()=>onSelectQLQue(ques)}  className="cursor-pointer material-icons absolute bottom-0 right-0 bg-green-100 text-gray-500 rounded-full p-0.5 hover:bg-blue-100"
             title="Adready Added!">check</span>
             :
             <span onClick={()=>onSelectQLQue(ques)}  className="cursor-pointer material-icons absolute bottom-0 right-0 bg-gray-100 text-gray-500 rounded-full p-0.5 hover:bg-blue-100"
             title="Add Question!">add</span>
            }

          </div>
        </div> 
        ):null}


    
      </div>
      {/*  */}
    </div>
    <div className="capitalize p-8 flex justify-between text-xs border-t items-center">
      <p className="text-gray-500 md:text-sm text-xs"> <b className="font-semibold ">{QuestionLibraryFinalList&&QuestionLibraryFinalList.length>0?" "+QuestionLibraryFinalList.length+" ":" 0 "}</b> Questions Added </p> 
      <button onClick={()=>SubmitOfQuesLibList()}  className="cursor-pointer text-white bg-blue-500 rounded-lg px-4 py-3 mx-2  uppercase">Continue </button>
    </div>         
  </div>
</div>

   </>
  );
}
