import {
  dashboardConstants
} from '../_constants';

export function dashboard(state = {}, action) {

  switch (action.type) {

    case dashboardConstants.GET_CLIENT_PROFILE_DATA_REQUEST:
      return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_CLIENT_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        getClientProfileSuccess: true,
        loading: false,
        ClientProfileData:action.dashboard.ClientProfileData,
      };
    case dashboardConstants.GET_CLIENT_PROFILE_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error
      };

      //----------------------------------------------------------------- 

      case dashboardConstants.GET_SURVEY_REQUEST:
        return {
          ...state,
          loading: true
        };
      case dashboardConstants.GET_SURVEY_SUCCESS:
        return {
          ...state,
          getSurveySuccess: true,
          loading: false,
          SurveyList:action.dashboard.SurveyData.list,
          SurveyTotal:action.dashboard.SurveyData.total

        };
      case dashboardConstants.GET_SURVEY_FAILURE:
        return {
          ...state,
          loading: false,
          error: action.error
        };

        //----------------------------------------------------------------- 


        case dashboardConstants.GET_HAPPINESS_INDEX_REQUEST:
          return {
            ...state,
            loading: true
          };
        case dashboardConstants.GET_HAPPINESS_INDEX_SUCCESS:
          return {
            ...state,
            getHappynessIndexSuccess: true,
            loading: false,
            HappynessIndexData:action.dashboard.HappynessIndexData
  
          };
        case dashboardConstants.GET_HAPPINESS_INDEX_FAILURE:
          return {
            ...state,
            loading: false,
            error: action.error
          };

          //----------------------------------------------------------------- 

          case dashboardConstants.GET_ENGAGEMENT_INDEX_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_ENGAGEMENT_INDEX_SUCCESS:
            return {
              ...state,
              getEngagementIndexSuccess: true,
              loading: false,
              EngagementIndexData:action.dashboard.EngagementIndexData
    
            };
          case dashboardConstants.GET_ENGAGEMENT_INDEX_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

          //----------------------------------------------------------------- 

          case dashboardConstants.GET_HOPE_INDEX_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_HOPE_INDEX_SUCCESS:
            return {
              ...state,
              getHopeIndexSuccess: true,
              loading: false,
              HopeIndexData:action.dashboard.HopeIndexData
    
            };
          case dashboardConstants.GET_HOPE_INDEX_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
          
          //----------------------------------------------------------------- 

          case dashboardConstants.GET_STRESS_INDEX_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_STRESS_INDEX_SUCCESS:
            return {
              ...state,
              getStressIndexSuccess: true,
              loading: false,
              StressIndexData:action.dashboard.StressIndexData
    
            };
          case dashboardConstants.GET_STRESS_INDEX_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

          //----------------------------------------------------------------- 

          case dashboardConstants.GET_MANAGER_INDEX_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_MANAGER_INDEX_SUCCESS:
            return {
              ...state,
              getManagerIndexSuccess: true,
              loading: false,
              ManagerIndexData:action.dashboard.ManagerIndexData
    
            };
          case dashboardConstants.GET_MANAGER_INDEX_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

          //----------------------------------------------------------------- 

          case dashboardConstants.GET_LEADER_INDEX_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_LEADER_INDEX_SUCCESS:
            return {
              ...state,
              getLeaderIndexSuccess: true,
              loading: false,

              LeaderIndexData:action.dashboard.LeaderIndexData
    
            };
          case dashboardConstants.GET_LEADER_INDEX_FAILURE:
            return {
              ...state,
              loading: false,

              error: action.error
            };

          //----------------------------------------------------------------- 

          case dashboardConstants.GET_HAPPINESS_INDEX_MNGR_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_HAPPINESS_INDEX_MNGR_SUCCESS:
            return {
              ...state,
              getHappinessIndexMNGRSuccess: true,
              loading: false,
              HappinessIndexMNGRData:action.dashboard.HappinessIndexMNGRData
    
            };
          case dashboardConstants.GET_HAPPINESS_INDEX_MNGR_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };


          //----------------------------------------------------------------- 

          case dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_SUCCESS:
            return {
              ...state,
              getEngagementIndexMNGRSuccess: true,
              loading: false,
              EngagementIndexMNGRData:action.dashboard.EngagementIndexMNGRData
    
            };
          case dashboardConstants.GET_ENGAGEMENT_INDEX_MNGR_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

          //----------------------------------------------------------------- 

          case dashboardConstants.GET_HOPE_INDEX_MNGR_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_HOPE_INDEX_MNGR_SUCCESS:
            return {
              ...state,
              getHopeIndexMNGRSuccess: true,
              loading: false,
              HopeIndexMNGRData:action.dashboard.HopeIndexMNGRData
    
            };
          case dashboardConstants.GET_HOPE_INDEX_MNGR_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };


          //----------------------------------------------------------------- 

          case dashboardConstants.GET_STRESS_INDEX_MNGR_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_STRESS_INDEX_MNGR_SUCCESS:
            return {
              ...state,
              getStressIndexMNGRSuccess: true,
              loading: false,
              StressIndexMNGRData:action.dashboard.StressIndexMNGRData
    
            };
          case dashboardConstants.GET_STRESS_INDEX_MNGR_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };
        

          //----------------------------------------------------------------- 

          case dashboardConstants.GET_HAPPINESS_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_HAPPINESS_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              getHappinessIndexFilterSuccess: true,
              loading: false,
              HappinessIndexFilterData:action.dashboard.HappinessIndexFilterData
    
            };
          case dashboardConstants.GET_HAPPINESS_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };


          //----------------------------------------------------------------- 

          case dashboardConstants.GET_ENGAGEMENT_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_ENGAGEMENT_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              loading: false,
              getEngagementIndexFilterSuccess: true,
              EngagementIndexFilterData:action.dashboard.EngagementIndexFilterData
    
            };
          case dashboardConstants.GET_ENGAGEMENT_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };


          //----------------------------------------------------------------- 

          case dashboardConstants.GET_HOPE_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_HOPE_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              loading: false,
              getHopeIndexFilterSuccess: true,
              HopeIndexFilterData:action.dashboard.HopeIndexFilterData
    
            };
          case dashboardConstants.GET_HOPE_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };


          //----------------------------------------------------------------- 

          case dashboardConstants.GET_STRESS_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_STRESS_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              loading: false,
              getStressIndexFilterSuccess: true,
              StressIndexFilterData:action.dashboard.StressIndexFilterData
    
            };
          case dashboardConstants.GET_STRESS_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

            
          //----------------------------------------------------------------- 

          case dashboardConstants.GET_MANAGER_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_MANAGER_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              loading: false,
              getManagerIndexFilterSuccess: true,
              ManagerIndexFilterData:action.dashboard.ManagerIndexFilterData
    
            };
          case dashboardConstants.GET_MANAGER_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

            
          //----------------------------------------------------------------- 

          case dashboardConstants.GET_LEADER_INDEX_FILTER_REQUEST:
            return {
              ...state,
              loading: true
            };
          case dashboardConstants.GET_LEADER_INDEX_FILTER_SUCCESS:
            return {
              ...state,
              loading: false,
              getLeaderIndexFilterSuccess: true,
              LeaderIndexFilterData:action.dashboard.LeaderIndexFilterData
    
            };
          case dashboardConstants.GET_LEADER_INDEX_FILTER_FAILURE:
            return {
              ...state,
              loading: false,
              error: action.error
            };

          //----------------------------------------------------------------- 

          case dashboardConstants.GET_EMP_FILTER_REQUEST:
            return {
              ...state,
              loading: false
            };
          case dashboardConstants.GET_EMP_FILTER_SUCCESS:
            return {
              ...state,
              getEmpFilterSuccess: true,
              loading: false,

              EmpFilterData:action.dashboard.EmpFilterData
    
            };
          case dashboardConstants.GET_EMP_FILTER_FAILURE:
            return {
              ...state,
              loading: false,

              error: action.error
            };


            case dashboardConstants.GET_MANAGER_LIST_REQUEST:
              return {
                ...state,
                loading: true
              };
            case dashboardConstants.GET_MANAGER_LIST_SUCCESS:
              return {
                ...state,
                getManagerListSuccess: true,
                loading: false,

                ManagerListData:action.dashboard.ManagerList.list,
              };
            case dashboardConstants.GET_MANAGER_LIST_FAILURE:
              return {
                ...state,
                loading: false,

                error: action.error
              };


              
              case dashboardConstants.GET_QUESTION_ANALYSIS_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case dashboardConstants.GET_QUESTION_ANALYSIS_SUCCESS:
                return {
                  ...state,
                  loading: false,
                  getQuestionAnalysisSuccess: true,
                  QuestionAnalysisData:action.dashboard.QuestionAnalysisData.questionDetails,
                };
              case dashboardConstants.GET_QUESTION_ANALYSIS_FAILURE:
                return {
                  ...state,
                  loading: false,
                  error: action.error
                };



                case dashboardConstants.GET_QUESTION_ANALYSIS_NEW_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case dashboardConstants.GET_QUESTION_ANALYSIS_NEW_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getQuestionAnalysisOverallSuccess: true,
                    QuestionAnalysisOverallData:action.dashboard.QuestionAnalysisOverallData.questionDetails,
                  };
                case dashboardConstants.GET_QUESTION_ANALYSIS_NEW_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };


                

                case dashboardConstants.GET_QUESTION_ANALYSIS_FILTER_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case dashboardConstants.GET_QUESTION_ANALYSIS_FILTER_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getQuestionAnalysisFilterSuccess: true,
                    QuestionAnalysisFilterData:action.dashboard.QuestionAnalysisFilterData,
                  };
                case dashboardConstants.GET_QUESTION_ANALYSIS_FILTER_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };




                case dashboardConstants.GET_COMPANY_EMP_LIST_REQUEST:
                  return {
                    ...state,
                    loading: true
                  };
                case dashboardConstants.GET_COMPANY_EMP_LIST_SUCCESS:
                  return {
                    ...state,
                    loading: false,
                    getCompanyEmployeeListSuccess: true,
                    CompanyEmployeeList:action.dashboard.CompanyEmployeeList
                  };
                case dashboardConstants.GET_COMPANY_EMP_LIST_FAILURE:
                  return {
                    ...state,
                    loading: false,
                    error: action.error
                  };


                  case dashboardConstants.GET_ASSIGN_ROLE_REQUEST:
                    return {
                      ...state,
                      loading: true
                    };
                  case dashboardConstants.GET_ASSIGN_ROLE_SUCCESS:
                    return {
                      ...state,
                      loading: false,
                      getAssignRoleSuccess: true
                    };
                  case dashboardConstants.GET_ASSIGN_ROLE_FAILURE:
                    return {
                      ...state,
                      loading: false,
                      error: action.error
                    };
    
                    case dashboardConstants.GET_HAPPINESS_INDEX_NEW_REQUEST:
                      return {
                        ...state,
                        loading: true
                      };
                    case dashboardConstants.GET_HAPPINESS_INDEX_NEW_SUCCESS:
                      return {
                        ...state,
                        getHappinessIndexNewSuccess: true,
                        loading: false,
                        HappinessIndexNewData:action.dashboard.HappinessIndexNewData
                      };
                    case dashboardConstants.GET_HAPPINESS_INDEX_NEW_FAILURE:
                      return {
                        ...state,
                        loading: false,
          
                        error: action.error
                      };

                      case dashboardConstants.GET_ENGAGEMENT_INDEX_NEW_REQUEST:
                        return {
                          ...state,
                          loading: true
                        };
                      case dashboardConstants.GET_ENGAGEMENT_INDEX_NEW_SUCCESS:
                        return {
                          ...state,
                          getEngagementIndexNewSuccess: true,
                          loading: false,
                          EngagementIndexNewData:action.dashboard.EngagementIndexNewData
                        };
                      case dashboardConstants.GET_ENGAGEMENT_INDEX_NEW_FAILURE:
                        return {
                          ...state,
                          loading: false,
            
                          error: action.error
                        };
                      
                        case dashboardConstants.GET_HOPE_INDEX_NEW_REQUEST:
                          return {
                            ...state,
                            loading: true
                          };
                        case dashboardConstants.GET_HOPE_INDEX_NEW_SUCCESS:
                          return {
                            ...state,
                            getHopeIndexNewSuccess: true,
                            loading: false,
                            HopeIndexNewData:action.dashboard.HopeIndexNewData
                          };
                        case dashboardConstants.GET_HOPE_INDEX_NEW_FAILURE:
                          return {
                            ...state,
                            loading: false,
              
                            error: action.error
                          };

                          case dashboardConstants.GET_STRESS_INDEX_NEW_REQUEST:
                            return {
                              ...state,
                              loading: true
                            };
                          case dashboardConstants.GET_STRESS_INDEX_NEW_SUCCESS:
                            return {
                              ...state,
                              loading: false,
                              getStressIndexNewSuccess: true,
                              StressIndexNewData:action.dashboard.StressIndexNewData
                            };
                          case dashboardConstants.GET_STRESS_INDEX_NEW_FAILURE:
                            return {
                              ...state,
                              loading: false,
                
                              error: action.error
                            };
                            

                            
                          case dashboardConstants.GET_MANAGER_INDEX_NEW_REQUEST:
                            return {
                              ...state,
                              loading: true
                            };
                          case dashboardConstants.GET_MANAGER_INDEX_NEW_SUCCESS:
                            return {
                              ...state,
                              loading: false,
                              getManagerIndexNewSuccess: true,
                              ManagerIndexNewData:action.dashboard.ManagerIndexNewData
                            };
                          case dashboardConstants.GET_MANAGER_INDEX_NEW_FAILURE:
                            return {
                              ...state,
                              loading: false,
                
                              error: action.error
                            };



                            
                            case dashboardConstants.GET_ORG_CORE_INDEX_REQUEST:
                              return {
                                ...state,
                                loading: true
                              };
                            case dashboardConstants.GET_ORG_CORE_INDEX_SUCCESS:
                              return {
                                ...state,
                                loading: false,
                                getOrgCoreIndexSuccess: true,
                                OrgCoreIndexData:action.dashboard.OrgCoreIndexData
                              };
                            case dashboardConstants.GET_ORG_CORE_INDEX_FAILURE:
                              return {
                                ...state,
                                loading: false,
                  
                                error: action.error
                              };



                            case dashboardConstants.GET_STACK_LIST_SUCCESS:
                              return {
                                ...state,
                                getStackListSuccess: true,
                                loading: false,
                                // StackListData:action.dashboard.StackListData
                              };





    // new api s------------------------------------------>


    case dashboardConstants.GET_INDEX_REQUEST:
        return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_SUCCESS:
        return {
        ...state,
        loading: false,
        getIndexSuccess: true,
        getIndexData:action.dashboard.getIndexData
      };
    case dashboardConstants.GET_INDEX_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




    case dashboardConstants.GET_INDEX_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_INDEX_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getIndexFilterSuccess: true,
        getIndexFilterData:action.dashboard.getIndexFilterData
      };
    case dashboardConstants.GET_INDEX_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_REQUEST:
        return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_SUCCESS:
        return {
        ...state,
        loading: false,
        getDemographicIndexSuccess: true,
        getDemographicIndexData:action.dashboard.getDemographicIndexData
      };
    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };




    case dashboardConstants.GET_QUESTION_REQUEST:
        return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_SUCCESS:

        return {
        ...state,
        loading: false,
        getQuestionSuccess: true,
        getQuestionData:action.dashboard.getQuestionData
      };
    case dashboardConstants.GET_QUESTION_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



      case dashboardConstants.GET_QUESTION_REPORT_REQUEST:
        return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_REPORT_SUCCESS:

        return {
        ...state,
        loading: false,
        getQuestionReportSuccess: true,
        getQuestionReportData:action.dashboard.getQuestionReportData
      };
    case dashboardConstants.GET_QUESTION_REPORT_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };



    case dashboardConstants.GET_QUESTION_FILTER_REQUEST:
        return {
        ...state,
        loading: true
      };
    case dashboardConstants.GET_QUESTION_FILTER_SUCCESS:
        return {
        ...state,
        loading: false,
        getQuestionFilterSuccess: true,
        getQuestionFilterData:action.dashboard.getQuestionFilterData
      };
    case dashboardConstants.GET_QUESTION_FILTER_FAILURE:
        return {
        ...state,
        loading: false,      
        error: action.error
      };








      
  case dashboardConstants.GET_HAPPINESS_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_HAPPINESS_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexV2Success: true,
      getHappinessIndexV2Data:action.dashboard.getHappinessIndexV2Data
    };
  case dashboardConstants.GET_HAPPINESS_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





  case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getEngagementIndexV2Success: true,
      getEngagementIndexV2Data:action.dashboard.getEngagementIndexV2Data
    };
  case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




  case dashboardConstants.GET_HOPE_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_HOPE_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getHopeIndexV2Success: true,
      getHopeIndexV2Data:action.dashboard.getHopeIndexV2Data
    };
  case dashboardConstants.GET_HOPE_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





  case dashboardConstants.GET_STRESS_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_STRESS_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getStressIndexV2Success: true,
      getStressIndexV2Data:action.dashboard.getStressIndexV2Data
    };
  case dashboardConstants.GET_STRESS_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





  case dashboardConstants.GET_MANAGER_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_MANAGER_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexV2Success: true,
      getHappinessIndexV2Data:action.dashboard.getHappinessIndexV2Data
    };
  case dashboardConstants.GET_MANAGER_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };






  case dashboardConstants.GET_LEADER_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_LEADER_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexV2Success: true,
      getHappinessIndexV2Data:action.dashboard.getHappinessIndexV2Data
    };
  case dashboardConstants.GET_LEADER_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





  case dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexV2Success: true,
      getHappinessIndexV2Data:action.dashboard.getHappinessIndexV2Data
    };
  case dashboardConstants.GET_SENIORLEADERSHIP_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case dashboardConstants.GET_ORG_INDEX_V2_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_ORG_INDEX_V2_SUCCESS:
      return {
      ...state,
      loading: false,
      getOrgIndexV2Success: true,
      getOrgIndexV2Data:action.dashboard.getOrgIndexV2Data
    };
  case dashboardConstants.GET_ORG_INDEX_V2_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };










  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexHappinessSuccess: true,
      getDemographicIndexHappinessData:action.dashboard.getDemographicIndexHappinessData
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexEngagementSuccess: true,
      getDemographicIndexEngagementData:action.dashboard.getDemographicIndexEngagementData
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexStressSuccess: true,
      getDemographicIndexStressData:action.dashboard.getDemographicIndexStressData
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };

  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexManagerSuccess: true,
      getDemographicIndexManagerData:action.dashboard.getDemographicIndexManagerData
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




    case dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexLeaderSuccess: true,
      getDemographicIndexLeaderData:action.dashboard.getDemographicIndexLeaderData
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };










  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexOrgSuccess: true,
      getDemographicIndexOrgData:action.dashboard.getDemographicIndexOrgData
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_ORG_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexDriverSuccess: true,
      getHappinessIndexDriverData:action.dashboard.getHappinessIndexDriverData
    };
  case dashboardConstants.GET_HAPPINESS_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getEngagementIndexDriverSuccess: true,
      getEngagementIndexDriverData:action.dashboard.getEngagementIndexDriverData
    };
  case dashboardConstants.GET_ENGAGEMENT_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.GET_STRESS_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_STRESS_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getStressIndexDriverSuccess: true,
      getStressIndexDriverData:action.dashboard.getStressIndexDriverData
    };
  case dashboardConstants.GET_STRESS_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.GET_MANAGER_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_MANAGER_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getManagerIndexDriverSuccess: true,
      getManagerIndexDriverData:action.dashboard.getManagerIndexDriverData
    };
  case dashboardConstants.GET_MANAGER_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    case dashboardConstants.GET_LEADER_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_LEADER_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getLeaderIndexDriverSuccess: true,
      getLeaderIndexDriverData:action.dashboard.getLeaderIndexDriverData
    };
  case dashboardConstants.GET_LEADER_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case dashboardConstants.GET_ORG_INDEX_DRIVER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_ORG_INDEX_DRIVER_SUCCESS:
      return {
      ...state,
      loading: false,
      getOrgIndexDriverSuccess: true,
      getOrgIndexDriverData:action.dashboard.getOrgIndexDriverData
    };
  case dashboardConstants.GET_ORG_INDEX_DRIVER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };

  




  case dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessIndexV2DemographicSuccess: true,
      getHappinessIndexV2DemographicData:action.dashboard.getHappinessIndexV2DemographicData
    };
  case dashboardConstants.GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS:
      return {
      ...state,
      loading: false,
      getEngagementIndexV2DemographicSuccess: true,
      getEngagementIndexV2DemographicData:action.dashboard.getEngagementIndexV2DemographicData
    };
  case dashboardConstants.GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS:
      return {
      ...state,
      loading: false,
      getStressIndexV2DemographicSuccess: true,
      getStressIndexV2DemographicData:action.dashboard.getStressIndexV2DemographicData
    };
  case dashboardConstants.GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexForManagerSuccess: true,
      getDemographicIndexForManagerData:action.dashboard.getDemographicIndexForManagerData
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





    case dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_SUCCESS:
      return {
      ...state,
      loading: false,
      getManagerListDashboardManagerIndexSuccess: true,
      getManagerListDashboardManagerIndexData:action.dashboard.getManagerListDashboardManagerIndexData
    };
  case dashboardConstants.GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



    case dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_SUCCESS:
      return {
      ...state,
      loading: false,
      getMultiManagerQuestionListSuccess: true,
      getMultiManagerQuestionListData:action.dashboard.getMultiManagerQuestionListData
    };
  case dashboardConstants.GET_MULTIMANAGER_QUESTIONLIST_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




  case dashboardConstants.GET_ALL_BENCHMARK_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_ALL_BENCHMARK_SUCCESS:
      return {
      ...state,
      loading: false,
      getAllBenchMarkSuccess: true,
      getAllBenchMarkData:action.dashboard.getAllBenchMarkData
    };
  case dashboardConstants.GET_ALL_BENCHMARK_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.GET_RESPONDANT_DETAILS_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_RESPONDANT_DETAILS_SUCCESS:
      return {
      ...state,
      loading: false,
      getRespondantDetailsSuccess: true,
      getRespondantDetailsData:action.dashboard.getRespondantDetailsData
    };
  case dashboardConstants.GET_RESPONDANT_DETAILS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS:
      return {
      ...state,
      loading: false,
      getManagerListDomainFilterSuccess: true,
      getManagerListDomainFilterData:action.dashboard.getManagerListDomainFilterData
    };
  case dashboardConstants.GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




  case dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS:
      return {
      ...state,
      loading: false,
      getQuestionManagerOfHappinessSuccess: true,
      getQuestionManagerOfHappinessData:action.dashboard.getQuestionManagerOfHappinessData
    };
  case dashboardConstants.GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS:
      return {
      ...state,
      loading: false,
      getQuestionManagerOfEngagementSuccess: true,
      getQuestionManagerOfEngagementData:action.dashboard.getQuestionManagerOfEngagementData
    };
  case dashboardConstants.GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };

  

  case dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_SUCCESS:
      return {
      ...state,
      loading: false,
      getQuestionManagerOfStressSuccess: true,
      getQuestionManagerOfStressData:action.dashboard.getQuestionManagerOfStressData
    };
  case dashboardConstants.GET_QUESTION_MANAGER_OF_STRESS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS:
      return {
      ...state,
      loading: false,
      getQuestionManagerOfManagerSuccess: true,
      getQuestionManagerOfManagerData:action.dashboard.getQuestionManagerOfManagerData
    };
  case dashboardConstants.GET_QUESTION_MANAGER_OF_MANAGER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    case dashboardConstants.GET_INDEX_QUANTILE_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_INDEX_QUANTILE_SUCCESS:
      return {
      ...state,
      loading: false,
      getIndexQuantileSuccess: true,
      getIndexQuantileData:action.dashboard.getIndexQuantileData.list,
      getIndexQuantileCount:action.dashboard.getIndexQuantileData.count
    };
  case dashboardConstants.GET_INDEX_QUANTILE_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case dashboardConstants.GET_HAPPINESS_CORRELATION_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_HAPPINESS_CORRELATION_SUCCESS:
      return {
      ...state,
      loading: false,
      getHappinessCorrelationSuccess: true,
      getHappinessCorrelationData:action.dashboard.getHappinessCorrelationData
    };
  case dashboardConstants.GET_HAPPINESS_CORRELATION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case dashboardConstants.GET_ENGAGEMENT_CORRELATION_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_ENGAGEMENT_CORRELATION_SUCCESS:
      return {
      ...state,
      loading: false,
      getEngagementCorrelationSuccess: true,
      getEngagementCorrelationData:action.dashboard.getEngagementCorrelationData
    };
  case dashboardConstants.GET_ENGAGEMENT_CORRELATION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };

  case dashboardConstants.GET_STRESS_CORRELATION_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_STRESS_CORRELATION_SUCCESS:
      return {
      ...state,
      loading: false,
      getStressCorrelationSuccess: true,
      getStressCorrelationData:action.dashboard.getStressCorrelationData
    };
  case dashboardConstants.GET_STRESS_CORRELATION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.GET_MANAGER_CORRELATION_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_MANAGER_CORRELATION_SUCCESS:
      return {
      ...state,
      loading: false,
      getManagerCorrelationSuccess: true,
      getManagerCorrelationData:action.dashboard.getManagerCorrelationData
    };
  case dashboardConstants.GET_MANAGER_CORRELATION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.GET_LEADER_CORRELATION_REQUEST:
    return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_LEADER_CORRELATION_SUCCESS:
      return {
      ...state,
      loading: false,
      getLeaderCorrelationSuccess: true,
      getLeaderCorrelationData:action.dashboard.getLeaderCorrelationData
    };
  case dashboardConstants.GET_LEADER_CORRELATION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexScatterSuccess: true,
      getDemographicIndexScatterData:action.dashboard.getDemographicIndexScatterData
    };
  case dashboardConstants.GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    case dashboardConstants.GET_RWA_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_RWA_SUCCESS:
      return {
      ...state,
      loading: false,
      getRWAnalysisSuccess: true,
      getRWAnalysisData:action.dashboard.getRWAnalysisData
    };
  case dashboardConstants.GET_RWA_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    case dashboardConstants.GET_TEXT_QUESTION_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_TEXT_QUESTION_SUCCESS:
      return {
      ...state,
      loading: false,
      getTextQuestionSuccess: true,
      getTextQuestionData:action.dashboard.getTextQuestionData
    };
  case dashboardConstants.GET_TEXT_QUESTION_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };
    

    

    case dashboardConstants.WORD_CLOUD_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.WORD_CLOUD_SUCCESS:
      return {
      ...state,
      loading: false,
      wordCloudSuccess: true,
      wordCloudData:action.dashboard.wordCloudData
    };
  case dashboardConstants.WORD_CLOUD_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };

    case dashboardConstants.ADVANCE_WORD_CLOUD_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.ADVANCE_WORD_CLOUD_SUCCESS:
      return {
      ...state,
      loading: false,
      AdvanceWordCloudSuccess: true,
      AdvanceWordCloudData:action.dashboard.AdvanceWordCloudData
    };
  case dashboardConstants.ADVANCE_WORD_CLOUD_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.WORD_CLOUD_TEXT_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.WORD_CLOUD_TEXT_SUCCESS:
      return {
      ...state,
      loading: false,
      wordCloudTextSuccess: true,
      wordCloudTextData:action.dashboard.wordCloudTextData,
      wordTextKeyWord:action.dashboard.wordTextKeyWord

    };
  case dashboardConstants.WORD_CLOUD_TEXT_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    case dashboardConstants.WORD_CLOUD_MULTI_TEXT_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.WORD_CLOUD_MULTI_TEXT_SUCCESS:
      return {
      ...state,
      loading: false,
      wordCloudTextSuccess: true,
      wordCloudMultiTextData:action.dashboard.wordCloudMultiTextData,
      wordMultiTextKeyWord:action.dashboard.wordMultiTextKeyWord

    };
  case dashboardConstants.WORD_CLOUD_MULTI_TEXT_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.COOCCUR_MATRIX_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.COOCCUR_MATRIX_SUCCESS:
      return {
      ...state,
      loading: false,
      cooccurmatrixSuccess: true,
      cooccurmatrixData:action.dashboard.cooccurmatrixData
    };
  case dashboardConstants.COOCCUR_MATRIX_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


  case dashboardConstants.NEIGHBOR_GRAPH_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.NEIGHBOR_GRAPH_SUCCESS:
      return {
      ...state,
      loading: false,
      neighborGraphSuccess: true,
      neighborGraphData:action.dashboard.neighborGraphData
    };
  case dashboardConstants.NEIGHBOR_GRAPH_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




    case dashboardConstants.GET_ENGAGEMENT_RWA_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.GET_ENGAGEMENT_RWA_SUCCESS:
      return {
      ...state,
      loading: false,
      getEngagementRWASuccess: true,
      getEngagementRWAData:action.dashboard.getEngagementRWAData
    };
  case dashboardConstants.GET_ENGAGEMENT_RWA_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





    
    case dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_REQUEST:
      return {
      ...state,
      loading: true
    };
  case dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_SUCCESS:
      return {
      ...state,
      loading: false,
      cronbatchAlphaVarianceSuccess: true,
      cronbatchAlphaVarianceData:action.dashboard.cronbatchAlphaVarianceData
    };
    case dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO:
      return {
      ...state,
      loading: false,
      cronbatchAlphaVarianceSuccess: true,
      cronbatchAlphaVarianceData:action.dashboard.cronbatchAlphaVarianceData
    };
  case dashboardConstants.CRONE_BATCH_ALPHA_VARIANCE_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




  case dashboardConstants.GET_QUESTION_FAVOURABILITY_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case dashboardConstants.GET_QUESTION_FAVOURABILITY_SUCCESS:
      return {
      ...state,
      loading: false,
      getQuestionFavorabilitySuccess: true,
      getQuestionFavorabilityData:action.dashboard.getQuestionFavorabilityData
    };

  case dashboardConstants.GET_QUESTION_FAVOURABILITY_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



    case dashboardConstants.GET_HISTORY_INDEX_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case dashboardConstants.GET_HISTORY_INDEX_SUCCESS:
      return {
      ...state,
      loading: false,
      getHistoryIndexSuccess: true,
      getHistoryIndexData:action.dashboard.getHistoryIndexData
    };

  case dashboardConstants.GET_HISTORY_INDEX_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



    case dashboardConstants.GET_RESPONSE_ANALYTIYCS_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case dashboardConstants.GET_RESPONSE_ANALYTIYCS_SUCCESS:
      return {
      ...state,
      loading: false,
      getResponseAnalyticsSuccess: true,
      getResponseAnalyticsData:action.dashboard.getResponseAnalyticsData
    };

  case dashboardConstants.GET_RESPONSE_ANALYTIYCS_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };




    case dashboardConstants.GET_COMMENT_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case dashboardConstants.GET_COMMENT_SUCCESS:
      return {
      ...state,
      loading: false,
      getCommentSuccess: true,
      getCommentData:action.dashboard.getCommentData.list
    };

  case dashboardConstants.GET_COMMENT_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };





    case dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS:
      return {
      ...state,
      loading: false,
      getDemographicIndexMultiFilterSuccess: true,
      getDemographicIndexMultiFilterData:action.dashboard.getDemographicIndexMultiFilterData
    };

  case dashboardConstants.GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    case dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_SUCCESS:
      return {
      ...state,
      loading: false,
      AssignManagerActionPlanSuccess: true,
    };

  case dashboardConstants.ASSIGN_MANAGER_ACTION_PLAN_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



    case dashboardConstants.GET_MANAGER_ACTION_PLAN_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case dashboardConstants.GET_MANAGER_ACTION_PLAN_SUCCESS:
      return {
      ...state,
      loading: false,
      getManagerActionPlanSuccess: true,
      getManagerActionPlanData:action.dashboard.getManagerActionPlanData
    };

  case dashboardConstants.GET_MANAGER_ACTION_PLAN_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    
  case dashboardConstants.UPDATE_EMPLOYEE_TASK_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case dashboardConstants.UPDATE_EMPLOYEE_TASK_SUCCESS:
      return {
      ...state,
      loading: false,
      updateEmployeeTaskSuccess: true,
      updateEmployeeTaskData:action.dashboard.updateEmployeeTaskData
    };

  case dashboardConstants.UPDATE_EMPLOYEE_TASK_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };


    

    
  case dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_REQUEST:
      return {
      ...state,
      loading: true
    };
    
  case dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_SUCCESS:
      return {
      ...state,
      loading: false,
      updateEmployeeResourceSuccess: true,
      updateEmployeeResourceData:action.dashboard.updateEmployeeResourceData
    };

  case dashboardConstants.UPDATE_EMPLOYEE_RESOURCE_FAILURE:
      return {
      ...state,
      loading: false,      
      error: action.error
    };



        
case dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_SUCCESS:
    return {
    ...state,
    loading: false,
    getAllManagerActionPlanSuccess: true,
    getAllManagerActionPlanData:action.dashboard.getAllManagerActionPlanData
  };

case dashboardConstants.GET_ALL_MANAGER_ACTION_PLAN_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
  };




  case dashboardConstants.CROSS_TAB_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.CROSS_TAB_SUCCESS:
    return {
    ...state,
    loading: false,
    crossTabSuccess: true,
    crossTabData:action.dashboard.crossTabData
  };

case dashboardConstants.CROSS_TAB_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
  };



  case dashboardConstants.CROSS_TAB_DMG_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.CROSS_TAB_DMG_SUCCESS:
    return {
    ...state,
    loading: false,
    crossTabDMGSuccess: true,
    crossTabDMGData:action.dashboard.crossTabDMGData
  };

case dashboardConstants.CROSS_TAB_DMG_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
  };



  case dashboardConstants.GET_QUESTION_OVERALL_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.GET_QUESTION_OVERALL_SUCCESS:
    return {
    ...state,
    loading: false,
    getQuestionOverallSuccess: true,
    getQuestionOverallData:action.dashboard.getQuestionOverallData
  };

case dashboardConstants.GET_QUESTION_OVERALL_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
  };



case dashboardConstants.CROSS_TAB_ANALYSIS_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.CROSS_TAB_ANALYSIS_SUCCESS:
    return {
    ...state,
    loading: false,
    crossTabAnalysisSuccess: true,
    crossTabAnalysisData:action.dashboard.crossTabAnalysisData
  };

case dashboardConstants.CROSS_TAB_ANALYSIS_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
  };





  case dashboardConstants.CORRELATION_TYPE2_ANALYSIS_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.CORRELATION_TYPE2_ANALYSIS_SUCCESS:
    return {
    ...state,
    loading: false,
    getCorrelationQuestionType2Success: true,
    getCorrelationQuestionType2Data:action.dashboard.getCorrelationQuestionType2Data
  };

case dashboardConstants.CORRELATION_TYPE2_ANALYSIS_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
  };





case dashboardConstants.GET_ALL_PARAMETER_LIST_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.GET_ALL_PARAMETER_LIST_SUCCESS:
    return {
    ...state,
    loading: false,
    getAllParameterListSuccess: true,
    getAllParameterListData:action.dashboard.getAllParameterListData.list
  };

case dashboardConstants.GET_ALL_PARAMETER_LIST_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};




case dashboardConstants.GET_CORRELATION_OVERALL_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.GET_CORRELATION_OVERALL_SUCCESS:
    return {
    ...state,
    loading: false,
    getCorrelationDimenionVsDimensionSuccess: true,
    getCorrelationDimenionVsDimensionData:action.dashboard.getCorrelationDimenionVsDimensionData
  };

case dashboardConstants.GET_CORRELATION_OVERALL_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};



case dashboardConstants.GET_CORRELATION_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.GET_CORRELATION_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getCorrelationDimensionVsDimensionFilterSuccess: true,
    getCorrelationDimensionVsDimensionFilterData:action.dashboard.getCorrelationDimensionVsDimensionFilterData
  };

case dashboardConstants.GET_CORRELATION_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};










    
case dashboardConstants.GET_HAPINESS_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_HAPINESS_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getHappinessQuestionSuccess: true,
  getHappinessQuestionData:action.dashboard.getHappinessQuestionData
};
case dashboardConstants.GET_HAPINESS_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};




case dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getHappinessManagerQuestionSuccess: true,
  getHappinessManagerQuestionData:action.dashboard.getHappinessQuestionData
};
case dashboardConstants.GET_HAPINESS_MANAGER_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};


case dashboardConstants.GET_HAPINESS_DMG_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_HAPINESS_DMG_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getHappinessDMGQuestionSuccess: true,
  getHappinessDMGQuestionData:action.dashboard.getHappinessQuestionData
};
case dashboardConstants.GET_HAPINESS_DMG_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};






case dashboardConstants.GET_ENGAGEMENT_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_ENGAGEMENT_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getEngagementQuestionSuccess: true,
  getEngagementQuestionData:action.dashboard.getEngagementQuestionData
};
case dashboardConstants.GET_ENGAGEMENT_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};



case dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getEngagementManagerQuestionSuccess: true,
  getEngagementManagerQuestionData:action.dashboard.getEngagementQuestionData
};
case dashboardConstants.GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};


case dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getEngagementDMGQuestionSuccess: true,
  getEngagementDMGQuestionData:action.dashboard.getEngagementQuestionData
};
case dashboardConstants.GET_ENGAGEMENT_DMG_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};







case dashboardConstants.GET_HOPE_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_HOPE_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getHopeQuestionSuccess: true,
  getHopeQuestionData:action.dashboard.getHopeQuestionData
};
case dashboardConstants.GET_HOPE_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};




case dashboardConstants.GET_STRESS_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_STRESS_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getStressQuestionSuccess: true,
  getStressQuestionData:action.dashboard.getStressQuestionData
};
case dashboardConstants.GET_STRESS_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};



case dashboardConstants.GET_STRESS_MANAGER_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_STRESS_MANAGER_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getStressManagerQuestionSuccess: true,
  getStressManagerQuestionData:action.dashboard.getStressQuestionData
};
case dashboardConstants.GET_STRESS_MANAGER_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};


case dashboardConstants.GET_STRESS_DMG_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_STRESS_DMG_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getStressDMGQuestionSuccess: true,
  getStressDMGQuestionData:action.dashboard.getStressQuestionData
};
case dashboardConstants.GET_STRESS_DMG_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};





case dashboardConstants.GET_MANAGER_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_MANAGER_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getManagerQuestionSuccess: true,
  getManagerQuestionData:action.dashboard.getManagerQuestionData
};
case dashboardConstants.GET_MANAGER_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};


case dashboardConstants.GET_MANAGER_MANAGER_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_MANAGER_MANAGER_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getManagerManagerQuestionSuccess: true,
  getManagerManagerQuestionData:action.dashboard.getManagerQuestionData
};
case dashboardConstants.GET_MANAGER_MANAGER_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};


case dashboardConstants.GET_MANAGER_DMG_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_MANAGER_DMG_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getManagerDMGQuestionSuccess: true,
  getManagerDMGQuestionData:action.dashboard.getManagerQuestionData
};
case dashboardConstants.GET_MANAGER_DMG_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};



case dashboardConstants.GET_LEADER_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_LEADER_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getLeaderQuestionSuccess: true,
  getLeaderQuestionData:action.dashboard.getLeaderQuestionData
};
case dashboardConstants.GET_LEADER_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};


case dashboardConstants.GET_LEADER_MANAGER_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_LEADER_MANAGER_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getLeaderManagerQuestionSuccess: true,
  getLeaderManagerQuestionData:action.dashboard.getLeaderQuestionData
};
case dashboardConstants.GET_LEADER_MANAGER_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};


case dashboardConstants.GET_LEADER_DMG_QUESTION_REQUEST:
  return {
  ...state,
  loading: true
};
case dashboardConstants.GET_LEADER_DMG_QUESTION_SUCCESS:

  return {
  ...state,
  loading: false,
  getLeaderDMGQuestionSuccess: true,
  getLeaderDMGQuestionData:action.dashboard.getLeaderQuestionData
};
case dashboardConstants.GET_LEADER_DMG_QUESTION_FAILURE:
  return {
  ...state,
  loading: false,      
  error: action.error
};








case dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getHappinessIndexByFilterSuccess: true,
    getHappinessIndexByFilterData:action.dashboard.getIndexFilterData
  };

case dashboardConstants.GET_HAPPINESS_INDEX_BY_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};


case dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getEngagementIndexByFilterSuccess: true,
    getEngagementIndexByFilterData:action.dashboard.getIndexFilterData
  };

case dashboardConstants.GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};


case dashboardConstants.GET_STRESS_INDEX_BY_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.GET_STRESS_INDEX_BY_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getStressIndexByFilterSuccess: true,
    getStressIndexByFilterData:action.dashboard.getIndexFilterData
  };

case dashboardConstants.GET_STRESS_INDEX_BY_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};








case dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getManagerIndexByFilterSuccess: true,
    getManagerIndexByFilterData:action.dashboard.getIndexFilterData
  };

case dashboardConstants.GET_MANAGER_INDEX_BY_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};




case dashboardConstants.GET_LEADER_INDEX_BY_FILTER_REQUEST:
    return {
    ...state,
    loading: true
  };
  
case dashboardConstants.GET_LEADER_INDEX_BY_FILTER_SUCCESS:
    return {
    ...state,
    loading: false,
    getLeaderIndexByFilterSuccess: true,
    getLeaderIndexByFilterData:action.dashboard.getIndexFilterData
  };

case dashboardConstants.GET_LEADER_INDEX_BY_FILTER_FAILURE:
    return {
    ...state,
    loading: false,      
    error: action.error
};




    default:
      return state
  }
}