export const questionsConstants = {

    LOGOUT: 'USERS_LOGOUT',

    ADD_QUESTIONS_REQUEST: 'ADD_QUESTIONS_REQUEST',
    ADD_QUESTIONS_SUCCESS: 'ADD_QUESTIONS_SUCCESS',
    ADD_QUESTIONS_FAILURE: 'ADD_QUESTIONS_FAILURE',

    DELETE_QUESTIONS_REQUEST: 'DELETE_QUESTIONS_REQUEST',
    DELETE_QUESTIONS_SUCCESS: 'DELETE_QUESTIONS_SUCCESS',
    DELETE_QUESTIONS_FAILURE: 'DELETE_QUESTIONS_FAILURE',
    
    UPDATE_QUESTIONS_REQUEST: 'UPDATE_QUESTIONS_REQUEST',
    UPDATE_QUESTIONS_SUCCESS: 'UPDATE_QUESTIONS_SUCCESS',
    UPDATE_QUESTIONS_FAILURE: 'UPDATE_QUESTIONS_FAILURE',
    

    DELETE_QUESTIONOPTION_REQUEST: 'DELETE_QUESTIONOPTION_REQUEST',
    DELETE_QUESTIONOPTION_SUCCESS: 'DELETE_QUESTIONOPTION_SUCCESS',
    DELETE_QUESTIONOPTION_FAILURE: 'DELETE_QUESTIONOPTION_FAILURE',

    QUESTIONS_GETALL_REQUEST: 'QUESTIONS_GETALL_REQUEST',
    QUESTIONS_GETALL_SUCCESS: 'QUESTIONS_GETALL_SUCCESS',
    QUESTIONS_GETALL_FAILURE: 'QUESTIONS_GETALL_FAILURE',

    ANSWERS_SUBMIT_REQUEST: 'ANSWERS_SUBMIT_REQUEST',
    ANSWERS_SUBMIT_SUCCESS: 'ANSWERS_SUBMIT_SUCCESS',
    ANSWERS_SUBMIT_FAILURE: 'ANSWERS_SUBMIT_FAILURE',


    QUESTIONS_LIST_GETALL_REQUEST: 'QUESTIONS_LIST_GETALL_REQUEST',
    QUESTIONS_LIST_GETALL_SUCCESS: 'QUESTIONS_LIST_GETALL_SUCCESS',
    QUESTIONS_LIST_GETALL_FAILURE: 'QUESTIONS_LIST_GETALL_FAILURE',

    UPDATE_QUESTIONS_RANK_REQUEST:'UPDATE_QUESTIONS_RANK_REQUEST',
    UPDATE_QUESTIONS_RANK_SUCCESS: 'UPDATE_QUESTIONS_RANK_SUCCESS',
    UPDATE_QUESTIONS_RANK_FAILURE: 'UPDATE_QUESTIONS_RANK_FAILURE',


    COPY_QUES_LIBRARY_REQUEST:'COPY_QUES_LIBRARY_REQUEST',
    COPY_QUES_LIBRARY_SUCCESS: 'COPY_QUES_LIBRARY_SUCCESS',
    COPY_QUES_LIBRARY_FAILURE: 'COPY_QUES_LIBRARY_FAILURE',

    TRANSLATE_QUESTION_REQUEST:'TRANSLATE_QUESTION_REQUEST',
    TRANSLATE_QUESTION_SUCCESS: 'TRANSLATE_QUESTION_SUCCESS',
    TRANSLATE_QUESTION_FAILURE: 'TRANSLATE_QUESTION_FAILURE',

    TRANSLATE_MANY_QUESTION_REQUEST:'TRANSLATE_MANY_QUESTION_REQUEST',
    TRANSLATE_MANY_QUESTION_SUCCESS: 'TRANSLATE_MANY_QUESTION_SUCCESS',
    TRANSLATE_MANY_QUESTION_FAILURE: 'TRANSLATE_MANY_QUESTION_FAILURE',

    TRANSLATE_SURVEY_REQUEST:'TRANSLATE_SURVEY_REQUEST',
    TRANSLATE_SURVEY_SUCCESS: 'TRANSLATE_SURVEY_SUCCESS',
    TRANSLATE_SURVEY_FAILURE: 'TRANSLATE_SURVEY_FAILURE',

    // PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST:'PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST',
    // PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS: 'PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS',
    // PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE: 'PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE',
};
