import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Filter from './Filter';
import Filter from './Filter/Filter';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName:'none',
      viewScore:true,
      showDropDown1:false,
      showDropDown2:false,
      showFilter:false,
      showValue:'',
      DimensionList:[
        {
          index:1,
          name:'Happiness',
          cutOff:59,
          range:72,
          type:""
        },
        {
          index:2,
          name:'Engagement',
          cutOff:78,
          range:100,
          type:"%"

        },
        {
          index:4,
          name:'Stress',
          cutOff:28,
          range:60,
          type:"%"

        },
        {
          index:5,
          name:'Manager',
          cutOff:59,
          range:100,
          type:"%"

        },
        {
          index:6,
          name:'Leader',
          cutOff:50,
          range:100,
          type:"%"

        },
        // {
        //   index:8,
        //   name:'OrgCore',
        //   cutOff:59
        // },
      ],

      selectedItem1:{
        index:1,
        name:'Happiness',
        cutOff:59,
        range:72,
        type:""
      },
      selectedItem2:{
        index:2,
        name:'Engagement',
        cutOff:78,
        range:100,
        type:"%"
      },

      
      currentSelectValue:'',
      FilterValues:[],
      showQueDrop:false,
      storeQues1:'',
      storeQues2:''



    }
  }
  componentDidMount() {

  }

  handleViewScore=(data)=>{
    this.setState({viewScore:data})
  }




  handleDropdown1=(check)=>{
    this.setState({showDropDown1:!check, showDropDown2:false})
  }
  handleDropdown2=(check)=>{
    this.setState({showDropDown2:!check, showDropDown1:false})
  }

  changeItem1=(item)=>{
    this.setState({selectedItem1:item})
    this.setState({showDropDown1:false, showDropDown2:false})

  }

  changeItem2=(item)=>{
    this.setState({selectedItem2:item})
    this.setState({showDropDown1:false, showDropDown2:false})

  }



  handleMultifilterIndexScore2=(finalFilter, name)=>{
    let {handleMultiFilterAdvanc4Quadrant}= this.props;
    this.setState({FilterValues:finalFilter});
    // handleMultiFilterAdvanc4Quadrant(finalFilter,true, name);

  }


  openQuesDrop=(check, no)=>{
    if(no===1){
      this.setState({showQueDrop1:check,showQueDrop2:false});
    }
    if(no===2){
      this.setState({showQueDrop2:check,showQueDrop1:false});
    }
  }

  handleSelectQues=(Ques,no)=>{
    if(no===1){
      this.setState({storeQues1:Ques,showQueDrop1:false});
    }
    if(no===2){
      this.setState({storeQues2:Ques,showQueDrop2:false});
    }
  }

  handleSaveCrossTab=()=>{
    let { handleCrossTabApi } = this.props;
    let { storeQues1,storeQues2, FilterValues } = this.state;


    let list = []

    if(storeQues1 && storeQues2){
      let NewFilter = [];
      if(FilterValues && FilterValues.length>0){
        FilterValues.forEach((filter)=>{
          if(filter.value!=="All"){
            NewFilter.push(filter);
          }
        });
      }
      handleCrossTabApi(storeQues1, storeQues2, NewFilter);
    }
  }


  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }




  render() {
    let {crossTabAnalysisData, indexType,optionType,loading ,getCommentData,getIndexData, getDemographicIndexData, EmpFilterData, getIndexFilterData } = this.props;

    
    let { showQueDrop1,showQueDrop2, showDropDown1, showDropDown2, DimensionList, selectedItem1, selectedItem2, currentSelectValue, FilterValues  } = this.state;


    console.log('crossTabAnalysisData:-----------------------------------------------------> ',crossTabAnalysisData)



    let optionList1 = [];
    let optionList2 = [];
    if(crossTabAnalysisData && crossTabAnalysisData.length>0){
      crossTabAnalysisData.forEach((item)=>{

        let getIndex1 = optionList1.findIndex(prev=>prev===item[0]);
        if(getIndex1===-1){
          optionList1.push(item[0]);
        }

        let getIndex2 = optionList2.findIndex(prev=>prev===item[1]);
        if(getIndex2===-1){
          optionList2.push(item[1]);
        }


      });
    }


    console.log('optionList1:-----------------------------------------------------> ',optionList1)
    console.log('optionList2:-----------------------------------------------------> ',optionList2)



    let EmpFilterData2 = []
    if(EmpFilterData && EmpFilterData.length>0){
     EmpFilterData.forEach((emp)=>{
       if(emp.name !=='ManagerPID'){
         EmpFilterData2.push(emp);
       }
     })
    }
 
    let isDisable  = true;
    // let DistributionIndexData = [];
   //  let DemographicDataDistribution = [];
   console.log('EmpFilterData: ',EmpFilterData)

    if(FilterValues && FilterValues.length>0){
 
      if(isDisable){
         FilterValues.forEach((item)=>{
             if(item.value==='All'){
               isDisable = false
             }
         });
      }
 
 
 
    }
 
    let { questionsList, crossTabData } = this.props;
    let { showQueDrop, storeQues1, storeQues2 } = this.state;

    let storeQuesIDs = [];

    if(storeQues1){
      storeQuesIDs.push(storeQues1);
    }
    if(storeQues2){
      storeQuesIDs.push(storeQues2);
    }

    console.log('storeQues1',storeQues1&&storeQues1.id?storeQues1.id:"");
    console.log('storeQues2',storeQues2&&storeQues2.id?storeQues2.id:"");


    // let OptionList = [
    //   "Strongly Agree",
    //   "Agree",
    //   "Slightly Agree",
    //   "Slightly Disagree",
    //   "Disagree",
    //   "Strongly Disagree"
    // ];

    let QuestionObj = {};
    if(storeQuesIDs && storeQuesIDs.length>0){
      storeQuesIDs.forEach((QUE)=>{
        QuestionObj[QUE.id]={}
      });

      storeQuesIDs.forEach((QUE)=>{
        if(crossTabData && crossTabData.length>0){
          optionList1.forEach((opt)=>{
          crossTabData.forEach((cross)=>{
            if(cross._id.questionId === QUE.id){
              let temp = {
                "name":cross._id.answerText,
                "count":cross.count
              }
                if(opt === cross._id.answerText){
                  QuestionObj[QUE.id][opt] =  cross.count;
                }
            
            }
          });
        });
        }
      });


    }

    console.log('Cross crossTabAnalysisData: ',crossTabAnalysisData);

    let ScoreListNew = [];
    // ScoreListNew = [storeQues1,storeQues2];

    if(crossTabAnalysisData && crossTabAnalysisData.length>0){
      crossTabAnalysisData.forEach((item)=>{

        let AnsQue1 = item[0];
        let AnsQue2 = item[1];
        let AnsScore = item[2];
        let AnsPerc = item[3];

        let getIndex = ScoreListNew.findIndex(prev=>prev.q1 === AnsQue1);
        
        if(getIndex===-1){
          let temp = {
            "q1":AnsQue1
          }
          temp[AnsQue2] = {
            "count":AnsScore,
            "per":AnsPerc
          };
          ScoreListNew.push(temp);
        }
        else{
          if(!(ScoreListNew && ScoreListNew[getIndex] && ScoreListNew[getIndex][AnsQue2])){
            ScoreListNew[getIndex][AnsQue2] = {
              "count":AnsScore,
              "per":AnsPerc
            };
          }
        }


      });
    }

    console.log('Cross ScoreListNew: ',ScoreListNew);

  let ScoreList = []
  if(optionList1 && optionList1.length>0){
    optionList1.forEach((opt)=>{
      if(ScoreListNew && ScoreListNew.length>0){
        let flag = 0;
        ScoreListNew.forEach((item)=>{
          if(item.q1===opt){
            ScoreList.push(item);
            flag++;
          }
        });
        if(!flag){
          let temp = {
            q1:opt
          }
          optionList2.forEach((opt1)=>{
            temp[opt1] = {
              count: 0,
              per: 0
            }
          });
          ScoreList.push(temp);
        }
        
      }   
    });
  }
  console.log('Cross ScoreList: ',ScoreList);


    // 0:
    // Agree:
    // count: 258
    // per: 22.872340425531913
    // __proto__: Object
    // Disagree: {count: 0, 


    function valueFn(storeId,no,option){
      let count = 0
      if(storeId && storeId[no] && storeId[no].id){
        let Id = storeId[no].id;
        if(QuestionObj && QuestionObj[Id] && QuestionObj[Id][option]){
          count=QuestionObj[Id][option];
        }
      }
      return "-";
    }

    return (
      <>
      <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
        <Filter
          showFilter={this.state.showFilter}
          showValue={this.state.showValue}
          closeFilter={this.closeFilter}

          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        />
        {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} /> */}

      </div>


      {true?
      <>
 
        <div className="xl:flex lg:flex md:flex justify-start items-center">

        {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



      </div>


      {FilterValues && FilterValues.length > 0 ?
          <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
            {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
              <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
            ) : null}</div>
          : null}


      <div onClick={()=>this.closeFilter(false)}  className=" " >
      <div className="flex w-full" >


      <div className="bg-white rounded w-5/12 shadow-lg px-4 m-4 mr-2">
      <div className="text-lg font-semibold py-4">CrossTab Question Selection</div>
                  
              <div className="py-4 px-4">
                {/* 1 */}
                {/* <div className="flex items-center justify-between text-sm text-gray-500 ">
                  <div className="mx-2 flex  w-full text-center">
                    <span className="py-2 border-b-2 border-gray-100 hover:border-blue-500 w-full">Rows</span>
                    <span className="py-2 border-b-2 border-gray-100  hover:border-blue-500 w-full">Columns</span>
                    <span className="py-2 border-b-2 border-gray-100 hover:border-blue-500 w-full">Filters</span>
                  </div>
                </div> */}
                {/* 2 */}
                {/* <div className="flex items-center justify-between py-4">
                  <div className="flex items-center text-sm"><span className="material-icons border rounded-full border-2 border-black hover:border-blue-500 hover:text-blue-500" style={{fontSize: '18px'}}>add</span><h1 className="ml-2">Questions</h1></div>
                  <span className="font-medium text-xs text-gray-500">2</span>
                </div> */}
                <div className="flex relative items-center justify-between py-4 border pl-2">

                    <span onClick={()=>this.openQuesDrop(!showQueDrop1,1)} className="cursor-pointer flex items-center text-sm"><span className="material-icons border rounded-full border-2 border-black hover:border-blue-500 hover:text-blue-500" style={{fontSize: '18px'}}>
                      {showQueDrop?"remove":"add"}</span><h1 className="ml-2">{storeQues1 && storeQues1.name? storeQues1.name.slice(0,45)+'...':"Select Question 1"}</h1></span> 

                    {showQueDrop1?
                    <div className="z-10 absolute top-10 left-0 bg-white  shadow-md rounded-md w-full text-gray-500 overflow-y-auto" style={{height:'400px',overflowY:'auto'}}>

                      {questionsList && questionsList.length>0?
                      questionsList.map((question, index)=>
                      ((storeQues2.id!==question.id))?
                      <span key={index} onClick={()=>this.handleSelectQues(question,1)} className=
                      {(storeQues1.id===question.id)?
                      "bg-blue-500 text-white cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b"
                      :
                      "hover:bg-gray-500 hover:text-white bg-white text-gray-500 cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b"
                      }
                      > 
                        {question.name}
                      </span>
                      :null)
                      :null}
                          


                    </div>
                    :null}


                    </div>



                    <div className="flex relative items-center justify-between py-4 mt-2 border pl-2">

                      <span onClick={()=>this.openQuesDrop(!showQueDrop2,2)} className="cursor-pointer flex items-center text-sm"><span className="material-icons border rounded-full border-2 border-black hover:border-blue-500 hover:text-blue-500" style={{fontSize: '18px'}}>
                        {showQueDrop2?"remove":"add"}</span><h1 className="ml-2">{storeQues2 && storeQues2.name? storeQues2.name.slice(0,45)+'...':"Select Question 2"}</h1></span> 

                      {showQueDrop2?
                      <div className="z-10 absolute top-10 left-0 bg-white  shadow-md rounded-md w-full text-gray-500" style={{height:'400px',overflowY:'auto'}}>

                        {questionsList && questionsList.length>0?
                        questionsList.map((question, index)=>
                        ((storeQues1.id!==question.id))?
                        <span key={index} onClick={()=>this.handleSelectQues(question,2)} className=
                        {(storeQues2.id===question.id)?
                          "bg-blue-500 text-white cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b"
                        :
                        "hover:bg-gray-500 hover:text-white bg-white text-gray-500 cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b"
                        }
                        > 
                          {question.name}
                        </span>
                        :null)
                        :null}
                            


                      </div>
                      :null}


                      </div>


                <div className="flex justify-center"> <button onClick={()=>this.handleSaveCrossTab()} className=" cursor-pointer bg-blue-500 text-white text-xs px-6 py-2 rounded-sm my-4 ">Submit</button></div>
              </div>


              </div>


              <div className="bg-white rounded w-full shadow-lg px-4 m-4 ml-2">
              <div className="text-lg font-semibold py-4">CrossTab Comparison</div>

              <div className=" py-4">
                {ScoreList && ScoreList.length>0?
                <div className="table  w-full p-2  overflow-x-auto ">
                  <table className="w-full border ">
                    <thead className>

                      <tr className="bg-gray-50 border-b">
                        <th className="p-2 border-r cursor-pointer text-sm font-normal ">
                          <div className="flex items-center justify-center">
                          
                          </div>
                        </th>
                        <th className="p-2 border-r cursor-pointer text-sm font-normal ">
                          <div className="flex items-center justify-center">
                          
                          </div>
                        </th>
                        <th colSpan={7} className="p-2 border-r cursor-pointer text-sm font-normal text-gray-800">
                          <div className="flex items-center justify-center">
                          {storeQues2 && storeQues2.name? storeQues2.name:"Question 2"}
                          </div>
                        </th>
                      </tr>



                      <tr className="bg-gray-50 border-b">
                        <th className="p-2 border-r cursor-pointer text-sm font-normal text-gray-800">
                          <div className="flex items-center justify-center">
                           
                          </div>
                        </th>

                        <th className="p-2 border-r cursor-pointer text-sm font-normal text-gray-800">
                          <div className="flex items-center justify-center">
                            
                          </div>
                        </th>

                        {optionList2 && optionList2.length>0?
                        optionList2.map((option)=>
                        <th className="p-2 border-r cursor-pointer text-sm font-normal ">
                          <div className="flex items-center justify-center font-bold">
                            {option}
                          </div>
                        </th>
                        )
                        :null}
                      
                      </tr>
                    </thead>
                    <tbody>
                      {/* one row table */}
                      {ScoreList && ScoreList.length>0?
                        ScoreList.map((item,index)=>
                        <tr className="bg-gray-100 text-center border-b text-sm text-gray-600">
                          {index===0?
                          <td className="p-2 border-r " rowSpan={7}>
                            {storeQues1 && storeQues1.name? storeQues1.name:"Question 1"}
                          </td>
                          :null}

                          <td className="border-r"> <p className=" p-2 font-bold">{item.q1}</p></td>

                         {optionList2 && optionList2.length>0?
                          optionList2.map((option)=>
                          <>
                            <td className=" border-r">
                            <p className=" pt-2 border-b font-semibold">{item&&item[option]&&item[option].count?item[option].count:0}</p>
                            <p className=" pb-2 text-blue-400 font-semibold">{item&&item[option]&&item[option].per?item[option].per.toFixed(2)+"%":"0%"}</p>
                            </td>
                          </>
                          )
                          :null}

                        </tr>
                        )
                        :null}

                    </tbody>
                  </table>
                </div>
                :
                <>
                <div className="text-gray-500 text-xl text-center">Please select both questions</div>
                </>
                }
              </div>


                  
              </div>




          </div>
        </div>
      </>
      :
      <>
        {!loading?
        <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>:null}
        </>
      } 
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
