import React from "react";

export default function SurveyList(props) {
  let {handleViewPackage,handleSuveyPackageSubmit,packageList , getSurveyListData,handleSurveyLibQuestions, getSurveyListTotal, handleSurveyLibraryClose,getSurveyFromLibData,
    onEditSurvey,onPreviewSurvey,onReportSurvey,onPublishSurvey, onDeleteSurvey} = props;

    console.log('getSurveyFromLibData:',getSurveyFromLibData);

  return (
   <>
     
  <main className="px-6 bg-gray-100 h-screen">
    <div className="container py-12 mx-auto">
      <div className="xl:flex lg:flex md:flex justify-between items-center pb-8">
        

        
        <div className="xl:pb-0 lg:pb-0 pb-4 xl:flex-none flex justify-between items-center">
            <i onClick={()=>handleSurveyLibraryClose()} className="fa fa-arrow-left pr-6 pt-1"></i><h1 className="cursor-pointer text-xl">Plus Survey Package Library</h1> 
            <h1 onClick={()=>handleSuveyPackageSubmit()} className="bg-blue-500 px-4  py-1 cursor-pointer ml-10  text-white">Continue</h1>
        </div> 



        {/* <div className="flex items-center justify-center">
          <div className=" relative  text-gray-600">
            <input type="search" name="search"  placeholder="Search by name. . ." className="border border bg-white py-2  px-4 pr-14 text-xs focus:outline-none"  />
            <button type="submit" className="absolute right-0 top-0 my-3 mr-4">
              <svg className="text-gray-600 h-3 w-3 fill-current" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 56.966 56.966" style={{enableBackground: 'new 0 0 56.966 56.966'}} xmlSpace="preserve" width="512px" height="512px">
                <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
              </svg>
            </button>
          </div>
        </div> */}


      </div>


      <div className="text-gray-600 justify-center grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-2 ">

        {getSurveyFromLibData && getSurveyFromLibData.length>0?
            getSurveyFromLibData.map((survey,index)=>
                <div onClick={()=>handleViewPackage(survey)} key={index} style={{height:"300px"}}
                style={{
                    background:packageList.some(prev=>prev === survey.id)?'linear-gradient(#1a3db0, #1ab09c)':'#fff',
                }}
                className="select-none cursor-pointer max-w-md bg-blue-500 shadow-md rounded-lg border-2 hover:border-blue-700">
                    <div className="justify-center align-center"> 
                    <h1 style={{
                      color:packageList.some(prev=>prev === survey.id)?'#fff':'#000'
                    }} className="text-center text-white text-2xl  my-16 px-2">{survey.name}</h1>
                    </div>   
                </div>
            )
        :null}

        


      </div>

    </div></main>
   </>
  );
}
