import React, { Component } from 'react';
import { connect } from 'react-redux';
import DriverComparionTable from './Tables/DriverComparionTable';


class IndexTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Tabtype:1,

        showTable:true,
        showQStack:true,

        optionTypeInner:2
  
    }
  }

  componentDidMount() {

  }

  handleQuesViewType=(check)=>{
    this.setState({showQStack:!check})
  }

  handleViewType=(check)=>{
    this.setState({showTable:!check})
  }

  handleBehaviourType=(type)=>{
    let { handleManagerDriver,handleManagerQuestion, optionTypeM } = this.props;
    this.setState({Tabtype:type})

    handleManagerQuestion(optionTypeM);
    handleManagerDriver(optionTypeM);

    // if(type===1){
    //   handleManagerQuestion(optionTypeM);
    // }
    // else if(type===2){
    //   handleManagerDriver(optionTypeM);
    // }

  }


  handleOption=(data)=>{
    this.setState({optionTypeInner:data});
  }





  SubmitManagerActionPlan=(data, isAssignAll)=>{
    let { AssignManagerActionPlan } = this.props;





    console.log('data-->',data)
    if(isAssignAll){
      let datatemp = [];
      if(data && data.length>0){
        data.forEach((item)=>{
          datatemp.push({
            dimensions:item.dimensions,
            emp_id: item.emp_id,
            managerName: item.managerName
          });
        });
      }
      AssignManagerActionPlan(datatemp);
    }
    else{
      let datatemp = {
        dimensions:data.dimensions,
        emp_id: data.emp_id,
        managerName: data.managerName
      }
      let list = [datatemp];
      AssignManagerActionPlan(list);
    }
  }


  render() {
    let { getAllManagerActionPlanData, DemographicIndexForManagerData, GetParameterLabel, CollectDataManagerForActionPlan,managerCollectionList, getQuestionOverallData,ParaLabelList,showMultiple,optionTypeM,DemographicDriverManagerData,temShowMultiple, ManagerListQuestionDataObj,indexType,optionType,getCommentData, loading, getQuestionData,Name, CurrentManagerName, ManagerName, DefaultManagerName ,ManagerIndexDriverData, ManagerDynaObj, EmpFilterData, CurrentManagerData, QuesDynaObj,ManagerIndexQuestionData } = this.props;
    let { Tabtype, showTable, showQStack, optionTypeInner } = this.state;

    // IndexName: "TimeAvg"
    // code: "191783_TimeAvg"
    // isLow: true
    // managerId: "191783"
    // managerName: "Abhishek Roy"
    // managerScore: 16.666666666666664
    // overallScore: 63.923521370621245

    console.log('managerCollectionList: ',managerCollectionList);
    let MngrActPlan = [];
    if(getAllManagerActionPlanData && getAllManagerActionPlanData.length>0){
      MngrActPlan = getAllManagerActionPlanData;
    }

    let ManagerWiseDimensionCollection = [];
    if(managerCollectionList && managerCollectionList.length>0){
      managerCollectionList.forEach((item)=>{
        let getIndex = ManagerWiseDimensionCollection.findIndex(prev=>prev.emp_id===item.emp_id);
        if(getIndex===-1){
          let temp = {
            "managerName":item.managerName,
            "emp_id":item.emp_id,
            "isAssigned":MngrActPlan.findIndex(prev=>prev.emp_id===item.emp_id)!==-1?true:false,
            "dimensions":[]
          }
          temp["dimensions"].push(
            {
              "dimensionName":item.IndexName.slice(0, -3),
              "managerScore":item.managerScore,
              "overallScore":item.overallScore
            }
            );
          ManagerWiseDimensionCollection.push(temp);
        }
        else{
          ManagerWiseDimensionCollection[getIndex]["dimensions"].push(
            {
              "dimensionName":item.IndexName.slice(0, -3),
              "managerScore":item.managerScore,
              "overallScore":item.overallScore
            }
          );
        }
      });
    }

    console.log('xxxxxxxxxxx DemographicDriverManagerData: ',DemographicDriverManagerData);
    console.log('xxxxxxxxxxxx getAllManagerActionPlanData: ',getAllManagerActionPlanData);
    
    return (
      <>
{true?
      <>
      {DemographicDriverManagerData && DemographicDriverManagerData.length>0?
      <div class="bg-white rounded  shadow-md px-2  m-4">

                      <div className="md:flex justify-between py-2">
                        <div className="text-lg font-semibold">{Name} Behaviour Driver</div>

                      </div>

                      {showTable?
                      <>
                      {(!showMultiple || !true)?
                        null
                        :
                        < DriverComparionTable
                          getQuestionData={getQuestionData}
                          Name={Name}
                          ManagerDynaObj={ManagerDynaObj}
                          EmpFilterData={EmpFilterData}
                          CurrentManagerData={CurrentManagerData}
                          ManagerIndexDriverData={ManagerIndexDriverData}
                          DemographicDriverManagerData={DemographicDriverManagerData}
                          optionTypeM={optionTypeM}
                          ParaLabelList={ParaLabelList}
                          CollectDataManagerForActionPlan={CollectDataManagerForActionPlan}
                          managerCollectionList={managerCollectionList}
                          GetParameterLabel={GetParameterLabel}
                          DemographicIndexForManagerData={DemographicIndexForManagerData}
                        />
                      }
                        </>
                      :
                      null
                      }



        </div>
        :
        <div className="bg-white rounded  shadow-md px-2  m-4 p-5 h-screen">
          <div className="text-center pt-32 text-gray-400 text-xl flex justify-center items-center">
          <span className="text-2xl">Please Select Single or Multiple Managers </span>
          <span className="material-icons p-4 ">arrow_upward</span></div>
        </div>
        
        }

        {managerCollectionList && managerCollectionList.length>0?
        <div className="bg-white rounded  shadow-md px-2  m-4 pb-4">
        <div className="md:flex justify-between py-2 border-b border-gray-300 py-4">
          <div className="text-lg font-semibold">Selected Manager's Dimensions</div>
          {/* <div onClick={()=>this.SubmitManagerActionPlan(ManagerWiseDimensionCollection,true)} className="cursor-pointer border border-green-500 text-green-500 mx-2 rounded-lg px-4 pt-1 ">Assign All</div> */}

        </div>

            {ManagerWiseDimensionCollection && ManagerWiseDimensionCollection.length>0?
            ManagerWiseDimensionCollection.map((manager)=>
            <>
              <div className="m-2 my-2 border-b border-gray-300 py-4">
                <div className="flex">
                  <div className="font-semibold py-2 mr-2" style={{width:"400px",overflowX:'hidden'}}>{manager.managerName}</div>
                  {manager.isAssigned?
                  <div className="cursor-pointer border border-green-500 text-green-500 mx-2 rounded-lg px-4 pt-1 ">Assigned</div>
                  :
                  <div onClick={()=>this.SubmitManagerActionPlan(manager,false)} className="cursor-pointer border border-blue-500 text-blue-500 mx-2 rounded-lg px-4 pt-1 ">Submit</div>
                  }
                </div>

                <div className="flex gap-2">
                  {manager && manager.dimensions && manager.dimensions.length>0?
                    manager.dimensions.map((item)=>{
                      return item.dimensionName
                    }
                    ).join(", "):null}
                </div>


              </div>
              </>
            ):null}
        </div>
          :null}

      </>
      :
<>
{!loading?
<div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
:null}
</>
}
    
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexTables);
