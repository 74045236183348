import React, { Component } from 'react';
import { connect } from 'react-redux';

class QuestionTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      OptimizedQuesDynaObj:{}
  
    }
  }

  componentDidMount() {

  }


  render() {
    let { GetParameterLabel, DemographicRestriction,getQuestionOverallData,getQuestionData,optionTypeM,ManagerListQuestionDataObj, Name, QuesDynaObj, ManagerIndexQuestionData, EmpFilterData, ManagerDynaObj, CurrentManagerData } = this.props;
    let { OptimizedQuesDynaObj } = this.state;

    let colorList = ['#dab3ff','#ffdf91','#94ebcd','#ffb4b4']

    let NewQuesDynaObj=[]

    // console.log('QuesDynaObj; ----><------ ',QuesDynaObj)
    // console.log('ManagerIndexQuestionData; ----><------ ',ManagerIndexQuestionData)
    let ManagerParameterScore = ''
    if(ManagerDynaObj && ManagerDynaObj.length>0){
      ManagerDynaObj.forEach((obj)=>{
        if(obj.name===CurrentManagerData["ManagerPID"]){
          ManagerParameterScore=obj;
        }
      })
    }

    if(EmpFilterData && EmpFilterData.length>0){
      EmpFilterData.forEach((emp)=>{
        if(DemographicRestriction.findIndex(prev=>prev===emp.name)!==-1){
        OptimizedQuesDynaObj[emp.name]=[];
        }
      })
    }


    // OptimizedQuesDynaObj


    if(ManagerIndexQuestionData && ManagerIndexQuestionData.length>0){
                            
      ManagerIndexQuestionData.forEach((item)=>{
              if(OptimizedQuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name)===-1){
                OptimizedQuesDynaObj[item.filters[0].level].push(item);
              }
              else{
                  let getIndex = OptimizedQuesDynaObj[item.filters[0].level].findIndex(prev=>prev.name === item.name);
                  OptimizedQuesDynaObj[item.filters[0].level][getIndex] = item;
              }
          }
      )

  }


  // console.log('QuesDynaObj; ----><------ ',QuesDynaObj)

  // console.log('OptimizedQuesDynaObj; ----><------ ',OptimizedQuesDynaObj)






  // console.log('QuesDynaObj; ----><------ ',QuesDynaObj)

  // console.log('OptimizedQuesDynaObj; ----><------ ',OptimizedQuesDynaObj)


    // let paraList = []

    // let count = 0;
    // let rowSpan = 0;
    // let NewParaList=[]

    // if(getQuestionData && getQuestionData.length>0){

    //   console.log('getQuestionData',getQuestionData)

    //   getQuestionData.forEach((ques)=>{
    //     if(paraList.findIndex(prev=>prev===ques.parameterDimensionName)===-1){
    //       paraList.push(ques.parameterDimensionName);
    //       count+=1
    //     }
    //     else{
    //     }
    //   })

    //   let obj = {}
    //   paraList.forEach((para)=>{
    //     obj[para]=0
    //     getQuestionData.forEach((ques)=>{
    //       if(para===ques.parameterDimensionName){
    //         obj[para]+=1
    //       }
    //     })
    //   })


    //   NewParaList = Object.keys(obj).map((key) => [key, obj[key]])
    // //   console.log('data NewParaList: ',NewParaList)

    //   rowSpan=(getQuestionData.length/count)

    //   // console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,getQuestionData.length)

    // }

    let QuestionList =[]
    let FilterQuestionList = []



    // console.log('NewQuesDynaObj===>',NewQuesDynaObj)



    // console.log('FilterQuestionList: ',FilterQuestionList)  



//------------------------------------------multi manager start --------------------------------------------------------
console.log('------------------>ManagerListQuestionDataObj; ----><------ ',ManagerListQuestionDataObj)



    let New1QuestionList = [];
    let New1ManagerList = [];

    if(ManagerListQuestionDataObj && ManagerListQuestionDataObj.length>0){
      ManagerListQuestionDataObj.forEach((item)=>{
        let QuesData1x=[]
        if(item && item.ManagerData && item.ManagerData.length>0){
          item.ManagerData.forEach((item2)=>{
            let getIndex = New1QuestionList.findIndex(prev=>prev.name === item2.name)
            if(getIndex===-1){
              New1QuestionList.push({
                name:item2.name,
                para:item2.parameterDimensionName
              });
            } 
            else{
              New1QuestionList[getIndex]={
                name:item2.name,
                para:item2.parameterDimensionName
              }
            }

           
            let total = item2["1"] + item2["2"] + item2["3"] + item2["4"] + item2["5"] + item2["6"];
            let sum = item2["5"] + item2["6"];
            let score = (  ( (sum*100)/total ).toFixed(2)  ).toString();
    
            QuesData1x.push({
              name:item2.name,
              score:score,
              para:item2.parameterDimensionName
            })

            
          });
        }


        New1ManagerList.push({
          id:item.ManagerPID,
          name:item.ManagerName,
          data:QuesData1x
        })
        



        
      });
    }






    let paraList = []

let count = 0;
let rowSpan = 0;
let NewParaList1=[]

if(New1QuestionList && New1QuestionList.length>0){

  console.log('New1QuestionList',New1QuestionList)

  New1QuestionList.forEach((ques)=>{
    if(paraList.findIndex(prev=>prev===ques.para)===-1){
      paraList.push(ques.para);
      count+=1
    }
    else{
    }
  })

  let obj = {}
  paraList.forEach((para)=>{
    obj[para]=0
    New1QuestionList.forEach((ques)=>{
      if(para===ques.para){
        obj[para]+=1
      }
    })
  })


  NewParaList1 = Object.keys(obj).map((key) => [key, obj[key]])

  rowSpan=(New1QuestionList.length/count);


}

let NewParaList = []
let listPara = [];
if(optionTypeM===1){
  listPara = ["Time","Being","WorkLife","Achivement","Relationship","Meeningfull"];
}
else if(optionTypeM===2){
  listPara = ["Pride","Presence","Performance"];
}
else if(optionTypeM===5){
  listPara = ["Connect","ACT","Respect","Empower"];
}
if(listPara && listPara.length>0){
  listPara.forEach((item)=>{
    if(NewParaList1 && NewParaList1.length>0){
      NewParaList1.forEach((item1)=>{
        if(item1[0]===item){
          NewParaList.push(item1);
        }
      });
    }

  })
}








console.log('NewParaList:asdasda ',NewParaList)



    let NewQuesDynaObj2 = []
    if(NewParaList && NewParaList.length>0){
      NewParaList.forEach((para,index)=>{
            if(New1QuestionList && New1QuestionList.length>0){
              New1QuestionList.forEach((NewQues)=>{
                    if(NewQues.para === para[0]){

                      NewQuesDynaObj2.push({
                            "name":NewQues.name,
                            "para":NewQues.para
                          })

                    }
                  })
            }
        
    })
}
console.log('New1QuestionList: ',New1QuestionList)
console.log('NewQuesDynaObj2; ----><------ ',NewQuesDynaObj2)
console.log('New1ManagerList; ----><------ ',New1ManagerList)





   // console.log('QuestionList: ',QuestionList)  
   let paraStore = []
   let previousPara = ''
   if(NewQuesDynaObj2 && NewQuesDynaObj2.length>0){
     NewQuesDynaObj2.forEach((question)=>{
       let show = 1
       if(question.para === previousPara){
         show = 0 ;
       }
       else{
         show = 1
       }

       paraStore.push({
         "para":question.para,
         "show":show
       })

       show = 1;
       previousPara=question.para;


     })
   }
   console.log('paraStore: ',paraStore)  


   console.log('QuestionList; ----><------ ',QuestionList)

   console.log('NewQuesDynaObj; ----><------ ',NewQuesDynaObj)

   console.log('NewParaList; ----><------ ',NewParaList)

//------------------------------------------multi manager end --------------------------------------------------------


let overAllNewObj = {

}

if(getQuestionOverallData && getQuestionOverallData.length>0){
  getQuestionOverallData.forEach((ques, queIndex)=>{
      let total = ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];
      let sum = ques["5"] + ques["6"];
      let score = (  ( (sum*100)/total ))
      
      overAllNewObj[ques.name] = score;

  });
}




console.log('NewParaList: ',NewParaList);
console.log('overAllNewObj: ',overAllNewObj);
console.log('getQuestionData: ',getQuestionData);


console.log('NewQuesDynaObj2: ',NewQuesDynaObj2);

       
function getColor(score, index) {

  if(score===0){
    return {Color:'#ffffff',diffTextColor:'#000000'}

  }
  else{

    let diffNo = parseInt(Math.abs(parseFloat(overAllNewObj[index]) - parseFloat(score)));
    // console.log("diffNo:",diffNo)
    if(parseFloat(overAllNewObj[index]) <= parseFloat(score)){ //greater (blue)
      if(diffNo>=0 && diffNo<=2){
        return {Color:'#33cc33',diffTextColor:'#ffffff'}

      }
      else if(diffNo>=2 && diffNo<=12){
        return {Color:'#70db70',diffTextColor:'#00000'}

      }
      else{
        return {Color:'#adebad',diffTextColor:'#00000'}
      }

    }
    else{ //lesse (red)
      if(diffNo>=0 && diffNo<=2){
        return {Color:'#f5babd',diffTextColor:'#000000'}

      }
      else if(diffNo>=2 && diffNo<=12){
        return {Color:'#ea95a1',diffTextColor:'#000000'}

      }
      else{
        return {Color:'#d03e4d',diffTextColor:'#ffffff'}

      }
    }
  }

}



    return (
      <>
      {NewQuesDynaObj2 && NewQuesDynaObj2.length>0?

        <div className="pb-2 whitespace-nowrap overflow-x-auto ">
        <table className="w-full border  ">
                    <thead>
                      <tr className="bg-gray-100 border-b text-xs   text-gray-800">


                        {/* <th className="w-1/4 text-left border-separate border border-black-800 bg-blue-500 text-white"></th> */}
                        <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{"Dimension"}</div></th>
                        <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-left">{"Question"}</div></th>
                        <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{"Overall (%)"}</div></th>

                        {/* <th className="w-1/2 text-left border-separate border border-black-800 bg-blue-500 text-white">{Name} Items</th> */}
                        {/* <th className="w-30 text-left border-separate border border-black-800 bg-blue-500 text-white">Question Score</th> */}

                        {New1ManagerList && New1ManagerList.length>0?
                          New1ManagerList.map((mngr)=>
                              <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-center">{mngr.name}</div></th>
                          )
                        :null}

                      </tr>
                    </thead>
                    <tbody>
                      <>
                      {NewQuesDynaObj2 && NewQuesDynaObj2.length>0?
                          NewQuesDynaObj2.map((ques, queIndex)=>
                            <tr className="border-separate border border-black-800">
                                {paraStore[queIndex].show?
                                  (NewParaList && NewParaList.length>0)?
                                    NewParaList.map((para,index3)=>
                                      (para[0]===ques.para)?
                                        // <td className="border-separate border border-black-800" rowSpan={para[1]}>{ques.para}</td>
                                        <td className="p-2 text-sm border border-b font-semibold text-center" rowSpan={para[1]}>    
                                        {GetParameterLabel(ques.para)}
                                      </td>
                                      :null
                                    )
                                  :null
                                :null}
                                <td className="tooltip-response-question1 text-sm pl-4 border border-b" style={{fontSize:'13px'}}>
                                  <div className="cursor-pointer whitespace-pre-line w-96">{ques.name}</div>
                                  {/* <div className="tooltip-response-question2" style={{padding:'2px 5px'}}>
                                      {ques.name}
                                    </div> */}
                                </td>
                                <td className="p-2 text-sm border border-b text-center" style={{fontSize:'13px'}}>
                                    {overAllNewObj && overAllNewObj[ques.name]?overAllNewObj[ques.name].toFixed(2):""}
                                </td>
                                {New1ManagerList && New1ManagerList.length>0?
                                  New1ManagerList.map((mngr,index)=>
                                    (mngr && mngr.data && mngr.data.length>0)?
                                      mngr.data.map((item)=>
                                        (ques.name === item.name)?
                                        <td className="p-2 font-normal bg-gray-50 text-gray-800 border border-gray-300 text-sm text-center" style={{

                                          background:getColor(item && item.score?item.score:0,ques.name).Color,
                                          color:getColor(item && item.score?item.score:0,ques.name).diffTextColor

                                        }}>{item && item.score?item.score+'%':'-'}</td>
                                        :null
                                      )
                                    :null
                                  )
                                :null}

                                
                            </tr>
                          )
                        :null}
            </>
          </tbody>
        </table>
        </div>

      :null}
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionTable);

