import React, { Component } from 'react';
import { connect } from 'react-redux';
// import QuestionMeanBarChart from '../QuestionMeanBarChart/QuestionMean';

import QuestionMeanHeatMap from '../QuestionMeanHeatMap/QuestionMeanHeatMap';
import QuestionMeanBarChart from '../QuestionMeanBarChart/QuestionMean';


class QuestionAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue:'none',
      currFilterName:'none',
      showHeatMap:true
    }
  }
  componentDidMount() {

  }

  handleFilterSave=(name)=>{
    this.setState({currFilterName:name});
    let { handleQuestionMeanScore } = this.props;
       handleQuestionMeanScore(name);

  }

  handleFilterValueSave=(name,value)=>{
    this.setState({currFilterValue:value});
    let { handleQuestionMeanFilter } = this.props;
    // if(name !=='none' && value !=='none'){
    //   handleQuestionMeanFilter(name,value)
    // }
  }

  handleViewType=(value)=>{
    this.setState({showHeatMap:value});

    // if(value===2){
    //   let { handleQuestionMeanScore } = this.state;
    //   handleQuestionMeanScore("Department");
    // }
  }


  render() {
    let { cardType, getIndexFilterData, handleQuestionMeanScore, handleResponseDistributionFilter, ItemSaveData, getQuestionDistData,
       EmpFilterData, getMeanScoreQwiseData, handleDemographicOverallQFilter } = this.props;

    let { currFilterName, currFilterValue, showHeatMap } = this.state;

    console.log('getMeanScoreQwiseData:',getMeanScoreQwiseData);



      let MeanScoreQwiseList = []
      if(currFilterValue !== 'none'){
          if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
            getMeanScoreQwiseData.forEach((ques)=>{
              if(ques.dmgName === currFilterValue){
                if(cardType==='overall'){
                  MeanScoreQwiseList.push(ques);
                }
                else if(cardType==='index'){
                    if(ItemSaveData && ItemSaveData.name && (ques.parameterBucketName === ItemSaveData.name)){
                      MeanScoreQwiseList.push(ques);
                    }
                }
              }
            })
          }
      }
      else{
        MeanScoreQwiseList = getMeanScoreQwiseData;
      }


      let FilterValueList = []
      if(getMeanScoreQwiseData && getMeanScoreQwiseData.length>0){
        getMeanScoreQwiseData.forEach((ques)=>{
          if(ques.dmg === currFilterName){
            if(!FilterValueList.some(prev=>prev === ques.dmgName) && ques.dmgName){
              FilterValueList.push(ques.dmgName);
            }
          }
        })
      }
    

    let overallQuestion= {
      "score":0
    }
    if(ItemSaveData && ItemSaveData.mean){
      overallQuestion["score"] = parseFloat(ItemSaveData.mean);
    }


    return (
      <>

    <div className="container mx-auto px-2 mt-2">

          <div className="text-lg px-2">
            <i className="show Table" onClick={()=>this.handleViewType(true)} style={{opacity:(showHeatMap)?1:0.3}} className="cursor-pointer fa fa-table mx-2"></i> 
            <i className="show Radar" onClick={()=>this.handleViewType(false)} style={{opacity:(!showHeatMap)?1:0.3}} className="cursor-pointer fa fa-bar-chart mx-2"></i> 
          </div>
          
          {showHeatMap?
          <QuestionMeanHeatMap  cardType={cardType} getIndexFilterData={getIndexFilterData} handleQuestionMeanScore={handleQuestionMeanScore} handleResponseDistributionFilter={handleResponseDistributionFilter} ItemSaveData = {ItemSaveData} getQuestionDistData = {getQuestionDistData} EmpFilterData={EmpFilterData} getMeanScoreQwiseData={getMeanScoreQwiseData} handleDemographicOverallQFilter={handleDemographicOverallQFilter}/>
          :
          <QuestionMeanBarChart  cardType={cardType} getIndexFilterData={getIndexFilterData} handleQuestionMeanScore={handleQuestionMeanScore} handleResponseDistributionFilter={handleResponseDistributionFilter} ItemSaveData = {ItemSaveData} getQuestionDistData = {getQuestionDistData} EmpFilterData={EmpFilterData} getMeanScoreQwiseData={getMeanScoreQwiseData} handleDemographicOverallQFilter={handleDemographicOverallQFilter}/>
          }

    </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionAnalysis);
