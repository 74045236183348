import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from './Filter';
import HeatMap from './Components/HeatMap';
import DonutChart from '../../../../Overview/Components/QuestionTypeCards/Charts/DonutChart';
import HorizontalBarChart from '../../../../Overview/Components/QuestionTypeCards/Charts/HorizontalBarChart';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName:'none',
      viewScore:true,
      showLeaderBoard:false,
      showFilter:false,
      showValue:'',
      XAxis:""
    }
  }
  componentDidMount() {

  }
  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }

  handleFilterSave=(name)=>{
    this.setState({currFilterName:name})
  }


    
  handleMultifilterIndexScore2=(finalFilter, XAxis)=>{
    console.log(':----------------------------------------------------------------------------------------------------------------XAxis:-------------------->',finalFilter, XAxis)

    let {handleOthersFilterCall}= this.props;
    this.setState({FilterValues:finalFilter, XAxis:XAxis});
    handleOthersFilterCall(finalFilter,XAxis,11);

  }

  render() {
    let { 
      getQuestionOthersResultData, currentQuestionId, EmpFilterData } = this.props;

    let { currFilterName ,FilterValues, XAxis } = this.state;

    //-------------------------------------------------------------------------------------------------------------------------
    let nEwAxis = "";
    if(XAxis){
     nEwAxis = XAxis;
    }
    else if(EmpFilterData && EmpFilterData.length>0){
        nEwAxis = EmpFilterData[0].name;
    }
    

      console.log("==========>EmpFilterData",EmpFilterData)



    let QuestionName = "";

    function FormattFun (inputData){
      let LIST = []
      if(inputData && inputData.length>0){
        inputData.forEach((ques)=>{


          if(currentQuestionId === ques.total[0]._id.questionId){


              let temp={
                name:ques && ques.question[0] && ques.question[0].name? ques.question[0].name:"",
                answerList:[]
              }
              QuestionName= temp["name"];

              if(ques && ques.total && ques.total.length>0){
                ques.total.forEach((item)=>{
                  console.log('item: -------->nEwAxis',item)
                  let ans={
                    count:item.count,
                    answerText:item["_id"]["answerText"],
                    template:item["_id"]["qtemplate"],
                    dmgValue:item["_id"][nEwAxis]
                  }
                  temp["answerList"].push(ans);
                });
              }
              LIST.push(temp);
          }
        });
       }
       return LIST
     }

     let NPSQuesList = FormattFun(getQuestionOthersResultData);


    console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxgetQuestionOthersResultData: ',getQuestionOthersResultData)

    console.log('NPSQuesList==============>: ',NPSQuesList)

     let totalCount = 0;
     
     let DemographicValues = [];
     let DemographicValuesObj = {};

     let item = NPSQuesList[0];


     
    let AnswerObjOverall = {};
    if(item && item.answerList && item.answerList.length>0){
      item.answerList.forEach((ans)=>{

        if(AnswerObjOverall && AnswerObjOverall[ans.answerText]){
          AnswerObjOverall[ans.answerText] += (ans.count);
        }
        else{
          AnswerObjOverall[ans.answerText] = (ans.count)
        }

      });
    }

    console.log('AnswerObjOverall==============>: ',AnswerObjOverall)

    let optionList = Object.keys(AnswerObjOverall);
    console.log('optionList==============>: ',optionList)

     let AnswerObjList  = [];

     if(item && item.answerList && item.answerList.length>0){
       item.answerList.forEach((ans)=>{

        let getIndex = DemographicValues.findIndex(prev=>prev===ans.dmgValue);
        if(getIndex===-1){
          DemographicValues.push(ans.dmgValue);
          DemographicValuesObj[ans.dmgValue] = {}
          if(optionList && optionList.length>0){
            optionList.forEach((opt)=>{
              DemographicValuesObj[ans.dmgValue][opt]=0;
            });
          }

        }
         console.log('ans (NPSQuesList)',NPSQuesList)
         console.log('dmgValue (NPS)',ans.dmgValue)

        let code = ans.answerText+ans.dmgValue;

        let getIndex1 = AnswerObjList.findIndex(prev=>prev.code===code);
        if(getIndex1===-1){
          AnswerObjList.push({
            "text":ans.answerText,
            "name":ans.dmgValue,
            "value":ans.count,
            "code":ans.answerText+ans.dmgValue
          });
        }
        else{
          AnswerObjList[getIndex1].value+=ans.count;
        }


 
       });
     }
 
     if(AnswerObjList && AnswerObjList.length>0){


      AnswerObjList.forEach(item1 => {

         let key = item1.text;
         let name = item1.name;
         let score = item1.value;

         totalCount++;
 

         if(optionList && optionList.length>0){
          optionList.forEach((opt)=>{
            if((key) === opt){

              DemographicValuesObj[name][opt]+=score;
               
             }
          });
        }
        //  if((key) === "Yes"){

        //   DemographicValuesObj[name]["Yes"]+=score;
           
        //  }
        //  if((key) === "No"){

        //   DemographicValuesObj[name]["No"]+=score;
        //  }
 
       });
     }
 



     let { getIndexFilterData } = this.props;


    return (
      <>
      <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">

                    <Filter 

                    showFilter={this.state.showFilter}
                    showValue={this.state.showValue}
                    closeFilter={this.closeFilter}

                    EmpFilterData2={EmpFilterData}
                    getIndexFilterData={getIndexFilterData}
                    handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        
                  />  
     

     </div>
                  <div onClick={()=>this.closeFilter(false)} className=" ">
                  <div className="flex w-full" >

                  {!(FilterValues && FilterValues.length>0) && false?
                  <>


                    <div className="bg-white rounded w-full shadow-lg px-4 m-4 pb-10">
                      <h1 className="py-4 font-semibold text-lg">{QuestionName}
                      <p className="text-sm font-semibold text-gray-500">Single Choice Question</p>
                      </h1>
                      {item && item.answerList[0] && item.answerList[0].template===1?
                        <HorizontalBarChart AnswerObj={AnswerObjOverall} name={"Overall"} templateId={1} stage={3}/>
                      :null}

                      
                    </div>


                    </>
                    :
                    <div className="bg-white rounded w-full shadow-lg px-4 m-4">
                    <div className="px-1 py-4 text-base text-gray-700 font-semibold flex justify-between items-center">
                    <h1 className="py-4 font-semibold text-lg">{QuestionName}
                      <p className="text-sm font-semibold text-gray-500">Single Choice Question</p>
                      <p className="text-base text-gray-700 font-semibold"><span className="text-blue-500">{(currFilterName!=='none')?"( "+currFilterName+" wise )":""}</span></p> 
                      </h1> 
                    </div>

                    <HeatMap 
                                // handleDistributionFilterNameSave={this.handleDistributionFilterNameSave}
                                // handleDistributionFilterValueSave={this.handleDistributionFilterValueSave}
                                // getDistributionIndexData={DistributionIndexData}
                                // getIndexDriverData={getIndexDriverData}
                                // currFilterName={CurrentFilterName}
                                // FilterValues={FilterValues}
                                // GetParameterLabel={GetParameterLabel}


                                AnswerObjOverall={AnswerObjOverall}
                                DemographicValues={DemographicValues}
                                DemographicValuesObj={DemographicValuesObj}
                                XAxis={nEwAxis}
                                optionList={optionList}
                              />
                  </div>
                    }

                  </div>
                </div>
                
                

                





      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
