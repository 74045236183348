
import { authHeader } from '../_helpers';
import { CONST } from '../_config';

export const dashboardService = {
    logout,
    getClientProfile,
    getSurvey,


    getManagerList,

    assignRole,


    getIndex,
    getEmpFilter,
    getIndexFilter,
    getDemographicIndex,

    getHappinessIndexV2,
    getEngagementIndexV2,
    getHopeIndexV2,
    getStressIndexV2,
    getManagerIndexV2,
    getLeaderIndexV2,
    getSeniorleadershipIndexV2,
    getOrgIndexV2,


    getDemographicIndexHappiness,
    getDemographicIndexEngagement,
    getDemographicIndexStress,
    getDemographicIndexManager,
    getDemographicIndexLeader,
    getDemographicIndexOrg,


    getHappinessIndexDriver,
    getEngagementIndexDriver,
    getStressIndexDriver,
    getManagerIndexDriver,
    getLeaderIndexDriver,
    getOrgIndexDriver,



    getHappinessIndexDriver,

    getQuestion,
    getQuestionOverall,
    getQuestionReport,
    getMultiManagerQuestionList,
    getQuestionFilter,


    getHappinessIndexV2Demographic,
    getEngagementIndexV2Demographic,
    getStressIndexV2Demographic,



    getDemographicIndexForManager,
    getManagerListDashboardManagerIndex,

    getAllBenchMark,

    getRespondantDetails,

    getManagerListDomainFilter,


    getQuestionManagerOfHappiness,
    getQuestionManagerOfEngagement,
    getQuestionManagerOfStress,
    getQuestionManagerOfManager,

    getIndexQuantile,

    getDemographicIndexScatter,



    getHappinessCorrelation,
    getHappinessCorrelationOverAll,

    getEngagementCorrelation,
    getEngagementCorrelationOverAll,

    getStressCorrelation,
    getStressCorrelationOverAll,

    getManagerCorrelation,
    getManagerCorrelationOverAll,

    getLeaderCorrelation,
    getLeaderCorrelationOverAll,



    
    getRWAnalysis,
    getTextQuestion,
    wordCloud,
    wordCloudText,
    wordCloudMultiText,
    AdvanceWordCloud,
    cooccurmatrix,
    neighborGraph,


    getEngagementRWA,


    cronbatchAlphaVariance,

    getQuestionFavorability,

    getHistoryIndex,

    getResponseAnalytics,

    getComment,



    getDemographicIndexMultiFilter,

    AssignManagerActionPlan,
    getManagerActionPlan,
    updateEmployeeTask,
    updateEmployeeResource,


    getAllManagerActionPlan,
    crossTab,
    crossTabDMG,
    crossTabAnalysis,
    getCorrelationQuestionType2,
    getAllParameterList,

    getCorrelationDimenionVsDimension,
    getCorrelationDimensionVsDimensionFilter,


    getHappinessQuestion,
    getEngagementQuestion,
    getHopeQuestion,
    getStressQuestion,
    getManagerQuestion,
    getLeaderQuestion,

    
};

async function logout() {
    localStorage.removeItem('adminuser');
    window.location.href = "#/login";
}

 function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        if (data.error) {
            if (data.code === 3) {
                logout();
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

async function getClientProfile() {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getClientProfile`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                ClientProfileData: data.data
            }
            return ProfileObj;
        });
}

async function getSurvey(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }

    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getSurvey`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let SurveyObj = {
                SurveyData: data.data
            }
            return SurveyObj;
        });
}





async function getEmpFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEmpFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let filterData = []
            if(data && data.data && data.data.length>0){
                data.data.forEach((filter)=>{
                    // if(["ManagerPID","Department","Function","Business"].findIndex(prev=>prev===filter.name)!==-1){
                    //     filterData.push(filter);
                    // }

                    filterData.push(filter);

                });
            }
            let IndexObj = {
                EmpFilterData: filterData
            }

            let getIndex = IndexObj["EmpFilterData"].findIndex(prev=>prev.name==="ManagerPID");
            if(getIndex===-1){
                IndexObj["EmpFilterData"].push({
                    "label":"ManagerPID",
                    "name":"ManagerPID"
                })
            }

            console.log('IndexObj: ',IndexObj)
            return IndexObj;
        });
}

async function getManagerList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getManagerList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                ManagerList: data.data
            }
            return IndexObj;
        });
}




async function assignRole(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)+ `/assignRole`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                CompanyEmployeeList: data.data
            }
            return IndexObj;
        });
}



// new api s -------------------------------->


async function getIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexData: data.data
            }
            return ProfileObj;
        });
}

async function getIndexFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getIndexByFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getIndexFilterData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getDemographicIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexData: data.data
            }
            return ProfileObj;
        });
}



async function getQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionData: data.data
            }
            return ProfileObj;
        });
}

async function getQuestionOverall(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionOverallData: data.data
            }
            return ProfileObj;
        });
}

async function getQuestionReport(value, template) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionReportData: {
                    "template":template,
                    "data":data.data
                }             
            }
            return ProfileObj;
        });
}

async function getQuestionFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getQuestionFilterData: data.data
            }
            return ProfileObj;
        });
}





async function getHappinessIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getHappinessIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHappinessIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getEngagementIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL) + `/getEngamentIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEngagementIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getHopeIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHopeIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHopeIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getStressIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getStressIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getManagerIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getManagerIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getLeaderIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getLeaderIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getSeniorleadershipIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getSeniorleadershipIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getSeniorleadershipIndexV2Data: data.data
            }
            return ProfileObj;
        });
}


async function getOrgIndexV2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getOrgIndexV2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOrgIndexV2Data: data.data
            }
            return ProfileObj;
        });
}




async function getDemographicIndexHappiness(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexHappiness`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexHappinessData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndexEngagement(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexEngagement`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexEngagementData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndexStress(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexStress`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexStressData: data.data
            }
            return ProfileObj;
        });
}

async function getDemographicIndexManager(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexManager`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexManagerData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicIndexLeader(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexLeader`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexLeaderData: data.data
            }
            return ProfileObj;
        });
}


async function getDemographicIndexOrg(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexOrg`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getDemographicIndexOrgData: data.data
            }
            return ProfileObj;
        });
}

async function getHappinessIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getHappinessIndexDriverData: data.data
            }
            return ProfileObj;
        });
}

async function getEngagementIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getEngagementIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getEngagementIndexDriverData: data.data
            }
            return ProfileObj;
        });
}

async function getStressIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getStressIndexDriverData: data.data
            }
            return ProfileObj;
        });
}

async function getManagerIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getManagerIndexDriverData: data.data
            }
            return ProfileObj;
        });
}



async function getLeaderIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getLeaderIndexDriverData: data.data
            }
            return ProfileObj;
        });
}




async function getOrgIndexDriver(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)
    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getOrgIndexDriver`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ProfileObj = {
                getOrgIndexDriverData: data.data
            }
            return ProfileObj;
        });
}



async function getHappinessIndexV2Demographic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessIndexV2Demographic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessIndexV2DemographicData: data.data
            }
            return IndexObj;
        });
}


async function getEngagementIndexV2Demographic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getEngagementIndexV2Demographic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementIndexV2DemographicData: data.data
            }
            return IndexObj;
        });
}


async function getStressIndexV2Demographic(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressIndexV2Demographic`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressIndexV2DemographicData: data.data
            }
            return IndexObj;
        });
}
async function getManagerListDashboardManagerIndex(value,mngr) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getIndexByFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerListDashboardManagerIndexData: {
                    "ManagerPID":mngr.id,
                    "ManagerName":mngr.name,
                    "ManagerData":data.data
                }
            }
            
            return IndexObj;
        });
}


async function getMultiManagerQuestionList(value,mngr) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getMultiManagerQuestionListData: {
                    "ManagerPID":mngr.id,
                    "ManagerName":mngr.name,
                    "ManagerData":data.data
                }
            }
            
            return IndexObj;
        });
}



async function getDemographicIndexForManager(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let ApiData = data.data;
            console.log('ApiData======?',ApiData)
            let PassData = []
            if(ApiData && ApiData.list && ApiData.list.length>0){
                ApiData.list.forEach((item)=>{
                    if(!(item&&item.isTest)){
                        let temp = {
                            ...item
                            // Function: item&&item.Function?item.Function:"",
                            // Business: item&&item.Business?item.Business:"",
                            // Department: item&&item.Department?item.Department:"",
                            // ManagerPID: item&&item.emp_id?parseInt(item.emp_id):"",
                            // id: item&&item.emp_id?parseInt(item.emp_id):"",
                            // emp_id: item&&item.emp_id?item.emp_id:"",
                            // name: item&&item.name?item.name:""
                        }
                        // temp["ManagerPID"] = item&&item.ManagerPID?parseInt(item.ManagerPID):"";
                        temp["id"] = item&&item.emp_id?parseInt(item.emp_id):"";
                        temp["emp_id"] = item&&item.emp_id?parseInt(item.emp_id):"";

                        PassData.push(temp);
                    }
                });
            }
            let IndexObj = {
                getDemographicIndexForManagerData: PassData
            }
            return IndexObj;
        });
}

async function getAllBenchMark(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getAllBenchMark`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getAllBenchMarkData: data.data
            }
            return IndexObj;
        });
}

async function getRespondantDetails(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getRespondantDetails`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getRespondantDetailsData: data.data
            }
            return IndexObj;
        });
}

async function getManagerListDomainFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getIndexByFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerListDomainFilterData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfHappiness(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfHappinessData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfEngagement(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfEngagementData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfStress(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfStressData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionManagerOfManager(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getQuestionManagerOfManagerData: data.data
            }
            return IndexObj;
        });
}


async function getIndexQuantile(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getIndexQuantile`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getIndexQuantileData: data.data
            }
            return IndexObj;
        });
}




async function getHappinessCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessCorrelationData: data.data
            }
            return IndexObj;
        });
}

async function getHappinessCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHappinessCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessCorrelationData: data.data
            }
            return IndexObj;
        });
}



async function getEngagementCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimensionVsDimensionFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementCorrelationData: data.data
            }
            return IndexObj;
        });
}


async function getEngagementCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimenionVsDimension`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementCorrelationData: data.data
            }
            return IndexObj;
        });
}



async function getStressCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressCorrelationData: data.data
            }
            return IndexObj;
        });
}

async function getStressCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getStressCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressCorrelationData: data.data
            }
            return IndexObj;
        });
}




async function getManagerCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerCorrelationData: data.data
            }
            return IndexObj;
        });
}
async function getManagerCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerCorrelationData: data.data
            }
            return IndexObj;
        });
}


async function getLeaderCorrelation(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderCorrelation`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getLeaderCorrelationData: data.data
            }
            return IndexObj;
        });
}

async function getLeaderCorrelationOverAll(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getLeaderCorrelationOverAll`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getLeaderCorrelationData: data.data
            }
            return IndexObj;
        });
}




async function getDemographicIndexScatter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexScatterData: data.data
            }
            return IndexObj;
        });
}



async function getRWAnalysis(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getRWAnalysis`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getRWAnalysisData: data.data
            }
            return IndexObj;
        });
}




async function getTextQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getTextQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getTextQuestionData: data.data.data
            }
            return IndexObj;
        });
}


async function wordCloud(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/wordCloud`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                wordCloudData: data.data
            }
            return IndexObj;
        });
}


async function AdvanceWordCloud(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/AdvanceWordCloud`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                AdvanceWordCloudData: data.data
            }
            return IndexObj;
        });
}


async function wordCloudText(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/wordCloudText`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                wordCloudTextData: data.data,
                wordTextKeyWord: value.keyWord
            }
            return IndexObj;
        });
}



async function wordCloudMultiText(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/wordCloudMultiText`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                wordCloudMultiTextData: data.data,
                wordMultiTextKeyWord: value.keyWord1+", "+value.keyWord2
            }
            return IndexObj;
        });
}


async function cooccurmatrix(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/cooccurmatrix`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                cooccurmatrixData: data.data
            }
            return IndexObj;
        });
}



async function neighborGraph(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/neighborGraph`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                neighborGraphData: data.data
            }
            return IndexObj;
        });
}



async function getEngagementRWA(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getEngagementRWA`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementRWAData: data.data
            }
            return IndexObj;
        });
}




async function cronbatchAlphaVariance(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/cronbatchAlphaVariance`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                cronbatchAlphaVarianceData: data.data
            }
            return IndexObj;
        });
}


async function getQuestionFavorability(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestionFavorability`, requestOptions)
        .then(handleResponse)
        .then(data => {
            console.log('getQuestionFavorabilityData: ',data)
            let IndexObj = {
                getQuestionFavorabilityData: data.data
            }
            return IndexObj;
        });
}





async function getHistoryIndex(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getHistoryIndex`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHistoryIndexData: data.data
            }
            return IndexObj;
        });
}


async function getResponseAnalytics(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getResponseAnalyticsFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getResponseAnalyticsData: data.data
            }
            return IndexObj;
        });
}



async function getComment(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getComment`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCommentData: data.data
            }
            return IndexObj;
        });
}


async function getDemographicIndexMultiFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getDemographicIndexMultiFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getDemographicIndexMultiFilterData: data.data
            }
            return IndexObj;
        });
}

async function AssignManagerActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/AssignManagerActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                AssignManagerActionPlanData: data.data
            }
            return IndexObj;
        });
}


async function getManagerActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getManagerActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerActionPlanData: data.data
            }
            return IndexObj;
        });
}



async function updateEmployeeTask(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/updateEmployeeTask`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                updateEmployeeTaskData: data.data
            }
            return IndexObj;
        });
}



async function updateEmployeeResource(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/updateEmployeeResource`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                updateEmployeeResourceData: data.data
            }
            return IndexObj;
        });
}



async function getAllManagerActionPlan(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getAllManagerActionPlan`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getAllManagerActionPlanData: data.data
            }
            return IndexObj;
        });
}


async function crossTab(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/crossTab`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                crossTabData: data.data
            }
            return IndexObj;
        });
}

async function crossTabDMG(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/crossTabDMG`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                crossTabDMGData: data.data
            }
            return IndexObj;
        });
}

async function crossTabAnalysis(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/crossTabAnalysis`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                crossTabAnalysisData: data.data
            }
            return IndexObj;
        });
}



async function getCorrelationQuestionType2(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationQuestionType2`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCorrelationQuestionType2Data: data.data
            }
            return IndexObj;
        });
}



async function getAllParameterList(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getParameterList`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getAllParameterListData: data.data
            }
            return IndexObj;
        });
}


async function getCorrelationDimenionVsDimension(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimenionVsDimension`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCorrelationDimenionVsDimensionData: data.data
            }
            return IndexObj;
        });
}

async function getCorrelationDimensionVsDimensionFilter(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getCorrelationDimensionVsDimensionFilter`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getCorrelationDimensionVsDimensionFilterData: data.data
            }
            return IndexObj;
        });
}


async function getHappinessQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHappinessQuestionData: data.data
            }
            return IndexObj;
        });
}



async function getEngagementQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getEngagementQuestionData: data.data
            }
            return IndexObj;
        });
}




async function getHopeQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getHopeQuestionData: data.data
            }
            return IndexObj;
        });
}



async function getStressQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getStressQuestionData: data.data
            }
            return IndexObj;
        });
}




async function getManagerQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getManagerQuestionData: data.data
            }
            return IndexObj;
        });
}


async function getLeaderQuestion(value) {
    let backendUrl = await JSON.parse(localStorage.getItem('backendUrl'));
    let header = new Headers({
        'Content-Type': 'application/json',
        "Authorization": authHeader().Authorization
    });
    const requestOptions = {
        method: "POST",
        headers: header,
        body: JSON.stringify(value)

    }
    return fetch((backendUrl?backendUrl:CONST.BACKEND_URL)  + `/getQuestion`, requestOptions)
        .then(handleResponse)
        .then(data => {
            let IndexObj = {
                getLeaderQuestionData: data.data
            }
            return IndexObj;
        });
}
