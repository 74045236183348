import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList:[],
      EmpIndexA:0,
      EmpIndexB:4
    }
  }
  componentDidMount() {

  }

  handleMatrix=(question)=>{
    let { stage } = this.props;
    if(stage==2){
      let { handleMatrixDetails } = this.props;
      handleMatrixDetails(question,stage)
    }
    
  }


  render() {
    let { stage , renderType } = this.props;
            


  
  let { getQuestionMatrixResultData, questionsList } = this.props;

  let MatrixList = [];
  if(getQuestionMatrixResultData && getQuestionMatrixResultData.length>0){
    getQuestionMatrixResultData.forEach((item)=>{
      let temp = {
        "name":item && item.question && item.question[0] && item.question[0].name?item.question[0].name:"",
        "optionsList":[],
        "qtemplate":item && item.total && item.total[0] && item.total[0]._id.qtemplate?item.total[0]._id.qtemplate:"",
        "qparentId":item && item.total && item.total[0] && item.total[0]._id.qparentId?item.total[0]._id.qparentId:"",
        "questionId":item && item.total && item.total[0] && item.total[0]._id.questionId?item.total[0]._id.questionId:"",
      }
      if(item && item.total && item.total.length>0){
        item.total.forEach((otp)=>{
          if(otp && otp._id){
            temp["optionsList"].push({
              "answer":otp && otp._id && otp._id.answer?otp._id.answer:"",
              "answerText":otp && otp._id && otp._id.answerText?otp._id.answerText:"",
              "heading":otp && otp._id && otp._id.heading?otp._id.heading:"",
              "count":otp && otp.count?otp.count:"",

            });
          }
        });
      }
      // item && item.total && item.total._id && item.total[0] && item.total[0]._id
      MatrixList.push(temp);
    });
  }

  function GetOriginalOptionList(mainQuesId){
    let OptionsList = []
    if(questionsList && questionsList.length>0){
        let getIndex = questionsList.findIndex(prev=>prev.id === mainQuesId);
        if(getIndex!==-1){
          OptionsList = questionsList && questionsList[getIndex] && questionsList[getIndex]["optionsList"]?questionsList[getIndex]["optionsList"]:[]
        }
    }

    return OptionsList;
  }

  let qMatrixList = []
  let GroupList = []
  if(MatrixList && MatrixList.length>0){
    MatrixList.forEach((item)=>{


      if(!item.qparentId){
        qMatrixList.push({
          ...item,
          "optionsList":GetOriginalOptionList(item.questionId),
          "SubQuestionList":[]
        })
      }
      else{
        GroupList.push(item)
      }



    });
  }


  let questionName = "";
  let question = "";

  if(qMatrixList && qMatrixList.length>0){
    qMatrixList.forEach((item,index1)=>{
      if(GroupList && GroupList.length>0){
        GroupList.forEach((subQues,index2)=>{
          if(subQues.qparentId===item.questionId){
            qMatrixList[index1]["SubQuestionList"].push(subQues)
          }
        });
      }
    });


    // if(renderType==="Allmatrix"){
      questionName = qMatrixList[0].name;
      question = qMatrixList[0];
    // }
    // else{

    // }



  }

  let ALLMatrixQuestionList = [];

  if(stage==2){
    ALLMatrixQuestionList = qMatrixList;
  }
  else{
    ALLMatrixQuestionList = qMatrixList && qMatrixList.length>0?[ qMatrixList[0] ]:[];
  }



    function FormatMatriXOptionHeading(question, isOption){
        let paraObjShow = {};
        if(question && question.optionsList && question.optionsList.length>0){
          question.optionsList.forEach((item)=>{
            if(paraObjShow && paraObjShow[item.heading]){
              paraObjShow[item.heading] += 1
            }
            else{
              paraObjShow[item.heading] = 1
            }
          });
        }
    
        let headingList = []
        let tempKey = Object.keys(paraObjShow);
        if(tempKey && tempKey.length>0){
          tempKey.forEach((item)=>{
          headingList.push({
            name:item,
            span:paraObjShow[item]
          })
        });
        }

        if(isOption){
          let OptionList = []
          if(headingList && headingList.length>0){
            headingList.forEach((heading)=>{
              if(question && question.optionsList && question.optionsList.length>0){
                question.optionsList.forEach((option)=>{
                  if(heading.name === option.heading){
                    OptionList.push(option)
                  }
                });
              }
            });
          }
          return OptionList
        }
        else{
          return headingList
        }

      }





    function getCountPerc(SubQues,option,heading){
      let total = 0;
      let count = 0;
      if(SubQues && SubQues.optionsList && SubQues.optionsList.length>0){
        SubQues.optionsList.forEach((item)=>{
          if(item.heading === heading){
            total += parseInt(item.count);
            if(item.answerText===option){
              count += parseInt(item.count);
            }
          }
        });
      }
      return (parseFloat((count*100)/total)).toString()+"%";
    }


    function getMatrixScore(sub){
      let temp = []
      if(getQuestionMatrixResultData && getQuestionMatrixResultData.length>0){
        getQuestionMatrixResultData.forEach((item )=>{
          if(item._id===sub.id){
            if(item && item.total && item.total.length>0){
              item.total.forEach((otp)=>{
                if(otp && otp._id){
                  temp.push({
                    "answer":otp && otp._id && otp._id.answer?otp._id.answer:"",
                    "answerText":otp && otp._id && otp._id.answerText?otp._id.answerText:"",
                    "heading":otp && otp._id && otp._id.heading?otp._id.heading:"",
                    "count":otp && otp.count?otp.count:"",
                  });
                }
              });
            }
          }
        });
      }
      return temp;
    }


    // -----------------------------------------------------------------------------------------------------------------------------------

    console.log('==========================================================>getQuestionMatrixResultData: ',getQuestionMatrixResultData);
    console.log('==========================================================>questionsList: ',questionsList);


    let MatricQuesList1 = []
    if(questionsList && questionsList.length>0){
      questionsList.forEach((question)=>{
        if(getQuestionMatrixResultData && getQuestionMatrixResultData.length>0){
          getQuestionMatrixResultData.forEach((item)=>{
            if(question.id === item._id){
              let temp = {
                ...question
              }
              let SubQuestionOption = []
              if(question && question.SubQuestionList && question.SubQuestionList.length>0){
                question.SubQuestionList.forEach((sub)=>{

                  let temp2 = {
                    ...sub,

                  }

                  
                  temp2["optionsList"] = getMatrixScore(sub);

                  SubQuestionOption.push(temp2);

                });
              }

              temp["SubQuestionList"] = SubQuestionOption;
              MatricQuesList1.push(temp)
            }
          });
        }
      });
    }

    let Question = MatricQuesList1 && MatricQuesList1.length>0? MatricQuesList1[0]:{}


    // let MatricQuesList1 = []
    // if(getQuestionMatrixResultData && getQuestionMatrixResultData.length>0){
    //   getQuestionMatrixResultData.forEach((item)=>{
    //     if(item.parent){
    //         let QuesMatrixMainId = item && item._id && item._id.questionId?item._id.questionId:"";
    //         if(QuesMatrixMainId===item.id){
    //           MatricQuesList1.push(item);
    //         }
    //     }
    //   });
    // }

    console.log('X==========================================================>Question: ',Question);



    function SingleScore (SubQues, option){
      let item = "";
      let total = 0;
      let count = 0;
      if(SubQues && SubQues.optionsList && SubQues.optionsList.length>0){
        SubQues.optionsList.forEach((item)=>{

          if((item.heading === option.heading)){
            total += item.count;
            if((item.answerText === option.name)){
              count += item.count;
            }
          }


        });
      }


      console.log('X==========================================================>count: ',count);

      
      return ((count*100)/total); 
    }



    return (
      <>
    
  <div className=" w-full   p-2">
  <div className="text-gray-700 text-lg font-semibold ">{questionName}</div>
  <table className="w-full border mt-4 " >
    <thead className="">
      
      

      <tr className=" border-b">
        
        
        <th className="p-2 border-r cursor-pointer text-sm font-medium">
          <div className="flex items-center justify-center">
          </div>
        </th>
        
        
        {FormatMatriXOptionHeading(Question, true) && FormatMatriXOptionHeading(question, false).length>0?
         FormatMatriXOptionHeading(question, false).map((heading, optionindex)=>
            <th className="w-2/6 border-r cursor-pointer text-sm  bg-green-400 text-white">
              <p className="w-full border-b  p-2 font-semibold">{heading.name.split("-")[0]}</p>
              <div className="flex font-normal ">
                
              {FormatMatriXOptionHeading(Question, true) && FormatMatriXOptionHeading(question, true).length>0?
              FormatMatriXOptionHeading(question, true).map((option, optionindex)=>(option.heading === heading.name)?
                <p className="w-full border-r p-2 font-semibold">{option.name}</p>
              
              :null ):null}
              </div>
            </th>
         ):null}


      </tr>


    </thead>
    <tbody>
      {Question && Question.SubQuestionList && Question.SubQuestionList.length>0?
        Question.SubQuestionList.map((SubQues)=>
            <tr className="bg-white  border-b text-sm"> 
                <td className=" border-r p-2 font-normal">{SubQues.name}</td>
                


                        
              {FormatMatriXOptionHeading(Question, true) && FormatMatriXOptionHeading(question, false).length>0?
              FormatMatriXOptionHeading(question, false).map((heading, optionindex)=>
              <td className="border-r "> 
                <div className="flex">
                  {FormatMatriXOptionHeading(Question, true) && FormatMatriXOptionHeading(question, true).length>0?
                  FormatMatriXOptionHeading(question, true).map((option, optionindex)=>(option.heading === heading.name)?
                  <div className="w-full flex justify-center font-semibold">{
                    SingleScore(SubQues, option)
                  }</div> 
                  :null ):null}
                </div>
              </td>
              ):null}



                 
          
          </tr>

      ):null}


    </tbody>
  </table>
</div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(PieChart);
