import React from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import moment from 'moment';


  export default function ReminderCampaign(props){
    let { 
      reminderformData,handleReminderInput, handleReminderMessageInput,campaignList,getCampaignEmployeesByIDData, actionList,
      
      
      campaignFormData, handleCampaignInput, handleMessageInput, handleReminderSubmit,employees,
        allEmployeesList, handleCampaignEmployees, EmpIndexA, EmpIndexB, prevEmpPage, nextEmpPage, SelectEmpPage, 
        currentEmpPage, SelectAllEmployees, selectAllEmp, showReminderEmployeeFilter,handleEmpReminderDropdown,empSelectRemindFilter, handleEmpDropdown, EmployeFilterList, 
        handleEmpRemindFilterSelect, empSelectFilter , LanguageObj, CurrentLanguage} = props;


          //--------------------------------------------------------------------------------

    let IndexList = []
    let pageCount = 7
    let IndexListIndex = []
    let indexA=0
    let indexB=0

    if(allEmployeesList && allEmployeesList.length>0){
    

     if(allEmployeesList && allEmployeesList.length>0){
      allEmployeesList.forEach((item,index)=>{
        if(index % pageCount === 0){
          IndexList.push(index)
        }
        if(index===(allEmployeesList.length-1)){
          IndexList.push(index)
        }
      })
     }
     
     console.log('IndexList: ',IndexList)

     if(IndexList && IndexList.length>0){
      IndexList.forEach((item,index)=>{
        if(index!==(IndexList.length-1)){
          IndexListIndex.push(index+1)
        }
      })
     }
     console.log('IndexListIndex: ',IndexListIndex)





     if(IndexListIndex && IndexListIndex.length>0){
      IndexListIndex.forEach((item,index)=>{
        if(item === currentEmpPage){

          if(index<(IndexList.length-2)){
            indexA=IndexList[index];
            indexB=IndexList[index]+(pageCount-1)
          }
          else{
            indexA=IndexList[index];
            indexB=IndexList[IndexList.length-1]
          }
        }
      })
     }

  }
     //--------------------------------------------------------------------------------



  console.log('getCampaignEmployeesByIDData:--->" ',getCampaignEmployeesByIDData)

    return (
      <>
    <div className="xl:flex lg:flex ">

      <div className="bg-white ml-4 mr-2 w-8/12 rounded-md  xl:mb-0 lg:mb-0 mb-2">
        <h2 className="px-4 bg-blue-500 py-2 text-white rounded-t-md">{LanguageObj && LanguageObj["reminder_campaign"+"_"+CurrentLanguage]? LanguageObj["reminder_campaign"+"_"+CurrentLanguage]:"Reminder Campaign"}</h2>
        <div className="px-4 py-4 overflow-y-auto" style={{height:"550px"}}>
          <label className="text-xs text-gray-700 font-medium">{LanguageObj && LanguageObj["campaign_title"+"_"+CurrentLanguage]? LanguageObj["campaign_title"+"_"+CurrentLanguage]:"Campaign Title"}</label><br />
          <input disabled={true} type="text" name="title" value={reminderformData.title} onChange={handleReminderInput} placeholder={LanguageObj && LanguageObj["campaign_title"+"_"+CurrentLanguage]? LanguageObj["campaign_title"+"_"+CurrentLanguage]:"Campaign Title"} className="text-sm text-gray-900 outline-none border-b py-1.5 w-full" /><br /><br />
          
          <label className="text-xs text-gray-700 font-medium">Reminder Type</label><br />

          <div className="cursor-pointer my-4 bg-white w-full border text-black flex justify-between px-2 py-1.5 items-center relative">
              <span className=" text-black text-xs font-semibold pr-4 w-full" onClick={()=>handleEmpReminderDropdown(showReminderEmployeeFilter)}>{
              reminderformData.actionId===2?LanguageObj && LanguageObj["reminder_to_incomplete"+"_"+CurrentLanguage]? LanguageObj["reminder_to_incomplete"+"_"+CurrentLanguage]:reminderformData.action:reminderformData.action
              }</span> 
              <span className="material-icons text-gray-500" onClick={()=>handleEmpReminderDropdown(showReminderEmployeeFilter)}>expand_more</span>

              {showReminderEmployeeFilter?
                    <div className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-gray-500 overflow-hidden">
                      {actionList && actionList.length>0?
                        actionList.map((item,index)=>
                          (item.id!==reminderformData.actionId)?
                          <span onClick={()=>handleEmpRemindFilterSelect(item)} className={(empSelectRemindFilter.template===item.type)?"inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100":"inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"}> 
                          {item.type}</span>
                          :null
                        )
                      :null}
                    </div>
              :null}


          </div>


          <label className="text-xs text-gray-700 font-medium">{LanguageObj && LanguageObj["email_subject"+"_"+CurrentLanguage]? LanguageObj["email_subject"+"_"+CurrentLanguage]:"Email Subject"}</label><br />
          <input type="text" name="subject" value={reminderformData.subject} onChange={handleReminderInput} placeholder="Email Subject" className="text-sm text-gray-900 outline-none border-b py-1.5 w-full" /><br /><br />
          
          <label className="text-xs text-gray-700 font-medium">{LanguageObj && LanguageObj["email_subject"+"_"+CurrentLanguage]? LanguageObj["email_subject"+"_"+CurrentLanguage]:"Email Subject"}</label><br />
          <input type="text" name="emailName" value={reminderformData.emailName} onChange={handleReminderInput} placeholder="Email Name" className="text-sm text-gray-900 outline-none border-b py-1.5 w-full" /><br /><br />

          <label className="text-xs text-gray-700 font-medium">{LanguageObj && LanguageObj["email_subject"+"_"+CurrentLanguage]? LanguageObj["email_subject"+"_"+CurrentLanguage]:"Email Message"}</label><br />
          {/* <textarea type="text" name="discption" rows={4} placeholder="Text here. ." className="text-sm text-gray-900 outline-none border-b py-1.5 border px-2 w-full resize-none" defaultValue={""} /> */}
          <div className="overflow-y-auto" style={{height:'150px'}}>
          <ReactQuill className="w-12/12" theme="snow" value={reminderformData.message} onChange={handleReminderMessageInput} placeholder="Message"/>
          </div>
          <br /><br />
          <label className="text-xs text-gray-700 font-medium">{LanguageObj && LanguageObj["team_name"+"_"+CurrentLanguage]? LanguageObj["team_name"+"_"+CurrentLanguage]:"Team Name"}</label><br />
          <input type="text" name="teamname" value={reminderformData.teamname} onChange={handleReminderInput} placeholder={LanguageObj && LanguageObj["team_name"+"_"+CurrentLanguage]? LanguageObj["team_name"+"_"+CurrentLanguage]:"Team Name"} className="text-sm text-gray-900 outline-none border-b py-1.5 w-full" /><br /><br />
          <label className="text-xs text-gray-700 font-medium">{LanguageObj && LanguageObj["created_date"+"_"+CurrentLanguage]? LanguageObj["created_date"+"_"+CurrentLanguage]:"Created Date	"}</label><br />
          <input disabled={true} type="text" name="teamname" value={moment.unix(reminderformData.createdAt / 1000).format("DD-MM-YYYY, HH:mm") } onChange={handleReminderInput} placeholder="Created At" className="text-sm text-gray-900 outline-none border-b py-1.5 w-full" /><br /><br />
          <div className="flex justify-center py-6">
            <button className="bg-blue-500 text-white rounded py-2.5 px-6 text-sm hover:shadow-lg" onClick={()=>handleReminderSubmit()}>{LanguageObj && LanguageObj["send_reminder"+"_"+CurrentLanguage]? LanguageObj["send_reminder"+"_"+CurrentLanguage]:"SEND REMINDER"}<span className="fa fa-paper-plane px-2" /></button>
          </div>
        </div>
      </div> 






      <div className="bg-white w-full  xl:ml-4 lg:ml-4 rounded-md">
        <h2 className="px-4 bg-blue-500 py-2 text-white rounded-t-md">{LanguageObj && LanguageObj["employees"+"_"+CurrentLanguage]? LanguageObj["employees"+"_"+CurrentLanguage]:"Employees"}</h2>
        <div className="px-4 py-4 overflow-y-auto overflow-x-hidden" style={{height:"550px"}}>
          {/* serch and button */}
          <div className="flex justify-between">

          </div>
          <div className="flex flex-col ">
            <div className="py-2 font-semibold">{
             reminderformData.actionId===2?LanguageObj && LanguageObj["reminder_to_incomplete"+"_"+CurrentLanguage]? LanguageObj["reminder_to_incomplete"+"_"+CurrentLanguage]:reminderformData.action:reminderformData.action
            }</div>

            <div className="-my-2 py-2 overflow-x-auto whitespace-nowrap sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
              <div className="align-middle inline-block min-w-full overflow-hidden  sm:rounded-lg border border-gray-200">
                <table className="min-w-full">
                  <thead>
                  <tr>
                      <th className="px-4 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                        <input type="checkbox" checked={selectAllEmp} onClick={()=>SelectAllEmployees(selectAllEmp)} name="list-check" className="mr-4" />
                        {LanguageObj && LanguageObj["name_label"+"_"+CurrentLanguage]? LanguageObj["name_label"+"_"+CurrentLanguage]:"Name"}</th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                      {LanguageObj && LanguageObj["email_address_label"+"_"+CurrentLanguage]? LanguageObj["email_address_label"+"_"+CurrentLanguage]:"Email Address"}</th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                      {LanguageObj && LanguageObj["mobile_no_label"+"_"+CurrentLanguage]? LanguageObj["mobile_no_label"+"_"+CurrentLanguage]:"Mobile No	"}</th>
                      {/* <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 tracking-wider">
                      {LanguageObj && LanguageObj["created_date"+"_"+CurrentLanguage]? LanguageObj["created_date"+"_"+CurrentLanguage]:"Created Date"}</th> */}
                      <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 tracking-wider">
                      {LanguageObj && LanguageObj["status_label"+"_"+CurrentLanguage]? LanguageObj["status_label"+"_"+CurrentLanguage]:"Status"}</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">

                      {getCampaignEmployeesByIDData && getCampaignEmployeesByIDData.length>0?
                        getCampaignEmployeesByIDData.map((item,index)=>
                        <>
                        {(reminderformData.actionId === 2 && !item.isComplete)?
                        <tr>
                          <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 ">
                                {/* <input type="checkbox" value={item.id} checked={employees.some(prev => prev.id === item.id)?true:false} onClick={() => handleCampaignEmployees(item)} onChange={()=>{}} /> */}
                              </div>
                              <div className="ml-4">
                                <div className="text-xs leading-5 text-gray-500">{(item && item.employeeId.name?item.employeeId.name:'Not Available').slice(0,15)}</div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="leading-5 text-gray-500 text-xs">{item.employeeId.email}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs font-medium text-gray-500">
                          {item && item.employeeId.mobile?item.employeeId.mobile:'Not Available'}
                          </td>
                          {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs leading-5 text-gray-500">
                          { moment.unix(item.createdAt / 1000).format(" DD MMM, YYYY ")}
                          </td> */}
                          <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center" >
                            {item && item.employeeId.isDisable?
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                              {LanguageObj && LanguageObj["inactive"+"_"+CurrentLanguage]? LanguageObj["inactive"+"_"+CurrentLanguage]:"InActive"}
                            </span>
                            :
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                              {LanguageObj && LanguageObj["active"+"_"+CurrentLanguage]? LanguageObj["active"+"_"+CurrentLanguage]:"Active"}
                            </span>
                            }
                            {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span> */}
                          </td>
                          </tr>
                        :null}

                        {(reminderformData.actionId === 3 && !item.isMailSent)?
                        <tr>
                          <td className="px-4 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="flex items-center">
                              <div className="flex-shrink-0 ">
                                {/* <input type="checkbox" value={item.id} checked={employees.some(prev => prev.id === item.id)?true:false} onClick={() => handleCampaignEmployees(item)} onChange={()=>{}} /> */}
                              </div>
                              <div className="ml-4">
                                <div className="text-xs leading-5 text-gray-500">{(item && item.employeeId.name?item.employeeId.name:'Not Available').slice(0,15)}</div>
                              </div>
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                            <div className="leading-5 text-gray-500 text-xs">{item.employeeId.email}</div>
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs font-medium text-gray-500">
                          {item && item.employeeId.mobile?item.employeeId.mobile:'Not Available'}
                          </td>
                          {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs leading-5 text-gray-500">
                          { moment.unix(item.createdAt / 1000).format(" DD MMM, YYYY ")}
                          </td> */}
                          <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center" >
                            {item && item.employeeId.isDisable?
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">InActive</span>
                            :
                            <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span>
                            }
                            {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">Active</span> */}
                          </td>
                          </tr>
                           :null}
                          </>

                          )
                      :null}

                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>




      </div>
    </div> 
      </>
    );
  }
