import React, { Component } from 'react';
import { connect } from 'react-redux';
import DriverTable from './Tables/DriverTable';
import QuestionTable from './Tables/QuestionTable';

import DriverRadar from './Charts/DriverRadar';
import QuestionStackView from './Charts/QuestionStackView/QuestionStackView';
import Commentary from '../../../Index/Components/Commentary/Commentary';
import QuestionComparisionTable from './Tables/QuestionComparisionTable';
import DriverComparionTable from './Tables/DriverComparionTable';
import DriverComparisionRadar from './Charts/DriverComparisionRadar';


class IndexTables extends Component {
  constructor(props) {
    super(props);
    this.state = {
        Tabtype:1,

        showTable:true,
        showQStack:true,

        optionTypeInner:1
  
    }
  }

  componentDidMount() {

  }

  handleQuesViewType=(check)=>{
    this.setState({showQStack:!check})
  }

  handleViewType=(check)=>{
    this.setState({showTable:!check})
  }

  handleBehaviourType=(type)=>{
    let { handleManagerDriver,handleManagerQuestion, optionTypeM } = this.props;
    this.setState({Tabtype:type})

    handleManagerQuestion(optionTypeM);
    handleManagerDriver(optionTypeM);

    // if(type===1){
    //   handleManagerQuestion(optionTypeM);
    // }
    // else if(type===2){
    //   handleManagerDriver(optionTypeM);
    // }

  }


  handleOption=(data)=>{
    this.setState({optionTypeInner:data});
    let { OrgLevelDriverScore } = this.props;

    if(data===2){
      OrgLevelDriverScore();
    }

  }

  render() {
    let { IndexDriverData, GetParameterLabel, DemographicRestriction,getQuestionOverallData,ParaLabelList,showMultiple,optionTypeM,DemographicDriverManagerData,temShowMultiple, ManagerListQuestionDataObj,indexType,optionType,getCommentData, loading, getQuestionData,Name, CurrentManagerName, ManagerName, DefaultManagerName ,ManagerIndexDriverData, ManagerDynaObj, EmpFilterData, CurrentManagerData, QuesDynaObj,ManagerIndexQuestionData } = this.props;
    let { Tabtype, showTable, showQStack, optionTypeInner } = this.state;





    return (
      <>
{true?
      <>
        {/* <nav className="scroll flex justify-left bg-gray  overflow-x-auto  px-2 pb-2 overscroll-x-contain">
          <div className="flex justify-left">
            <a onClick={()=>this.handleOption(1)} className={(optionTypeInner===1)?"cursor-pointer text-gray-600 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-bold text-xs py-4 mr-8":"cursor-pointer text-gray-600 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-bold text-xs py-4 mr-8"} href>
              {Name} Questions
            </a>
            <a onClick={()=>this.handleOption(2)} className={(optionTypeInner===2)?"cursor-pointer text-gray-600 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-bold text-xs py-4 mr-8":"cursor-pointer text-gray-600 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-bold text-xs py-4 mr-8"} href>
              {Name} Drivers
            </a>
          </div>
          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        </nav> */}

        <div className="px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b border-t  h-10">
        <div className="cursor-pointer text-xs font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden">
          <span onClick={()=>this.handleOption(1)} className={(optionTypeInner===1)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>{Name} Items</span>
          <span onClick={()=>this.handleOption(2)} className={(optionTypeInner===2)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>{Name} Dimensions</span>
        </div>
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

      </div>





                  {optionTypeInner === 1?
                  
                

                    <>
                      {true?
                        <div class="bg-white rounded  shadow-md px-2 m-4">
                            <div className="text-base text-gray-700 font-semibold flex justify-between items-center py-2">
                            {/* <h1 className="py-2 text-base text-gray-700 font-semibold "> {Name} Questions</h1>  */}
                              {/* <div className="text-lg pb-4">
                                <i className="show Table" onClick={()=>this.handleQuesViewType(false)} style={{opacity:(showQStack)?1:0.3}} className="cursor-pointer fa fa-bar-chart mx-2"></i> 
                                <i className="show Radar" onClick={()=>this.handleQuesViewType(true)} style={{opacity:(!showQStack)?1:0.3}} className="cursor-pointer fa fa-table mx-2"></i> 
                              </div> */}
                          <div className="text-lg font-semibold">{Name} Behaviour Items</div>

                            </div>
                                {/* {showQStack?
                                  <QuestionStackView
                                    getQuestionData={getQuestionData}
                                    QuesDynaObj={QuesDynaObj}
                                    EmpFilterData={EmpFilterData}
                                    ManagerIndexQuestionData={ManagerIndexQuestionData}
                                    Name={Name}
                                    ManagerDynaObj={ManagerDynaObj}
                                    CurrentManagerData={CurrentManagerData}
                                  />
                                : */}
                        {showMultiple?
                               <QuestionComparisionTable
                               getQuestionData={getQuestionData}
                               QuesDynaObj={QuesDynaObj}
                               EmpFilterData={EmpFilterData}
                               ManagerIndexQuestionData={ManagerIndexQuestionData}
                               ManagerDynaObj={ManagerDynaObj}
                               CurrentManagerData={CurrentManagerData}
                               Name={Name}
                               ManagerListQuestionDataObj={ManagerListQuestionDataObj}
                               optionTypeM={optionTypeM}
                               ParaLabelList={ParaLabelList}
                               
                               getQuestionOverallData={getQuestionOverallData}
                               DemographicRestriction={DemographicRestriction}
                               GetParameterLabel={GetParameterLabel}

                           />
                                :
                                <QuestionTable
                                getQuestionData={getQuestionData}
                                QuesDynaObj={QuesDynaObj}
                                EmpFilterData={EmpFilterData}
                                ManagerIndexQuestionData={ManagerIndexQuestionData}
                                ManagerDynaObj={ManagerDynaObj}
                                CurrentManagerData={CurrentManagerData}
                                Name={Name}
                                optionTypeM={optionTypeM}
                                ParaLabelList={ParaLabelList}

                                getQuestionOverallData={getQuestionOverallData}
                                DemographicRestriction={DemographicRestriction}
                                GetParameterLabel={GetParameterLabel}

                            />
 
                        }



                        </div>
                    :null}
                    </>

                    :
                  

                    <>
                    {true?
                        <div class="bg-white rounded  shadow-md px-2  m-4">
                        {/* <h1 className="px-2 py-4 text-base text-gray-700 font-semibold "> {Name} Drivers</h1>  */}
                        {/* <div className="pb-1 text-base text-gray-700 font-semibold flex justify-between items-center">
                          <div className="text-lg">
                            <i className="show Table" onClick={()=>this.handleViewType(false)} style={{opacity:(showTable)?1:0.3}} className="cursor-pointer fa fa-table mx-2"></i> 
                            <i className="show Radar" onClick={()=>this.handleViewType(true)} style={{opacity:(!showTable)?1:0.3}} className="cursor-pointer fa fa-snowflake-o mx-2"></i> 
                          </div>


                        </div> */}

                      <div className="md:flex justify-between py-2">
                        <div className="text-lg font-semibold">{Name} Behaviour Dimensions</div>
                        <div className="text-xs flex items-center">
                          <div onClick={()=>this.handleViewType(false)} className={showTable?"cursor-pointer border mx-1 bg-blue-50 ring-1 ring-blue-500 text-blue-500 py-1.5 px-4 rounded-full outline-none":"cursor-pointer border mx-1 py-1.5 px-4 rounded-full"}>Table view</div>
                          <div onClick={()=>this.handleViewType(true)} className={!showTable?"cursor-pointer border mx-1 bg-blue-50 ring-1 ring-blue-500 text-blue-500 py-1.5 px-4 rounded-full outline-none":"cursor-pointer border mx-1 py-1.5 px-4 rounded-full"}>Radar view</div>
                        </div>
                      </div>





                      {showTable?
                      <>
                      {!showMultiple || !temShowMultiple?
                        < DriverTable
                          getQuestionData={getQuestionData}
                          Name={Name}
                          ManagerDynaObj={ManagerDynaObj}
                          EmpFilterData={EmpFilterData}
                          CurrentManagerData={CurrentManagerData}
                          ManagerIndexDriverData={ManagerIndexDriverData}
                          optionTypeM={optionTypeM}
                          ParaLabelList={ParaLabelList}
                          DemographicRestriction={DemographicRestriction}
                          GetParameterLabel={GetParameterLabel}
                          IndexDriverData={IndexDriverData}
                        />
                        :
                        < DriverComparionTable
                          getQuestionData={getQuestionData}
                          Name={Name}
                          ManagerDynaObj={ManagerDynaObj}
                          EmpFilterData={EmpFilterData}
                          CurrentManagerData={CurrentManagerData}
                          ManagerIndexDriverData={ManagerIndexDriverData}
                          DemographicDriverManagerData={DemographicDriverManagerData}
                          optionTypeM={optionTypeM}
                          ParaLabelList={ParaLabelList}
                          DemographicRestriction={DemographicRestriction}
                          GetParameterLabel={GetParameterLabel}
                          IndexDriverData={IndexDriverData}

                        />
                      }
                        </>
                      :
                      <>
                      {!showMultiple || !temShowMultiple?
                        < DriverRadar
                          getQuestionData={getQuestionData}
                          Name={Name}
                          ManagerDynaObj={ManagerDynaObj}
                          EmpFilterData={EmpFilterData}
                          CurrentManagerData={CurrentManagerData}
                          ManagerIndexDriverData={ManagerIndexDriverData}
                          ParaLabelList={ParaLabelList}
                          DemographicRestriction={DemographicRestriction}
                          GetParameterLabel={GetParameterLabel}
                          IndexDriverData={IndexDriverData}
                        />
                        :
                        < DriverComparisionRadar
                          getQuestionData={getQuestionData}
                          Name={Name}
                          ManagerDynaObj={ManagerDynaObj}
                          EmpFilterData={EmpFilterData}
                          CurrentManagerData={CurrentManagerData}
                          ManagerIndexDriverData={ManagerIndexDriverData}
                          DemographicDriverManagerData={DemographicDriverManagerData}
                          ParaLabelList={ParaLabelList}
                          DemographicRestriction={DemographicRestriction}
                          GetParameterLabel={GetParameterLabel}
                          IndexDriverData={IndexDriverData}
                      />
                      }
                      </>
                      }



                        </div>
                    :null}
                    </>
                  }


      </>
      :
<>
{!loading?
<div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
:null}
</>
}
    
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexTables);
