import React, { Component } from 'react';
import { connect } from 'react-redux';

class CoreCircles extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName:'none',
      viewScore:true
    }
  }
  componentDidMount() {

  }

  handleViewScore=(data)=>{
    this.setState({viewScore:data})
  }


  handleFilternameSave=(name)=>{
    this.setState({currFilterName:name})
  }

  render() {
    let { valueList, GetParameterLabel } = this.props;


    valueList.sort((a,b)=>b[1]-a[1]);


    return (
      <>

              <div className="container mx-auto px-2 py-2 bg-gray-50">
                <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 xl:grid-cols-3  gap-2">
                  {valueList && valueList.length>0?
                    valueList.map((item)=>
                    <div className="bg-white rounded w-full shadow-lg px-4 py-2">
                      <h1 className="px-2 py-4 text-base text-gray-700 font-semibold ">{GetParameterLabel(item[0])}</h1>
                        {/* <div className="org_core_icon float-left my-2">
                            {item[0][0]}
                        </div> */}

                            <div className="Happier-box1">
                              <div className="percent ">
                              <svg>
                                  <circle cx={70} cy={70} r={70} />
                                  <circle cx={70} cy={70} r={70}
                                   style={{
                                        stroke:'#4f90f7',
                                        strokeDashoffset:440 - (440 * ((item[1]*100)/100))/ 100 }} />
                                  </svg>
                                  <div className="number cursor-default">
                                  
                                  <h1 className="text-lg font-semibold">{(item[1])?item[1].toFixed(2):'NaN'}%</h1>
                                  </div>
                              </div>
                            </div>

                        <div className="float-left mx-4">
                          {/* <h1 className="px-2 py-4 text-base text-gray-700 font-semibold ">{item[0]}</h1> */}
                          {/* <h1 className="px-2 pb-4 text-base text-gray-700 font-semibold ">{item[1].toFixed(2)}%</h1> */}
                        </div>
                    </div>
                    )
                  :null}
                </div>
              </div>
                
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(CoreCircles);
