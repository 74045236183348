import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionScore from './Charts/QuestionScore';
import Commentary from '../../../Index/Components/Commentary/Commentary';
import Filter from './Filter/Filter';

class DimensionPriorities extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',


      currentSelectValue: '',
      FilterValues: [],
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleMultiFilterGetQuestion } = this.props;
    this.setState({ FilterValues: finalFilter });
    handleMultiFilterGetQuestion(finalFilter);

  }



  render() {
    let { indexType, loading, optionType, getCommentData, selectedFilterValue,

      getQuestionData, getQuestionFilterData, handleQuestionFilter, EmpFilterData, getIndexFilterData

    } = this.props;

    let QuestionData = []
    let { currentSelectValue, FilterValues } = this.state;

    if (this.state.currFilterValue === 'none') {
      QuestionData = getQuestionData
    }
    else {
      QuestionData = getQuestionFilterData
    }


    console.log('QuestionData--->', QuestionData)
    console.log('getQuestionData--->', getQuestionData)
    console.log('getQuestionFilterData--->', getQuestionFilterData)


    let paraList = []

    let count = 0;
    let rowSpan = 0;
    let NewParaList = []

    if (QuestionData && QuestionData.length > 0) {

      QuestionData.forEach((ques) => {
        if (paraList.findIndex(prev => prev === ques.parameterDimensionName) === -1) {
          paraList.push(ques.parameterDimensionName);
          count += 1
        }
        else {
        }
      })

      let obj = {}
      paraList.forEach((para) => {
        obj[para] = 0
        QuestionData.forEach((ques) => {
          if (para === ques.parameterDimensionName) {
            obj[para] += 1
          }
        })
      })


      NewParaList = Object.keys(obj).map((key) => [key, obj[key]])
      //   console.log('data NewParaList: ',NewParaList)

      rowSpan = (QuestionData.length / count)

      // console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,QuestionData.length)

    }
    // console.log('ORG ==== NewParaList ===== > : ',NewParaList)
    // console.log('ORG ==== QuestionData ===== > : ',QuestionData)

    let NewQuesList = [];
    if (QuestionData && QuestionData.length > 0) {
      QuestionData.forEach((ques) => {
        let total = ques['1'] + ques['2'] + ques['3'] + ques['4'] + ques['5'] + ques['6'];
        NewQuesList.push({
          ...ques,
          score: parseFloat(((ques['5'] + ques['6']) * 100 / total).toFixed(2))
        })
      })
    }


    NewQuesList.sort((a, b) => b.score - a.score);


    console.log('ORG ==== NewQuesList ===== > : ', NewQuesList)




    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;

    }

    let { GetParameterLabel, CountRestrict } = this.props;

    return (
      <>
        <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}
            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        </div>


        {true ?
          <>

            <div className="xl:flex lg:flex md:flex justify-start items-center">

              {/* <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



            </div>


            {FilterValues && FilterValues.length > 0 ?
              <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
                {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
                  <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                ) : null}</div>
              : null}


            <div onClick={() => this.closeFilter(false)} className=" " >
              <div className="flex w-full" >


                <div className="bg-white rounded w-full shadow-lg px-4 m-4">
                  <div className="text-lg font-semibold py-4">Item Ranking</div>
                  <div class="overflow-x-auto whitespace-nowrap w-full overflow-hidden  mb-4">
                    <table class="w-full border ">
                      <thead>
                        <tr className="bg-gray-100 border-b text-xs text-gray-800">
                          <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Dimension</div></th>
                          <th className="py-2 px-2 border-r font-bold"><div class="flex items-center justify-left">Question</div></th>
                          <th className="py-2 px-4 border-r font-bold"><div class="flex items-center justify-left">Favorability Score</div></th>
                        </tr>
                      </thead>
                      <tbody>
                        {NewQuesList && NewQuesList.length > 0 ?
                          NewQuesList.map((question, index) =>
                            <tr key={index} className="bg-white  mb-10 lg:mb-0 text-xs text-gray-800">
                              <td className="px-2 border border-b w-2/12">
                                <div style={{ width: '20%' }}>{question && question.parameterDimensionName ?
                                  GetParameterLabel(question.parameterDimensionName) :
                                  ""}</div>
                              </td>
                              <td className="tooltip-response-question1 px-2 border border-b w-2/12">
                                <div className=" cursor-pointer whitespace-pre-line w-96">{question.name}</div>
                                
                                {/* <div className=" cursor-pointer" style={{width:'20%'}}>{(question.name.length>55)?question.name.slice(0,55)+'...':question.name}</div> */}
                                {/* <div className="tooltip-response-question2">
                              {question.name}
                            </div> */}
                              </td>
                              <td className=" border border-b w-10/12" >
                                <QuestionScore question={question} CountRestrict={CountRestrict} currFilterValue={this.state.currFilterValue} currFilterName={this.state.currFilterName} selectedFilterValue={selectedFilterValue} getQuestionFilterData={getQuestionFilterData} />
                              </td>
                            </tr>
                          )
                          : null}

                      </tbody>
                    </table>
                  </div>
                  <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>
                </div>
              </div>
            </div>
          </>
          :
          <>
            {!loading ?
              <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(DimensionPriorities);
