import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select2  from 'react-select';
import { SketchPicker } from 'react-color';

 export default function WelcomePage (props) {

    let { welcomeFormData,
      dashQuesTypeRank,
      getQuestionTypeListData,
      handleRankDash
    
    } = props;


    console.log("==>dashQuesTypeRank: ",dashQuesTypeRank);


  
    let List = [];

    if (getQuestionTypeListData && getQuestionTypeListData.length > 0) {
      for (let i = getQuestionTypeListData.length - 1; i >= 0; i--) {
        List.push(getQuestionTypeListData[i]);
      }
    }


    return (
      <>
<main className="xl:px-6 lg:px-6 md:px-6 px-2 bg-gray-100">
  {/* <div className="cursor-pointer item-end" onClick={()=>saveWelcomePage()}>save</div> */}
    <div className="container py-12 xl:w-2/3 xl:px-12 lg:w-2/3 lg:px-8  md:w-2/3 md:px-0 px-2 w-full mx-auto">


      <div className="bg-white p-2">
          <div className="font-semibold">Dashboard Component Ranking</div>
          <div className="flex ml-1 pt-4 ">
            <div className="text-sm font-semibold w-5/12 mr-16 text-gray-400">Question Type</div>
            <div className="text-sm font-semibold w-2/12 text-gray-400">Component Order</div>
          </div>
          <div className="p-2">
          {List && List.length>0?
          List.map((quesType, index)=>
              <div className="flex items-center justify-between py-2">
                <div className=" w-full">
                  <input 
                    type="text"
                    value={quesType.name}
                    onChange={()=>{}}
                    disabled={true}
                    placeholder={quesType.name}
                    className="border border-gray-500 outline-none  px-4 text-sm py-1.5 w-9/12 mr-4" 
                  />
                </div>

                <div className=" w-full">
                  <input 
                    type="number"
                    value={dashQuesTypeRank[quesType.templateId]}
                    onChange={(event)=>handleRankDash(quesType.templateId,event.target.value)}
                    // placeholder={quesType.rank}
                    className="border border-purple-500 outline-none  px-4 text-sm py-1.5 w-9/12 mr-4" 
                  />
                </div>

              </div>
          ):null}
        </div>

      </div>




    </div>
  </main>
      </>
    );
  }