import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let {  count, MAX_COUNT , isData} = this.props;
    



    // let filterQuestion = {}
    // if(question && getQuestionFilterData && getQuestionFilterData.length>0){
    //     getQuestionFilterData.forEach((quesFilter)=>{
    //         if(quesFilter.name === question.name){
    //             filterQuestion = quesFilter;
    //         }
    //     })
    // }


    let total = 0
    let names = []
    let weightage1 = [];
    let weightage2 = [];



    let labelList = []
    let CountList = []
    let PercList = []


    let Score = 0;


    // list.sort((b,a)=>parseInt(a[1])- parseInt(b[1]));

    let list2 = this.props.list;
    let list = []
    console.log('getQuestionFilterData:--ss-x> ',list)

    if(list2 && list2.length>0){
      for(let j = list2.length ; j>=0; j--){
        if(list2 && list2[j]){
          list.push(list2[j])
        }
      }
    }


    if (list && list.length>0) {
      list.forEach((item)=>{

      
      let word1 = item && item[0] && item[0][0]?item[0][0]:"NaN";
      let word2 = item && item[0] && item[0][1]?item[0][1]:"NaN";
      let appear = item && item[1]?item[1]:0;

 
      let name = "("+word1+","+word2+")";

      labelList.push(name);
      CountList.push(appear);
      PercList.push(((appear*100)/count).toFixed(2));

        
      // Score = CountRestrict(question && question.count?question.count:"NaN")?0:question.score;

        names.push({
            value:"("+word1+","+word2+")",
            textStyle:{fontWeight:'bold'}
        })
        weightage1.push({
            value:appear,
            itemStyle: {color: '#6536d1'}
        });


        
        // weightage2.push({
        //     value:100-appear,
        //     itemStyle: {color: '#ffffff'}

        // });

    });
    
    }


    console.log('list:---> ',list)


    console.log('CountList:---> ',CountList)


    return (
      <>
        <div style={{zIndex:'10'}}>

{list && list.length>0?

              <ReactEcharts
              style={{height:'200px'}}
              option={{

                tooltip: {
                  trigger: 'axis',
                  axisPointer: {
                    type: 'shadow'
                  }
                },

                xAxis: {
                  type: 'value',
                  boundaryGap: [0, 0.01],
                  splitLine: {
                    lineStyle: {
                        color: '#fff'
                    },
                },
                max:parseInt((MAX_COUNT)/2),
                axisLabel:{
                  show:false
                }
                },
                yAxis: {
                  type: 'category',
                  data: labelList,
                  axisLabel:{
                    show:!isData?true:false
                  }
                },
                series: [
                  {
                    name: 'Count',
                    type: 'bar',
                    barWidth:'30px',
                    data: CountList
                  },
                  {
                    name: 'Percentage',
                    type: 'bar',
                    barWidth:'30px',
                    data: PercList
                  }
                ]
              }}
            />
            : <div className="text-purple-500 font-semibold text-left p-2 px-4">NaN</div>}

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
//   console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionScore);
