import React, { Component } from 'react';
import { connect } from 'react-redux';
import IndexKPI from './Components/IndexKPI';
// import ReactSpeedometer from "react-d3-speedometer";

// import Happiness from './OverIndex/HappinessOverview/Happiness';
// import Engagement from './OverIndex/EngagementOverview/Engagement';
// import Stress from './OverIndex/StressOverview/Stress';
// import Manager from './OverIndex/Manager';

import ScoreGauge from './Components/ScoreGauge';
import SurveyReport from './SurveyReport/SurveyReport';

class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      managerDetailsIndexType: 0,
      showManagerList: true,
      CurrentManagerName: 'Select Manager',

      showOverview: true,

      allIndexList: [
        {
          "name": "Happiness",
          "template": 1,
          "score": "NaN",
          "type": "Score",
          "color": "#FF4081",
          "image": "/img/Dashboard/Index/happiness_index.png"
        },
        {
          "name": "Engagement",
          "template": 2,
          "score": "NaN",
          "type": "Percentage",
          "color": "#2196F3",
          "image": "/img/Dashboard/Index/engagement_index.png"

        },
        {
          "name": "Hope",
          "template": 3,
          "score": "NaN",
          "type": "Percentage",
          "color": "#009688",
          "image": "/img/Dashboard/Index/hope_index.png"

        },
        {
          "name": "Stress",
          "template": 4,
          "score": "NaN",
          "type": "Percentage",
          "color": "#EF5350",
          "image": "/img/Dashboard/Index/stress_index.png"

        },
        {
          "name": "Manager",
          "template": 5,
          "score": "NaN",
          "type": "Percentage",
          "color": "#9C27B0",
          "image": "/img/Dashboard/Index/manager_index.png"

        },
        {
          "name": "Leadership",
          "template": 6,
          "score": "NaN",
          "type": "Percentage",
          "color": "#FFB300",
          "image": "/img/Dashboard/Index/leadership_index.png"

        },
        {
          "name": "Value",
          "template": 7,
          "score": "NaN",
          "type": "Percentage",
          "color": "#C0CA33",
          "image": "/img/Dashboard/Index/value_index.png"

        },
        // {
        //   "name":"Organization Core",
        //   "template":8,
        //   "score":"NaN"
        // },
        {
          "name": "Safety",
          "template": 9,
          "score": "NaN",
          "type": "Percentage",
          "color": "#00CD7E",
          "image": "/img/Dashboard/Index/safety_index.png"

        },
        {
          "name": "Diversity",
          "template": 10,
          "score": "NaN",
          "type": "Percentage",
          "color": "#3F51B5",
          "image": "/img/Dashboard/Index/diversity_index.png"

        }
      ]

    }
  }

  componentDidMount() {

  }


  openSurveyResponse = () => {
    this.setState({ showOverview: false });

  }

  closeResponseAnalytics = () => {
    this.setState({ showOverview: true })
  }

  render() {
    let { allIndexList, showOverview } = this.state;
    let {
      getIndexData,
      SurveyList,
      CurrentSurveyId,
      onOptionType,

      getHappinessIndexV2Data,
      getEngagementIndexV2Data,
      getStressIndexV2Data,
      getManagerIndexV2Data,

      getRespondantDetailsData,

      EmpFilterData,
      getIndexFilterData

    } = this.props;

    let surveyId = '';
    // if(SurveyList && SurveyList.length>0){

    //   if(CurrentSurveyId){
    //     surveyId = CurrentSurveyId;
    //   }
    //   else{
    //     surveyId = SurveyList[0].id;
    //   }

    // }
    surveyId = CurrentSurveyId;

    console.log('x-------->>>>> IndexList:-------->>>>>', getIndexData)

    let overviewScoreList = []
    let IndexList = []

    function getIndexName(key) {
      let name = ''
      if (key === 1) { name = 'Happiness' }
      else if (key === 2) { name = 'Engagement' }
      else if (key === 3) { name = 'Hope' }
      else if (key === 4) { name = 'Stress' }
      else if (key === 5) { name = 'Manager' }
      else if (key === 6) { name = 'Leadership' }
      else if (key === 7) { name = 'Safety' }
      else if (key === 9) { name = 'Diversity' }
      else { name = 'NaN' }

      return name;
    }

    function checkIndex(key) {
      let check = false
      if (key === 1) { check = true }
      else if (key === 2) { check = true }
      else if (key === 3) { check = true }
      else if (key === 4) { check = true }
      else if (key === 5) { check = true }
      else if (key === 6) { check = true }
      else if (key === 7) { check = true }
      else if (key === 9) { check = true }
      else { check = false }

      return check;
    }
    let IndexListNew = [];
    let newFilterObj = {}
    if (getIndexData) {



      IndexList = Object.keys(getIndexData).map((key) => [Number(key), getIndexData[key]]);

      if (getIndexFilterData) {
        if (IndexList && IndexList.length > 0) {
          IndexList.forEach((data) => {
            if (checkIndex(data[0])) {
              newFilterObj[data[0]] = getIndexFilterData[data[0]];
            }
          });
        };

        IndexListNew = Object.keys(newFilterObj).map((key) => [Number(key), newFilterObj[key]]);
      }
      //console.log('IndexListNew: ',IndexListNew)

      if (IndexListNew && IndexListNew.length > 0) {
        IndexListNew.forEach((data) => {
          let GetIndex = allIndexList.findIndex(prev => prev.template === data[0])
          if (getIndexName(data[0]) !== 'NaN' && data && data[0] && data[1]) {
            overviewScoreList.push({
              template: data[0],
              score: data[1].toFixed(2),
              name: getIndexName(data[0]),
              type: (GetIndex !== -1) ? allIndexList[GetIndex].type : 'score',
              color: (GetIndex !== -1) ? allIndexList[GetIndex].color : '#757575',
              image: (GetIndex !== -1) ? allIndexList[GetIndex].image : null
            })
          }

        })
      }

    }

    let OverViewIndexList = overviewScoreList;

    if (allIndexList && allIndexList.length > 0) {
      allIndexList.forEach((item) => {
        let GetIndex = OverViewIndexList.findIndex(prev => prev.template === item.template);
        if (GetIndex === -1) {
          OverViewIndexList.push({
            ...item,
            "type": item.type,
            "color": item.color,
            "image": item.image
          });
        }
      });
    }

    // console.log('OverViewIndexList: ',OverViewIndexList)

    return (
      <>
        <main className="flex-1 w-full bg-gray-100  overflow-hidden overflow-y-auto ">

          {showOverview ?
            <div className=" mx-auto px-2 py-2 bg-gray-100">
              <div onClick={() => this.openSurveyResponse()}><IndexKPI 
              getRespondantDetailsData={getRespondantDetailsData} /></div>
              <div className="grid lg:grid-cols-3 md:grid-cols-3 grid-cols-1 xl:grid-cols-3  gap-2">


                {OverViewIndexList && OverViewIndexList.length > 0 ?
                  OverViewIndexList.map((item, index) =>
                    <div key={index} >
                      {item.score !== 'NaN' ?
                        <div onClick={() => onOptionType(surveyId, item.template, 1)} >
                          <ScoreGauge item={item} />
                        </div>
                        :
                        <div >
                          <ScoreGauge item={item} />
                        </div>
                      }
                    </div>
                  )
                  : null}

              </div>
            </div>
            :

            <div className="px-2 bg-gray-100">

              <SurveyReport
                CurrentSurveyId={surveyId}
                closeResponseAnalytics={this.closeResponseAnalytics}

                EmpFilterData={EmpFilterData}
                getIndexFilterData={getIndexFilterData}
                getRespondantDetailsData={getRespondantDetailsData}

              />

            </div>

          }

        </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Overview);
