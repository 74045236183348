import React from 'react';
import Parameter from '../Parameter/Parameter';
import ParameterView from '../Parameter/ParameterView';

  export default function GroupQuestions(props){
    let { SurveyTemplateId,question,quesindex, currQuesEditId,showTranslatePage,



      QuestionTypeListData,
      CurrentLanguage,
      LanguageObj,
      handleQuestionTypeGroup, 
      questionTypeTemplateGroup,


      translQuestion, handleTranslateQuestion, handleTranslateOption, onTranslateQuestionSave,translShortName,handleAddQuestion,

      handleQuestionInput, handleOptionInput, handleWeightageInput, onDeleteOption, onAddNewOption, onQuesMandatory, onSaveQues,
      handleParameterBucket, parameterList, handleParameterQuestion,paraBucketName,
      showBucketDropdown,showDimensionDropdown, handleParameterDimension, getParameterListbyParentData, paraDimensionName, showParameterModal, onParamaterModal,
      innerEdit,handleQuesCancel ,GetParameterLabel
    } = props;

    let [ showQuestionTypeList, handleShowQuestionType ] = React.useState(false);

    return (
      <>
{currQuesEditId === question.id?
                  <>
                  {showTranslatePage?
                  <>
                    <div className="flex justify-between items-center mt-2">
                      <div className="text-sm font-semibold text-gray-400">Question</div>
                      <div className="flex px-2">
                      <div onClick={()=>onTranslateQuestionSave(true, translShortName)} className="py-1 px-3 rounded-sm border border-1 border-green-500 cursor-pointer text-sm text-green-500 mr-2">Save</div>
                      <div onClick={()=>onTranslateQuestionSave(false, translShortName)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500">Cancel</div>
                    </div>                    </div>
                    <input 
                      type="text" 
                      value={question.name}
                      onChange={(event)=>handleQuestionInput(event.target.value,question.id)}
                      placeholder={"Q."+ (quesindex + 1) + " " + question.name}
                      className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                    />
                    <input 
                      type="text" 
                      value={translQuestion["transl"][translShortName].name}
                      onChange={(event)=>handleTranslateQuestion(event.target.value,question.id,translShortName)}
                      placeholder="Translate here..."
                      className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                    />

                    <hr />


                  </>
                  :
                  <>
                    <div className="flex justify-between items-center mt-2">
                      <div className="text-sm font-semibold text-gray-400">Question</div>
                      <div onClick={()=>handleQuesCancel(false)} className="py-1 px-2 rounded-sm border border-1 border-red-500 cursor-pointer text-sm text-red-500 mr-2">Cancel</div>
                    </div>
                    <input 
                      type="text" 
                      value={question.name}
                      onChange={(event)=>handleQuestionInput(event.target.value,question.id)}
                      placeholder={"Q."+ (quesindex + 1) + " " + question.name}
                      className="py-2 px-4 border-2 outline-none w-full mt-2" 
                    />
                    <hr/>
                    <ul className="pb-4">
                      {/* <p className="text-xs text-gray-500 pb-2">Answer</p> */}
                      <div className="mb-1.5 mt-2" style={{height:"30px"}}>
                        <div className="py-1" >
                          <input
                          id="mandatory-check"
                          value={question.isMandatory}
                          checked={question.isMandatory}
                          type="checkbox"
                          onChange={()=>{}}
                          className="cursor-pointer ml-1"
                          onClick={()=>onQuesMandatory(question.isMandatory,question.id)}
                          />
                          <label htmlFor="mandatory-check" className="text-sm text-gray-500 pb-8 pl-2">Mandatory</label>
                        </div>
                         {/* <div className="ml-6 mt-1 cursor-pointer text-sm text-purple-500 uppercase font-semibold" onClick={()=>onParamaterModal(!showParameterModal)} ><i className={showParameterModal?"fa fa-times":"pr-2 fa fa-plus"} />{showParameterModal?"":"Add Paramaters"}</div> */}
                      
                      
{/*                       
                        
                        <Parameter
                        showBucketDropdown={showBucketDropdown} 
                        parameterList={parameterList}
                        handleParameterBucket={handleParameterBucket}
                        handleParameterQuestion={handleParameterQuestion}
                        getParameterListbyParentData={getParameterListbyParentData}
                        paraBucketName={paraBucketName}
                        question={question}
                        showDimensionDropdown={showDimensionDropdown}
                        paraDimensionName={paraDimensionName}
                        handleParameterDimension={handleParameterDimension}
                        GetParameterLabel={GetParameterLabel}
                        /> */}
                      
  
                              </div>

                    </ul>


                  
                    <div className="flex justify-center pb-2 pt-8">
                      <span className="cursor-pointer text-sm rounded uppercase   bg-blue-500 py-2 px-4 text-white w-28  mx-1 text-center" onClick={()=>onSaveQues(question)}> save</span>
                    </div>

                      </>
                      }

                  </>
              :
                  <>
                  <div className="mt-2">
                    <div  className="text-black font-semibold text-sm inline-flex"><p className="pr-2">Q{quesindex+1}.</p> <h1>{question.name}{(question.isMandatory)?'*':null}</h1> </div>
                      {/* <ParameterView question={question} GetParameterLabel={GetParameterLabel}/> */}

                    <ul className="py-4 pl-6 flex">
                        


                        <div style={{zIndex:100, width:'250px'}} className="mx-1.5 w-full border border-purple-500 text-black flex rounded-sm justify-between px-4  items-center relative">

                          <span className=" text-purple-500 text-xs font-semibold cursor-pointer w-full" onClick={() => handleShowQuestionType(!showQuestionTypeList)}>
                            {questionTypeTemplateGroup.templateId ?
                              QuestionTypeListData && QuestionTypeListData.length > 0 ?
                                QuestionTypeListData.map((item, index) =>
                                  (item.templateId === questionTypeTemplateGroup.templateId) ?
                                    (item.name)
                                    : null
                                ) : null
                              :
                              (LanguageObj && LanguageObj["choose_question_type_label" + "_" + CurrentLanguage] ? LanguageObj["choose_question_type_label" + "_" + CurrentLanguage] : "Add SubQuestion Type")
                            }
                          </span>

                          <span className="material-icons text-purple-500 select-none cursor-pointer" 
                          onClick={() => handleShowQuestionType(!showQuestionTypeList)}>expand_more</span>

                          {showQuestionTypeList ?
                            <div className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-purple-500 py-4">
                              {QuestionTypeListData && QuestionTypeListData.length > 0 ?
                                QuestionTypeListData.map((item, index) =>
                                (item.templateId !==13 && item.templateId !==14)?

                                  (item.templateId === questionTypeTemplateGroup.templateId) ?
                                    <span key={index} onClick={() =>{
                                      handleQuestionTypeGroup(item);
                                      handleShowQuestionType(false)
                                      }} className="cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100"><i className="fa fa-list pr-4" />
                                      {item.name}</span>
                                    :
                                    <span key={index} onClick={() =>{
                                      handleQuestionTypeGroup(item);
                                      handleShowQuestionType(false)
                                      }} className="cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"><i className="fa fa-list pr-4" />
                                      {item.name}</span>



                              : null
                                ) : null}
                            </div>
                            : null}

                        </div>

                      <div className="">
                        <span onClick={()=>handleAddQuestion(true, question.id ,questionTypeTemplateGroup)} className="py-2 material-icons cursor-pointer">add_to_photos</span>
                      </div>




                    </ul>
                    </div>
                  </>
              }
      </>
    );
  }