import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList=()=>{
    if(this.state.showChartList){
      this.setState({showChartList:false})
    }
    else{
      this.setState({showChartList:true})
    }
  }

  handleChartType=(item)=>{
    this.setState({chartTypeName:item})
  }

  render() {
    let { CountRestrict, getDemographicIndexData,getIndexDistributionData, currFilterName, getIndexScore,getIndexData ,indexType} = this.props;

    let nameList = []
    let weightage1 = [];
    let weightage2 = [];
    let weightage3 = [];
    let weightage4 = [];

    if(getIndexData){
      nameList.push({
        value:"Overall",
        textStyle:{fontWeight:'bold'}
      })
      weightage1.push({
        value:(getIndexData["Happiest"]).toFixed(2),
        itemStyle: {color: '#ff4081'}
      });
      weightage2.push({
        value:(getIndexData["Happy"]).toFixed(2),
        itemStyle: {color: '#00cd7e'}
      });
      weightage3.push({
        value:(getIndexData["ModeratelyHappy"]).toFixed(2),
        itemStyle: {color: '#ffc107'}
      });
      weightage4.push({
        value:(getIndexData["Unhappy"]).toFixed(2),
        itemStyle: {color: '#e53935'}
      });
    }

    let tempSortData = []
    if(getIndexDistributionData && getIndexDistributionData.length>0){
      getIndexDistributionData.forEach((data)=>{
          tempSortData.push(data)
      })
    }
    tempSortData.sort((a,b)=>a.name.charCodeAt(0) - b.name.charCodeAt(0));


    if(tempSortData  && tempSortData.length>0){

        function showNaN (item,name){
          let dataScore = CountRestrict(item.count)?"NaN":(item[name]).toFixed(2);
          return dataScore
        }

        tempSortData.forEach((item)=>{
          nameList.push({
            value:item.name,
            textStyle:{fontWeight:'bold'}
          })
          weightage1.push({
            // value:(item["Happiest"]).toFixed(2),
            value:showNaN(item,"Happiest"),
            itemStyle: {color: '#ff4081'}
          });
          weightage2.push({
            // value:(item["Happy"]).toFixed(2),
            value:showNaN(item,"Happy"),
            itemStyle: {color: '#00cd7e'}
          });
          weightage3.push({
            // value:(item["ModeratelyHappy"]).toFixed(2),
            value:showNaN(item,"ModeratelyHappy"),
            itemStyle: {color: '#ffc107'}
          });
          weightage4.push({
            // value:(item["Unhappy"]).toFixed(2),
            value:showNaN(item,"Unhappy"),
            itemStyle: {color: '#e53935'}
          });
        })
    }



    return (
      <>
         {/* <div className="stack-title">
            {currFilterName} 
          </div> */}
          <div className="stack-body">
              {true?
              <>
                            <ReactEcharts
                            style={{height:(nameList.length<4)?'200px':(nameList.length<5)?'300px':(nameList.length>20)?(nameList.length*60).toString()+'px':(nameList.length*50).toString()+'px',
                            marginTop:'-60px'
                          }}
                            option={{
                              tooltip: {
                                  trigger: 'axis',
                                  axisPointer: {     
                                      type: 'shadow'        
                                  },
                                  // formatter: function(a,b,c,d){
                                  //   return (a && a[0] && a[0].name)?a[0].name+' = '+a[0].value+'%':''
                                  // } 


                              },
                              legend: {
                                data:[]
                              },
                              grid: {
                                  left: '3%',
                                  right: '4%',
                                  bottom: '3%',
                                  containLabel: true
                              },
                              xAxis: {
                                  type: 'value',
                                  splitLine: {
                                    lineStyle: {
                                        color: '#f2f2f2'
                                    }
                                },
                                axisLine: {
                                  lineStyle: {
                                    color:'#e6e6e6'
                                  }
                                },
                                max:100                             
                              },
                              yAxis: {
                                  type: 'category',
                                  data: nameList.reverse(),
                                  axisLine: {
                                    lineStyle: {
                                      color:'#e6e6e6'
                                    }
                                  }
                              },
                              axisLabel: {
                                color: "#000",
                                interval: 0,
                                formatter: function(value) {
                                  if (value.length > 15) {
                                    return value.substring(0, 15) + "..";
                                  } else {
                                    return value;
                                  }
                                }
                              },
                              series: [
                                {
                                  name: 'Happiest',
                                  type: 'bar',
                                  stack: '总量',
                                  barWidth:'20px',
                                  label: {
                                      show: true,
                                      position: 'insideRight',
                                      formatter: function(d) {
                                          if(parseInt(d.value) <7){
                                              return '';
                                          }
                                          else{
                                              return d.value;
                                          }
                                        }
                                  },
                                  data: weightage1.reverse(),
                                  inverse:true
                              },
                              {
                                  name: 'Happier',
                                  type: 'bar',
                                  stack: '总量',
                                  barWidth:'20px',
                                  label: {
                                      show: true,
                                      position: 'insideRight',
                                      formatter: function(d) {
                                          if(parseInt(d.value) <7){
                                              return '';
                                          }
                                          else{
                                              return d.value;
                                          }
                                        }
                                  },
                                  data: weightage2.reverse(),
                                  inverse:true
                              },
                              {
                                  name: 'Moderately Happy',
                                  type: 'bar',
                                  stack: '总量',
                                  barWidth:'20px',
                                  label: {
                                      show: true,
                                      position: 'insideRight',
                                      formatter: function(d) {
                                          if(parseInt(d.value) <7){
                                              return '';
                                          }
                                          else{
                                              return d.value;
                                          }
                                        }
                                  },
                                  data: weightage3.reverse(),
                                  inverse:true
                              },
                              {
                                  name: 'Unhappy',
                                  type: 'bar',
                                  stack: '总量',
                                  barWidth:'20px',
                                  label: {
                                      show: true,
                                      position: 'insideRight',
                                      formatter: function(d) {
                                          if(parseInt(d.value) <7){
                                              return '';
                                          }
                                          else{
                                              return d.value;
                                          }
                                        }
                                  },
                                  data: weightage4.reverse(),
                                  inverse:true
                              }
                                 
                              ]
                          }}
                          />
                </>
              :null}
          </div>
     

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StackChart);
