import React, { Component } from 'react';
import { connect } from 'react-redux';

import Filter from './Filter';
import MatrixFullTable from './Components/MatrixFullTable';

class SummaryData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterName:'none',
      viewScore:true,
      showLeaderBoard:false,
      showFilter:false,
      showValue:'',
      XAxis:""
    }
  }
  componentDidMount() {

  }
  closeFilter=(check1,check2)=>{
    this.setState({showFilter:check1, showValue:check2});
  }

  handleFilterSave=(name)=>{
    this.setState({currFilterName:name})
  }


    
  handleMultifilterIndexScore2=(finalFilter, XAxis)=>{
    console.log(':----------------------------------------------------------------------------------------------------------------XAxis:-------------------->',finalFilter, XAxis)

    let {handleOthersFilterCall}= this.props;
    this.setState({FilterValues:finalFilter, XAxis:XAxis});
    handleOthersFilterCall(finalFilter,XAxis,13);

  }

  render() {
    let { 

      getQuestionMatrixResultData, questionsList,

      getIndexFilterData, currentQuestionId, EmpFilterData } = this.props;

    let { currFilterName ,FilterValues, XAxis } = this.state;





    return (
      <>
      <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">

                    <Filter 

                    showFilter={this.state.showFilter}
                    showValue={this.state.showValue}
                    closeFilter={this.closeFilter}

                    EmpFilterData2={EmpFilterData}
                    getIndexFilterData={getIndexFilterData}
                    handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        
                  />  
     

        </div>
                  <div onClick={()=>this.closeFilter(false)} className=" ">
                  <div className="flex w-full" >

                    <div className="bg-white rounded w-full shadow-lg px-4 m-4 mb-24" >
                      <MatrixFullTable stage={3} 
                        questionsList={questionsList}
                        getQuestionMatrixResultData={getQuestionMatrixResultData} 
                        renderType={"matrix"}
                      />
                    
                    </div>

                  </div>
                </div>
                
                

                





      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(SummaryData);
