import React from 'react';


export default function Happiness(props) {
    
    let { 

        item,

        DistributionData

     } = props;


    // console.log('item: ',item)

    let score = 'NaN';
    let type = '';

    if(item['score']!=='NaN'){
        score = parseFloat(item['score']);
        type = item['type'];
    }



    return (
      <>	
        <div className="bg-white cursor-pointer rounded w-full shadow-lg px-4 mx-1 my-1 py-2 overflow-hidden" style={{
            background:(item['color'])?item['color']:'#757575',
            height:'190px',
            width:'96%'
        }}>
            <div className="text-white text-lg">{item.name} Index</div>
            <div className="text-white pt-8" style={{fontSize:'35px'}}>{(score !== 'NaN')?(type==='Percentage')?score+'%':score : 
            <p style={{fontSize:'15px',position:'relative'}}>We have not calculated <br/> {item.name} for the organization</p>}</div>
            {(score !== 'NaN')?<div className="text-white text-sm ">Total {type}</div>:null}
        
            <div ><img src={item["image"]} style={{marginLeft:'120px',marginTop:(score !== 'NaN')?'-120px':'-50px',width:'170px',height:'170px'}}/></div>

        </div>
      </>
    );
  }
