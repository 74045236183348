export const CONST = {
  
  BACKEND_URL: 'https://survey.happyplus.in/api/v1',

  SURVEY_URL:"https://survey.happyplus.in/surveylink/#/v2/",
  
  APP_NAME: 'PLUS HR',
  FOOTER_TEXT: '©2020 Happyplus. All rights reserved.',

};
