import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";

// pages
import Dashboard from "../../pages/Dashboard/Dashboard";
import Logout from "../../pages/Other/Logout";
import AddQuestion from "../../pages/SurveyMgmt/AddQuestion/AddQuestion";

//  SecondDashboard
import GeneralDashboard from "../../pages/GeneralDashboard/GeneralDashboard";

// Survey Management 
import Survey from "../../pages/SurveyMgmt/Survey/Survey";
import Campaign from "../../pages/SurveyMgmt/Campaign/Campaign";
import LandingPage from "../../pages/SurveyMgmt/LandingPage/LandingPage";
import SurveyReport from "../../pages/SurveyMgmt/Survey/Components/SurveyReport/SurveyReport";

import CampaignReport from "../../pages/SurveyMgmt/Campaign/Components/CampaignReport/CampaignReport";

import CampaignReportCustomer from "../../pages/SurveyMgmt/Campaign/Components/CampaignReportCustomer/CampaignReportCustomer";



import Employees from '../../pages/Employees/Employees';
import ManagerActionPlan from "../../pages/ManagerActionPlan/ManagerActionPlan";
import Preview from "../../pages/SurveyMgmt/AddQuestion/Components/Preview/Preview";
import Users from '../../pages/Users/Users';

function Layout(props) {
  return (
    <div>
        <>
        <Switch>
            <Route path="/app/dashboard/:id" component={withRouter(Dashboard)} />
            <Route path="/app/generaldashboard/:id" component={withRouter(GeneralDashboard)} />
            <Route path="/app/logout" component={withRouter(Logout)} />

            {/* Survey Managerment */}
            <Route path="/app/home" component={withRouter(LandingPage)} />
            <Route path="/app/survey" component={withRouter(Survey)} />
            <Route path="/app/question/:id" component={withRouter(AddQuestion)} />
            <Route path="/app/publish/:id" component={withRouter(Campaign)} />
            <Route path="/app/surveyreport/:id" component={withRouter(SurveyReport)} />

            <Route path="/app/campaignreport/:id/:idc" component={withRouter(CampaignReport)} />
            <Route path="/app/campaignkioskreport/:id/:idc" component={withRouter(CampaignReportCustomer)} />


            <Route path="/app/employees/" component={withRouter(Employees)} />
            <Route path="/app/users/" component={withRouter(Users)} />
            <Route path="/app/manageractionplan" component={withRouter(ManagerActionPlan)} />
            <Route path="/app/preview/:id" component={withRouter(Preview)} />

        </Switch>      
        </>
    </div>
  );
}

export default withRouter(Layout);
