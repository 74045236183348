import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../_actions';

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {
    this.props.dispatch(userActions.logout());
  }

  render() {

    return (
      <>
        <div>
            <h1 style={{color:'#757575', textAlign:'center'}}>Loging out...</h1>
        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users } = state;
  return {
    loggingIn,
    users
  };
}
export default connect(mapStateToProps)(Logout);
