import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList:[],
      EmpIndexA:0,
      EmpIndexB:4
    }
  }
  componentDidMount() {

  }

  render() {
    let { managerStatus } = this.props;
    

    let NewDataList= [
      // {
      //   name: "Assigned Manager",
      //   value:  managerStatus.assigned,
      //   itemStyle: {color:'blue'}
      // },
      {
        name: "Pending Manager",
        value:  managerStatus.pending,
        itemStyle: {color:'#eb3434'}
      },
      {
        name: "Completed Manager",
        value:  managerStatus.completed,
        itemStyle: {color:'#34eb71'}
      },
    ]



    // function ColorFn(key){
    //   if(templateId===11){
    //     return {color:key==="Yes"?'#00cc66':'#ff5050'}
    //   }
    //   else{
    //     return {}
    //   }
    // }

    // let total = 0;
    // let DataList = []
    // if(AnswerObj){
    //   Object.keys(AnswerObj).forEach(key => {
    //     let score = parseInt(AnswerObj[key]);
    //     total+=score;
    //     DataList.push({
    //       name: key,
    //       value: score,
    //       itemStyle:ColorFn(key)
    //     });
    //   });
    // }

    // let NewDataList = [];
    // if(DataList&&DataList.length>0){DataList.forEach((item)=>{NewDataList.push({
    //   name: item.name,
    //   value:  ((item.value*100)/total).toFixed(2),
    //   itemStyle: item.itemStyle
    // })})}

    // console.log('AnswerObj: ',AnswerObj)
    // console.log('DataList: ',DataList)
    // console.log('NewDataList: ',NewDataList)

    // [
    //   {value: 1, name: '7'},
    //   {value: 2, name: '2'},
    //   {value: 3, name: '9'},
    //   {value: 4, name: '10'},
    //   {value: 5, name: '3'}
    // ]

    return (
      <>
                                    
      <div  className=" " >
          <ReactEcharts
            style={{height:"120px", marginTop:'-20px'}}
            option={{
              tooltip: {
                  trigger: 'item'
              },
              // legend: {
              //     top: '5%',
              //     left: 'center'
              // },
              series: [
                  {
                      name: "Status",
                      type: 'pie',
                      radius: ['40%', '70%'],
                      avoidLabelOverlap: false,
                      label: {
                          show: false,
                          position: 'center'
                      },
                      // emphasis: {
                      //     label: {
                      //         show: true,
                      //         fontSize: '40',
                      //         fontWeight: 'bold'
                      //     }
                      // },
                      labelLine: {
                          show: false
                      },
                      data: NewDataList
                  }
              ]
          }}
                            
          />
     </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(PieChart);
