import { userConstants } from '../_constants';
import { userService } from '../_services';
import { CONST } from '../_config';
export const userActions = {
    userlogin,
    logout,
    validateOtp,


    login,

    uploadImage,
    uploadImageLogo,
    uploadImageLogo2
    
};
function userlogin(data) {
    return dispatch => {
        dispatch(request({ data }));
        userService.userlogin(data)
            .then(
                user => {
                    dispatch(success(user));
                    localStorage.removeItem('backendUrl');
                    localStorage.removeItem('accessModule');

                },
                error => {
                    dispatch(failure(error));
                    console.log(error);
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function validateOtp(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.validateOtp(data)
            .then(
                user => {
                    dispatch(success(user));
                    console.log('user:--------------------> ',user);
                    let userRole="";
                    let accessModule="";
                    if(user && user.userinfo && user.userinfo.role){
                        userRole = user.userinfo.role;
                    }
                    if(user && user.userinfo && user.userinfo.role){
                        accessModule = user.userinfo.moduleAccess;
                    }
                    if(accessModule){
                        localStorage.setItem('accessModule',  JSON.stringify(accessModule));
                    }
                    console.log("accessModule login:",accessModule)



                    if(userRole==="ADMIN"){
                    localStorage.setItem('backendUrl', JSON.stringify(CONST.BACKEND_URL+""));

                        setTimeout(()=>{
                            props.history.push(`app/survey/`);
                        },1000)
                    }
                    else if(userRole==="MNGR"){
                    localStorage.setItem('backendUrl', JSON.stringify(CONST.BACKEND_URL+""));

                        setTimeout(()=>{
                            props.history.push(`app/manageractionplan/`);
                        },1000)
                    }
                    else if(userRole==="CROLE"){
                     localStorage.setItem('backendUrl', JSON.stringify(CONST.BACKEND_URL+"/crole"));

                        setTimeout(()=>{
                            // props.history.push(`app/dashboard/5fa04d316ede0f0336066cce`);
                            props.history.push(`app/survey/`);

                        },1000)
                    }
                    else{
                    localStorage.setItem('backendUrl', JSON.stringify(CONST.BACKEND_URL+""));

                        setTimeout(()=>{
                            props.history.push(`app/survey/`);
                        },1000)
                    }


                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_OTP_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_OTP_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_OTP_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}





//SUPER ADMIN LOGIN 

function login(data, props) {
    return dispatch => {
        dispatch(request({ data }));
        userService.login(data)
            .then(
                user => {
                    dispatch(success(user));
                    props.history.push({ pathname: 'app/survey' });
                    console.log("user: ", user);

                },
                error => {
                    console.log("errorerror ", error);
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_SUPER_ADMIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUPER_ADMIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_SUPER_ADMIN_FAILURE, error } }
}


function uploadImage(data) {
    return dispatch => {
        userService.uploadImage(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.FILE_UPLOAD_STATUS_FAILURE, error } }
}
function uploadImageLogo(data) {
    return dispatch => {
        userService.uploadImageLogo(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.LOGO_FILE_UPLOAD_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.LOGO_FILE_UPLOAD_STATUS_FAILURE, error } }
}

function uploadImageLogo2(data) {
    return dispatch => {
        userService.uploadImageLogo2(data)
            .then(
                uploadImage => {
                    dispatch(success(uploadImage));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function success(uploadImage) { return { type: userConstants.LOGO_FILE_UPLOAD_2_STATUS_SUCCESS, uploadImage } }
    function failure(error) { return { type: userConstants.LOGO_FILE_UPLOAD_2_STATUS_FAILURE, error } }
}
