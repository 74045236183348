import React ,{ useState } from "react";
export default function AddFilter(props) {
  let [view,onAddFilter] = React.useState(false);

  let { 
    closeEmployeeFilter, onEmployeeFilterDropdown,handleEmployeeColumnSelect, handleFilterCheck, createEmployeeFilter,
    onDeleteFilter,handleFilterStatus,handleFilterRank,updateformDataFilter,updatehandleFilterRank,

    showFilterNames,formDataFilter, getEmployeeColumnNameData, EmployeeFilterCompanyList, LanguageObj, CurrentLanguage,getEmployeeFilterListByCompanyIdData,
    onClickQuesUp, onClickQuesDown
  
  } = props;
  return (
   <>
              <div className="absolute left-0 top-12 bg-white shadow-2xl overflow-hidden font-normal  border  rounded-md  ">

            <div className="flex justify-between "> 
            <h1 className="text-md text-blue-500 font-medium p-4">Employee Filter</h1>

              <span onClick={()=>closeEmployeeFilter(false)} className="cursor-pointer text-lg font-medium text-gray-500 material-icons p-4">close</span>
            </div>
          
            

            {true?
            <div className="overflow-y-auto whitespace-nowrap w-full h-60 lang">
              <div className="align-middle inline-block min-w-full overflow-hidden">
                <table className=" ">
                  <thead>
                    <tr className="capitalize text-xs text-gray-800">
                      <th className="px-6 py-3 border-b border-gray-200 bg-white text-left font-medium "> 
                      {LanguageObj && LanguageObj["s_no_label"+"_"+CurrentLanguage]? LanguageObj["s_no_label"+"_"+CurrentLanguage]:"S No."}
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-white text-left font-medium"> 
                      {LanguageObj && LanguageObj["name_label"+"_"+CurrentLanguage]? LanguageObj["name_label"+"_"+CurrentLanguage]:"Name"}
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-white text-left font-medium "> 
                      {LanguageObj && LanguageObj["enable_label"+"_"+CurrentLanguage]? LanguageObj["enable_label"+"_"+CurrentLanguage]:"Enable"}
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-white text-center font-medium "> 
                      {LanguageObj && LanguageObj["delete_label"+"_"+CurrentLanguage]? LanguageObj["delete_label"+"_"+CurrentLanguage]:"Delete"}
                      </th>
                      <th className="px-6 py-3 border-b border-gray-200 bg-white text-center font-medium "> 
                      {LanguageObj && LanguageObj["rank_label"+"_"+CurrentLanguage]? LanguageObj["rank_label"+"_"+CurrentLanguage]:"Rank"}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                  {getEmployeeFilterListByCompanyIdData && getEmployeeFilterListByCompanyIdData.length>0?
                        getEmployeeFilterListByCompanyIdData.map((empFilter, index)=>
                        <>
                          <tr>
                            <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200">
                              <div className="leading-5 text-gray-500 text-xs font-semibold">{index+1}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200 text-xs font-normal text-gray-500 font-semibold"> 
                            {empFilter && empFilter.label?empFilter.label:""} </td>

                            <td className="px-6 py-2 whitespace-no-wrap border-b border-gray-200 text-xs leading-5 text-gray-500 font-semibold">
                              
                              {empFilter.isDisable?
                              <>
                              <div onClick={()=>handleFilterStatus(empFilter.id)} className="rounded-full w-8 h-4 p-0.5 bg-gray-200">
                                <div className="rounded-full w-3 h-3 bg-white -translate-x-2 transform mx-auto duration-300 ease-in-out" />
                              </div>
                              </>
                              :
                              <>
                              <div onClick={()=>handleFilterStatus(empFilter.id)} className="rounded-full w-8 h-4 p-0.5 bg-green-400">
                                <div className="rounded-full w-3 h-3 bg-white translate-x-2 transform mx-auto duration-300 ease-in-out" />
                              </div>
                              </>
                              }
                            </td>

                            <td className="px-6 py-2 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center">
                               <span onClick={()=>onDeleteFilter(empFilter)} className="material-icons-outlined hover:bg-blue-100 p-1 rounded-md hover:text-blue-500 text-gray-500" 
                               style={{fontSize: '1.3rem'}}>delete</span> 
                            </td>

                            <td className="px-6 py-2 whitespace-no-wrap  border-b border-gray-200 text-sm leading-5 font-medium text-center"> 
                            
                              <span onClick={()=>onClickQuesUp(empFilter.id,index)} className="font-semibold material-icons hover:bg-blue-100 p-2 rounded-md hover:text-blue-500 text-gray-500" 
                              style={{fontSize: '16px'}}>arrow_upward</span> 

                              <span onClick={()=>onClickQuesDown(empFilter.id,index)} className="font-semibold material-icons hover:bg-blue-100 p-2 rounded-md hover:text-blue-500 text-gray-500" 
                              style={{fontSize: '16px'}}>arrow_downward</span> 

                            </td>

                          </tr>
                        </>
                        ):null
                      }
                  </tbody>
                </table>
              </div>
            </div>
            :null}



          </div>
   </>
  );
}
