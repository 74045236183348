import { questionsConstants } from '../_constants';
import { questionsService } from '../_services';
import { surveyActions } from '../_actions';

export const questionsActions = {
    createQuestion,
    updateQuestion,


    disableQuestions,
    deleteQuestion,
    getAllQuestions,
    getQuestionListBySurveyID,
    handleNameQuestion,
    handleSubQuestionName,
    handleNameOption,
    handleNameOptionHeading,
    deleteOption,
    addOption,
    surveySubmit,
    handleParaInput,
    handleUpdateWeightage,
    handleNameWeightage,
    handleMandatoryQuestion,
    handleSubmitPreChange,
    handleParameterQuestion,
    updateQuestionRenk,
    copyFromQuestionLibrary,

    translateQuestion,
    translateManyQuestions,
    translateSurvey

    // getParameterListbyParentId
};

function createQuestion(data) {
    let survey = {
        surveyId: data.surveyId,
    }
    return dispatch => {
        dispatch(request());
        questionsService.createQuestion(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.ADD_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.ADD_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.ADD_QUESTIONS_FAILURE, error } }
}

function updateQuestion(data,call, call2) {
    let tempdata = {
        surveyId: data.surveyId,
    }
    let updateData = {   
        id:data.id,
        surveyId: data.surveyId,
        name: data.name,
        rank: data.rank,
        options:[],
        questionTypeId: data.questionTypeId,
        templateId: data.templateId,
        isMandatory: data.isMandatory,
        parameterBucketId:(data && data.parameterBucketId && data.parameterBucketId.id)?data.parameterBucketId.id: "",
        parameterDimensionId:(data && data.parameterDimensionId && data.parameterDimensionId.id)?data.parameterDimensionId.id: "",
        SubQuestionList:[]

    }
    data.options.forEach(element => {
        updateData.options.push({id:element.id , weightage:element.weightage , name:element.name,
        heading:element.heading
        })
    })
    console.log("data.updateData>>>>>> ",updateData)
    // console.log("data.updateQuestion>>>>>> ",data)

    if(data && data.SubQuestionList && data.SubQuestionList.length>0){
        data.SubQuestionList.forEach((subQues)=>{
            updateData["SubQuestionList"].push({
                "id":subQues.id,
                "name":subQues.name
            });
        });
    }

    return dispatch => {
        dispatch(request());
        questionsService.updateQuestion(updateData)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(tempdata));
                    if(!call){
                    }
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.UPDATE_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.UPDATE_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.UPDATE_QUESTIONS_FAILURE, error } }
}







function getAllQuestions(data) {
    return dispatch => {
        dispatch(request());
        questionsService.getAllQuestions(data)
            .then(
                questions => dispatch(success(questions)),
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.QUESTIONS_GETALL_REQUEST } }
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.QUESTIONS_GETALL_FAILURE, error } }
}



function deleteQuestion(data) {

    let survey = {
        surveyId: data.surveyId
    }
    return dispatch => {
        dispatch(request());
        questionsService.deleteQuestion({ id: data.id })
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(survey));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.DELETE_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.DELETE_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.DELETE_QUESTIONS_FAILURE, error } }
}
function disableQuestions(data) {
    let tempdata = {
        "keyWord": data.keyWord,
        "pageNo": data.pageNo,
        "size": data.size
    }
    return dispatch => {
        dispatch(request());
        questionsService.disableQuestions({ id: data.id })
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getAllQuestions(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.ADD_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.ADD_QUESTIONS_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.ADD_QUESTIONS_FAILURE, error } }
}
// function getQuestionListBySurveyID(data) {
   
//     return dispatch => {
//         dispatch(request());
//         questionsService.getQuestionListBySurveyID(data)
//             .then(
//                 questions => {
//                     dispatch(success(questions));
//                 },
//                 error => {
                    
//                     dispatch(alertActions.error(error));
//                     dispatch(failure(error))
//                 }
//             );
//     };
//     function request() { return { type: questionsConstants.QUESTIONS_GETALL_REQUEST } }
//     function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
//     function failure(error) { return { type: questionsConstants.QUESTIONS_GETALL_FAILURE, error } }
// }


function handleNameQuestion(questionList, quesId, value) {
    let quesIndex = questionList.findIndex(element=>element.id === quesId);
    questionList[quesIndex]['name'] = value;
    let questionObj = {
        questionsList: questionList
    }
    console.log('===---andleQuestionInput:',questionList[quesIndex]['name'])

    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}


function handleSubQuestionName(questionList, QuesId, subQuesId, value) {

    let currentQuestionIndex = questionList.findIndex(element=>element.id === QuesId);

    let SubQuestionIndex = questionList[currentQuestionIndex]['SubQuestionList'].findIndex(element=>element.id === subQuesId);
    
    questionList[currentQuestionIndex]['SubQuestionList'][SubQuestionIndex]["name"] = value;

    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}



function handleMandatoryQuestion(questionList, quesId, value) {
    let quesIndex = questionList.findIndex(element=>element.id === quesId);
    questionList[quesIndex]['isMandatory'] = value;
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleParameterQuestion(questionList, quesId, para, paraType) {
    let quesIndex = questionList.findIndex(element=>element.id === quesId);
    
    if(paraType === 1){
 
            questionList[quesIndex]['parameterBucketId']={
                "id":para.id,
                "name":para.name
            }
       
    }
    else if(paraType === 2){

            questionList[quesIndex]['parameterDimensionId']={
                "id":para.id,
                "name":para.name
            }
    
    }
    else{
        questionList[quesIndex]['parameterBucketId'] = "";
        questionList[quesIndex]['parameterDimensionId'] = "";

    }
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleParaInput(questionList, quesId, value) {
    let quesIndex = questionList.findIndex(element=>element.id === quesId);
    questionList[quesIndex]['parameter'] = value.name;
    questionList[quesIndex]['parameterId'] = value.id;
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleUpdateWeightage(questionList, quesId, value) {
    let quesIndex = questionList.findIndex(element=>element.id === quesId);
    questionList[quesIndex]['weightage'] = value;
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleNameOption(questionList, quesId, optionId, value) {
    let quesindex = questionList.findIndex(element=>element.id === quesId);
    let optionIndex = questionList[quesindex]['optionsList'].findIndex(x=>x.id === optionId);
    if(questionList[quesindex]['optionsList'][optionIndex]){
        questionList[quesindex]['optionsList'][optionIndex]['name'] = value;
        questionList[quesindex]['options'] = questionList[quesindex]['optionsList']

    }
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleNameOptionHeading(questionList, quesId, optionId, value) {
    let quesindex = questionList.findIndex(element=>element.id === quesId);
    let optionIndex = questionList[quesindex]['optionsList'].findIndex(x=>x.id === optionId);
    if(questionList[quesindex]['optionsList'][optionIndex]){
        questionList[quesindex]['optionsList'][optionIndex]['heading'] = value;
        questionList[quesindex]['options'] = questionList[quesindex]['optionsList']

    }
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function handleNameWeightage(questionList, quesId, optionId, value) {
    let quesindex = questionList.findIndex(element=>element.id === quesId);
    let optionIndex = questionList[quesindex]['optionsList'].findIndex(x=>x.id === optionId);
    if(questionList[quesindex]['optionsList'][optionIndex]){
        questionList[quesindex]['optionsList'][optionIndex]['weightage'] = value;
        questionList[quesindex]['options'] = questionList[quesindex]['optionsList']
    }
    let questionObj = {
        questionsList: questionList
    }
    return dispatch => {
        dispatch(success(questionObj));
    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function deleteOption(questionList, quesId, optionId) {
    let quesindex = questionList.findIndex(element=>element.id === quesId);
    let optionIndex = questionList[quesindex]['optionsList'].findIndex(x=>x.id === optionId);
    questionList[quesindex]['optionsList'].splice(optionIndex,1);
    let questionObj = {
        questionsList: questionList
    }
    let cur_ques =  questionList[quesindex];
    console.log('X.1>>> ',cur_ques);

    return dispatch => {
        dispatch(success(questionObj));
        dispatch(request());
        questionsService.deleteQuestionOption({id: optionId},false)
            .then(
                questions => {
                    dispatch(success(questions));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.DELETE_QUESTIONOPTION_REQUEST } }
    function success(questions) { return { type: questionsConstants.DELETE_QUESTIONOPTION_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.DELETE_QUESTIONOPTION_FAILURE, error } }
    // function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}

function addOption(questionList, question) {
    let currentQuestionIndex = questionList.findIndex(element=>element.id === question.id);

    // questionList[currentQuestionIndex]['optionsList'].push({weightage:1,name:"New Choice"})

    questionList[currentQuestionIndex]['options'].push({weightage:1,name:"New Choice"})

    let questionObj = {
        questionsList: questionList
    }
    console.log("{{addOption: ",questionObj)
    delete questionList[currentQuestionIndex].createdBy;
    delete questionList[currentQuestionIndex].createdAt;
    // let cur_ques =  questionList[currentQuestionIndex];
    // let tempdata = {
    //     "keyWord": "",
    //     "pageNo": 1,
    //     "size": 10
    // }
    return dispatch => {
        dispatch(success(questionObj));
        dispatch(request());
        console.log("{{{question: ",question)
        dispatch(this.updateQuestion(question,true, true));
        // questionsService.updateQuestion(cur_ques)
        //     .then(
        //         questions => {
        //             dispatch(success(questions));
        //             dispatch(this.updateQuestion(survey));
        //             dispatch(this.getQuestionListBySurveyID(tempdata));
        //             dispatch(alertActions.success("Update successfully."));
        //         },
        //         error => {
        //             dispatch(alertActions.error(error));
        //             dispatch(failure(error))
        //         }
        //     );
    };
    function request() { return { type: questionsConstants.UPDATE_QUESTIONS_REQUEST } }
    function success(questions) { return { type: questionsConstants.UPDATE_QUESTIONS_SUCCESS, questions } }
    // function failure(error) { return { type: questionsConstants.UPDATE_QUESTIONS_FAILURE, error } }
    // function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}



// function handleSubmitPreChange(currQues, answersList,questionsList, value, weightage ,currOptionId) {
//     let questionObj={};
//     if(currQues.templateId===2){
        
//         let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
//         console.log('currIndex: ', currIndex)
//         if(answersList[currIndex]['answer'] === ""){
//             answersList[currIndex]['selected'] = value.id;
//             answersList[currIndex]['answer'] = value.name;
//             answersList[currIndex]['weightage'] = weightage;
//             answersList[currIndex]['id'] = currOptionId;

//         }
//         else{
//             answersList[currIndex]['selected'] = "";
//             answersList[currIndex]['answer'] = "";
//             answersList[currIndex]['weightage'] = 0;
//             answersList[currIndex]['id'] = currOptionId;

//         }
        
//         questionObj = {
//             questionsList:questionsList,
//             answersList:answersList
//         }
//     }
//     else{
//         let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
//         console.log('currIndex: ', currIndex)

//         answersList[currIndex]['selected'] = value.id;
//         answersList[currIndex]['answer'] = value.name;
//         answersList[currIndex]['weightage'] = weightage;
//         answersList[currIndex]['id'] = currOptionId;

//         questionObj = {
//             questionsList:questionsList,
//             answersList:answersList
//         }
//     }
   
//     return dispatch => {
//         dispatch(success(questionObj));
//         console.log("- X- X- C AnswerList: ",questionObj)

//     };
//     function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

// }

function surveySubmit(answersList,dataId,props) {
    console.log("surveyData: ", answersList)

    let ansData={
        surveyId: dataId.surveyId,
        employeeId: dataId.employeeId,
        answers: []
    }

    answersList.forEach((surveyData)=>{
        ansData.answers.push({
            questionId:surveyData.questionId,
            answer:surveyData.answer,
            parameterType:surveyData.parameterType,
            weightage:surveyData.weightage,
            parameter:surveyData.parameter
        })
    })


    console.log("finalData: ", ansData)

    

             return dispatch => {
            dispatch(request());
            questionsService.surveySubmit(ansData)
                .then(
                    answers => {
                        console.log("-------------------------------------------answers ==== == = ",answers)
                        dispatch(success(answers));
                        props.history.push(`/app/thankyou/${dataId.surveyId}`)
                    },
                    error => {
                        console.log("--------------------------------------------failser ==== == = ",error)
                        dispatch(failure(error))
                    }
                );
        };
        function request() { return { type: questionsConstants.ANSWERS_SUBMIT_REQUEST } }
        function success(answers) { return { type: questionsConstants.ANSWERS_SUBMIT_SUCCESS, answers } }
        function failure(error) { return { type: questionsConstants.ANSWERS_SUBMIT_FAILURE, error } }
}


function getQuestionListBySurveyID(data,props) {
   
    return dispatch => {
        dispatch(request());
        questionsService.getQuestionListBySurveyID(data)
            .then(
                questions => {
                    // console.log("answers_submission list 2", QuesSub)
                    dispatch(success(questions));
                },
                error => {
                    // props.history.push(`/done`)
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.QUESTIONS_GETALL_REQUEST } }
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.QUESTIONS_GETALL_FAILURE, error } }
}


function handleSubmitPreChange(currQues, answersList,questionsList, value, weightage ,surveyScreenData) {
    let questionObj={};
    if(currQues.templateId===2){
        
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
        console.log('currIndex: ', currIndex)
        if(answersList[currIndex]['answer'] === ""){
            answersList[currIndex]['questionOptionId'] = value.id;
            answersList[currIndex]['answer'] = value.name;
            // answersList[currIndex]['parameter'] = currQues.parameterId;
        }
        else{
            answersList[currIndex]['questionOptionId'] = "";
            answersList[currIndex]['answer'] = "";
            // answersList[currIndex]['parameter'] = currQues.parameterType;
        }
        
        console.log("handleSubmitPreChange 2",surveyScreenData)
        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData
        }
    }
    else if(currQues.templateId===3 || currQues.templateId===4){
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
        console.log('currIndex: ', currIndex)

        answersList[currIndex]['questionOptionId'] = "";
        answersList[currIndex]['answer'] = value.name;
        // answersList[currIndex]['parameter'] = currQues.parameterType;
        console.log("answersList templateId===3,4: ",answersList)

        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData
        }
    }
    else{
        let currIndex = answersList.findIndex(element=>element.questionId === currQues.id);
        console.log('currIndex: ', currIndex)

        answersList[currIndex]['questionOptionId'] = value.id;
        answersList[currIndex]['answer'] = value.name;
        // answersList[currIndex]['parameter'] = currQues.parameterType;
        console.log("handleSubmitPreChange 3",surveyScreenData)

        questionObj = {
            questionsList:questionsList,
            answersList:answersList,
            surveyScreenData:surveyScreenData
        }
    }
   
    return dispatch => {
        dispatch(success(questionObj));
        console.log("- X- X- C AnswerList: ",questionObj)

    };
    function success(questions) { return { type: questionsConstants.QUESTIONS_GETALL_SUCCESS, questions } }

}



function updateQuestionRenk(data,surveyId) {
    let tempdata = {
        surveyId: surveyId
    }
    
    return dispatch => {
        dispatch(request());
        questionsService.updateQuestionRenk(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.UPDATE_QUESTIONS_RANK_REQUEST } }
    function success(questions) { return { type: questionsConstants.UPDATE_QUESTIONS_RANK_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.UPDATE_QUESTIONS_RANK_FAILURE, error } }
}


function copyFromQuestionLibrary(data) {
    let tempdata = {
        surveyId: data.surveyId
    }
    
    return dispatch => {
        dispatch(request());
        questionsService.copyFromQuestionLibrary(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.COPY_QUES_LIBRARY_REQUEST } }
    function success(questions) { return { type: questionsConstants.COPY_QUES_LIBRARY_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.COPY_QUES_LIBRARY_FAILURE, error } }
}


function translateQuestion(data, tempdata,TranslationSubList) {
   
    return dispatch => {
        dispatch(request());
        questionsService.translateQuestion(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    if(TranslationSubList && TranslationSubList.length>0){
                        let dataSubQues = {
                            translList:TranslationSubList
                        }
                        dispatch(this.translateManyQuestions(dataSubQues,tempdata));

                    }
                    else{
                        dispatch(this.getQuestionListBySurveyID(tempdata));
                    }

                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.TRANSLATE_QUESTION_REQUEST } }
    function success(questions) { return { type: questionsConstants.TRANSLATE_QUESTION_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.TRANSLATE_QUESTION_FAILURE, error } }
}

function translateManyQuestions(data, tempdata) {
   
    return dispatch => {
        dispatch(request());
        questionsService.translateManyQuestions(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(this.getQuestionListBySurveyID(tempdata));

                },
                error => {
                    dispatch(failure(error));
                    dispatch(this.getQuestionListBySurveyID(tempdata));
                }
            );
    };
    function request() { return { type: questionsConstants.TRANSLATE_MANY_QUESTION_REQUEST } }
    function success(questions) { return { type: questionsConstants.TRANSLATE_MANY_QUESTION_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.TRANSLATE_MANY_QUESTION_FAILURE, error } }
}


function translateSurvey(data, tempdata) {
   
    return dispatch => {
        dispatch(request());
        questionsService.translateSurvey(data)
            .then(
                questions => {
                    dispatch(success(questions));
                    dispatch(surveyActions.getSurveyById(tempdata));
                },
                error => {
                    dispatch(failure(error))
                }
            );
    };
    function request() { return { type: questionsConstants.TRANSLATE_SURVEY_REQUEST } }
    function success(questions) { return { type: questionsConstants.TRANSLATE_SURVEY_SUCCESS, questions } }
    function failure(error) { return { type: questionsConstants.TRANSLATE_SURVEY_FAILURE, error } }
}



// function getParameterListbyParentId(data) {
//     return dispatch => {
//         dispatch(request());
//         questionsService.getParameterListbyParentId(data)
//             .then(
//                 questions => {
//                     dispatch(success(questions));
//                 },
//                 error => {
//                     dispatch(failure(error));
//                 }
//             );
//     };
//     function request() { return { type: questionsConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST } }
//     function success(questions) { return { type: questionsConstants.PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS, questions } }
//     function failure(error) { return { type: questionsConstants.PARAMETERLIST_BYTYPE_GETALL_PARENT_FAILURE, error } }
// }