import { dataTool } from 'echarts';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
// import Actions from '../Actions/Actions';
// import Resource from '../TaskResouce/Resource/Resource';
// import Task from '../TaskResouce/Task/Task';
// import TaskResource from '../TaskResouce/TaskResource';
// import CompleteModal from './CompleteModal';

class Dimension extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {

  }

  render() {
    let { getManagerActionPlanData, CurrentActionId, navigate, CurrentActionData, onSelectTask, CurrentDimensionData } = this.props;

    let TaskList = [];
    if(getManagerActionPlanData && getManagerActionPlanData.length>0){
      getManagerActionPlanData.forEach((action)=>{
        if(action && action.id===CurrentActionId){
          if(action && action.TaskList && action.TaskList.length>0){
            TaskList = action.TaskList;
          }
        }
      });
    }

    function taskStatusFn(task){
    //1: start
    //2: pending
    //3: completed

      let taskStatus = 1
      if(task && task.isStarted){
        if(task && task.isComplete){
          taskStatus= 3;
        }
        else{
          taskStatus= 2;
        }
      }
      return taskStatus
    }


    function colorCodeFn(task){
      let color = "#757575";
      if(taskStatusFn(task)===1){
        color = "#0083db";
      }
      else if(taskStatusFn(task)===2){
        color = "#db9900";
      }
      else if(taskStatusFn(task)===3){
        color = "#02bd5f";
      }
      return color
    }



    // getManagerActionPlanData,currentParameterId
    return (
      <> 
<div className="w-full lg:pl-4  lg:mt-0 mt-4 mb-4 ">
  {/* indicators */}
  <div className="text-sm text-gray-500 flex items-center "> <p className="cursor-pointer"  
  onClick={()=>navigate("actionlist")}>{CurrentDimensionData&&CurrentDimensionData.name?CurrentDimensionData.name:""}</p>
  <span className="material-icons px-1" style={{fontSize: '16px'}}>chevron_right</span> <p className="text-blue-500 cursor-pointer">{CurrentActionData&&CurrentActionData.name?CurrentActionData.name:""}</p></div>
  {/* upperbox */}
  <div className="bg-white flex p-4 rounded-lg mt-4 mb-2 shadow-md">
    {/* left */}
    <div><div className="bg-blue-500 w-14 h-14 rounded-lg flex items-center justify-center" style={{background:CurrentActionData && CurrentActionData.colorCode?CurrentActionData.colorCode:"#757575"}}><span className="w-8 text-white material-icons" style={{fontSize: '2rem'}}>group</span></div></div>
    {/* right */}
    <div className="mx-4"><h1 className="text-sm font-medium">{CurrentActionData&&CurrentActionData.name?CurrentActionData.name:""}</h1>
    <p className="text-xs py-1 text-gray-500">
     {CurrentActionData&&CurrentActionData.desc?CurrentActionData.desc:""}
     {/* <span>View More</span> */}
     </p></div>
  </div>
  {/* tabs */}
  <div className="flex items-center text-xs text-gray-500 border-b">
    <span onClick={()=>navigate("tasklist")} className="p-4 cursor-pointer border-b text-blue-600 border-blue-500 font-semibold">TASKS</span> 
    <span onClick={()=>navigate("resourcelist")} className="p-4 cursor-pointer text-gray-700">RESOURCES</span>
  </div>
  {/* a line */}
  <p className="px-1  text-xs font-medium flex items-center text-gray-600 py-4">To improve {CurrentActionData && CurrentActionData.name?CurrentActionData.name:""} follow the below tasks.</p>
  {/* boxes */}
  <div className="grid xl:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4 ">

  {TaskList && TaskList.length>0?
    TaskList.map((task,index)=>

    <div style={{
      borderWidth:'0px',
      borderColor:colorCodeFn(task)
    }} onClick={()=>onSelectTask(task,index+1)} className="cursor-pointer bg-white p-4 rounded-lg shadow-xl">
    <h1 className="text-sm font-medium h-20 overflow-hidden font-semibold">{task && task.name?(task.name.length>70)?task.name.slice(0,70)+"...":task.name:""}</h1>
    <div className=" text-xs items-center justify-between">
    


    {taskStatusFn(task)===1?
    <>
        <p className="opacity-60">Not started yet,</p> 
        <p className="opacity-60">click here to start the task</p> 
    </>
    :null}
    {taskStatusFn(task)===2?
      <>
      <p className="opacity-60">{(task && task.initdate)?"Start Date: "+moment.unix(task.initdate).format("DD-MM-YY"):""}</p> 
      <p className="opacity-60">This task is pending...</p> 
      </>
    :null}
    {taskStatusFn(task)===3?
      <>
      <p className="opacity-60">{(task && task.initdate)?"Start Date: "+moment.unix(task.initdate).format("DD-MM-YY"):""}</p> 
      <p className="opacity-60">{(task && task.date)?"End Date: "+moment.unix(task.date).format("DD-MM-YY"):""}</p> 
      </>
    :null}



    </div>
    {/* loader */}
    <div className="h-1 bg-white rounded-full my-2 bg-opacity-50 w-full overflow-hidden">
      {task.isComplete?<div className="bg-white w-full h-1" />:<div className="bg-white w-0 h-1" />}
      </div>
      
      <span className="flex">
        <span style={{
          color:colorCodeFn(task)
        }} className="text-xs block mt-6 font-semibold">{task.isStarted? 
          task.isComplete?"COMPLETED":"PENDING":
          "LET'S START"
          }
          </span>
        {task.isComplete?<span className="material-icons text-green-400 mx-2 mt-5">check_circle</span>:null}
        </span>
      

    </div>
    )
    :null}

  </div>
</div>



      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(Dimension);
