import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import 'material-icons/iconfont/material-icons.css';

import './Index.css';

import reportWebVitals from './reportWebVitals';
import { store } from './_helpers';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

ReactDOM.render(
    <Provider store={store}>
    <ToastContainer />
      <App />
    </Provider>,
  document.getElementById('root')
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
