import React from 'react';
import moment from 'moment';
import { CONST } from '../../../../_config';

  export default function CampaignList(props){
    let { campaignList, onReportCampaign,chooseCampaignOption,showCampaignOptions,handleCampaignOptions, onReminderCampaign, LanguageObj , CurrentLanguage} = props;
    console.log('campaignList c: ',campaignList)

    return (
      <>
      {campaignList && campaignList.length>0?
       campaignList.map((item,index)=>
      <div className="cursor-default flex mx-4 items-center bg-white px-4 py-2 rounded-md my-2 shadow-lg">
        <div><span className="material-icons text-blue-500 bg-blue-100 p-2 rounded-full">mail</span></div>
        <div className=" overflow-x-auto whitespace-nowrap w-full ">
          <div className="align-middle inline-block min-w-full overflow-hidden">
            <table className="min-w-full">
              <thead className="bg-white">
                <tr className="text-left">
                  <th style={{width:'250px'}} className="px-4 py-1  flex text-left text-sm  leading-4 font-semibold text-gray-800  ">
                    <h1>{item.title}</h1>
                    
                    <span style={{height:"25px"}}>
                      {item.isTest?
                      <span style={{fontSize: '10px'}} className="bg-green-50 text-green-500 py-1 px-2 rounded-full font-normal mx-2">Test</span>
                      :null}
                    </span>
                    
                    
                    
                    </th>
                  <th className="px-4 py-1    text-xs leading-4 font-normal text-gray-500 ">
                    {LanguageObj && LanguageObj["created_date"+"_"+CurrentLanguage]? LanguageObj["created_date"+"_"+CurrentLanguage]:"Created Date"}</th>

                  <th className="px-4 py-1   text-xs leading-4 font-normal text-gray-500 tracking-wider">
                    </th>
                  <th className="px-4 py-1   text-xs leading-4 font-normal text-gray-500 tracking-wider">
                    </th>
                  <th className="px-4 py-1   text-xs leading-4 font-normal text-gray-500 tracking-wider">
                    </th>


                  <th className="px-4 py-1    text-xs leading-4   font-normal text-gray-500 tracking-wider">
                  {LanguageObj && LanguageObj["reminder_count"+"_"+CurrentLanguage]? LanguageObj["reminder_count"+"_"+CurrentLanguage]:"Reminder Count"}</th>
                </tr>
              </thead>
              <tbody className="bg-white">
                <tr className="text-left">
                  <td className="px-4   whitespace-no-wrap ">
                    <div className="flex items-center">
                      <div className="flex-shrink-0 ">
                      </div>
                      <div className>
                        <div className="text-xs leading-5 text-gray-500">{item.teamname}</div>
                      </div>
                    </div>
                  </td>
                  <td className="px-4  whitespace-no-wrap  font-medium text-sm  text-gray-800">
                    { moment.unix(item.createdAt / 1000).format(" DD MMM, YYYY , HH:mm")}
                  </td>
                  
                  <td className="px-4   whitespace-no-wrap  font-medium text-sm  text-gray-800">
                    
                  </td>
                  <td className="px-4   whitespace-no-wrap font-medium text-sm  text-gray-800">
                    
                  </td>
                  <td className="px-4   whitespace-no-wrap font-medium text-sm  text-gray-800">
                    
                  </td>

                  <td className="px-4 py-1 whitespace-no-wrap font-medium text-sm  text-gray-800">
                    {item.reminderCount}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


        {item.campaign_type==="KIOSK"?
        <>
        <div className="relative dropdown inline-block relative mr-10">
          <span onClick={() => handleCampaignOptions(item.id)} title="Action" className="cursor-pointer p-1 material-icons text-gray-400 hover:text-blue-500 hover:bg-blue-100 rounded-full" style={{ fontSize: '18px' }}>qr_code_2</span>
            
            
            <div className="dropdown-menu hidden absolute bg-white rounded-md shadow-xl text-gray-500 right-6 top-0 inline-block overflow-hidden">
              <span className="p-1 text-gray-500 px-4">Survey Link</span>
              <span className="px-4 py-2 text-xs hover:bg-gray-50 block">
                <input value={CONST.SURVEY_URL + item.code} disabled={true} className="w-96 border border-gray-500 rouned-lg p-1" />
              </span>
            </div>



        </div>
        </>
        :<div className="mr-16" />}


        <div className="relative dropdown inline-block relative">
          <span onClick={() => handleCampaignOptions(item.id)} title="Action" className="cursor-pointer p-1 material-icons text-gray-400 hover:text-blue-500 hover:bg-blue-100 rounded-full" style={{ fontSize: '18px' }}>more_vert</span>
            
            <div className="dropdown-menu hidden absolute bg-white rounded-md shadow-xl text-gray-500 right-6 top-0 inline-block overflow-hidden">
              <span onClick={() => chooseCampaignOption(item, 1)} className="px-4 py-2 text-xs hover:bg-gray-50 block"> Campaign Response Analytics</span>
              <span onClick={() => chooseCampaignOption(item, 2)} className="px-4 py-2 text-xs hover:bg-gray-50 block"> Campaign Reminder</span>
            </div>

        </div>

      </div>
      )
      :null}
      </>
    );
  }
