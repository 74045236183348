// import React from 'react'
// import  { Redirect } from 'react-router-dom'
// return <Redirect to='/login'  />

export function accessModule() {
    // return authorization header with jwt token

    let data = JSON.parse(localStorage.getItem('accessModule'));
    //console.log("usersssssssss : ",user.menus);
    
    if (data) {
        return data;
    } else {
        return {};
    }
}