import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let { NPSObj } = this.props;
    

    // let filterQuestion = {}
    // if(question && getQuestionFilterData && getQuestionFilterData.length>0){
    //     getQuestionFilterData.forEach((quesFilter)=>{
    //         if(quesFilter.name === question.name){
    //             filterQuestion = quesFilter;
    //         }
    //     })
    // }

    let names = []
    let weightage1 = [];
    let weightage2 = [];
    let weightage3 = [];

    if(true){
        names.push({
            value:"Overall",
            textStyle:{fontWeight:'bold'}
        })
        weightage1.push({
            value:NPSObj.PromotersPer,
            itemStyle: {color: '#00cc99'}
        });
        weightage2.push({
          value:NPSObj.PasssivesPer,
          itemStyle: {color: '#ff9933'}
        });
        weightage3.push({
          value:NPSObj.DistractorsPer,
          itemStyle: {color: '#ff4d4d'}
        });
    }

    // console.log('filterQuestion:---> ',filterQuestion)

    // if(currFilterValue !=='none'){
    //     if(currFilterName !=='none'){
    //         total = filterQuestion["1"] + filterQuestion["2"] + filterQuestion["3"] + filterQuestion["4"] + filterQuestion["5"] + filterQuestion["6"];
    //         names.push({
    //             value:currFilterValue,
    //             textStyle:{fontWeight:'bold'}
    //         })
    //         weightage1.push({
    //             value:(parseFloat(filterQuestion["1"])*100/total).toFixed(2),
    //             itemStyle: {color: '#e60000'}
    //         });
    //         weightage2.push({
    //             value:(parseFloat(filterQuestion["2"])*100/total).toFixed(2),
    //             itemStyle: {color: '#ff3333'}
    //         });
    //         weightage3.push({
    //             value:(parseFloat(filterQuestion["3"])*100/total).toFixed(2),
    //             itemStyle: {color: '#ff8080'}
    //         });
    //         weightage4.push({
    //             value:(parseFloat(filterQuestion["4"])*100/total).toFixed(2),
    //             itemStyle: {color: '#6ad4fb'}

    //         });
    //         weightage5.push({
    //             value:(parseFloat(filterQuestion["5"])*100/total).toFixed(2),
    //             itemStyle: {color: '#1fbff9'}
    //         });
    //         weightage6.push({
    //             value:(parseFloat(filterQuestion["6"])*100/total).toFixed(2),
    //             itemStyle: {color: '#06a6e0'}
    //         });
    //     }
    // }
    
                        

    return (
      <>
        <div className="p-4 justify-center items-center">

{true?
              <ReactEcharts
              style={{height:'95px',marginTop:"-65px",width:'100%',marginLeft:'2%'}}
              option={{
                            tooltip: {
                                trigger: 'axis',
                                axisPointer: {            
                                    type: 'shadow'        
                                }
                            },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'value',
                                axisLine:'left',
                                splitLine: {
                                    lineStyle: {
                                        color: '#fff'
                                    }
                                },
                                axisLabel:{
                                    show:false
                                }

                            },
                            yAxis: {
                                type: 'category',
                                data: names,
                                position:'left',
                                inverse:true,
                                axisLine: {
                                    lineStyle: {
                                      color:'#fff'
                                    }
                                  },
                                axisLabel:{
                                    show:false
                                }
                            },
                            axisLabel: {
                                color: "#000",
                                interval: 0,
                                formatter: function(value) {
                                  if (value.length > 20) {
                                    return value.substring(0, 20) + ".. :";
                                  } else {
                                    return value + ":";
                                  }
                                }
                              },
                            series: [
                                {
                                    name: 'Promoters',
                                    type: 'bar',
                                    stack: '总量',
                                    barWidth:'30px',
                                    label: {
                                        show: true,
                                        position: 'insideRight',
                                        color:'#fff',
                                        formatter: function(d) {
                                            if(parseInt(d.value) <7){
                                                return '';
                                            }
                                            else{
                                                return d.value+' ';
                                            }
                                          }
                                    },
                                    data: weightage1,
                                    inverse:true
                                },
                                {
                                  name: 'Passives',
                                  type: 'bar',
                                  stack: '总量',
                                  barWidth:'30px',
                                  label: {
                                      show: true,
                                      position: 'insideRight',
                                      color:'#fff',
                                      formatter: function(d) {
                                          if(parseInt(d.value) <7){
                                              return '';
                                          }
                                          else{
                                              return d.value+' ';
                                          }
                                        }
                                  },
                                  data: weightage2,
                                  inverse:true
                              },
                              {
                                name: 'Distractors',
                                type: 'bar',
                                stack: '总量',
                                barWidth:'30px',
                                label: {
                                    show: true,
                                    position: 'insideRight',
                                    color:'#fff',
                                    formatter: function(d) {
                                        if(parseInt(d.value) <7){
                                            return '';
                                        }
                                        else{
                                            return d.value+' ';
                                        }
                                      }
                                },
                                data: weightage3,
                                inverse:true
                            },
                            ]
                        }}
                          />
              :null}

        </div>

      <div className="flex grid grid-cols-3  p-2">
      <div className="px-4 text-center text-green-400">Promoters (9-10)</div><div className="px-2 text-center text-yellow-400">Passives (7-8)</div><div className="px-2 text-center text-red-400">Distractors (0-6)</div>
      </div>
      </>
    );
  }
}
function mapStateToProps(state) {
//   console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionScore);
