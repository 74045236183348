import React from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import {withRouter} from 'react-router';
//components
import Layout from "./Layout/Layout";
// pages
// import Error from "../pages/error";
import Login from "../pages/Login/Login";

//SUPER ADMIN LOGIN

// import Login from "../pages/SurveyMgmt/Login/Login";

export default function App() {

  // return (
  //   <HashRouter>
  //     <Switch>
  //       <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} />
  //       <Route
  //         exact
  //         path="/app"
  //         render={() => <Redirect to="/app/dashboard" />}
  //       />
  //       <PublicRoute path="/app" component={withRouter(Layout)} />
  //       <PublicRoute path="/login" component={withRouter(Login)} />
  //     </Switch>
  //   </HashRouter>
  // );

//SUPER ADMIN LOGIN

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/app/survey" />} />
        {/* <Route exact path="/" render={() => <Redirect to="/app/dashboard" />} /> */}

        <Route exact path="/app" render={() => <Redirect to="/app/survey" />} />
        {/* <Route exact path="/app" render={() => <Redirect to="/app/dashboard" />} /> */}

        <PublicRoute path="/app" component={withRouter(Layout)} />
        <PublicRoute path="/login" component={withRouter(Login)} />
      </Switch>
    </HashRouter>
  );


  //SUPER ADMIN LOGIN


    //   <HashRouter>
    //   <Switch>
    //     <Route exact path="/" render={() => <Redirect to="/app/survey" />} />

    //     <Route exact path="/app" render={() => <Redirect to="/app/survey" />} />

    //     <PublicRoute path="/app" component={withRouter(Layout)} />
    //     <PublicRoute path="/login" component={withRouter(Login)} />
    //   </Switch>
    // </HashRouter>
  // );

  // #######################################################################


  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          false ? (
            <Redirect
              to={{
                pathname: "/login",
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
  
}
