import {
  campaignConstants
} from '../_constants';

export function campaign(state = {}, action) {

  switch (action.type) {

      case campaignConstants.ADD_CAMPAIGN_REQUEST:
        return {
          ...state,
          loading:true
        };
      case campaignConstants.ADD_CAMPAIGN_SUCCESS:
        return {
          ...state,
          addCampaignSuccess: true,
          expireCampaignSuccess: false,        
          loading:false, 

          createNewCampaign: action.campaign.createNewCampaign,
        };
      case campaignConstants.ADD_CAMPAIGN_FAILURE:
        return {
          ...state,
          loading:false, 
          error: action.error
        };
  
        
        case campaignConstants.EXPIRE_CAMPAIGN_REQUEST:
          return {
            ...state,
            loading:true
          };
        case campaignConstants.EXPIRE_CAMPAIGN_SUCCESS:
          return {
            ...state,
            loading:false, 
            expireCampaignSuccess: true
          };
        case campaignConstants.EXPIRE_CAMPAIGN_FAILURE:
          return {
            ...state,
            loading:false, 
            error: action.error
          };

          
          case campaignConstants.RESEND_CAMPAIGN_REQUEST:
            return {
              ...state,
              loading:true
            };
          case campaignConstants.RESEND_CAMPAIGN_SUCCESS:
            return {
              ...state,
              loading:false, 
              resendCampaignSuccess: true
            };
          case campaignConstants.RESEND_CAMPAIGN_FAILURE:
            return {
              ...state,
              loading:false, 
              error: action.error
            };         


      case campaignConstants.CAMPAIGN_GETALL_REQUEST:
        return {
          ...state,
          loading: true
        };
      case campaignConstants.CAMPAIGN_GETALL_SUCCESS:
        return {
          ...state,   
          expireCampaignSuccess: false,  
          addCampaignSuccess:false,   
          loading:false,    
          campaignList: action.campaign.campaignList
        };
      case campaignConstants.CAMPAIGN_GETALL_FAILURE:
        return {
          ...state,
          loading:false, 
          error: action.error
        };


        case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_REQUEST:
          return {
            ...state,
            loading: true
          };
        case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_SUCCESS:
          return {
            ...state,
            getCampaignEmployeeSuccess:true,
            expireCampaignSuccess: false,  
            addCampaignSuccess:false,  
            loading:false,     
            resendCampaignSuccess: false,


            getCampaignEmployeesByIDData: action.campaign.getCampaignEmployeesByIDData
          };
        case campaignConstants.CAMPAIGN_EMPLOYEES_GETALL_FAILURE:
          return {
            ...state,
            loading:false, 
            error: action.error
          };



          case campaignConstants.CAMPAIGN_CUSTOMER_GETALL_REQUEST:
            return {
              ...state,
              loading: true
            };
          case campaignConstants.CAMPAIGN_CUSTOMER_GETALL_SUCCESS:
            return {
              ...state,
              getCampaignCustomerByIDSuccess:true,
              expireCampaignSuccess: false,  
              addCampaignSuccess:false,  
              loading:false,     
              resendCampaignSuccess: false,
  
              getCampaignCustomerByIDData: action.campaign.getCampaignCustomerByIDData
            };
          case campaignConstants.CAMPAIGN_CUSTOMER_GETALL_FAILURE:
            return {
              ...state,
              loading:false, 
              error: action.error
            };
  

          case campaignConstants.GET_RESPONSE_ANALYTICS_REQUEST:
            return {
              ...state,
              loading: true
            };
          case campaignConstants.GET_RESPONSE_ANALYTICS_SUCCESS:
            return {
              ...state,   
              getResponseAnalyticsSuccess: true,
              addCampaignSuccess:false,
              loading:false, 
              resendCampaignSuccess: false,

              responseAnalysisByCampaignIdData: action.campaign.responseAnalysisByCampaignIdData

            };
          case campaignConstants.GET_RESPONSE_ANALYTICS_FAILURE:
            return {
              ...state,
              loading:false, 
              error: action.error
            };





            
          case campaignConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_REQUEST:
            return {
              ...state,
              loading: true
            };
          case campaignConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_SUCCESS:
            return {
              ...state,   
              getCustomerResponseAnalyticsSuccess: true,
              addCampaignSuccess:false,
              loading:false, 
              resendCampaignSuccess: false,

              customerResponseAnalysisByCampaignIdData: action.campaign.customerResponseAnalysisByCampaignIdData

            };
          case campaignConstants.GET_CUSTOMER_RESPONSE_ANALYTICS_FAILURE:
            return {
              ...state,
              loading:false, 
              error: action.error
            };




            case campaignConstants.RESEND_MAIL_IN_BULK_REQUEST:
              return {
                ...state,
                loading: true
              };
            case campaignConstants.RESEND_MAIL_IN_BULK_SUCCESS:
              return {
                ...state,   
                getResendBulkMailSuccess: true ,
                addCampaignSuccess:false,
                loading:false, 
                getResponseAnalyticsSuccess: false

              };
            case campaignConstants.RESEND_MAIL_IN_BULK_FAILURE:
              return {
                ...state,
                loading:false, 
                error: action.error
              };

              case campaignConstants.GET_REMINDER_HISTORY_REQUEST:
                return {
                  ...state,
                  loading: true
                };
              case campaignConstants.GET_REMINDER_HISTORY_SUCCESS:
                return {
                  ...state,   
                  getReminderHistorySuccess: true,  
                  loading:false,  
                  getReminderHistoryList: action.campaign.getReminderHistoryData.list,
                  getReminderHistoryTotal: action.campaign.getReminderHistoryData.total

                };
              case campaignConstants.GET_REMINDER_HISTORY_FAILURE:
                return {
                  ...state,
                  loading:false, 
                  error: action.error
                };





      case campaignConstants.ALLEMPLOYEE_GETALL_REQUEST:
          return {
          ...state,
          loading: true
        };
      case campaignConstants.ALLEMPLOYEE_GETALL_SUCCESS:
          return {
          ...state,
          addCompanySuccess: false,
          loading:false, 
          expireSurveySuccess: false,
          
          allEmployeesList: action.campaign.allEmployeesList.list
        };
      case campaignConstants.ALLEMPLOYEE_GETALL_FAILURE:
          return {
          ...state,
          loading:false, 
          error: action.error
         };













    default:
      return state
  }
}