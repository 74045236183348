import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class QuestionScore extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let {  question, getQuestionFilterData, currFilterValue, currFilterName , CountRestrict } = this.props;
    

    console.log('getQuestionFilterData:--ss-x> ',getQuestionFilterData)


    // let filterQuestion = {}
    // if(question && getQuestionFilterData && getQuestionFilterData.length>0){
    //     getQuestionFilterData.forEach((quesFilter)=>{
    //         if(quesFilter.name === question.name){
    //             filterQuestion = quesFilter;
    //         }
    //     })
    // }


    let total = 0
    let names = []
    let weightage1 = [];
    let weightage2 = [];
    let weightage3 = [];
    let weightage4 = [];
    let weightage5 = [];
    let weightage6 = [];

    let Score = 0
    if (question) {

      Score = CountRestrict(question && question.count?question.count:"NaN")?0:question.score;
      console.log('getQuestionFilterData:--ss-question> ', question,Score)

        names.push({
            value:"Overall",
            textStyle:{fontWeight:'bold'}
        })
        weightage1.push({
            value:Score,
            itemStyle: {color: '#6536d1'}
        });
        weightage2.push({
            value:100-Score,
            itemStyle: {color: '#ffffff'}

        });
    
    }


    console.log('overallQuestion:---> ',question)
    // console.log('filterQuestion:---> ',filterQuestion)

    // if(currFilterValue !=='none'){
    //     if(currFilterName !=='none'){
    //         total = filterQuestion["1"] + filterQuestion["2"] + filterQuestion["3"] + filterQuestion["4"] + filterQuestion["5"] + filterQuestion["6"];
    //         names.push({
    //             value:currFilterValue,
    //             textStyle:{fontWeight:'bold'}
    //         })
    //         weightage1.push({
    //             value:(parseFloat(filterQuestion["1"])*100/total).toFixed(2),
    //             itemStyle: {color: '#e60000'}
    //         });
    //         weightage2.push({
    //             value:(parseFloat(filterQuestion["2"])*100/total).toFixed(2),
    //             itemStyle: {color: '#ff3333'}
    //         });
    //         weightage3.push({
    //             value:(parseFloat(filterQuestion["3"])*100/total).toFixed(2),
    //             itemStyle: {color: '#ff8080'}
    //         });
    //         weightage4.push({
    //             value:(parseFloat(filterQuestion["4"])*100/total).toFixed(2),
    //             itemStyle: {color: '#6ad4fb'}

    //         });
    //         weightage5.push({
    //             value:(parseFloat(filterQuestion["5"])*100/total).toFixed(2),
    //             itemStyle: {color: '#1fbff9'}
    //         });
    //         weightage6.push({
    //             value:(parseFloat(filterQuestion["6"])*100/total).toFixed(2),
    //             itemStyle: {color: '#06a6e0'}
    //         });
    //     }
    // }
    
                        

    return (
      <>
        <div style={{zIndex:'10'}}>

{Score?
              <ReactEcharts
              style={{height:'95px',marginTop:"-55px",width:'100%'}}
              option={{
                            // tooltip: {
                            //     trigger: 'axis',
                            //     axisPointer: {            
                            //         type: 'shadow'        
                            //     },
                            //     formatter:function(a,b) {
                            //         console.log('a,b--->',a,b)
                            //     }
                            // },
                            grid: {
                                left: '3%',
                                right: '4%',
                                bottom: '3%',
                                containLabel: true
                            },
                            xAxis: {
                                type: 'value',
                                axisLine:'left',
                                splitLine: {
                                    lineStyle: {
                                        color: '#fff'
                                    }
                                },
                                axisLabel:{
                                    show:false
                                }

                            },
                            yAxis: {
                                type: 'category',
                                data: names,
                                position:'left',
                                inverse:true,
                                axisLine: {
                                    lineStyle: {
                                      color:'#fff'
                                    }
                                  },
                                axisLabel:{
                                    show:false
                                }
                            },
                            axisLabel: {
                                color: "#000",
                                interval: 0,
                                formatter: function(value) {
                                  if (value.length > 20) {
                                    return value.substring(0, 20) + ".. :";
                                  } else {
                                    return value + ":";
                                  }
                                }
                              },
                            series: [
                                {
                                    // name: 'Strongly Disagree',
                                    type: 'bar',
                                    stack: '总量',
                                    barWidth:'30px',
                                    label: {
                                        show: true,
                                        position: 'insideRight',
                                        formatter: function(d) {
                                            if(parseInt(d.value) <7){
                                                return '';
                                            }
                                            else{
                                                return d.value+' %';
                                            }
                                          }
                                    },
                                    data: weightage1,
                                    inverse:true
                                },
                                {
                                    // name: 'Disagree',
                                    type: 'bar',
                                    stack: '总量',
                                    barWidth:'30px',
                                    label: {
                                        show: false,
                                        position: 'insideRight',
                                        formatter: function(d) {
                                            if(parseInt(d.value) <7){
                                                return '';
                                            }
                                            else{
                                                return d.value;
                                            }
                                          }
                                    },
                                    data: weightage2,
                                    inverse:true
                                },
                               
                            ]
                        }}
                          />
                          : <div className="text-purple-500 font-semibold text-left p-2 px-4">NaN</div>}

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
//   console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionScore);
