export const surveyConstants = {

    SURVEY_GETALL_REQUEST: 'SURVEY_GETALL_REQUEST',
    SURVEY_GETALL_SUCCESS: 'SURVEY_GETALL_SUCCESS',
    SURVEY_GETALL_FAILURE: 'SURVEY_GETALL_FAILURE',

    GET_SURVEY_DATA_REQUEST: 'GET_SURVEY_DATA_REQUEST',
    GET_SURVEY_DATA_SUCCESS: 'GET_SURVEY_DATA_SUCCESS',
    GET_SURVEY_DATA_FAILURE: 'GET_SURVEY_DATA_FAILURE',


    QUESTIONTYPE_GETALL_REQUEST: 'QUESTIONTYPE_GETALL_REQUEST',
    QUESTIONTYPE_GETALL_SUCCESS: 'QUESTIONTYPE_GETALL_SUCCESS',
    QUESTIONTYPE_GETALL_FAILURE: 'QUESTIONTYPE_GETALL_FAILURE',

    PARAMETERLIST_GETALL_REQUEST: 'PARAMETERLIST_GETALL_REQUEST',
    PARAMETERLIST_GETALL_SUCCESS: 'PARAMETERLIST_GETALL_SUCCESS',
    PARAMETERLIST_GETALL_FAILURE: 'PARAMETERLIST_GETALL_FAILURE',

    PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST:'PARAMETERLIST_BYTYPE_PARENT_GETALL_REQUEST',
    PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS: 'PARAMETERLIST_BYTYPE_PARENT_GETALL_SUCCESS',
    PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE: 'PARAMETERLIST_BYTYPE_PARENT_GETALL_FAILURE',
    
    WELCOME_SURVEY_REQUEST: 'WELCOME_SURVEY_REQUEST',
    WELCOME_SURVEY_SUCCESS: 'WELCOME_SURVEY_SUCCESS',
    WELCOME_SURVEY_FAILURE: 'WELCOME_SURVEY_FAILURE',

    SET_EXPIRY_SURVEY_REQUEST: 'SET_EXPIRY_SURVEY_REQUEST',
    SET_EXPIRY_SURVEY_SUCCESS: 'SET_EXPIRY_SURVEY_SUCCESS',
    SET_EXPIRY_SURVEY_FAILURE: 'SET_EXPIRY_SURVEY_FAILURE',

    CREATE_NEW_SURVEY_REQUEST: 'CREATE_NEW_SURVEY_REQUEST',
    CREATE_NEW_SURVEY_SUCCESS: 'CREATE_NEW_SURVEY_SUCCESS',
    CREATE_NEW_SURVEY_FAILURE: 'CREATE_NEW_SURVEY_FAILURE',

    DELETE_SURVEY_REQUEST: 'DELETE_SURVEY_REQUEST',
    DELETE_SURVEY_SUCCESS: 'DELETE_SURVEY_SUCCESS',
    DELETE_SURVEY_FAILURE: 'DELETE_SURVEY_FAILURE',

    UPDATE_SURVEY_REQUEST: 'UPDATE_SURVEY_REQUEST',
    UPDATE_SURVEY_SUCCESS: 'UPDATE_SURVEY_SUCCESS',
    UPDATE_SURVEY_FAILURE: 'UPDATE_SURVEY_FAILURE',

    RESPONSE_ANALYTICS_SURVEY_REQUEST: 'RESPONSE_ANALYTICS_SURVEY_REQUEST',
    RESPONSE_ANALYTICS_SURVEY_SUCCESS: 'RESPONSE_ANALYTICS_SURVEY_SUCCESS',
    RESPONSE_ANALYTICS_SURVEY_FAILURE: 'RESPONSE_ANALYTICS_SURVEY_FAILURE',

    CAMPAIGN_EMPLOYEES_SURVEY_REQUEST: 'CAMPAIGN_EMPLOYEES_SURVEY_REQUEST',
    CAMPAIGN_EMPLOYEES_SURVEY_SUCCESS: 'CAMPAIGN_EMPLOYEES_SURVEY_SUCCESS',
    CAMPAIGN_EMPLOYEES_SURVEY_FAILURE: 'CAMPAIGN_EMPLOYEES_SURVEY_FAILURE',

    RESEND_CAMPAIGN_REQUEST: 'RESEND_CAMPAIGN_REQUEST',
    RESEND_CAMPAIGN_SUCCESS: 'RESEND_CAMPAIGN_SUCCESS',
    RESEND_CAMPAIGN_FAILURE: 'RESEND_CAMPAIGN_FAILURE',

    CREATE_SURVEY_FROM_LIB_REQUEST: 'CREATE_SURVEY_FROM_LIB_REQUEST',
    CREATE_SURVEY_FROM_LIB_SUCCESS: 'CREATE_SURVEY_FROM_LIB_SUCCESS',
    CREATE_SURVEY_FROM_LIB_FAILURE: 'CREATE_SURVEY_FROM_LIB_FAILURE',


    GET_SURVEY_FROM_LIB_REQUEST: 'GET_SURVEY_FROM_LIB_REQUEST',
    GET_SURVEY_FROM_LIB_SUCCESS: 'GET_SURVEY_FROM_LIB_SUCCESS',
    GET_SURVEY_FROM_LIB_FAILURE: 'GET_SURVEY_FROM_LIB_FAILURE',

    
    GET_EMPLOYEE_LIST_REQUEST: 'GET_EMPLOYEE_LIST_REQUEST',
    GET_EMPLOYEE_LIST_SUCCESS: 'GET_EMPLOYEE_LIST_SUCCESS',
    GET_EMPLOYEE_LIST_FAILURE: 'GET_EMPLOYEE_LIST_FAILURE',

    SAVE_EMPLOYEE_LIST_REQUEST: 'SAVE_EMPLOYEE_LIST_REQUEST',
    SAVE_EMPLOYEE_LIST_SUCCESS: 'SAVE_EMPLOYEE_LIST_SUCCESS',
    SAVE_EMPLOYEE_LIST_FAILURE: 'SAVE_EMPLOYEE_LIST_FAILURE',


    UPDATE_EMPLOYEE_STATUS_REQUEST: 'UPDATE_EMPLOYEE_STATUS_REQUEST',
    UPDATE_EMPLOYEE_STATUS_SUCCESS: 'UPDATE_EMPLOYEE_STATUS_SUCCESS',
    UPDATE_EMPLOYEE_STATUS_FAILURE: 'UPDATE_EMPLOYEE_STATUS_FAILURE',

    GET_ALL_LANGUGAGE_LABEL_REQUEST: 'GET_ALL_LANGUGAGE_LABEL_REQUEST',
    GET_ALL_LANGUGAGE_LABEL_SUCCESS: 'GET_ALL_LANGUGAGE_LABEL_SUCCESS',
    GET_ALL_LANGUGAGE_LABEL_FAILURE: 'GET_ALL_LANGUGAGE_LABEL_FAILURE',

    GET_ALL_LANGUGAGE_REQUEST: 'GET_ALL_LANGUGAGE_REQUEST',
    GET_ALL_LANGUGAGE_SUCCESS: 'GET_ALL_LANGUGAGE_SUCCESS',
    GET_ALL_LANGUGAGE_FAILURE: 'GET_ALL_LANGUGAGE_FAILURE',

    GET_EMPLOYEE_COLUMN_NAME_REQUEST: 'GET_EMPLOYEE_COLUMN_NAME_REQUEST',
    GET_EMPLOYEE_COLUMN_NAME_SUCCESS: 'GET_EMPLOYEE_COLUMN_NAME_SUCCESS',
    GET_EMPLOYEE_COLUMN_NAME_FAILURE: 'GET_EMPLOYEE_COLUMN_NAME_FAILURE',

    GET_EMPLOYEE_FILTER_LIST_REQUEST: 'GET_EMPLOYEE_FILTER_LIST_REQUEST',
    GET_EMPLOYEE_FILTER_LIST_SUCCESS: 'GET_EMPLOYEE_FILTER_LIST_SUCCESS',
    GET_EMPLOYEE_FILTER_LIST_FAILURE: 'GET_EMPLOYEE_FILTER_LIST_FAILURE',

    GET_EMPLOYEE_BATCH_RANGE_REQUEST: 'GET_EMPLOYEE_BATCH_RANGE_REQUEST',
    GET_EMPLOYEE_BATCH_RANGE_SUCCESS: 'GET_EMPLOYEE_BATCH_RANGE_SUCCESS',
    GET_EMPLOYEE_BATCH_RANGE_FAILURE: 'GET_EMPLOYEE_BATCH_RANGE_FAILURE',

    GET_CUSTOMER_BATCH_RANGE_REQUEST: 'GET_CUSTOMER_BATCH_RANGE_REQUEST',
    GET_CUSTOMER_BATCH_RANGE_SUCCESS: 'GET_CUSTOMER_BATCH_RANGE_SUCCESS',
    GET_CUSTOMER_BATCH_RANGE_FAILURE: 'GET_CUSTOMER_BATCH_RANGE_FAILURE',


    CREATE_EMPLOYEE_FILTER_REQUEST: 'CREATE_EMPLOYEE_FILTER_REQUEST',
    CREATE_EMPLOYEE_FILTER_SUCCESS: 'CREATE_EMPLOYEE_FILTER_SUCCESS',
    CREATE_EMPLOYEE_FILTER_FAILURE: 'CREATE_EMPLOYEE_FILTER_FAILURE',

    UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST: 'UPDATE_EMPLOYEE_FILTER_STATUS_REQUEST',
    UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS: 'UPDATE_EMPLOYEE_FILTER_STATUS_SUCCESS',
    UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE: 'UPDATE_EMPLOYEE_FILTER_STATUS_FAILURE',
    
    DELETE_EMPLOYEE_FILTER_REQUEST: 'DELETE_EMPLOYEE_FILTER_REQUEST',
    DELETE_EMPLOYEE_FILTER_SUCCESS: 'DELETE_EMPLOYEE_FILTER_SUCCESS',
    DELETE_EMPLOYEE_FILTER_FAILURE: 'DELETE_EMPLOYEE_FILTER_FAILURE',

    USER_ASSIGN_ROLE_REQUEST: 'USER_ASSIGN_ROLE_REQUEST',
    USER_ASSIGN_ROLE_SUCCESS: 'USER_ASSIGN_ROLE_SUCCESS',
    USER_ASSIGN_ROLE_FAILURE: 'USER_ASSIGN_ROLE_FAILURE',

    UPDATE_EMPLOYEE_REQUEST: 'UPDATE_EMPLOYEE_REQUEST',
    UPDATE_EMPLOYEE_SUCCESS: 'UPDATE_EMPLOYEE_SUCCESS',
    UPDATE_EMPLOYEE_FAILURE: 'UPDATE_EMPLOYEE_FAILURE',

    QUESTION_LIBRARY_REQUEST: 'QUESTION_LIBRARY_REQUEST',
    QUESTION_LIBRARY_SUCCESS: 'QUESTION_LIBRARY_SUCCESS',
    QUESTION_LIBRARY_FAILURE: 'QUESTION_LIBRARY_FAILURE',
  
    ALL_FILTER_VALUES_REQUEST: 'ALL_FILTER_VALUES_REQUEST',
    ALL_FILTER_VALUES_SUCCESS: 'ALL_FILTER_VALUES_SUCCESS',
    ALL_FILTER_VALUES_FAILURE: 'ALL_FILTER_VALUES_FAILURE',

    UPDATE_EMPLOYEE_FILTER_RANK_REQUEST: 'UPDATE_EMPLOYEE_FILTER_RANK_REQUEST',
    UPDATE_EMPLOYEE_FILTER_RANK_SUCCESS: 'UPDATE_EMPLOYEE_FILTER_RANK_SUCCESS',
    UPDATE_EMPLOYEE_FILTER_RANK_FAILURE: 'UPDATE_EMPLOYEE_FILTER_RANK_FAILURE',

    GET_ASSIGNED_ROLES_REQUEST: 'GET_ASSIGNED_ROLES_REQUEST',
    GET_ASSIGNED_ROLES_SUCCESS: 'GET_ASSIGNED_ROLES_SUCCESS',
    GET_ASSIGNED_ROLES_FAILURE: 'GET_ASSIGNED_ROLES_FAILURE',

    GET_EMPLOYEE_SEARCH_REQUEST: 'GET_EMPLOYEE_SEARCH_REQUEST',
    GET_EMPLOYEE_SEARCH_SUCCESS: 'GET_EMPLOYEE_SEARCH_SUCCESS',
    GET_EMPLOYEE_SEARCH_FAILURE: 'GET_EMPLOYEE_SEARCH_FAILURE',

    GET_INDEX_BY_FILTER_DMG_REQUEST: 'GET_INDEX_BY_FILTER_DMG_REQUEST',
    GET_INDEX_BY_FILTER_DMG_SUCCESS: 'GET_INDEX_BY_FILTER_DMG_SUCCESS',
    GET_INDEX_BY_FILTER_DMG_FAILURE: 'GET_INDEX_BY_FILTER_DMG_FAILURE',

    GET_RESPONSE_ANALYTICS_PAGINATION_REQUEST: 'GET_RESPONSE_ANALYTICS_PAGINATION_REQUEST',
    GET_RESPONSE_ANALYTICS_PAGINATION_SUCCESS: 'GET_RESPONSE_ANALYTICS_PAGINATION_SUCCESS',
    GET_RESPONSE_ANALYTICS_PAGINATION_FAILURE: 'GET_RESPONSE_ANALYTICS_PAGINATION_FAILURE',

    REMOVE_USER_ROLE_REQUEST: 'REMOVE_USER_ROLE_REQUEST',
    REMOVE_USER_ROLE_SUCCESS: 'REMOVE_USER_ROLE_SUCCESS',
    REMOVE_USER_ROLE_FAILURE: 'REMOVE_USER_ROLE_FAILURE',

    UPDATE_SURVEY_DESIGN_REQUEST: 'UPDATE_SURVEY_DESIGN_REQUEST',
    UPDATE_SURVEY_DESIGN_SUCCESS: 'UPDATE_SURVEY_DESIGN_SUCCESS',
    UPDATE_SURVEY_DESIGN_FAILURE: 'UPDATE_SURVEY_DESIGN_FAILURE',

    GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_REQUEST: 'GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_REQUEST',
    GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_SUCCESS: 'GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_SUCCESS',
    GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_FAILURE: 'GET_ALL_RESPONSE_ANALYTICS_BY_SURVEY_FAILURE',

    GET_CUSTOMER_RESPONSE_DAYWISE_REQUEST: 'GET_CUSTOMER_RESPONSE_DAYWISE_REQUEST',
    GET_CUSTOMER_RESPONSE_DAYWISE_SUCCESS: 'GET_CUSTOMER_RESPONSE_DAYWISE_SUCCESS',
    GET_CUSTOMER_RESPONSE_DAYWISE_FAILURE: 'GET_CUSTOMER_RESPONSE_DAYWISE_FAILURE',

    GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_REQUEST: 'GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_REQUEST',
    GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_SUCCESS: 'GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_SUCCESS',
    GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_FAILURE: 'GET_CUSTOMER_RESPONSE_ANALYTICS_PAGINATION_FAILURE',

};
