import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class BoxPlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  render() {
    let {  getIndexQuantileData, GetParameterLabel , XAxis ,name} = this.props;
 

  
    console.log('/===================/getIndexQuantileData/===================',getIndexQuantileData)
    
    let XAxisName= []
    let XAxisNew= []

    if(XAxis && XAxis.length>0){
      XAxis.forEach((item)=>{
        
        XAxisNew.push(GetParameterLabel(item));
        XAxisName.push(item+"Avg");
      });
    }


    
    let list2 = []

    if(getIndexQuantileData){
      if(XAxisName && XAxisName.length>0){
          XAxisName.forEach((item)=>{
              if(getIndexQuantileData[item]){
                  list2.push(
                      getIndexQuantileData[item]
                  )
              }

          })
      }
  }

  


    let list = []

    if(list2&&list2.length>0){
      list2.forEach((item, index)=>{
        let data = []
        if(item&&item.length>0){
          item.forEach((item2)=>{
            data.push(parseFloat( typeof item2 !== 'string' || item2? item2.toFixed(2):0))
          })
        }
        list.push(data)
      })
    }

    console.log('/===================/list2/',list2)

    console.log('/===================/list2/',list)

    return (
      <>
        {/* <div className="px-2 py-2 font-semibold">{"Drivers BoxPlot"}</div> */}

        <div >
        {getIndexQuantileData && list && list.length>0?
<>
    <ReactEcharts
        option={{

          dataset: [
            {
              source: list
          }, 
          {
            transform: {
              type: 'boxplot',
              config: { itemNameFormatter:function name(a) {
                return XAxisNew[a.value]
              } }
          }

          }, 
          {
              fromDatasetIndex: 1,
              fromTransformResult: 1
          }
        ],

          tooltip: {
              trigger: 'item',
              axisPointer: {
                  type: 'shadow'
              },
              formatter:function(a,b){
                return (
                  `<h1>`+
                    `<b>`+XAxisNew[a.dataIndex]+ `</b><br/>`+
                    // 'Points: '+list[a.dataIndex].map((item)=>{return item}).join(", ")+ `</b><br/>`+
                    `<p>`+'Maximum: '+a.data[5]+ `</p>`+
                    `<p>`+'Q3 (Upper Quartile): '+ a.data[4]+`</p>`+
                    `<p>`+'Q2 (Median): '+a.data[3]+ `</p> `+
                    `<p>`+'Q1 (Lower Quartile): '+a.data[2]+ `</p>`+
                    `<p>`+'Minimum: '+a.data[1]+ `</p>`
                    +`</h1>`
                )
              }
          },
          grid: {
              left: '10%',
              right: '10%',
              bottom: '15%'
          },
          xAxis: {
              type: 'category',
              boundaryGap: true,
              nameGap: 30,
              splitArea: {
                  show: false
              },
              splitLine: {
                  show: false
              },
              label:{
                show:false
            }
          },
          yAxis: {
              type: 'value',
            //   name: 'km/s minus 299,000',
              splitArea: {
                  show: true
              },
              data: XAxisNew

              
          },
          series: [
              {
                name: 'boxplot',
                type: 'boxplot',
                datasetIndex: 1
              }
          ]
      }}
      />
      <div className="text-center mb-6"><b>X-Axis: </b>{name+' Behaviour'}{',  '}<b>Y-Axis: </b>{'Employee Response'}</div>
</>
      :null}

      {getIndexQuantileData==="NaN"?
      <div className="m-10"/>
      :null}
      <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>

        </div>
      </>
    );
  }
}
function mapStateToProps(state) {
//   console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(BoxPlot);
