import React, { Component } from "react";
import { connect } from 'react-redux';
import { dashboardActions, userActions } from '../../_actions';


class Login extends Component {
  constructor(props) {
    super(props);
    this.props.dispatch(userActions.logout());
    this.loginSubmit = this.loginSubmit.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.state = {
      email: '',
      otp_code: '',
      showLogin:true,
      failureMSG:'',
      failureOTPMSG:''
    }
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.emptyClientProfile());


    var slideIndex = 0;
    showSlides();
    
    function showSlides() {
      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";  
      }
      slideIndex++;
      if (slideIndex > slides.length) 
      {
        slideIndex = 1
      }    
      for (i = 0; i < dots.length; i++) {
        // console.log("dots",dots.length)
        dots[i].className = dots[i].className.replace(" activeslide", "");
      }
      if(slides[slideIndex-1] && slides[slideIndex-1].style){
        slides[slideIndex-1].style.display = "block";  
      }
      if(dots[slideIndex-1] && dots[slideIndex-1].className){
        dots[slideIndex-1].className += " activeslide";
      }
      
      setTimeout(showSlides, 5000); // Change image every 5 seconds
    }


  }
  static getDerivedStateFromProps(nextProps, prevState) {

    if (nextProps.user.UserLoginFailure) {         
      return {
        ...nextProps,
        failureMSG:'Please enter valid username !'
      
      }
    }
    if (nextProps.user.UserLoginOTPFailure) {         
      return {
        ...nextProps,
        failureOTPMSG:'Invalid or expired OTP !'
      
      }
    }
    if (nextProps.user.UserLoginEmailSuccess) {         
      return {
        ...nextProps,
        showLogin:false
      
      }
    } 
    else {
      return {
        ...nextProps
      }
    }
    
  }
  inputChange(e) {
    e.preventDefault();
    let { name, value } = e.target;
    this.setState({ [name]: value });
  }
  loginSubmit=()=> {
    let {email}=this.state;
    this.props.dispatch(userActions.userlogin({ username:email},this.props));
    this.setState({ failureMSG: '' });

  }
  OTPSubmit=()=> {
    const { user } = this.props;
    const { UserEmailToken } = user;
    // console.log("UserEmailToken: ",UserEmailToken)
    let {otp_code}=this.state;
    let data = {
        token:UserEmailToken, 
        otp:otp_code
    }
    // console.log("data: ",data)
    this.setState({ failureOTPMSG: '' });

    this.props.dispatch(userActions.validateOtp(data,this.props));
  }
  render() {
    // let { dashboard } = this.props;
    // let { dashboardStates } = dashboard;

    // console.log("dashboardStates", dashboardStates)


    return (
      <>
<div className="xl:flex lg:flex  w-full">
  {/* slider picture area */}
  <div className="bg-gray-50  xl:w-1/2 lg:w-1/2 g xl:h-screen lg:max-h-full md:w-full flex items-center">
    <div className="logo absolute top-6 left-6"><img src="img/logo/happypluslogo.png" className="w-2/4" /></div>
    <div className="slideshow-container w-full px-8 pt-2">
      
      <div className="mySlides fade">
        <img src="img\imagesplushr\login\img_01.png" className="xl:w-full md:w-7/12 md:mx-auto" />
        <div className="text"><h3 className="text-2xl font-bold text-center py-4 text-blue-900">Happiness is a journey, enjoy exploring it!</h3>
          <p className="text-center px-8 text-blue-900 text-lg">Happiness is a state of emotion that most of us aspire to but are unsure about how to get there. .
            .</p></div>
      </div>

      <div className="mySlides fade">
        <img src="img\imagesplushr\login\img_02.png" className="xl:w-full md:w-7/12 md:mx-auto" />
        <div className="text"><h3 className="text-2xl font-bold text-center py-4 text-blue-900">Get surrounded with positive people!</h3>
          <p className="text-center px-8 text-blue-900 text-lg">Positivity acts like a shield against negativity. It forgives, heals, encourages and inspires. .
            .</p></div>
      </div>

      <div className="mySlides fade">
        <img src="img\imagesplushr\login\img_03.png" className="xl:w-full md:w-7/12 md:mx-auto" />
        <div className="text"><h3 className="text-2xl font-bold text-center py-4 text-blue-900">Happiness is a journey, enjoy exploring it!</h3>
          <p className="text-center px-8 text-blue-900 text-lg">This helps to frame a better attitude and takes off <br /> of the negatives. .
            .</p></div>
      </div>

      <div className="mySlides fade">
        <img src="img\imagesplushr\login\img_04.png" className="xl:w-full md:w-7/12 md:mx-auto" />
        <div className="text"><h3 className="text-2xl font-bold text-center py-4 text-blue-900"> 
            Focus on the present!
          </h3>
          <p className="text-center px-8 text-blue-900 text-lg">Right now is the only moment guaranteed to us. Right now, is life. <br />Don’t miss it. .
            .</p></div>
      </div>
      
      <div className="dotD  text-center pt-8 flex justify-center mb-6  ">
        <span className="dot w-8 h-1.5 bg-gray-300 mx-1 rounded-lg" /> 
        <span className="dot w-8 h-1.5 bg-gray-300 mx-1 rounded-lg" /> 
        <span className="dot w-8 h-1.5 bg-gray-300 mx-1 rounded-lg" /> 
        <span className="dot w-8 h-1.5 bg-blue-900 mx-1 rounded-lg" /> 
      </div>
    </div>
    {/*  */}
  </div>
  {/* Login area */}
  <div style={{background:'#fff'}} className="bg-white-500  xl:w-1/2 lg:w-1/2  md:w-full xl:py-0 lg:py-0  w-full md:py-24 py:20  flex justify-center items-center">
  {this.state.showLogin?
    <div className="xl:max-w-md lg:max-w-md md:max-w-md max-w-sm px-6 xl:pt-14 lg:pt-14 md:pt-14  pt-14">
      <div>
        <h1 className="text-3xl font-extrabold text-blue-900 cursor-default">Login</h1>
      </div>
      <input className="text-lg text-gray-900 py-2 mt-6 mb-2 border-b-2 w-full border-blue-500 outline-none" 
      type="email" name="email"  required value={this.state.email} onChange={this.inputChange}  placeholder="Enter email Address" />
      {!this.state.failureMSG?
      <p className="text-gray-400 text-sm py-2 cursor-default w-96">
        We will send you an OTP (One Time Password)
        on this email address.</p>
        :
      <p className="text-gray-400 text-sm py-2 cursor-default w-96">{this.state.failureMSG}</p>
      }
      <div className="cursor-pointer bg-blue-500 rounded-full text-center justify-center py-2 mt-16 text-white font-medium" disabled={this.state.email.length === 0 } onClick={()=>this.loginSubmit()}> GET OTP</div>
    </div>
    :
    <div className="xl:max-w-md lg:max-w-md md:max-w-md max-w-sm px-6 xl:pt-14 lg:pt-14 md:pt-14  pt-14">
    <div>
      <h1 className="text-3xl font-extrabold text-blue-900 cursor-default">Verification</h1>
    </div>
    <input className="text-lg text-gray-900 py-2 mt-6 mb-2 border-b-2 w-full border-blue-500 outline-none" 
    type="number" name="otp_code" required value={this.state.otp_code} onChange={this.inputChange} placeholder="Enter OTP" />
    {!this.state.failureOTPMSG?
    <p className="text-gray-400 text-sm py-2 cursor-default w-96">OTP is sent to {" "+this.state.email}</p> 
    :
    <p className="text-gray-400 text-sm py-2 cursor-default w-96">{this.state.failureOTPMSG}</p> 
    }
    <div disabled={this.state.otp_code.length === 0 } onClick={()=>this.OTPSubmit()} className="cursor-pointer bg-blue-500 rounded-full text-center justify-center py-2 mt-16 text-white font-medium"> VERIFY</div>
   </div>
    }

  </div>
</div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { user, dashboard } = state;
  return {
    loggingIn,
    user,
    dashboard
  };
}
export default connect(mapStateToProps)(Login);
