import React, { Component } from 'react';
import { connect } from 'react-redux';

class DriverTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
    
      
        }
      }
    
      componentDidMount() {
    
      }
    
    
      render() {
        let { IndexDriverData, GetParameterLabel,DemographicRestriction,getQuestionData,optionTypeM, Name, ManagerDynaObj, EmpFilterData, CurrentManagerData, ManagerIndexDriverData} = this.props;
        
        // console.log('>>>>-----------noraml------>>>>; ')

        // console.log('>>>>ManagerDynaObj; ',ManagerDynaObj)
        // console.log('>>>>getQuestionData; ',getQuestionData)

        let colorList = ['#dab3ff','#ffdf91','#94ebcd','#ffb4b4']

        let ManagerDimensionList = []
        
        if(EmpFilterData && EmpFilterData.length>0){
        //   ManagerDimensionList.push({
        //     "filterName":"ManagerPID",
        //     "filterValue":CurrentManagerData["id"]
        // })
            EmpFilterData.forEach((filter)=>{
              if(DemographicRestriction.findIndex(prev=>prev===filter.name)!==-1){
                ManagerDimensionList.push({
                    "filterName":filter.name,
                    "filterValue":CurrentManagerData[filter.name]
                });
              }
            })
        }
        // console.log('>>>>ManagerDimensionList; ',ManagerDimensionList)

        let FieldList = []
        if(ManagerDimensionList && ManagerDimensionList.length>0){
            ManagerDimensionList.forEach((value)=>{
                if(ManagerDynaObj && ManagerDynaObj.length>0){
                    ManagerDynaObj.forEach((obj)=>{

                        if(obj.name === value.filterValue){
                            let data ={
                                ...obj,
                                level:value.filterName
                            }
                            FieldList.push(data)
                        }
                    })
                }
            })
        }


        






    

    
        let paraList = []
    
        let count = 0;
        let rowSpan = 0;
        let NewParaList1=[]
    
        if(getQuestionData && getQuestionData.length>0){
    
          getQuestionData.forEach((ques)=>{
            if(paraList.findIndex(prev=>prev===ques.parameterDimensionName)===-1){
              paraList.push(ques.parameterDimensionName);
              count+=1
            }
            else{
            }
          })
    
          let obj = {}
          paraList.forEach((para)=>{
            obj[para]=0
            getQuestionData.forEach((ques)=>{
              if(para===ques.parameterDimensionName){
                obj[para]+=1
              }
            })
          })
    
    
          NewParaList1 = Object.keys(obj).map((key) => [key, obj[key]])
        //   console.log('data NewParaList: ',NewParaList)
    
          rowSpan=(getQuestionData.length/count)
    
          // console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,getQuestionData.length)
    
        }

        let NewParaList = []
        let listPara = [];
        if(optionTypeM===1){
          listPara = ["Time","Being","WorkLife","Achivement","Relationship","Meeningfull"];
        }
        else if(optionTypeM===2){
          listPara = ["Pride","Presence","Performance"];
        }
        else if(optionTypeM===5){
          listPara = ["Connect","ACT","Respect","Empower"];
        }
        if(listPara && listPara.length>0){
          listPara.forEach((item)=>{
            if(NewParaList1 && NewParaList1.length>0){
              NewParaList1.forEach((item1)=>{
                if(item1[0]===item){
                  NewParaList.push(item1);
                }
              });
            }
    
          })
        }

        




    
        let QuestionList =[]
    
        if(NewParaList && NewParaList.length>0){
          NewParaList.forEach((para,index)=>{
            if(getQuestionData && getQuestionData.length>0){
              getQuestionData.forEach((ques, queIndex)=>{
                if(ques.parameterDimensionName === para[0]){
                  let total = ques["1"] + ques["2"] + ques["3"] + ques["4"] + ques["5"] + ques["6"];
                  let sum = ques["5"] + ques["6"];
    
                  let score = (  ( (sum*100)/total ).toFixed(2)  ).toString()  + "%"
                  
                  QuestionList.push({
                    "name":ques.name,
                    "score":score,
                    "para":ques.parameterDimensionName
                  })
                }
              })
            }
          })
        }
    
        // console.log('NewParaList: ',NewParaList)  
        let paraStore = []
        let previousPara = ''
        if(QuestionList && QuestionList.length>0){
          QuestionList.forEach((question)=>{
            let show = 1
            if(question.para === previousPara){
              show = 0 ;
            }
            else{
              show = 1
            }
    
            paraStore.push({
              "para":question.para,
              "show":show
            })
    
            show = 1;
            previousPara=question.para;
    
    
          })
        }
    




           let overAllNewObj = {

           }


           console.log('asds IndexDriverData: ',IndexDriverData)

           if(IndexDriverData){
            let DriverScoreList = Object.keys(IndexDriverData);
            if(DriverScoreList && DriverScoreList.length>0){
              DriverScoreList.forEach((para)=>{
                const driverName = para.slice(0,-5);
                if(driverName){
                  overAllNewObj[driverName+'Avg'] = IndexDriverData[para];
                }
              });
            }
           }
           console.log('overAllNewObj: ',overAllNewObj)





          //  if(NewParaList && NewParaList.length>0){
          //   NewParaList.forEach((para)=>{
          //     overAllNewObj[para[0]+"Avg"] = 0;
          //   })
          //  }

          //  if(ManagerIndexDriverData && ManagerIndexDriverData.length>0){
          //   ManagerIndexDriverData.forEach((driver,index)=>{
          //     if(NewParaList && NewParaList.length>0){
          //       NewParaList.forEach((para)=>{
          //         overAllNewObj[para[0]+"Avg"] += driver[para[0]+"Avg"];
                  
          //         if((index+1)===ManagerIndexDriverData.length){
          //           overAllNewObj[para[0]+"Avg"] = overAllNewObj[para[0]+"Avg"]/ManagerIndexDriverData.length;
          //         }
          //       });
          //      }
          //   });
          //  }

          //  console.log('NewParaList: ',NewParaList);
          //  console.log('overAllNewObj: ',overAllNewObj);
          //  console.log('getQuestionData: ',getQuestionData);
          //  console.log('ManagerIndexDriverData: ',ManagerIndexDriverData);
          //  console.log('FieldList: ',FieldList);



           
      function getColor(score, index) {

        if(score===0){
          return {Color:'#ffffff',diffTextColor:'#000000'}

        }
        else{

          let diffNo = parseInt(Math.abs(parseFloat(overAllNewObj[index]) - parseFloat(score)));
          // console.log("diffNo:",diffNo)
          if(parseFloat(overAllNewObj[index]) <= parseFloat(score)){ //greater (blue)
            if(diffNo>=0 && diffNo<=2){
              return {Color:'#33cc33',diffTextColor:'#ffffff'}

            }
            else if(diffNo>=2 && diffNo<=12){
              return {Color:'#70db70',diffTextColor:'#00000'}

            }
            else{
              return {Color:'#adebad',diffTextColor:'#00000'}
            }

          }
          else{ //lesse (red)
            if(diffNo>=0 && diffNo<=2){
              return {Color:'#f5babd',diffTextColor:'#000000'}

            }
            else if(diffNo>=2 && diffNo<=12){
              return {Color:'#ea95a1',diffTextColor:'#000000'}

            }
            else{
              return {Color:'#d03e4d',diffTextColor:'#ffffff'}

            }
          }
        }

      }


      console.log('FieldList: ',FieldList)

      let FieldNewList = [];
      if(FieldList && FieldList.length>0){
        FieldList.forEach((data)=>{
          if(FieldNewList.findIndex(prev=>prev.level===data.level)===-1){
            FieldNewList.push(data);
          }
        });
      }

      console.log('NewParaList: ',NewParaList)


    
        return (
          <>
    
              <div className="pb-2  whitespace-nowrap overflow-x-auto ">
              <table className="border-collapse w-full ">
                          <thead>
                            <tr className="text-xs">


                              <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300 text-center">{"Drivers"}</th>
                              <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300 text-center">{"Overall (%)"}</th>


                              {FieldNewList && FieldNewList.length>0?
                                FieldNewList.map((item)=>
                                  (item.level==='ManagerPID')?
                                  <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300">{"Manager (%)"}</th>
                                  :null
                                )
                              :null}
                              {FieldNewList && FieldNewList.length>0?
                                FieldNewList.map((item)=>
                                  (item.level!=='ManagerPID')?
                                  <th className="p-3 font-semibold bg-gray-50 text-gray-800 border border-gray-300 text-center">{item.level+" (%)"}</th>
                                  :null
                                )
                              :null}

                            </tr>
                          </thead>
                          <tbody>
                          <>
                            {NewParaList && NewParaList.length>0?
                              NewParaList.map((driver, driverIndex)=>
                                <tr key={driverIndex} className="border-separate border border-black-800">
                                    <td className="p-3 text-sm border border-b font-semibold text-center">
                                      {GetParameterLabel(driver[0])}</td> 

                                      <td className="p-3 text-sm border border-b font-semibold text-center">
                                      {overAllNewObj && overAllNewObj[driver[0]+"Avg"]?overAllNewObj[driver[0]+"Avg"].toFixed(2):""}</td> 

                                    {FieldNewList && FieldNewList.length>0?
                                        FieldNewList.map((item)=>
                                        (item.level ==='ManagerPID')?
                                        <td className="p-3 text-sm border border-b text-center" style={{
                                          
                                          background:getColor((item && item[driver[0]+"Avg"])?item[driver[0]+"Avg"]:0,driver[0]+"Avg").Color,
                                          color:getColor((item && item[driver[0]+"Avg"])?item[driver[0]+"Avg"]:0,driver[0]+"Avg").diffTextColor

                                        }}>  {(item && item[driver[0]+"Avg"])?item[driver[0]+"Avg"].toFixed(2):'NaN'} </td> 
                                        :null
                                          )
                                    :null}

                                    {FieldNewList && FieldNewList.length>0?
                                        FieldNewList.map((item)=>
                                        (item.level!=='ManagerPID')?
                                          <td className="p-3 text-sm border border-b text-center" style={{
                                            
                                            background:getColor((item && item[driver[0]+"Avg"])?item[driver[0]+"Avg"]:0,driver[0]+"Avg").Color,
                                            color:getColor((item && item[driver[0]+"Avg"])?item[driver[0]+"Avg"]:0,driver[0]+"Avg").diffTextColor
                                          
                                          }}>{(item && item[driver[0]+"Avg"])?item[driver[0]+"Avg"].toFixed(2):'NaN'}</td>

                                        :null
                                          )
                                    :null}
                                </tr>
                              )
                            :null}
                        </>


                          
                </tbody>
              </table>
              </div>
          </>
        );
      }
    }
    function mapStateToProps(state) {
      // console.log("state  ", state);
      const { loggingIn } = state.authentication;
      const { users, dashboard } = state;
      return {
        loggingIn,
        users,
        dashboard
      };
    }
export default connect(mapStateToProps)(DriverTable);
