import React, { Component } from 'react';
import { connect } from 'react-redux';
import { accessModule } from '../../_helpers';

//api call
import { surveyActions, dashboardActions, generalActions, questionsActions } from '../../_actions';

//libraries
import LoadingOverlay from 'react-loading-overlay';

//Layout

import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import Overview from './Overview//Overview';
import Index from './Index/Index';
import TextAnalysis from '../Dashboard/TextAnalysis/TextAnalysis';
import QuestionDetails from './QuestionDetails/QuestionDetails';
import AdvanceInsight from './AdvanceInsight/AdvanceInsight';

import QuestionCard from './Overview/Components/QuestionTypeCards/QuestionCard';


class GeneralDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      IndexNo:0,
      CurrentSurveyName:'',
      CurrentSurveyId:this.props.match.params.id,
      // "5fa04d316ede0f0336066cce"
      showOverview:true,
      showAllQuestions:false,
      showIndex:false,
      showTextAnalysis:false,
      showAdvanceInsight:false,
      ItemSaveData:'',
      cardType:'',
      currentQuestionId:'',
      currentQuestionTemplate:'',

      itemSurveyReport:false,
      itemOverview:false,
      itemAllQuestions:false,

      showAllQues:false

    }
  }
  componentDidMount() {

    let data1 = {
        "keyWord": "",
        "pageNo": 1,
        "size": 10
    }


    // let data2 = {
    //   "surveyId": this.state.CurrentSurveyId
    // }

    this.props.dispatch(questionsActions.getQuestionListBySurveyID({"surveyId": this.state.CurrentSurveyId}));


    this.props.dispatch(dashboardActions.getEmpFilter("getMeanScoreQwise",this.state.CurrentSurveyId));

    this.props.dispatch(dashboardActions.getClientProfile(data1));

    // this.props.dispatch(generalActions.getSurvey(data1));


    let data2 = {
      "surveyId": this.state.CurrentSurveyId
    }
    this.props.dispatch(surveyActions.getSurveyById(data2));

    this.props.dispatch(generalActions.getDashboardCard(data2));

    this.props.dispatch(dashboardActions.getRespondantDetails(data2));





    let data3 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters": []
    }
    this.props.dispatch(dashboardActions.getIndexFilter(data3));

    this.props.dispatch(generalActions.getQuestionDistOverall(data3)); 
    
    this.props.dispatch(generalActions.getQuestionMatrixResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[13]}));

    this.props.dispatch(generalActions.getQuestionOthersResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[1,5,6,11]}));
    // this.props.dispatch(generalActions.getQuestionOthersResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[13]}));

    // this.props.dispatch(generalActions.getQuestionOthersResult({
    //   surveyId:this.state.CurrentSurveyId,
    //   qtemplateList:[5,11],
    //   filters:[{
    //     level:"Gender",
    //     value:"Male"
    //   }]
    // }));

  }


  

  ChooseSurvey=(val)=>{
    
    // console.log('CurrentSurveyId: -------->',val)

    this.setState({CurrentSurveyId:val})


  }

  onOverview=()=>{
    this.setState({
      showOverview:true,
      showAllQuestions:false,
      showIndex:false,
      showTextAnalysis:false,
      showAdvanceInsight:false,
      showQuesCard:false,
      CurrentSurveyId:this.state.CurrentSurveyId
    });
    this.props.dispatch(generalActions.getQuestionOthersResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[1,5,6,11]}));

  }


  onCardClick=(cardType,item, index, check)=>{
    
    if(check){
      let { dashboard } = this.props;
      let { EmpFilterData } = dashboard;
      if(EmpFilterData && EmpFilterData.length>0){
        this.handleDemographicFilter(EmpFilterData[0].name);
      }
        this.setState({
          showOverview:false,
          showAllQuestions:false,
          showIndex:true,
          showTextAnalysis:false,
          showAdvanceInsight:false,
          showQuesCard:false,
          IndexNo:index,
          cardType:cardType,
          ItemSaveData:item
        });
    }

  }

  handleSidebarClick=(isIndex,data, index, check)=>{
    if(isIndex){
      this.onCardClick('index',data, index,check);
    }
    else{
      this.setState({
        showOverview:true,
        showAllQuestions:false,
        showIndex:false,
        showTextAnalysis:false,
        showAdvanceInsight:false,
        showQuesCard:false,
        IndexNo:0,
        ItemSaveData:'',
        CurrentSurveyId:this.state.CurrentSurveyId
      });
    }
  }



  handleSidebarExtra=(type)=>{
    if(type===1){
          this.setState({
            showOverview:false,
            showAllQuestions:false,
            showIndex:false,
            showTextAnalysis:true,
            showAdvanceInsight:false,
            showQuesCard:false,
            IndexNo:0
          });

          let data2 ={
            "surveyId": this.state.CurrentSurveyId
          }
          this.props.dispatch(dashboardActions.getTextQuestion(data2, 1));
    }
    else if(type==2){
            this.setState({
              showOverview:false,
              showAllQuestions:false,
              showIndex:false,
              showTextAnalysis:false,
              showAdvanceInsight:true,
              showQuesCard:false,
              IndexNo:0
            });

            let data2 ={
              "surveyId": this.state.CurrentSurveyId
            }
    
    
    
    }

  }


//------------------------------------------------------------------------------------------------------------------------
handleCrossTabApi=(QuesID1,QuesID2, FilterValues)=>{
    
  let data = {
    // "xAxis": "Department",
    "surveyId":this.state.CurrentSurveyId,
    "questionId1": QuesID1.id,
    "questionId2": QuesID2.id,
    "filters": FilterValues
  }
  // this.props.dispatch(dashboardActions.crossTab(data));

  this.props.dispatch(dashboardActions.crossTabAnalysis(data));


}



  handleDemographicFilter=(value)=>{
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis":value
    }
    // this.props.dispatch(generalActions.getMeanScore(data2));
    this.props.dispatch(generalActions.getMeanScoreQwise(data2));

  }
  handleQuestionMeanScore=(value)=>{
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "xAxis":value
    }
    this.props.dispatch(generalActions.getMeanScoreQwise(data2));
  }

  handleDemographicOverallQFilter =(value)=>{
    // //console.log('value handleDemographicOverallQFilter',value)
      let data2 = {
        "surveyId": this.state.CurrentSurveyId,
        "xAxis":value
      }
      this.props.dispatch(generalActions.getMeanScoreQwise(data2));
  
  }

  
//------------------------------------------------------------------------------------------------------------------------

  handleQuestion=()=>{
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters":[]
    }
    this.props.dispatch(generalActions.getQuestionDist(data2)); 
    this.props.dispatch(generalActions.getQuestionDistOverall(data2)); 
     
  }

  handleResponseDistributionFilter=(name,value)=>{
    let data2 = {
      "surveyId": this.state.CurrentSurveyId,
      "filters":[{
        "level":name,
        "value":value
      }]
    }
    this.props.dispatch(generalActions.getQuestionDist(data2));
  }
//------------------------------------------------------------------------------------------------------------------------
handleWordCloudDemographicFilter=(QuesId, FilterName, FilterValue)=>{
  let { CurrentSurveyId } = this.state;
  let data2 ={
    "surveyId": CurrentSurveyId,
    "questionId": QuesId,
    "filters": [
         {"level": FilterName,"value":FilterValue}
    ]
}
  this.props.dispatch(dashboardActions.wordCloud(data2));
}

callWordCloudText=(QuesId, keyWord, FilterName, FilterValue)=>{
  let { CurrentSurveyId } = this.state;
  let data2 = {}

  if(FilterName && FilterValue){
    data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "keyWord": keyWord,
      "filters": [
           {"level": FilterName,"value":FilterValue}
      ]
    }
  }
  else{
    data2 = {
      "surveyId": CurrentSurveyId,
      "questionId": QuesId,
      "keyWord": keyWord,
      "filters": []
    }
  }



  // //console.log('<-------callWordCloudText---->',data2)


  this.props.dispatch(dashboardActions.wordCloudText(data2));
}


callQuestionWordCloud=(QuesId)=>{
  let { CurrentSurveyId } = this.state;
  let data2 ={
    "surveyId": CurrentSurveyId,
    "questionId": QuesId,
    "filters": []
  }
  this.props.dispatch(dashboardActions.wordCloud(data2));
}
onNetworkGraph = () =>{
  let { CurrentSurveyId } = this.state;

  let data2 ={
    "surveyId": CurrentSurveyId
  }
  this.props.dispatch(dashboardActions.getTextQuestion(data2,2));

}
  
handleNetworkDemographicFilter=(Qid, Name, Value, graphType)=>{
  let { CurrentSurveyId } = this.state;
  let data2 ={
    "surveyId": CurrentSurveyId,
    "questionId": Qid,
    "filters": [
      {"level": Name,"value":Value}
    ]
}

  if(graphType===1){
    this.props.dispatch(dashboardActions.cooccurmatrix(data2));
  }
  else if(graphType===2){
    this.props.dispatch(dashboardActions.neighborGraph(data2));
  }

}

handleNeighborGraph = () =>{
  let { CurrentSurveyId } = this.state;

  let data2 ={
    "surveyId": CurrentSurveyId
  }
  this.props.dispatch(dashboardActions.getTextQuestion(data2,3));

}

handleOverview=()=>{
  // this.overviewAPI();
  if(this.state.showOverview){
    if(this.state.showAllQuestions){
      this.setState({ 
        showOverview:true,
        showAllQuestions:false,
        showIndex:false,
        showTextAnalysis:false,
        showAdvanceInsight:false,
        showQuesCard:false,
        currentQuestionTemplate:0
      })
    }
    else{
      this.props.history.goBack()
    }
  }
  else{

    if(this.state.showQuesCard){
      this.setState({ 
        showOverview:false,
        showAllQuestions:true,
        showIndex:false,
        showTextAnalysis:false,
        showAdvanceInsight:false,
        showQuesCard:false
      })
    }
    else{

      this.onOverview();

      this.handleSidebarClick(false)
    }


  }
}


// onNPSCardClick=(item)=>{

//   console.log('item:',item);
//   console.log('item Qid:',item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"");


//   this.setState({
//     showOverview:false,
//     showIndex:false,
//     showTextAnalysis:false,
//     showQuesCard:true,
//     currentQuestionId:item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"",
//     currentQuestionTemplate:item.answerList&&item.answerList[0]&&item.answerList[0].template?item.answerList[0].template:""

//   });
// }


onOthersCardClick=(item)=>{

  // console.log('item:',item);
  // console.log('item Qid:',item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"");


  this.setState({
    showOverview:false,
    showAllQuestions:false,
    showIndex:false,
    showTextAnalysis:false,
    showAdvanceInsight:false,
    showQuesCard:true,
    currentQuestionId:item.answerList&&item.answerList[0]&&item.answerList[0].QuesId?item.answerList[0].QuesId:"",
    currentQuestionTemplate:item.answerList&&item.answerList[0]&&item.answerList[0].template?item.answerList[0].template:""

  },()=>{
    let { currentQuestionTemplate } = this.state;

    let { dashboard } = this.props;
    let { EmpFilterData } = dashboard;

    let AxisInit = "Department";
    if(EmpFilterData && EmpFilterData.length>0){
      EmpFilterData.forEach((item, index)=>{
        if(item.level && index===0){
          AxisInit = item.level;
        }
      });
    }

    if(currentQuestionTemplate!==13){
      let data7 = {
        "surveyId": this.state.CurrentSurveyId,
        "qtemplateList":[currentQuestionTemplate],
        "xAxis":AxisInit,
        "filters":[]
      }
      this.props.dispatch(generalActions.getQuestionOthersResult(data7));
    }

  });



}




  handleOverViewRoute = ( item1, item2, item3 )=>{

    this.setState({
      itemSurveyReport: item1,
      itemOverview: item2,
      itemAllQuestions: item3
    });


  }




  // handleNPSFilterCall=(FilterValues,isValue)=>{
  //   let XAxis = "";
  //   let Filters = [];
  //   if(FilterValues && FilterValues.length>0){
  //     let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
  //     if(getIndex !== -1){
  //       XAxis = FilterValues[getIndex].level;
  //       FilterValues.forEach((item)=>{
  //         if(item.value !== "All"){
  //           Filters.push(item);
  //         }
  //       });
  //     }
  //     else{
  //       XAxis = FilterValues[FilterValues.length-1].level;
  //       Filters = FilterValues
  //     }

  //   }


  //   let data7 = {
  //     "surveyId": this.state.CurrentSurveyId,
  //     "qtemplateList":[6],
  //     "xAxis":isValue,
  //     "filters":Filters
  //   }

  //   this.props.dispatch(generalActions.getQuestionMatrixResult(data7));

  // }




  handleOthersFilterCall=(FilterValues,isValue, qTemplate)=>{
    let XAxis = "";
    let Filters = [];
    if(FilterValues && FilterValues.length>0){
      let getIndex = FilterValues.findIndex(prev=>prev.value === "All");
      if(getIndex !== -1){
        XAxis = FilterValues[getIndex].level;
        FilterValues.forEach((item)=>{
          if(item.value !== "All"){
            Filters.push(item);
          }
        });
      }
      else{
        XAxis = FilterValues[FilterValues.length-1].level;
        Filters = FilterValues
      }

    }


    let data7 = {
      "surveyId": this.state.CurrentSurveyId,
      "qtemplateList":[qTemplate],
      "xAxis":isValue,
      "filters":Filters
    }

    if(qTemplate===13){
      this.props.dispatch(generalActions.getQuestionMatrixResult(data7));
    }
    else{
      this.props.dispatch(generalActions.getQuestionOthersResult(data7));
    }

  }

  overviewAPI=()=>{
    this.props.dispatch(generalActions.getQuestionOthersResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[1,5,6,11]}));
  }




  onCrossTab=()=>{

    let data ={
      surveyId:this.state.CurrentSurveyId
    }

    this.props.dispatch(questionsActions.getQuestionListBySurveyID(data));


  }
  handleCrossTabApi=(QuesID1,QuesID2, FilterValues)=>{
    
    let data = {
      // "xAxis": "Department",
      "surveyId":this.state.CurrentSurveyId,
      "questionId1": QuesID1.id,
      "questionId2": QuesID2.id,
      "filters": FilterValues
    }
    // this.props.dispatch(dashboardActions.crossTab(data));

    this.props.dispatch(dashboardActions.crossTabAnalysis(data));

}


handleQueCorrelation=(QuesID1,QuesList, FilterValues)=>{
    console.log("asdasdasd")
  let data2 = {
    "surveyId":this.state.CurrentSurveyId,
    "questionId1": QuesID1.id,
    "questionList": QuesList,
    "filters": FilterValues
  }

  console.log("===========handleQueCorrelation============")

  this.props.dispatch(dashboardActions.getCorrelationQuestionType2(data2));

}




  storeInnerRoute=(value)=>{
    this.setState({showAllQues:value});
  }



  onAllQuestions=(item)=>{
    // this.setState({CurrentTemplateId:item.templateId})
    this.setState({ 
      showOverview:false,
      showAllQuestions:true,
      showIndex:false,
      showTextAnalysis:false,
      showAdvanceInsight:false,
      showQuesCard:false,
      currentQuestionTemplate:item.templateId
    })

  }



  onAllMatrixQuestions=(item)=>{
    // this.setState({CurrentTemplateId:item.templateId})
    this.setState({ 
      showOverview:false,
      showAllQuestions:true,
      showIndex:false,
      showTextAnalysis:false,
      showAdvanceInsight:false,
      showQuesCard:false,
      currentQuestionTemplate:13
    })

  }


  // oMatrixQuestionDetail=()=>{
  //   this.setState({
  //     showOverview:false,
  //     showAllQuestions:false,
  //     showIndex:false,
  //     showTextAnalysis:false,
  //     showAdvanceInsight:false,
  //     showQuesCard:true,
  //     currentQuestionId:ques && ques.id?ques.id:"",
  //     currentQuestionTemplate:13
  
  //   });
  //   this.props.dispatch(generalActions.getQuestionMatrixResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[13]}));

  // }
  handleMatrixDetails=(ques)=>{
    this.setState({
      showOverview:false,
      showAllQuestions:false,
      showIndex:false,
      showTextAnalysis:false,
      showAdvanceInsight:false,
      showQuesCard:true,
      currentQuestionId:ques && ques.id?ques.id:"",
      currentQuestionTemplate:13
  
    });
    this.props.dispatch(generalActions.getQuestionMatrixResult({surveyId:this.state.CurrentSurveyId,qtemplateList:[13]}));

  }



  render() {
    
    let { general, dashboard, survey, questions } = this.props;

    let { getSurveyData } = survey;
    let { questionsList } = questions;

    let { neighborGraphData, cooccurmatrixData,wordCloudData, wordTextKeyWord, 
      wordCloudTextData,  getTextQuestionData, ClientProfileData, EmpFilterData,getIndexFilterData, 
      getRespondantDetailsData,
    
      crossTabAnalysisData,
      crossTabData,

      getCorrelationQuestionType2Data
    
    } = dashboard;
    let { getQuestionOthersResultData, getQuestionMatrixResultData, SurveyList,getMeanScoreQwiseData, getDashboardCardData, getQuestionDistData, getQuestionDistOverallData, getMeanScoreData } = general;  

    let { CurrentSurveyId, ItemSaveData,currentQuestionId,currentQuestionTemplate,

      showOverview,      showAllQuestions,
      showTextAnalysis,showAdvanceInsight,showQuesCard, showIndex, IndexNo, cardType

    } = this.state;

    let loading = dashboard.loading || general.loading;
    // let loading = false;

    
    
    
    console.log('/////////////////////////////////////////////////=================================|||=========================>:');
    console.log( 'getCorrelationQuestionType2Data ',getCorrelationQuestionType2Data)








      // //console.log('getDashboardCardData: ',getDashboardCardData)
      // //console.log('getQuestionDistData: ',getQuestionDistData)

      // //console.log('showIndex: ',showIndex)
      // //console.log('showManagerList: ',showManagerList)
      // //console.log('showAdvanceComparision: ',showAdvanceComparision)
      // //console.log('showTextAnalysis: ',showTextAnalysis)
      let OverviewList = [];
      let SumMean = 0;
      let OverallMean = 0;
      let total = 0;

      if(getDashboardCardData && getDashboardCardData.length>0){
         getDashboardCardData.forEach((item, index)=>{
          if(item && item._id){
            SumMean+=parseFloat(item && item.mean?item.mean:0.0);
            total += 1;
            OverviewList.push({
              "id":item._id,
              "name":item.parameter && item.parameter.length>0 && item.parameter[0] && item.parameter[0].name?item.parameter[0].name:"NaN",
              "mean":(typeof item !== 'string' || item instanceof Number) && item && item.mean?item.mean.toFixed(2):'NaN',
              "color":'#757575'
            });
          }
         });
         OverallMean = (SumMean/total);

      }
      //console.log("OverallMean",OverallMean)
      //console.log("SumMean",SumMean)
      //console.log("total",total)
      //console.log("getDashboardCardData",getDashboardCardData)


      if(ClientProfileData && ClientProfileData.role && ClientProfileData.role==="MNGR"){
        this.props.history.push(`/app/manageactionplan`)
      }

      //ROLE MANAGEMENT ----------------------------------------------
let moduleAccess = {
  "isAdmin":false,
  "access":{}
};
if(accessModule()){
  moduleAccess["access"] = accessModule();
}
//console.log('moduleAccess: ',moduleAccess)



let SHOW_ELEMENT = false;
let clientData = this.props.dashboard.ClientProfileData;
if(clientData && clientData.role){

    if(clientData.role === "ADMIN"){
      SHOW_ELEMENT = true;
      moduleAccess["isAdmin"] = true;
    }
    else if(clientData.role === "MNGR"){
      SHOW_ELEMENT = true;
      moduleAccess["isAdmin"] = false;
    }
    else if(clientData.role === "CROLE"){
      SHOW_ELEMENT = true;
      moduleAccess["isAdmin"] = false;
    }
    else if(clientData.role === "EMP"){
      SHOW_ELEMENT = false;
      moduleAccess["isAdmin"] = false;
    }
}
function accessAllow (item){
  return (moduleAccess.isAdmin?true:(moduleAccess && moduleAccess.access && moduleAccess.access[item]?true:false))
}

//END -------------------------------------------------------







function FormattFun (inputData,isSummary){
  let RankObj = getSurveyData && getSurveyData.designObj && getSurveyData.designObj.componentRank?getSurveyData.designObj.componentRank:{}

  let LIST = []
  let prevTemList=[]
  if(inputData && inputData.length>0){
    inputData.forEach((ques)=>{

      let templateId = ques.total[0]._id.qtemplate;
      
      let temp={
        name:ques && ques.question[0] && ques.question[0].name?ques.question[0].name:"",
        answerList:[],
        rank:RankObj && RankObj[templateId]?RankObj[templateId]:100,
        templateId:templateId
      }
      if(ques && ques.total && ques.total.length>0){
        ques.total.forEach((item)=>{
          let ans={
            count:item.count,
            answerText:item._id.answerText,
            heading:item && item._id && item._id.heading?item._id.heading:"",
            parentId:item && item._id && item._id.parentId?item._id.parentId:"",
            template:item._id.qtemplate,
            QuesId:item._id.questionId
          }
          temp["answerList"].push(ans);
        });
      }


      if(isSummary){
        let getIndex = prevTemList.findIndex(prev=>prev===templateId);
        if(getIndex===-1){
          LIST.push(temp);
          prevTemList.push(templateId)
        }
      }
      else{
        LIST.push(temp);
      }


    });
   }
   return LIST
 }

//  let NPSQuesList = FormattFun(getQuestionNPSResultData);
 let AllQuesList = FormattFun(getQuestionOthersResultData, false).filter(x=>x.templateId===currentQuestionTemplate);


    return (
      <>
              <LoadingOverlay
                active={loading}
                // active={false}

                spinner
                text='Loading Data...'
                className="loader"
                >

    <div>

      <Header
        ClientProfileData={ClientProfileData}
        handleLogout={this.handleLogout}
        SurveyList={SurveyList}
        ChooseSurvey={this.ChooseSurvey}
        CurrentSurveyId={CurrentSurveyId}
        props={this.props}
        handleOverview={this.handleOverview}
      />

      <div className="flex bg-gray-200 w-full h-screen fixed" >
      {accessAllow("dashboard")?
      <>

      <Sidebar
        SurveyList={SurveyList}
        OverviewList={OverviewList}
        handleSidebarClick={this.handleSidebarClick}
        IndexNo={IndexNo}
        showIndex={showIndex}
        showOverview={showOverview}
        showTextAnalysis={showTextAnalysis}
        showAdvanceInsight={showAdvanceInsight}
        handleSidebarExtra={this.handleSidebarExtra}
      />

      
      {showOverview?
        <Overview
          CurrentSurveyId={CurrentSurveyId}
          SurveyList={SurveyList}
          EmpFilterData={EmpFilterData}
          OverviewList={OverviewList}
          OverallMean={OverallMean}
          onCardClick={this.onCardClick}
          getMeanScoreQwiseData={getMeanScoreQwiseData}
          getIndexFilterData={getIndexFilterData}
          getRespondantDetailsData={getRespondantDetailsData}
          getQuestionDistOverallData={getQuestionDistOverallData}

          getQuestionMatrixResultData={getQuestionMatrixResultData}
          questionsList={questionsList}

          getQuestionOthersResultData={getQuestionOthersResultData}


          onOthersCardClick={this.onOthersCardClick}

          getSurveyData={getSurveyData}
          overviewAPI={this.overviewAPI}
          handleMatrixDetails={this.handleMatrixDetails}
          handleOverViewRoute={this.handleOverViewRoute}

          storeInnerRoute={this.storeInnerRoute}

          onAllQuestions={this.onAllQuestions}
          onAllMatrixQuestions={this.onAllMatrixQuestions}
        />
      :null}




{showAllQuestions?
          <>
                  <main className="flex-1 w-full bg-gray-50  overflow-hidden overflow-y-auto pb-20 ">

             <div className="px-2 py-2 bg-gray-50">
             
             <div  className="mx-auto p-4  h-full overflow-hidden overflow-y-auto " style={{height: 'calc(100% - 4rem)'}}>
               
             {/* <div onClick={() => this.openSurveyResponse()}>
               <IndexKPI dashboardType={2} getRespondantDetailsData={getRespondantDetailsData} />
             </div> */}

             {/* <div onClick={()=>this.goToOverview()} className="material-icons pb-4 cursor-pointer">arrow_back</div> */}



             {/* <h1 className="text-base font-medium mb-2">{
              CurrentTemplateId===1?"All Single Choice Questions:-":
              CurrentTemplateId===5?"All Star Rating Questions:-":
              CurrentTemplateId===6?"All Net Promoter Score Questions:-":
              CurrentTemplateId===11?"All Yes/No Questions:-":
              CurrentTemplateId===13?"All Matrix Questions:-":
             ""
             }</h1> */}
             
             <div className="grid lg:grid-cols-3 md:grid-cols-2  lg:gap-6 gap-4">


              {currentQuestionTemplate!==13?
              <>
               {AllQuesList && AllQuesList.length>0?AllQuesList.map((item, index)=>
                <div onClick={()=>this.onOthersCardClick(item)} className="bg-white p-4 border">
                  <QuestionCard item={item} name={""} stage={2} index={index}/>
                </div>
                ):null} 
              </>
              :
              <>
                  {getQuestionMatrixResultData && getQuestionMatrixResultData.length>0?
                  <div className="bg-white p-4 border " >
                  <QuestionCard  
                    questionType={"matrix"}
                    getQuestionMatrixResultData={getQuestionMatrixResultData} 
                    questionsList={questionsList}
                    stage={2}
                    handleMatrixDetails={this.handleMatrixDetails}
                    />
                  </div>
                  :null}
              </>}






              

               </div>
              </div>
              </div>

              </main>
          </>
          :null}





      {showIndex?
        <Index 
          ItemSaveData={ItemSaveData}
          cardType={cardType}

          handleQuestion={this.handleQuestion}
          getQuestionDistData={getQuestionDistData}
          getQuestionDistOverallData={getQuestionDistOverallData}
          
          getMeanScoreQwiseData={getMeanScoreQwiseData}
          handleResponseDistributionFilter={this.handleResponseDistributionFilter}
          handleQuestionMeanScore={this.handleQuestionMeanScore}

          EmpFilterData={EmpFilterData}
          getIndexFilterData={getIndexFilterData}
          handleDemographicFilter={this.handleDemographicFilter}
          getMeanScoreData={getMeanScoreData}
          handleDemographicOverallQFilter={this.handleDemographicOverallQFilter}
          OverviewList={OverviewList}

        />
      :null}


      {showQuesCard?

        <QuestionDetails 
          ItemSaveData={ItemSaveData}
          cardType={cardType}
          handleQuestion={this.handleQuestion}
          getQuestionDistData={getQuestionDistData}
          getQuestionDistOverallData={getQuestionDistOverallData}
          getMeanScoreQwiseData={getMeanScoreQwiseData}
          handleResponseDistributionFilter={this.handleResponseDistributionFilter}
          handleQuestionMeanScore={this.handleQuestionMeanScore}
          EmpFilterData={EmpFilterData}
          getIndexFilterData={getIndexFilterData}
          handleDemographicFilter={this.handleDemographicFilter}
          getMeanScoreData={getMeanScoreData}
          handleDemographicOverallQFilter={this.handleDemographicOverallQFilter}
          OverviewList={OverviewList}
          getQuestionOthersResultData={getQuestionOthersResultData}
          currentQuestionId={currentQuestionId}
          currentQuestionTemplate={currentQuestionTemplate}

          handleOthersFilterCall={this.handleOthersFilterCall}
        
          getQuestionMatrixResultData={getQuestionMatrixResultData}
          questionsList={questionsList}

        />

      :null}
      
      


          {showTextAnalysis?
            <TextAnalysis
              // indexType={indexType}
              getTextQuestionData={getTextQuestionData}
              // handleChangeQuestion={this.handleChangeQuestion}
              wordCloudData={wordCloudData}
              EmpFilterData={EmpFilterData}
              getIndexFilterData = {getIndexFilterData}
              handleWordCloudDemographicFilter={this.handleWordCloudDemographicFilter}
              callWordCloudText={this.callWordCloudText}
              wordCloudTextData={wordCloudTextData}
              wordTextKeyWord={wordTextKeyWord}
              callQuestionWordCloud={this.callQuestionWordCloud}
              cooccurmatrixData={cooccurmatrixData}
              onNetworkGraph={this.onNetworkGraph}
              handleNetworkDemographicFilter={this.handleNetworkDemographicFilter}
              neighborGraphData={neighborGraphData}
              handleNeighborGraph={this.handleNeighborGraph}
              CountRestrict={()=>{return true}}
              // getCommentData={getCommentData}
            />
          :null}







          {showAdvanceInsight?
            <AdvanceInsight 
              onCrossTab={this.onCrossTab}
              EmpFilterData={EmpFilterData}
              getIndexFilterData={getIndexFilterData}
              handleCrossTabApi={this.handleCrossTabApi}
              crossTabData={crossTabData}
              crossTabAnalysisData={crossTabAnalysisData}
              questionsList={questionsList}
              handleQueCorrelation={this.handleQueCorrelation}
              getCorrelationQuestionType2Data={getCorrelationQuestionType2Data}

            />
          :null}












        {/* <main className="flex-1 w-full bg-gray-100  overflow-hidden overflow-y-auto ">
          
          
          <nav className="scroll flex justify-left bg-white  overflow-x-auto  px-6 overscroll-x-contain  static">
            <div className="flex justify-left">
              <span className="text-gray-500 border-b-2 hover:border-blue-500 border-blue-500  tracking-wide font-semibold text-xs py-4 mr-8" href=" ">
                Summary 
              </span>
              <span className="text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8" href=" ">
                Question Analysis 
              </span>
              <span className="text-gray-500 border-b-2 hover:border-blue-500 border-transparent  tracking-wide font-semibold text-xs py-4 mr-8" href=" ">
                Driver Distribution
              </span>
            </div>
          </nav>


          <div className="xl:flex lg:flex md:flex justify-between items-center py-4 px-2">
            <div className="flex justify-left ">
              <div id="expand" className="bg-white w-40 border text-black flex justify-between px-4 items-center relative py-1.5 mx-1">
                <span className=" text-black text-xs font-semibold pr-4">All</span> 
                <span id="expandicon" className="material-icons text-gray-500">expand_more</span>
                <div id="dropdown" className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-gray-500 overflow-hidden hidden">
                  <span className="inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"> 
                    Multiple Choice</span>
                  <span className="inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100">
                    Check Box</span>
                  <span className="inline-flex items-center py-3 px-4 text-xs w-full hover:bg-gray-100">
                    Text Box</span>
                </div>
              </div>
            </div>
          </div>


          <div className="container mx-auto px-2 pt-0 pb-8">
            <div className="md:flex ">
              <div className="bg-white rounded shadow-md px-4 md:mx-1 md:w-3/6 w-full ">
                <h1 className="px-2 py-4 text-sm text-gray-900 font-semibold">Overall Score</h1>
                <div className="flex justify-center items-center py-4 ">
                  <div className="w-40 h-40 bg-green-100 rounded-full p-2" />
                </div> 
              </div>
              <div className="bg-white rounded w-full shadow-md  px-4 md:mx-1 md:my-0 my-2">
                <div className="flex justify-between items-center">
                  <h1 className="px-2 py-4 text-sm text-gray-900 font-semibold">Evaluation</h1>
                </div>
                <div className="h-60" />
              </div>
            </div>
            <div className="md:pr-2 md:pt-2">
              <div className="bg-white rounded w-full shadow-md px-4 md:mx-1">
                <div className="flex justify-between items-center">
                  <h1 className="px-2 py-4 text-sm text-gray-900 font-semibold">Distribution</h1>
                  <ul className="text-xs text-gray-500">
                    <div className="inline-flex items-center pr-4" title="Unhappy"><li className="inline-flex bg-red-500 w-3 h-3 rounded mr-1.5"> </li> <p className="md:flex hidden">Unhappy</p></div>
                    <div className="inline-flex items-center pr-4 " title="Moderate"><li className="inline-flex bg-yellow-500 w-3 h-3 rounded mr-1.5"> </li> <p className="md:flex hidden">Moderate</p></div>
                    <div className="inline-flex items-center pr-4" title="Happier"><li className="inline-flex bg-green-500 w-3 h-3 rounded mr-1.5"> </li> <p className="md:flex hidden">Happier</p></div>
                    <div className="inline-flex items-center" title="Happiest"><li className="inline-flex bg-pink-500 w-3 h-3 rounded mr-1.5"> </li> <p className="md:flex hidden">Happiest</p></div>
                  </ul>
                </div>
                <div className="h-60" />
              </div>
            </div>
          </div>
        </main> */}


        </>
        :null}
      </div>

      <div id="sidebar-btn" className="bg-white hover:bg-blue-500 hover:text-white text-gray-900 w-10 h-10 rounded-full shadow-md bottom-8 right-8  flex justify-center items-center fixed z-30 lg:hidden">
        <span className="material-icons">view_quilt</span>
      </div>
    </div>





    </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, general, dashboard, survey, questions } = state;
  return {
    loggingIn,
    users,
    general,
    dashboard,
    survey,
    questions
  };
}
export default connect(mapStateToProps)(GeneralDashboard);
