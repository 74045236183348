import React from 'react';
import GaugeScore from './Charts/GaugeScore';


export default function ScoreBenchmark(props) {
    
    let { 

        item,

        DistributionData,
        Benchmark,
        cronBachAlpha

     } = props;


    //  let Benchmark ={
    //    score:0
    //  }


     console.log('Benchmark: ',Benchmark)

    return (
      <>	
          <div className="py-4">

                            <div className="" >

                              <div className="flex justify-center item-center"><GaugeScore item={item} Benchmark={Benchmark}/></div>

                              {/* {Benchmark && Benchmark.score?
                              <div className="flex mt-12 justify-center ">
                                <div className="text-sm items-center text-center mx-2"><p className="ml-2 text-indigo-800 font-bold">Benchmark Score: {Benchmark.score}</p></div>
                                <div className="text-sm items-center text-center mx-2"><p className="ml-2 text-yellow-500 font-bold">Cronbach’s Alpha: {cronBachAlpha.score}</p></div>
                              </div>
                              :
                              <div className="text-xs items-center text-center mx-2 mt-14"><p className="ml-2 text-yellow-500 font-bold">Cronbach’s Alpha: {cronBachAlpha.score}</p></div>
                              } */}

                              <div className='my-12 w-full'/>


                              <div className="ml-4 grid xl:grid-cols-4  grid-cols-2 gap-4 text-gray-500 mt-4">
                                <div className="flex text-xs items-center "><div className="h-3 w-3 bg-red-500 rounded-sm" /><p className="ml-2">Unhappy (0-48)</p></div>
                                <div className="flex text-xs items-center "><div className="h-3 w-3 bg-yellow-500 rounded-sm" /><p className="ml-2">Moderate (49-60)</p></div>
                                <div className="flex text-xs items-center "><div className="h-3 w-3 bg-green-500 rounded-sm" /><p className="ml-2">Happier (61-66)</p></div>
                                <div className="flex text-xs items-center "><div className="h-3 w-3 bg-pink-500 rounded-sm" /><p className="ml-2">Happiest (67-72)
                                  </p></div>
                              </div>
                              
                            </div>


                </div>  
      </>
    );
  }
