import React from "react";
import moment from 'moment';

export default function AddUserEmployeeList(props) {
  let { 
    getAssignedRolesData,
    LanguageObj,
    CurrentLanguage,
    openAccessUpdateTab,
    AccessList

  } = props;


  console.log('getAssignedRolesData: ',getAssignedRolesData)





  return (
   <>
    <div className="align-middle inline-block min-w-full overflow-hidden  ">
        <table className="min-w-full">
          <thead>
            <tr className="text-sm text-gray-800">
            <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left  font-medium  tracking-wider">{"Emp Name"}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left  font-medium  tracking-wider">{LanguageObj && LanguageObj["emp_id_label"+"_"+CurrentLanguage]? LanguageObj["emp_id_label"+"_"+CurrentLanguage]:"Emp ID"}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left  font-medium  tracking-wider">{LanguageObj && LanguageObj["email_address_label"+"_"+CurrentLanguage]? LanguageObj["email_address_label"+"_"+CurrentLanguage]:"Email Address"}</th>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left  font-medium  tracking-wider"> {"Role"}</th>

              {AccessList && AccessList.length>0?
              AccessList.map((item)=>
              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center  font-medium  tracking-wider"> {item.label}</th>
              ):null}

              <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center  font-medium  tracking-wider">{LanguageObj && LanguageObj["action_label"+"_"+CurrentLanguage]? LanguageObj["action_label"+"_"+CurrentLanguage]:"Access"}</th>
            </tr>
          </thead>
          <tbody className="bg-white">
          {getAssignedRolesData && getAssignedRolesData.length>0?
            getAssignedRolesData.map((emp, index)=>
            (!(emp && emp.role==="ADMIN"))?
            <tr className="text-sm">
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <div className="leading-5  "> {emp && emp.employeeId && emp.employeeId.name?emp.employeeId.name:"-----"}</div>
                </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                <div className="leading-5  ">{emp && emp.employeeId && emp.employeeId.emp_id?emp.employeeId.emp_id:"-----"}</div>
              </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200   font-normal ">{emp && emp.employeeId && emp.employeeId.email?emp.employeeId.email:"-----"} </td>
              <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200   leading-5 "> {emp && emp.employeeId && emp.employeeId.role?emp.employeeId.role:"-----"}</td>

              {AccessList && AccessList.length>0?
              AccessList.map((item)=>
              <>
                {/* <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-200">
                  <div className={emp && emp.moduleAccess && emp.moduleAccess[item.value]?"leading-5 text-green-500 font-semibold text-xs text-center":"leading-5 text-red-500 text-xs text-center"}>
                  {emp && emp.moduleAccess && emp.moduleAccess[item.value]?"Yes":"No"}  
                  </div>
                </td> */}

                {emp && emp.moduleAccess && emp.moduleAccess[item.value]?
                <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200  leading-5 font-medium text-center"> 
                  <span className="px-2 inline-flex   leading-5 font-medium rounded-full bg-green-100 text-green-800">Yes</span> 
                </td>
                :
                <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200  leading-5 font-medium text-center"> 
                <span className="px-2 inline-flex   leading-5 font-medium rounded-full bg-red-100 text-red-800">No</span> 
                </td>}

              </>
              ):null}


              <td className="px-6 py-4 whitespace-no-wrap  border-b border-gray-200   leading-5 font-medium text-center"> 
                <span onClick={()=>openAccessUpdateTab(emp)} className="cursor-pointer material-icons-outlined hover:bg-blue-100 p-2 rounded-md hover:text-blue-500 " style={{fontSize: '1.2rem'}}>privacy_tip</span>
             </td>
            </tr>
            :null
            ):null
          }
          </tbody>
        </table>
      </div>
   </>
  );
}
