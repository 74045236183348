import React from "react";
export default function UpdateSurvey(props) {
  let { handleCreateSurveyPopupClose, updateformSurveyData, handleUpdateSurveyInput, updatehandleSurveyRole,handleUpdateSurveySubmit  } = props;
  console.log("updateformSurveyData; ",updateformSurveyData)
  return (
   <>
        <div style={{backgroundColor: 'rgba(0, 0, 0, 0.8)'}} className="fixed z-40 top-0 right-0 left-0 bottom-0 h-full w-full" >
        <div className="p-4 max-w-xl mx-auto relative absolute left-0 right-0 overflow-hidden mt-24">
          <div onClick={()=>handleCreateSurveyPopupClose()} className="shadow absolute right-0 top-0 w-10 h-10 rounded-full bg-white text-gray-500 hover:text-gray-800 inline-flex items-center justify-center cursor-pointer" >
            <svg className="fill-current w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M16.192 6.344L11.949 10.586 7.707 6.344 6.293 7.758 10.535 12 6.293 16.242 7.707 17.656 11.949 13.414 16.192 17.656 17.606 16.242 13.364 12 17.606 7.758z" />
            </svg>
          </div>
          <div className="shadow w-full rounded-lg bg-white overflow-hidden w-full block p-8">
            <h2 className="font-bold text-xl  mb-2 text-gray-800  text-center pb-4">Update Access</h2>
            {/* <p className=" text-base mb-6 text-gray-700 border-b pb-2 text-center"></p> */}

            {/* <div className="my-2">
              <input type="text" name="name" value={updateformSurveyData.name} onChange={handleUpdateSurveyInput} placeholder="Survey Title here.." className="rounded-lg py-2 px-2 w-full border border-gray-300 outline-none" />
            </div> */}
            {/* <div className="my-2">
              <textarea name="desc" value={updateformSurveyData.desc} onChange={handleUpdateSurveyInput} rows={4} placeholder="Survey Description here. ." className="rounded-lg text-sm text-gray-900 outline-none border-b border-gray-200 py-1.5 border px-2 w-full resize-none" defaultValue={""} />
            </div> */}

            {/* <div className="my-2">
              <div>Select Survey Type:</div>
              <div className="flex py-1 ml-4"><div><input onClick={()=>handleSurveyType("custom")} checked={surveyFormData.type==="custom"?true:false} 
              className="pt-4 mr-2" type="radio"/></div><div className={surveyFormData.type==="custom"?"font-normal":""}>Build Your Own Survey (Editable)</div></div>
              <div className="flex py-1 ml-4"><div><input onClick={()=>handleSurveyType("package")} checked={surveyFormData.type==="package"?true:false} 
              className="pt-4 mr-2" type="radio"/></div><div className={surveyFormData.type==="package"?"font-normal":""}>Build Using Plus Survey Package (Non Editable)</div></div>
            </div> */}

            {/* <hr/> */}
            <div className="my-2">
            <div>Select Survey Access:</div>
            <div className="flex ml-4">
              <div className="flex py-1 pr-2"><div><input onClick={()=>updatehandleSurveyRole("ADMIN")} checked={updateformSurveyData.roleTemp==="ADMIN"?true:false} 
              className="pt-4 mr-2" type="radio"/></div><div className={updateformSurveyData.roleTemp==="ADMIN"?"font-normal":""}>ADMIN</div></div>
              <div className="flex py-1 pr-2"><div><input onClick={()=>updatehandleSurveyRole("CROLE")} checked={updateformSurveyData.roleTemp==="CROLE"?true:false} 
              className="pt-4 mr-2" type="radio"/></div><div className={updateformSurveyData.roleTemp==="CROLE"?"font-normal":""}>CROLE</div></div>
            </div>
            </div>
{/* 
            <div className="mt-8 text-center text-red-400">

              {
              surveyValidation.name?
              null
              :
              <p>Please enter survey name.</p>

              }

              </div> */}


            <div className="mt-8 text-center">
              <button onClick={()=>handleUpdateSurveySubmit()} type="button" className="bg-blue-500 hover:bg-blue-500 text-white font-semibold py-2 px-6  rounded-full shadow-sm" >
                {"Update"}
              </button> 
            </div>
          </div>
        </div>
        </div>
   </>
  );
}
