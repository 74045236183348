import React from "react";

export default function SurveyList(props) {
  let {GetParameterLabel, savePackage, isPackageSelected,handlePackageSelection, handleADDpackage,handleSuveyPackageSubmit,packageList , getSurveyListData,handleSurveyLibQuestions, getSurveyListTotal, QuestionLibraryClose,getSurveyFromLibData,
    onEditSurvey,onPreviewSurvey,onReportSurvey,onPublishSurvey, onDeleteSurvey} = props;

    console.log('savePackage:',savePackage);


    savePackage.questionLibraryList.sort((a, b) => a.rank - b.rank);


  return (
   <>
     
  <main className="px-6 bg-gray-100 h-100%">
    <div className="container py-12 mx-auto">
      <div className="xl:flex lg:flex md:flex justify-between items-center pb-8">
        

        
        <div className="xl:pb-0 lg:pb-0 pb-4 xl:flex-none flex justify-between items-center">
            <i onClick={()=>QuestionLibraryClose()} className="fa fa-arrow-left pr-6 pt-1"></i><h1 className="cursor-pointer text-xl">Plus Question Library</h1> 
        </div> 



        <div className="flex items-center justify-center">
          <div className=" relative  text-gray-600">
           <h1 onClick={()=>handlePackageSelection(isPackageSelected, savePackage.id)} className={isPackageSelected?"text-red-500 border-2 border-red-500 px-4 rounded-lg py-1 cursor-pointer font-semibold":"text-blue-500 border-2 border-blue-500 px-6 px-4 rounded-lg py-1 cursor-pointer font-semibold text-white"}>{isPackageSelected?"Remove This Survey Package":"Use This Survey Package"}</h1>

          </div>

        </div>
      </div>


      <div className="text-gray-600 justify-center items-center lg:mx-36 xl:mx-60 cursor-default">
        <div className="text-center text-xl font-semibold text-gray-500 py-2 mb-4 rounded-lg">{savePackage && savePackage.name?savePackage.name:""}</div>
        {savePackage.questionLibraryList && savePackage.questionLibraryList.length>0?
            savePackage.questionLibraryList.map((ques,index)=>
            <>
                <div className="bg-white shadow-lg my-2 p-2 rounded-lg">
                    <div className="p-2 font-semibold text-xl">Q{index+1}) {ques.name}</div>
                    <div className="flex pt-2 px-4 font-semibold" style={{fontSize:"12px"}}>
                      <span className="text-purple-500">{ques && ques.parameterBucketId && ques.parameterBucketId.name?GetParameterLabel(ques.parameterBucketId.name):""}</span>
                      <span className="text-green-500">{ques && ques.parameterDimensionId && ques.parameterDimensionId.name?", "+GetParameterLabel(ques.parameterDimensionId.name):""}</span>
                    </div>
                    {ques.templateId === 1?
                      <div className="p-2">
                      {ques.optionsList && ques.optionsList.length>0?
                        ques.optionsList.map((opt,index)=>
                        <div className="px-2 py-0.5 font-normal"><input type="radio" className="px-2"/><span className="px-2 text-lg">{opt.name}</span></div>
                        )
                        :null}
                      </div>
                    :
                      null
                    }

                    {ques.templateId === 4?
                      <div className="p-2 mx-2">
                        <textarea type="text" placeholder="Write here..." className="p-2 border-2 border-blue-200 rounded-lg w-full" rows={3} />
                      </div>
                    :
                      null
                    }

                    {ques.templateId === 6?
                      [...Array(10).keys()].map((item,index)=>
                        <div className="cursor-default inline-block w-30 h-30 px-2">
                             <div><img style={{height:'40px',width:"40px"}} src={"img/nps/"+(index+1)+".png"}/></div>
                             <div className="text-center">{item+1}</div>
                         </div>
                       )
                    :
                      null
                    }

                </div>
            </>
            )
        :null}

        


      </div>

    </div></main>
   </>
  );
}
