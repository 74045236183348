import React from 'react';
import GaugeScore from './Charts/GaugeScore';
import BarDistribution from './Charts/BarDistribution';


export default function Stress(props) {
    
    let { 

        item,

        DistributionData,
        Benchmark,
        cronBachAlpha

     } = props;


     console.log('DistributionData:====> ',DistributionData)



    return (
      <>	
          <div className="grid xl:grid-cols-2 lg:grid-cols-2 md:grid-cols-2 sm:grid-cols-2 py-4">

                            <div className="" >
                              <BarDistribution DistributionData={DistributionData}/>
                            </div>



                </div>  
      </>
    );
  }
