export const dashboardConstants = {

    GET_CLIENT_PROFILE_DATA_REQUEST: 'GET_CLIENT_PROFILE_DATA_REQUEST',
    GET_CLIENT_PROFILE_DATA_SUCCESS: 'GET_CLIENT_PROFILE_DATA_SUCCESS',
    GET_CLIENT_PROFILE_DATA_FAILURE: 'GET_CLIENT_PROFILE_DATA_FAILURE',

    GET_SURVEY_REQUEST:'GET_SURVEY_REQUEST',
    GET_SURVEY_SUCCESS: 'GET_SURVEY_SUCCESS',
    GET_SURVEY_FAILURE: 'GET_SURVEY_FAILURE',

    GET_HAPPINESS_INDEX_REQUEST:'GET_HAPPINESS_INDEX_REQUEST',
    GET_HAPPINESS_INDEX_SUCCESS: 'GET_HAPPINESS_INDEX_SUCCESS',
    GET_HAPPINESS_INDEX_FAILURE: 'GET_HAPPINESS_INDEX_FAILURE',

    GET_ENGAGEMENT_INDEX_REQUEST:'GET_ENGAGEMENT_INDEX_REQUEST',
    GET_ENGAGEMENT_INDEX_SUCCESS: 'GET_ENGAGEMENT_INDEX_SUCCESS',
    GET_ENGAGEMENT_INDEX_FAILURE: 'GET_ENGAGEMENT_INDEX_FAILURE',

    GET_HOPE_INDEX_REQUEST:'GET_HOPE_INDEX_REQUEST',
    GET_HOPE_INDEX_SUCCESS: 'GET_HOPE_INDEX_SUCCESS',
    GET_HOPE_INDEX_FAILURE: 'GET_HOPE_INDEX_FAILURE',

    GET_STRESS_INDEX_REQUEST:'GET_STRESS_INDEX_REQUEST',
    GET_STRESS_INDEX_SUCCESS: 'GET_STRESS_INDEX_SUCCESS',
    GET_STRESS_INDEX_FAILURE: 'GET_STRESS_INDEX_FAILURE',

    GET_MANAGER_INDEX_REQUEST:'GET_MANAGER_INDEX_REQUEST',
    GET_MANAGER_INDEX_SUCCESS: 'GET_MANAGER_INDEX_SUCCESS',
    GET_MANAGER_INDEX_FAILURE: 'GET_MANAGER_INDEX_FAILURE',

    GET_LEADER_INDEX_REQUEST:'GET_LEADER_INDEX_REQUEST',
    GET_LEADER_INDEX_SUCCESS: 'GET_LEADER_INDEX_SUCCESS',
    GET_LEADER_INDEX_FAILURE: 'GET_LEADER_INDEX_FAILURE',

    GET_HAPPINESS_INDEX_MNGR_REQUEST:'GET_HAPPINESS_INDEX_MNGR_REQUEST',
    GET_HAPPINESS_INDEX_MNGR_SUCCESS: 'GET_HAPPINESS_INDEX_MNGR_SUCCESS',
    GET_HAPPINESS_INDEX_MNGR_FAILURE: 'GET_HAPPINESS_INDEX_MNGR_FAILURE',

    GET_ENGAGEMENT_INDEX_MNGR_REQUEST:'GET_ENGAGEMENT_INDEX_MNGR_REQUEST',
    GET_ENGAGEMENT_INDEX_MNGR_SUCCESS: 'GET_ENGAGEMENT_INDEX_MNGR_SUCCESS',
    GET_ENGAGEMENT_INDEX_MNGR_FAILURE: 'GET_ENGAGEMENT_INDEX_MNGR_FAILURE',

    GET_HOPE_INDEX_MNGR_REQUEST:'GET_HOPE_INDEX_MNGR_REQUEST',
    GET_HOPE_INDEX_MNGR_SUCCESS: 'GET_HOPE_INDEX_MNGR_SUCCESS',
    GET_HOPE_INDEX_MNGR_FAILURE: 'GET_HOPE_INDEX_MNGR_FAILURE',

    GET_STRESS_INDEX_MNGR_REQUEST:'GET_STRESS_INDEX_MNGR_REQUEST',
    GET_STRESS_INDEX_MNGR_SUCCESS: 'GET_STRESS_INDEX_MNGR_SUCCESS',
    GET_STRESS_INDEX_MNGR_FAILURE: 'GET_STRESS_INDEX_MNGR_FAILURE',

    GET_HAPPINESS_INDEX_FILTER_REQUEST:'GET_HAPPINESS_INDEX_FILTER_REQUEST',
    GET_HAPPINESS_INDEX_FILTER_SUCCESS: 'GET_HAPPINESS_INDEX_FILTER_SUCCESS',
    GET_HAPPINESS_INDEX_FILTER_FAILURE: 'GET_HAPPINESS_INDEX_FILTER_FAILURE',
    
    GET_ENGAGEMENT_INDEX_FILTER_REQUEST:'GET_ENGAGEMENT_INDEX_FILTER_REQUEST',
    GET_ENGAGEMENT_INDEX_FILTER_SUCCESS: 'GET_ENGAGEMENT_INDEX_FILTER_SUCCESS',
    GET_ENGAGEMENT_INDEX_FILTER_FAILURE: 'GET_ENGAGEMENT_INDEX_FILTER_FAILURE',

    GET_HOPE_INDEX_FILTER_REQUEST:'GET_HOPE_INDEX_FILTER_REQUEST',
    GET_HOPE_INDEX_FILTER_SUCCESS: 'GET_HOPE_INDEX_FILTER_SUCCESS',
    GET_HOPE_INDEX_FILTER_FAILURE: 'GET_HOPE_INDEX_FILTER_FAILURE',

    GET_STRESS_INDEX_FILTER_REQUEST:'GET_STRESS_INDEX_FILTER_REQUEST',
    GET_STRESS_INDEX_FILTER_SUCCESS: 'GET_STRESS_INDEX_FILTER_SUCCESS',
    GET_STRESS_INDEX_FILTER_FAILURE: 'GET_STRESS_INDEX_FILTER_FAILURE',

    GET_MANAGER_INDEX_FILTER_REQUEST:'GET_MANAGER_INDEX_FILTER_REQUEST',
    GET_MANAGER_INDEX_FILTER_SUCCESS: 'GET_MANAGER_INDEX_FILTER_SUCCESS',
    GET_MANAGER_INDEX_FILTER_FAILURE: 'GET_MANAGER_INDEX_FILTER_FAILURE',

    GET_LEADER_INDEX_FILTER_REQUEST:'GET_LEADER_INDEX_FILTER_REQUEST',
    GET_LEADER_INDEX_FILTER_SUCCESS: 'GET_LEADER_INDEX_FILTER_SUCCESS',
    GET_LEADER_INDEX_FILTER_FAILURE: 'GET_LEADER_INDEX_FILTER_FAILURE',

    GET_EMP_FILTER_REQUEST:'GET_EMP_FILTER_REQUEST',
    GET_EMP_FILTER_SUCCESS: 'GET_EMP_FILTER_SUCCESS',
    GET_EMP_FILTER_FAILURE: 'GET_EMP_FILTER_FAILURE',

    GET_MANAGER_LIST_REQUEST:'GET_MANAGER_LIST_REQUEST',
    GET_MANAGER_LIST_SUCCESS: 'GET_MANAGER_LIST_SUCCESS',
    GET_MANAGER_LIST_FAILURE: 'GET_MANAGER_LIST_FAILURE',

    GET_QUESTION_ANALYSIS_REQUEST:'GET_QUESTION_ANALYSIS_REQUEST',
    GET_QUESTION_ANALYSIS_SUCCESS: 'GET_QUESTION_ANALYSIS_SUCCESS',
    GET_QUESTION_ANALYSIS_FAILURE: 'GET_QUESTION_ANALYSIS_FAILURE',

    GET_QUESTION_ANALYSIS_NEW_REQUEST:'GET_QUESTION_ANALYSIS_NEW_REQUEST',
    GET_QUESTION_ANALYSIS_NEW_SUCCESS: 'GET_QUESTION_ANALYSIS_NEW_SUCCESS',
    GET_QUESTION_ANALYSIS_NEW_FAILURE: 'GET_QUESTION_ANALYSIS_NEW_FAILURE',

    GET_QUESTION_ANALYSIS_FILTER_REQUEST:'GET_QUESTION_ANALYSIS_FILTER_REQUEST',
    GET_QUESTION_ANALYSIS_FILTER_SUCCESS: 'GET_QUESTION_ANALYSIS_FILTER_SUCCESS',
    GET_QUESTION_ANALYSIS_FILTER_FAILURE: 'GET_QUESTION_ANALYSIS_FILTER_FAILURE',

    GET_COMPANY_EMP_LIST_REQUEST:'GET_COMPANY_EMP_LIST_REQUEST',
    GET_COMPANY_EMP_LIST_SUCCESS: 'GET_COMPANY_EMP_LIST_SUCCESS',
    GET_COMPANY_EMP_LIST_FAILURE: 'GET_COMPANY_EMP_LIST_FAILURE',

    GET_ASSIGN_ROLE_REQUEST:'GET_ASSIGN_ROLE_REQUEST',
    GET_ASSIGN_ROLE_SUCCESS: 'GET_ASSIGN_ROLE_SUCCESS',
    GET_ASSIGN_ROLE_FAILURE: 'GET_ASSIGN_ROLE_FAILURE',

    GET_HAPPINESS_INDEX_NEW_REQUEST:'GET_HAPPINESS_INDEX_NEW_REQUEST',
    GET_HAPPINESS_INDEX_NEW_SUCCESS: 'GET_HAPPINESS_INDEX_NEW_SUCCESS',
    GET_HAPPINESS_INDEX_NEW_FAILURE: 'GET_HAPPINESS_INDEX_NEW_FAILURE',

    GET_ENGAGEMENT_INDEX_NEW_REQUEST:'GET_ENGAGEMENT_INDEX_NEW_REQUEST',
    GET_ENGAGEMENT_INDEX_NEW_SUCCESS: 'GET_ENGAGEMENT_INDEX_NEW_SUCCESS',
    GET_ENGAGEMENT_INDEX_NEW_FAILURE: 'GET_ENGAGEMENT_INDEX_NEW_FAILURE',

    GET_HOPE_INDEX_NEW_REQUEST:'GET_HOPE_INDEX_NEW_REQUEST',
    GET_HOPE_INDEX_NEW_SUCCESS: 'GET_HOPE_INDEX_NEW_SUCCESS',
    GET_HOPE_INDEX_NEW_FAILURE: 'GET_HOPE_INDEX_NEW_FAILURE',

    GET_STRESS_INDEX_NEW_REQUEST:'GET_STRESS_INDEX_NEW_REQUEST',
    GET_STRESS_INDEX_NEW_SUCCESS: 'GET_STRESS_INDEX_NEW_SUCCESS',
    GET_STRESS_INDEX_NEW_FAILURE: 'GET_STRESS_INDEX_NEW_FAILURE',

    GET_MANAGER_INDEX_NEW_REQUEST:'GET_MANAGER_INDEX_NEW_REQUEST',
    GET_MANAGER_INDEX_NEW_SUCCESS: 'GET_MANAGER_INDEX_NEW_SUCCESS',
    GET_MANAGER_INDEX_NEW_FAILURE: 'GET_MANAGER_INDEX_NEW_FAILURE',

    GET_DATA_FILTER_OBJECT_SUCCESS:'GET_DATA_FILTER_OBJECT_SUCCESS',

    GET_STACK_LIST_SUCCESS:'GET_STACK_LIST_SUCCESS',

    GET_ORG_CORE_INDEX_REQUEST:'GET_ORG_CORE_INDEX_REQUEST',
    GET_ORG_CORE_INDEX_SUCCESS: 'GET_ORG_CORE_INDEX_SUCCESS',
    GET_ORG_CORE_INDEX_FAILURE: 'GET_ORG_CORE_INDEX_FAILURE',






    

    GET_INDEX_REQUEST: 'GET_INDEX_REQUEST',
    GET_INDEX_SUCCESS: 'GET_INDEX_SUCCESS',
    GET_INDEX_FAILURE: 'GET_INDEX_FAILURE',

    GET_INDEX_FILTER_REQUEST: 'GET_INDEX_FILTER_REQUEST',
    GET_INDEX_FILTER_SUCCESS: 'GET_INDEX_FILTER_SUCCESS',
    GET_INDEX_FILTER_FAILURE: 'GET_INDEX_FILTER_FAILURE',

    GET_DEMOGRAPHIC_INDEX_REQUEST: 'GET_DEMOGRAPHIC_INDEX_REQUEST',
    GET_DEMOGRAPHIC_INDEX_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_FAILURE: 'GET_DEMOGRAPHIC_INDEX_FAILURE',



    GET_QUESTION_REQUEST:'GET_QUESTION_REQUEST',
    GET_QUESTION_SUCCESS: 'GET_QUESTION_SUCCESS',
    GET_QUESTION_FAILURE: 'GET_QUESTION_FAILURE',

    GET_QUESTION_REPORT_REQUEST:'GET_QUESTION_REPORT_REQUEST',
    GET_QUESTION_REPORT_SUCCESS: 'GET_QUESTION_REPORT_SUCCESS',
    GET_QUESTION_REPORT_FAILURE: 'GET_QUESTION_REPORT_FAILURE',

    GET_QUESTION_FILTER_REQUEST: 'GET_QUESTION_FILTER_REQUEST',
    GET_QUESTION_FILTER_SUCCESS: 'GET_QUESTION_FILTER_SUCCESS',
    GET_QUESTION_FILTER_FAILURE: 'GET_QUESTION_FILTER_FAILURE',


    
    GET_HAPPINESS_INDEX_V2_REQUEST: 'GET_HAPPINESS_INDEX_V2_REQUEST',
    GET_HAPPINESS_INDEX_V2_SUCCESS: 'GET_HAPPINESS_INDEX_V2_SUCCESS',
    GET_HAPPINESS_INDEX_V2_FAILURE: 'GET_HAPPINESS_INDEX_V2_FAILURE',

    GET_ENGAGEMENT_INDEX_V2_REQUEST: 'GET_ENGAGEMENT_INDEX_V2_REQUEST',
    GET_ENGAGEMENT_INDEX_V2_SUCCESS: 'GET_ENGAGEMENT_INDEX_V2_SUCCESS',
    GET_ENGAGEMENT_INDEX_V2_FAILURE: 'GET_ENGAGEMENT_INDEX_V2_FAILURE',

    GET_HOPE_INDEX_V2_REQUEST: 'GET_HOPE_INDEX_V2_REQUEST',
    GET_HOPE_INDEX_V2_SUCCESS: 'GET_HOPE_INDEX_V2_SUCCESS',
    GET_HOPE_INDEX_V2_FAILURE: 'GET_HOPE_INDEX_V2_FAILURE',

    GET_STRESS_INDEX_V2_REQUEST: 'GET_STRESS_INDEX_V2_REQUEST',
    GET_STRESS_INDEX_V2_SUCCESS: 'GET_STRESS_INDEX_V2_SUCCESS',
    GET_STRESS_INDEX_V2_FAILURE: 'GET_STRESS_INDEX_V2_FAILURE',

    GET_MANAGER_INDEX_V2_REQUEST: 'GET_MANAGER_INDEX_V2_REQUEST',
    GET_MANAGER_INDEX_V2_SUCCESS: 'GET_MANAGER_INDEX_V2_SUCCESS',
    GET_MANAGER_INDEX_V2_FAILURE: 'GET_MANAGER_INDEX_V2_FAILURE',

    GET_LEADER_INDEX_V2_REQUEST: 'GET_LEADER_INDEX_V2_REQUEST',
    GET_LEADER_INDEX_V2_SUCCESS: 'GET_LEADER_INDEX_V2_SUCCESS',
    GET_LEADER_INDEX_V2_FAILURE: 'GET_LEADER_INDEX_V2_FAILURE',

    GET_SENIORLEADERSHIP_INDEX_V2_REQUEST: 'GET_SENIORLEADERSHIP_INDEX_V2_REQUEST',
    GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS: 'GET_SENIORLEADERSHIP_INDEX_V2_SUCCESS',
    GET_SENIORLEADERSHIP_INDEX_V2_FAILURE: 'GET_SENIORLEADERSHIP_INDEX_V2_FAILURE',

    GET_ORG_INDEX_V2_REQUEST: 'GET_ORG_INDEX_V2_REQUEST',
    GET_ORG_INDEX_V2_SUCCESS: 'GET_ORG_INDEX_V2_SUCCESS',
    GET_ORG_INDEX_V2_FAILURE: 'GET_ORG_INDEX_V2_FAILURE',




    GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST: 'GET_DEMOGRAPHIC_INDEX_HAPPINESS_REQUEST',
    GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_HAPPINESS_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE: 'GET_DEMOGRAPHIC_INDEX_HAPPINESS_FAILURE',

    GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST:'GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_REQUEST',
    GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE: 'GET_DEMOGRAPHIC_INDEX_ENGAGEMENT_FAILURE',
 
    GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST:'GET_DEMOGRAPHIC_INDEX_STRESS_REQUEST',
    GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_STRESS_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE: 'GET_DEMOGRAPHIC_INDEX_STRESS_FAILURE',

    GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST:'GET_DEMOGRAPHIC_INDEX_MANAGER_REQUEST',
    GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_MANAGER_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE: 'GET_DEMOGRAPHIC_INDEX_MANAGER_FAILURE',

    GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST:'GET_DEMOGRAPHIC_INDEX_LEADER_REQUEST',
    GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_LEADER_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE: 'GET_DEMOGRAPHIC_INDEX_LEADER_FAILURE',

    GET_DEMOGRAPHIC_INDEX_ORG_REQUEST:'GET_DEMOGRAPHIC_INDEX_ORG_REQUEST',
    GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_ORG_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_ORG_FAILURE: 'GET_DEMOGRAPHIC_INDEX_ORG_FAILURE',



    GET_HAPPINESS_INDEX_DRIVER_REQUEST: 'GET_HAPPINESS_INDEX_DRIVER_REQUEST',
    GET_HAPPINESS_INDEX_DRIVER_SUCCESS: 'GET_HAPPINESS_INDEX_DRIVER_SUCCESS',
    GET_HAPPINESS_INDEX_DRIVER_FAILURE: 'GET_HAPPINESS_INDEX_DRIVER_FAILURE',

    GET_ENGAGEMENT_INDEX_DRIVER_REQUEST: 'GET_ENGAGEMENT_INDEX_DRIVER_REQUEST',
    GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS: 'GET_ENGAGEMENT_INDEX_DRIVER_SUCCESS',
    GET_ENGAGEMENT_INDEX_DRIVER_FAILURE: 'GET_ENGAGEMENT_INDEX_DRIVER_FAILURE',

    GET_STRESS_INDEX_DRIVER_REQUEST: 'GET_STRESS_INDEX_DRIVER_REQUEST',
    GET_STRESS_INDEX_DRIVER_SUCCESS: 'GET_STRESS_INDEX_DRIVER_SUCCESS',
    GET_STRESS_INDEX_DRIVER_FAILURE: 'GET_STRESS_INDEX_DRIVER_FAILURE',

    GET_MANAGER_INDEX_DRIVER_REQUEST: 'GET_MANAGER_INDEX_DRIVER_REQUEST',
    GET_MANAGER_INDEX_DRIVER_SUCCESS: 'GET_MANAGER_INDEX_DRIVER_SUCCESS',
    GET_MANAGER_INDEX_DRIVER_FAILURE: 'GET_MANAGER_INDEX_DRIVER_FAILURE',

    GET_LEADER_INDEX_DRIVER_REQUEST: 'GET_LEADER_INDEX_DRIVER_REQUEST',
    GET_LEADER_INDEX_DRIVER_SUCCESS: 'GET_LEADER_INDEX_DRIVER_SUCCESS',
    GET_LEADER_INDEX_DRIVER_FAILURE: 'GET_LEADER_INDEX_DRIVER_FAILURE',

    GET_ORG_INDEX_DRIVER_REQUEST: 'GET_ORG_INDEX_DRIVER_REQUEST',
    GET_ORG_INDEX_DRIVER_SUCCESS: 'GET_ORG_INDEX_DRIVER_SUCCESS',
    GET_ORG_INDEX_DRIVER_FAILURE: 'GET_ORG_INDEX_DRIVER_FAILURE',

    GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST:'GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_REQUEST',
    GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS: 'GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_SUCCESS',
    GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE: 'GET_HAPPINESS_INDEX_V2_DEMOGRAPHIC_FAILURE',

    GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST:'GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_REQUEST',
    GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS: 'GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_SUCCESS',
    GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE: 'GET_ENGAGEMENT_INDEX_V2_DEMOGRAPHIC_FAILURE',

    GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST:'GET_STRESS_INDEX_V2_DEMOGRAPHIC_REQUEST',
    GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS: 'GET_STRESS_INDEX_V2_DEMOGRAPHIC_SUCCESS',
    GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE: 'GET_STRESS_INDEX_V2_DEMOGRAPHIC_FAILURE',

    GET_INDEX_BY_MANAGER_WISE_REQUEST:'GET_INDEX_BY_MANAGER_WISE_REQUEST',
    GET_INDEX_BY_MANAGER_WISE_SUCCESS: 'GET_INDEX_BY_MANAGER_WISE_SUCCESS',
    GET_INDEX_BY_MANAGER_WISE_FAILURE: 'GET_INDEX_BY_MANAGER_WISE_FAILURE',

    GET_ALL_BENCHMARK_REQUEST: 'GET_ALL_BENCHMARK_REQUEST',
    GET_ALL_BENCHMARK_SUCCESS: 'GET_ALL_BENCHMARK_SUCCESS',
    GET_ALL_BENCHMARK_FAILURE: 'GET_ALL_BENCHMARK_FAILURE',

    GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST: 'GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_REQUEST',
    GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE: 'GET_DEMOGRAPHIC_INDEX_FOR_MANAGER_FAILURE',

    GET_RESPONDANT_DETAILS_REQUEST: 'GET_RESPONDANT_DETAILS_REQUEST',
    GET_RESPONDANT_DETAILS_SUCCESS: 'GET_RESPONDANT_DETAILS_SUCCESS',
    GET_RESPONDANT_DETAILS_FAILURE: 'GET_RESPONDANT_DETAILS_FAILURE',

    GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST: 'GET_MANAGER_LIST_DOMAIN_FILTER_REQUEST',
    GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS: 'GET_MANAGER_LIST_DOMAIN_FILTER_SUCCESS',
    GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE: 'GET_MANAGER_LIST_DOMAIN_FILTER_FAILURE',


    GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST: 'GET_QUESTION_MANAGER_OF_HAPPINESS_REQUEST',
    GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS: 'GET_QUESTION_MANAGER_OF_HAPPINESS_SUCCESS',
    GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE: 'GET_QUESTION_MANAGER_OF_HAPPINESS_FAILURE',

    GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST: 'GET_QUESTION_MANAGER_OF_ENGAGEMENT_REQUEST',
    GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS: 'GET_QUESTION_MANAGER_OF_ENGAGEMENT_SUCCESS',
    GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE: 'GET_QUESTION_MANAGER_OF_ENGAGEMENT_FAILURE',

    GET_QUESTION_MANAGER_OF_STRESS_REQUEST: 'GET_QUESTION_MANAGER_OF_STRESS_REQUEST',
    GET_QUESTION_MANAGER_OF_STRESS_SUCCESS: 'GET_QUESTION_MANAGER_OF_STRESS_SUCCESS',
    GET_QUESTION_MANAGER_OF_STRESS_FAILURE: 'GET_QUESTION_MANAGER_OF_STRESS_FAILURE',

    GET_QUESTION_MANAGER_OF_MANAGER_REQUEST: 'GET_QUESTION_MANAGER_OF_MANAGER_REQUEST',
    GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS: 'GET_QUESTION_MANAGER_OF_MANAGER_SUCCESS',
    GET_QUESTION_MANAGER_OF_MANAGER_FAILURE: 'GET_QUESTION_MANAGER_OF_MANAGER_FAILURE',

    GET_INDEX_QUANTILE_REQUEST: 'GET_INDEX_QUANTILE_REQUEST',
    GET_INDEX_QUANTILE_SUCCESS: 'GET_INDEX_QUANTILE_SUCCESS',
    GET_INDEX_QUANTILE_FAILURE: 'GET_INDEX_QUANTILE_FAILURE',


    GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST:'GET_DEMOGRAPHIC_INDEX_SCATTER_REQUEST',
    GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS: 'GET_DEMOGRAPHIC_INDEX_SCATTER_SUCCESS',
    GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE: 'GET_DEMOGRAPHIC_INDEX_SCATTER_FAILURE',


    GET_HAPPINESS_CORRELATION_REQUEST: 'GET_HAPPINESS_CORRELATION_REQUEST',
    GET_HAPPINESS_CORRELATION_SUCCESS: 'GET_HAPPINESS_CORRELATION_SUCCESS',
    GET_HAPPINESS_CORRELATION_FAILURE: 'GET_HAPPINESS_CORRELATION_FAILURE',

    GET_ENGAGEMENT_CORRELATION_REQUEST: 'GET_ENGAGEMENT_CORRELATION_REQUEST',
    GET_ENGAGEMENT_CORRELATION_SUCCESS: 'GET_ENGAGEMENT_CORRELATION_SUCCESS',
    GET_ENGAGEMENT_CORRELATION_FAILURE: 'GET_ENGAGEMENT_CORRELATION_FAILURE',

    GET_STRESS_CORRELATION_REQUEST: 'GET_STRESS_CORRELATION_REQUEST',
    GET_STRESS_CORRELATION_SUCCESS: 'GET_STRESS_CORRELATION_SUCCESS',
    GET_STRESS_CORRELATION_FAILURE: 'GET_STRESS_CORRELATION_FAILURE',

    GET_MANAGER_CORRELATION_REQUEST: 'GET_MANAGER_CORRELATION_REQUEST',
    GET_MANAGER_CORRELATION_SUCCESS: 'GET_MANAGER_CORRELATION_SUCCESS',
    GET_MANAGER_CORRELATION_FAILURE: 'GET_MANAGER_CORRELATION_FAILURE',

    GET_LEADER_CORRELATION_REQUEST:'GET_LEADER_CORRELATION_REQUEST',
    GET_LEADER_CORRELATION_SUCCESS: 'GET_LEADER_CORRELATION_SUCCESS',
    GET_LEADER_CORRELATION_FAILURE: 'GET_LEADER_CORRELATION_FAILURE',

    GET_RWA_REQUEST: 'GET_RWA_REQUEST',
    GET_RWA_SUCCESS: 'GET_RWA_SUCCESS',
    GET_RWA_FAILURE: 'GET_RWA_FAILURE',

    GET_TEXT_QUESTION_REQUEST: 'GET_TEXT_QUESTION_REQUEST',
    GET_TEXT_QUESTION_SUCCESS: 'GET_TEXT_QUESTION_SUCCESS',
    GET_TEXT_QUESTION_FAILURE: 'GET_TEXT_QUESTION_FAILURE',

    WORD_CLOUD_REQUEST: 'WORD_CLOUD_REQUEST',
    WORD_CLOUD_SUCCESS: 'WORD_CLOUD_SUCCESS',
    WORD_CLOUD_FAILURE: 'WORD_CLOUD_FAILURE',

    ADVANCE_WORD_CLOUD_REQUEST: 'ADVANCE_WORD_CLOUD_REQUEST',
    ADVANCE_WORD_CLOUD_SUCCESS: 'ADVANCE_WORD_CLOUD_SUCCESS',
    ADVANCE_WORD_CLOUD_FAILURE: 'ADVANCE_WORD_CLOUD_FAILURE',

    WORD_CLOUD_TEXT_REQUEST: 'WORD_CLOUD_TEXT_REQUEST',
    WORD_CLOUD_TEXT_SUCCESS: 'WORD_CLOUD_TEXT_SUCCESS',
    WORD_CLOUD_TEXT_FAILURE: 'WORD_CLOUD_TEXT_FAILURE',

    WORD_CLOUD_MULTI_TEXT_REQUEST: 'WORD_CLOUD_MULTI_TEXT_REQUEST',
    WORD_CLOUD_MULTI_TEXT_SUCCESS: 'WORD_CLOUD_MULTI_TEXT_SUCCESS',
    WORD_CLOUD_MULTI_TEXT_FAILURE: 'WORD_CLOUD_MULTI_TEXT_FAILURE',

    COOCCUR_MATRIX_REQUEST: 'COOCCUR_MATRIX_REQUEST',
    COOCCUR_MATRIX_SUCCESS: 'COOCCUR_MATRIX_SUCCESS',
    COOCCUR_MATRIX_FAILURE: 'COOCCUR_MATRIX_FAILURE',

    NEIGHBOR_GRAPH_REQUEST: 'NEIGHBOR_GRAPH_REQUEST',
    NEIGHBOR_GRAPH_SUCCESS: 'NEIGHBOR_GRAPH_SUCCESS',
    NEIGHBOR_GRAPH_FAILURE: 'NEIGHBOR_GRAPH_FAILURE',

    GET_ENGAGEMENT_RWA_REQUEST: 'GET_ENGAGEMENT_RWA_REQUEST',
    GET_ENGAGEMENT_RWA_SUCCESS: 'GET_ENGAGEMENT_RWA_SUCCESS',
    GET_ENGAGEMENT_RWA_FAILURE: 'GET_ENGAGEMENT_RWA_FAILURE',

    CRONE_BATCH_ALPHA_VARIANCE_REQUEST: 'CRONE_BATCH_ALPHA_VARIANCE_REQUEST',
    CRONE_BATCH_ALPHA_VARIANCE_SUCCESS: 'CRONE_BATCH_ALPHA_VARIANCE_SUCCESS',
    CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO: 'CRONE_BATCH_ALPHA_VARIANCE_SUCCESS_TWO',
    CRONE_BATCH_ALPHA_VARIANCE_FAILURE: 'CRONE_BATCH_ALPHA_VARIANCE_FAILURE',

    GET_QUESTION_FAVOURABILITY_REQUEST: 'GET_QUESTION_FAVOURABILITY_REQUEST',
    GET_QUESTION_FAVOURABILITY_SUCCESS: 'GET_QUESTION_FAVOURABILITY_SUCCESS',
    GET_QUESTION_FAVOURABILITY_FAILURE: 'GET_QUESTION_FAVOURABILITY_FAILURE',

    GET_HISTORY_INDEX_REQUEST: 'GET_HISTORY_INDEX_REQUEST',
    GET_HISTORY_INDEX_SUCCESS: 'GET_HISTORY_INDEX_SUCCESS',
    GET_HISTORY_INDEX_FAILURE: 'GET_HISTORY_INDEX_FAILURE',


    GET_RESPONSE_ANALYTIYCS_REQUEST: 'GET_RESPONSE_ANALYTIYCS_REQUEST',
    GET_RESPONSE_ANALYTIYCS_SUCCESS: 'GET_RESPONSE_ANALYTIYCS_SUCCESS',
    GET_RESPONSE_ANALYTIYCS_FAILURE: 'GET_RESPONSE_ANALYTIYCS_FAILURE',

    
    GET_COMMENT_REQUEST: 'GET_COMMENT_REQUEST',
    GET_COMMENT_SUCCESS: 'GET_COMMENT_SUCCESS',
    GET_COMMENT_FAILURE: 'GET_COMMENT_FAILURE',

    GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_REQUEST: 'GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_REQUEST',
    GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_SUCCESS: 'GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_SUCCESS',
    GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_FAILURE: 'GET_MANAGER_LIST_DASHBOARD_MANAGER_INDEX_FAILURE',

    GET_MULTIMANAGER_QUESTIONLIST_REQUEST: 'GET_MULTIMANAGER_QUESTIONLIST_REQUEST',
    GET_MULTIMANAGER_QUESTIONLIST_SUCCESS: 'GET_MULTIMANAGER_QUESTIONLIST_SUCCESS',
    GET_MULTIMANAGER_QUESTIONLIST_FAILURE: 'GET_MULTIMANAGER_QUESTIONLIST_FAILURE',

    GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST: 'GET_DEMOGRAPHIC_MULTI_FILTER_REQUEST',
    GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS: 'GET_DEMOGRAPHIC_MULTI_FILTER_SUCCESS',
    GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE: 'GET_DEMOGRAPHIC_MULTI_FILTER_FAILURE',

    ASSIGN_MANAGER_ACTION_PLAN_REQUEST:'ASSIGN_MANAGER_ACTION_PLAN_REQUEST',
    ASSIGN_MANAGER_ACTION_PLAN_SUCCESS: 'ASSIGN_MANAGER_ACTION_PLAN_SUCCESS',
    ASSIGN_MANAGER_ACTION_PLAN_FAILURE: 'ASSIGN_MANAGER_ACTION_PLAN_FAILURE',

    GET_MANAGER_ACTION_PLAN_REQUEST:'GET_MANAGER_ACTION_PLAN_REQUEST',
    GET_MANAGER_ACTION_PLAN_SUCCESS: 'GET_MANAGER_ACTION_PLAN_SUCCESS',
    GET_MANAGER_ACTION_PLAN_FAILURE: 'GET_MANAGER_ACTION_PLAN_FAILURE',

    UPDATE_EMPLOYEE_TASK_REQUEST:'UPDATE_EMPLOYEE_TASK_REQUEST',
    UPDATE_EMPLOYEE_TASK_SUCCESS: 'UPDATE_EMPLOYEE_TASK_SUCCESS',
    UPDATE_EMPLOYEE_TASK_FAILURE: 'UPDATE_EMPLOYEE_TASK_FAILURE',

    UPDATE_EMPLOYEE_RESOURCE_REQUEST:'UPDATE_EMPLOYEE_RESOURCE_REQUEST',
    UPDATE_EMPLOYEE_RESOURCE_SUCCESS: 'UPDATE_EMPLOYEE_RESOURCE_SUCCESS',
    UPDATE_EMPLOYEE_RESOURCE_FAILURE: 'UPDATE_EMPLOYEE_RESOURCE_FAILURE',

    GET_ALL_MANAGER_ACTION_PLAN_REQUEST:'GET_ALL_MANAGER_ACTION_PLAN_REQUEST',
    GET_ALL_MANAGER_ACTION_PLAN_SUCCESS: 'GET_ALL_MANAGER_ACTION_PLAN_SUCCESS',
    GET_ALL_MANAGER_ACTION_PLAN_FAILURE: 'GET_ALL_MANAGER_ACTION_PLAN_FAILURE',

    CROSS_TAB_REQUEST:'CROSS_TAB_REQUEST',
    CROSS_TAB_SUCCESS: 'CROSS_TAB_SUCCESS',
    CROSS_TAB_FAILURE: 'CROSS_TAB_FAILURE',

    CROSS_TAB_DMG_REQUEST:'CROSS_TAB_DMG_REQUEST',
    CROSS_TAB_DMG_SUCCESS: 'CROSS_TAB_DMG_SUCCESS',
    CROSS_TAB_DMG_FAILURE: 'CROSS_TAB_DMG_FAILURE',

    GET_QUESTION_OVERALL_REQUEST:'GET_QUESTION_OVERALL_REQUEST',
    GET_QUESTION_OVERALL_SUCCESS: 'GET_QUESTION_OVERALL_SUCCESS',
    GET_QUESTION_OVERALL_FAILURE: 'GET_QUESTION_OVERALL_FAILURE',
    
    CROSS_TAB_ANALYSIS_REQUEST:'CROSS_TAB_ANALYSIS_REQUEST',
    CROSS_TAB_ANALYSIS_SUCCESS: 'CROSS_TAB_ANALYSIS_SUCCESS',
    CROSS_TAB_ANALYSIS_FAILURE: 'CROSS_TAB_ANALYSIS_FAILURE',
    
    GET_ALL_PARAMETER_LIST_REQUEST:'GET_ALL_PARAMETER_LIST_REQUEST',
    GET_ALL_PARAMETER_LIST_SUCCESS: 'GET_ALL_PARAMETER_LIST_SUCCESS',
    GET_ALL_PARAMETER_LIST_FAILURE: 'GET_ALL_PARAMETER_LIST_FAILURE',

    GET_CORRELATION_OVERALL_REQUEST:'GET_CORRELATION_OVERALL_REQUEST',
    GET_CORRELATION_OVERALL_SUCCESS: 'GET_CORRELATION_OVERALL_SUCCESS',
    GET_CORRELATION_OVERALL_FAILURE: 'GET_CORRELATION_OVERALL_FAILURE',

    GET_CORRELATION_FILTER_REQUEST:'GET_CORRELATION_FILTER_REQUEST',
    GET_CORRELATION_FILTER_SUCCESS: 'GET_CORRELATION_FILTER_SUCCESS',
    GET_CORRELATION_FILTER_FAILURE: 'GET_CORRELATION_FILTER_FAILURE',

    CORRELATION_TYPE2_ANALYSIS_REQUEST:'CORRELATION_TYPE2_ANALYSIS_REQUEST',
    CORRELATION_TYPE2_ANALYSIS_SUCCESS: 'CORRELATION_TYPE2_ANALYSIS_SUCCESS',
    CORRELATION_TYPE2_ANALYSIS_FAILURE: 'CORRELATION_TYPE2_ANALYSIS_FAILURE',


        
    GET_HAPINESS_QUESTION_REQUEST:'GET_HAPINESS_QUESTION_REQUEST',
    GET_HAPINESS_QUESTION_SUCCESS: 'GET_HAPINESS_QUESTION_SUCCESS',
    GET_HAPINESS_QUESTION_FAILURE: 'GET_HAPINESS_QUESTION_FAILURE',

    GET_HAPINESS_MANAGER_QUESTION_REQUEST:'GET_HAPINESS_MANAGER_QUESTION_REQUEST',
    GET_HAPINESS_MANAGER_QUESTION_SUCCESS: 'GET_HAPINESS_MANAGER_QUESTION_SUCCESS',
    GET_HAPINESS_MANAGER_QUESTION_FAILURE: 'GET_HAPINESS_MANAGER_QUESTION_FAILURE',

    GET_HAPINESS_DMG_QUESTION_REQUEST:'GET_HAPINESS_DMG_QUESTION_REQUEST',
    GET_HAPINESS_DMG_QUESTION_SUCCESS: 'GET_HAPINESS_DMG_QUESTION_SUCCESS',
    GET_HAPINESS_DMG_QUESTION_FAILURE: 'GET_HAPINESS_DMG_QUESTION_FAILURE',



    GET_ENGAGEMENT_QUESTION_REQUEST:'GET_ENGAGEMENT_QUESTION_REQUEST',
    GET_ENGAGEMENT_QUESTION_SUCCESS: 'GET_ENGAGEMENT_QUESTION_SUCCESS',
    GET_ENGAGEMENT_QUESTION_FAILURE: 'GET_ENGAGEMENT_QUESTION_FAILURE',

    GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST:'GET_ENGAGEMENT_MANAGER_QUESTION_REQUEST',
    GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS: 'GET_ENGAGEMENT_MANAGER_QUESTION_SUCCESS',
    GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE: 'GET_ENGAGEMENT_MANAGER_QUESTION_FAILURE',

    GET_ENGAGEMENT_DMG_QUESTION_REQUEST:'GET_ENGAGEMENT_DMG_QUESTION_REQUEST',
    GET_ENGAGEMENT_DMG_QUESTION_SUCCESS: 'GET_ENGAGEMENT_DMG_QUESTION_SUCCESS',
    GET_ENGAGEMENT_DMG_QUESTION_FAILURE: 'GET_ENGAGEMENT_DMG_QUESTION_FAILURE',


    

    GET_STRESS_QUESTION_REQUEST:'GET_STRESS_QUESTION_REQUEST',
    GET_STRESS_QUESTION_SUCCESS: 'GET_STRESS_QUESTION_SUCCESS',
    GET_STRESS_QUESTION_FAILURE: 'GET_STRESS_QUESTION_FAILURE',

    GET_STRESS_MANAGER_QUESTION_REQUEST:'GET_STRESS_MANAGER_QUESTION_REQUEST',
    GET_STRESS_MANAGER_QUESTION_SUCCESS: 'GET_STRESS_MANAGER_QUESTION_SUCCESS',
    GET_STRESS_MANAGER_QUESTION_FAILURE: 'GET_STRESS_MANAGER_QUESTION_FAILURE',

    GET_STRESS_DMG_QUESTION_REQUEST:'GET_STRESS_DMG_QUESTION_REQUEST',
    GET_STRESS_DMG_QUESTION_SUCCESS: 'GET_STRESS_DMG_QUESTION_SUCCESS',
    GET_STRESS_DMG_QUESTION_FAILURE: 'GET_STRESS_DMG_QUESTION_FAILURE',





    GET_HOPE_QUESTION_REQUEST:'GET_HOPE_QUESTION_REQUEST',
    GET_HOPE_QUESTION_SUCCESS: 'GET_HOPE_QUESTION_SUCCESS',
    GET_HOPE_QUESTION_FAILURE: 'GET_HOPE_QUESTION_FAILURE',



    GET_MANAGER_QUESTION_REQUEST:'GET_MANAGER_QUESTION_REQUEST',
    GET_MANAGER_QUESTION_SUCCESS: 'GET_MANAGER_QUESTION_SUCCESS',
    GET_MANAGER_QUESTION_FAILURE: 'GET_MANAGER_QUESTION_FAILURE',

    GET_MANAGER_MANAGER_QUESTION_REQUEST:'GET_MANAGER_MANAGER_QUESTION_REQUEST',
    GET_MANAGER_MANAGER_QUESTION_SUCCESS: 'GET_MANAGER_MANAGER_QUESTION_SUCCESS',
    GET_MANAGER_MANAGER_QUESTION_FAILURE: 'GET_MANAGER_QUESTION_FAILURE',

    GET_MANAGER_DMG_QUESTION_REQUEST:'GET_MANAGER_DMG_QUESTION_REQUEST',
    GET_MANAGER_DMG_QUESTION_SUCCESS: 'GET_MANAGER_DMG_QUESTION_SUCCESS',
    GET_MANAGER_DMG_QUESTION_FAILURE: 'GET_MANAGER_DMG_QUESTION_FAILURE',



    GET_LEADER_QUESTION_REQUEST:'GET_LEADER_QUESTION_REQUEST',
    GET_LEADER_QUESTION_SUCCESS: 'GET_LEADER_QUESTION_SUCCESS',
    GET_LEADER_QUESTION_FAILURE: 'GET_LEADER_QUESTION_FAILURE',

    GET_LEADER_MANAGER_QUESTION_REQUEST:'GET_LEADER_MANAGER_QUESTION_REQUEST',
    GET_LEADER_MANAGER_QUESTION_SUCCESS: 'GET_LEADER_MANAGER_QUESTION_SUCCESS',
    GET_LEADER_MANAGER_QUESTION_FAILURE: 'GET_LEADER_MANAGER_QUESTION_FAILURE',

    GET_LEADER_DMG_QUESTION_REQUEST:'GET_LEADER_DMG_QUESTION_REQUEST',
    GET_LEADER_DMG_QUESTION_SUCCESS: 'GET_LEADER_DMG_QUESTION_SUCCESS',
    GET_LEADER_DMG_QUESTION_FAILURE: 'GET_LEADER_DMG_QUESTION_FAILURE',




    GET_HAPPINESS_INDEX_BY_FILTER_REQUEST:'GET_HAPPINESS_INDEX_BY_FILTER_REQUEST',
    GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS:'GET_HAPPINESS_INDEX_BY_FILTER_SUCCESS',
    GET_HAPPINESS_INDEX_BY_FILTER_FAILURE:'GET_HAPPINESS_INDEX_BY_FILTER_FAILURE',

    GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST:'GET_ENGAGEMENT_INDEX_BY_FILTER_REQUEST',
    GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS:'GET_ENGAGEMENT_INDEX_BY_FILTER_SUCCESS',
    GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE:'GET_ENGAGEMENT_INDEX_BY_FILTER_FAILURE',

    GET_STRESS_INDEX_BY_FILTER_REQUEST:'GET_STRESS_INDEX_BY_FILTER_REQUEST',
    GET_STRESS_INDEX_BY_FILTER_SUCCESS:'GET_STRESS_INDEX_BY_FILTER_SUCCESS',
    GET_STRESS_INDEX_BY_FILTER_FAILURE:'GET_STRESS_INDEX_BY_FILTER_FAILURE',
    

    
    GET_MANAGER_INDEX_BY_FILTER_REQUEST:'GET_MANAGER_INDEX_BY_FILTER_REQUEST',
    GET_MANAGER_INDEX_BY_FILTER_SUCCESS:'GET_MANAGER_INDEX_BY_FILTER_SUCCESS',
    GET_MANAGER_INDEX_BY_FILTER_FAILURE:'GET_MANAGER_INDEX_BY_FILTER_FAILURE',

    GET_LEADER_INDEX_BY_FILTER_REQUEST:'GET_LEADER_INDEX_BY_FILTER_REQUEST',
    GET_LEADER_INDEX_BY_FILTER_SUCCESS:'GET_LEADER_INDEX_BY_FILTER_SUCCESS',
    GET_LEADER_INDEX_BY_FILTER_FAILURE:'GET_LEADER_INDEX_BY_FILTER_FAILURE',
};

