import React, { Component } from 'react';
import { connect } from 'react-redux';
import HeatMap from './Charts/HeatMap';
import IndexCards from './Charts/IndexCards';


import IndexComponent from './Components/IndexComponent/IndexComponent';
import Commentary from '../Index/Components/Commentary/Commentary';

import DetailedView from './Components/Overview/DetailedView';
import SummaryView from './Components/Overview/SummaryView';


class ManagerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
        managerDetailsIndexType:0,
        showManagerList:true,

        mgrIndexA:0,
        mgrIndexB:8,
        CurrentManagerName:'',
        CurrentManagerId:'',
        dynamicList:[],

        optionTypeM:101,

        CurrentManagerData:'',

        showCircle:true,

        crrManagersList:[],


        crrManagerName:'none',
        crrMangerId:'',
        showMultiple:false,

        AllSelected:false,

        managerSearchName:'',
        managerCollectionList:[],

        DimensionList:[
          {
            index:1,
            name:'Happiness',
            cutOff:59
          },
          {
            index:2,
            name:'Engagement',
            cutOff:78
  
          },
          {
            index:4,
            name:'Stress',
            cutOff:28
  
          },
          {
            index:5,
            name:'Manager',
            cutOff:50
          },
          // {
          //   index:6,
          //   name:'Leader',
          //   cutOff:50
          // },
          // {
          //   index:8,
          //   name:'OrgCore',
          //   cutOff:59
          // },
        ],
        showViewBy:false
  
    }
  }

  componentDidMount() {

  }




  handleViewType=(data)=>{
    this.setState({showCircle:data});

  }

  goPrev=()=>{
    let { getDemographicIndexForManagerData } = this.props;
    let { mgrIndexA, mgrIndexB } = this.state;
    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      if(mgrIndexA>0){
        this.setState({mgrIndexA:mgrIndexA-1,mgrIndexB:mgrIndexB-1})
      }

    }
    
  }
  goNext=()=>{
    let { getDemographicIndexForManagerData } = this.props;
    let { mgrIndexA, mgrIndexB } = this.state;
    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      if(mgrIndexB<getDemographicIndexForManagerData.length-1){
        this.setState({mgrIndexA:mgrIndexA+1,mgrIndexB:mgrIndexB+1})
        
      }
    }
  }


  SelectManager=(data,ManagerName1)=>{
    let { handleManagerHeatmap, EmpFilterData } = this.props;
    let { optionTypeM } = this.state;

    // this.setState({CurrentManagerName:data.name, CurrentManagerId:data.ManagerPID, CurrentManagerData:data})
    this.setState({dynamicList:[]})
    // DynaObj=[]


    if(EmpFilterData && EmpFilterData.length>0){
      // handleManagerHeatmap({
      //   level:'ManagerPID',
      //   value:data['ID']
      // });
       EmpFilterData.forEach((filter)=>{

        if(data && data[filter.name]){
          let filterdata = {
            level:filter.name,
            value:data[filter&&filter.name==="ManagerPID"?"id":filter.name]
          }
          // console.log('filterdata: ',filterdata);
          handleManagerHeatmap(filterdata);
        }

      })
    }


  }

  handleOption=(optionTypeM,managerPID,managerData, ManagerName1)=>{
    let {crrManagersList} = this.state;


    if(crrManagersList && crrManagersList.length>0){
      this.setState({showViewBy:false});
    }
    else{

      if(this.state.optionTypeM=== 101 || this.state.optionTypeM=== 102){
        this.setState({showViewBy:true});
      }
      else{
          this.setState({showViewBy:true});
      }

    }


    this.setState({optionTypeM:optionTypeM});
    
    this.setState({managerCollectionList:[]});

    // if(true){
    //   if(crrManagersList.findIndex(prev=>prev===managerData)===-1){
    //     crrManagersList.push(managerData);
    //     this.setState({crrManagersList});
    //   }
    // }


    this.setState({CurrentManagerId:managerPID, CurrentManagerData:managerData, CurrentManagerName: ManagerName1})


    let { handleManagerQuestion,handleManagerDashboardCall,handleMultiManagerWiseDriver,getDemographicIndexForManagerData, handleManagerWiseDriver,  EmpFilterData,handleManagerListIndexQuestionCall, QuesDynaObj, ManagerListQuestionDataObj  } = this.props;

    

      if(optionTypeM!==101 && optionTypeM!==102){
        let {crrManagersList}= this.state;
        this.setState({showMultiple:true});

        for(let i=0;i<2;i++){

              if(crrManagersList && crrManagersList.length>0){
                crrManagersList.forEach((mngr)=>{
                  handleMultiManagerWiseDriver(optionTypeM,mngr);

                });
              }
        }
      }



  }


  handleManagerQuestion=(optionType)=>{
    let {CurrentManagerId,CurrentManagerData} = this.state;
    this.handleOption(optionType,CurrentManagerId,CurrentManagerData)
  }



  handleManagerDriver=(Mindextype)=>{
    let { handleManagerWiseDriver, EmpFilterData } = this.props;

    let { CurrentManagerId } = this.state;

    if(EmpFilterData && EmpFilterData.length>0){
      handleManagerWiseDriver(Mindextype,CurrentManagerId,"ManagerPID");
      EmpFilterData.forEach((emp)=>{
        handleManagerWiseDriver(Mindextype,CurrentManagerId,emp.name);
      })
    }


  }

  openDropdown=(check)=>{
    this.setState({showViewBy:!check});
  }



  handleManagerALLSelection=()=>{
    let { AllSelected,crrManagersList } = this.state;
    if(AllSelected){
      this.setState({crrManagersList:[]});
      this.setState({AllSelected:false});

    }
    else{
      let { dashboard } = this.props;
      let { getDemographicIndexForManagerData } = dashboard;
      if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
        getDemographicIndexForManagerData.forEach((mngr)=>{
          crrManagersList.push(mngr);
        });
      }
      this.setState({crrManagersList,AllSelected:true});
    }


    let defaultMngr = [(this.prop.dashboard && this.prop.dashboard.sgetDemographicIndexForManagerData && this.prop.dashboard.getDemographicIndexForManagerData.length>0)?this.prop.dashboard.getDemographicIndexForManagerData[0]:{}]

  }

  


  handleManagerSelection=(mngr)=>{
    console.log("-------------------->mngr ",mngr)
    let {crrManagersList } = this.state;
    this.setState({AllSelected:false});

    let getIndex = crrManagersList.findIndex(prev=>prev.emp_id===mngr.emp_id);
    if(getIndex===-1){
      crrManagersList.push(mngr);

    }
    else{
      crrManagersList.splice(getIndex,1);
    }
    this.setState({crrManagersList});



  }

  handleManagersCall=(optionTypeM)=>{
    this.setState({managerCollectionList:[]});

    let { crrManagersList} = this.state;
    if(crrManagersList && crrManagersList.length>1){
      this.setState({showMultiple:true});
    }
    else{
      this.setState({showMultiple:false});
    }
    let { handleManagerDashboardCall } = this.props;
    let { handleManagerQuestion,handleMultiManagerWiseDriver, handleManagerWiseDriver,  EmpFilterData,handleManagerListIndexQuestionCall, QuesDynaObj  } = this.props;
    




    if(optionTypeM !== 101 && optionTypeM !== 102){
      if(crrManagersList && crrManagersList.length>0){
          if(crrManagersList.length===1 && false){

                        // if(EmpFilterData && EmpFilterData.length>0){
                        //   EmpFilterData.forEach((emp)=>{
                            
                        //     let data = {
                        //       template:optionTypeM,
                        //       filters:[
                        //         {
                        //           "level":emp.name,
                        //           "value":crrManagersList[0][emp&&emp.name==="ManagerPID"?"id":emp.name]
                        //         }
                        //       ]
                        //     }
                        //     handleManagerQuestion(data);
                        //   })
                        // }

                        for(let i=0;i<2;i++){
                          if(EmpFilterData && EmpFilterData.length>0){
                            handleManagerWiseDriver(optionTypeM,crrManagersList[0].id,"ManagerPID");
                            EmpFilterData.forEach((emp)=>{
                              handleManagerWiseDriver(optionTypeM,crrManagersList[0].id,emp.name);
                            })
                          }
                        }

                        this.setState({showMultiple:false,CurrentManagerData:crrManagersList[0]});

          }
          else{
            this.setState({showMultiple:true});
            for(let i=0;i<2;i++){

              if(crrManagersList && crrManagersList.length>0){
                crrManagersList.forEach((mngr)=>{

                  // handleManagerListIndexQuestionCall(optionTypeM,mngr);

                  handleMultiManagerWiseDriver(optionTypeM,mngr);

                });
              }
            }


          }

      }

  }
  else{
    // if(crrManagersList && crrManagersList.length>0){

    //   if(crrManagersList.length===1&& false){
    //   }
    //   else{
    //     crrManagersList.forEach((mngr)=>{
    //       handleManagerDashboardCall(mngr);
    //     });
    //   }

    // }
  }















    this.setState({showViewBy:false});

  }




  getReportMngrQuestion=(mngr, template)=>{
    let {EmpFilterData, handleManagerQuestion} = this.props;
    if(EmpFilterData && EmpFilterData.length>0){
      // handleManagerQuestion({
      //   template:template,
      //   filters:[
      //     {
      //       "level":"ManagerPID",
      //       "value":mngr["ManagerPID"]
      //     }
      //   ]
      // });
      EmpFilterData.forEach((emp)=>{
        
        let data = {
          template:template,
          filters:[
            {
              "level":emp.name,
              "value":mngr[emp&&emp.name==="ManagerPID"?"id":emp.name]
            }
          ]
        }
        handleManagerQuestion(data);
      });
    }
  }

  handleSearchManager=(e)=>{
    this.setState({managerSearchName:e.target.value});
  }


  CollectDataManagerForActionPlan=(data)=>{
    let { managerCollectionList }= this.state;
    let getIndex = managerCollectionList.findIndex(prev=>prev.code===data.code);
    if(getIndex===-1){
      managerCollectionList.push(data)
    }
    else{
      managerCollectionList.splice(getIndex,1);
    }
    this.setState({managerCollectionList});

  }


  render() {
    let {getAllManagerActionPlanData, getDemographicIndexHappinessData,getDemographicIndexEngagementData,getDemographicIndexManagerData, indexType,getCommentData, getIndexData, getDemographicIndexForManagerData, getManagerListDomainFilterData, ManagerIndexDriverData,
      ManagerDynaObj,EmpFilterData , getIndexFilterData ,QuesDynaObj, ManagerIndexQuestionData,

      ManagerListDasboardDataObj,
      ManagerListQuestionDataObj,

      QuesMngrHappinessReportObject,
      QuesMngrEngagementReportObject,
      QuesMngrStressReportObject,
      QuesMngrManagerReportObject
    
    } = this.props;

    let {managerSearchName, crrManagerName,crrMangerId,crrManagersList,showViewBy,
      managerCollectionList

    } = this.state;

    let { 
    
      mgrIndexA,
      mgrIndexB,

      CurrentManagerName,
      CurrentManagerId,

      dynamicList,


      showManagerScore,
      showEngagement,
      showHappiness,
      optionTypeM,

      CurrentManagerData ,

      showCircle
    
    } = this.state;


    // dynamicList.push(getManagerListDomainFilterData)

    // console.log('crrManagersList: ',crrManagersList)
    // console.log('----------crrManagersList---------------------------------->: ',crrManagersList)

    // console.log('dynamicList: ',dynamicList)

    let DefaultManagerId = '';
    let DefaultManagerName = '';

    let IndexForManagerData={}
    let IndexData={}


    function getIndexName(key){
      let name = ''
        if(key===1){ name='Happiness' }
        else if(key===2){ name='Engagement' }
        else if(key===3){ name='Hope' }
        else if(key===4){ name='Stress' }
        else if(key===5){ name='Manager' }
        else if(key===8){ name='Organization' }

      return name;
    }

    let IndexList=[]
    let IndexLabels=[]
    if(getIndexData){
      IndexList = Object.keys(getIndexData).map((key) => [ Number(key), getIndexData[key] ]);
    }
    if(IndexList && IndexList.length>0){
      IndexList.forEach((item)=>{

        if(item[1]){
          IndexData[item[0]]=(item[1])?(item[1]).toFixed(2):'NaN';
        }

        if(getIndexName(item[0])){
          IndexLabels.push({
            indexType:item[0],
            indexName:getIndexName(item[0])
          });
        }
      })
    }
    this.state.FilterList=IndexLabels



    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      if(!CurrentManagerId){
          DefaultManagerId=getDemographicIndexForManagerData[0].ManagerPID;
          DefaultManagerName=getDemographicIndexForManagerData[0].name;

          if(IndexList && IndexList.length>0){
            IndexList.forEach((item)=>{
              IndexForManagerData[item[0]]=getDemographicIndexForManagerData[0][item[0]];
            })
          }
      }
     else{
      getDemographicIndexForManagerData.forEach((mgr)=>{

        if(mgr.ManagerPID===CurrentManagerId){
          if(IndexList && IndexList.length>0){
            IndexList.forEach((item)=>{
              IndexForManagerData[item[0]] = mgr[item[0]];
            })
          }
        }
    
        })
     }
    }


    let managerPID='';
    let managerData='';
    let managerNewName='';

    if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
      if(!CurrentManagerId){
        managerPID=getDemographicIndexForManagerData[0].id;
        managerData=getDemographicIndexForManagerData[0];
        managerNewName=getDemographicIndexForManagerData[0].name;

      }
      else{
        managerPID=CurrentManagerId;
        managerData=CurrentManagerData;
        managerNewName=CurrentManagerName
      }
    }

  

    // console.log('managerPID: ',managerPID);
    // console.log('managerData: ',managerData);
    // console.log('managerNewName: ',managerNewName);
    // console.log('getDemographicIndexForManagerData: ',getDemographicIndexForManagerData);

    // console.log('IndexLabels: ',IndexLabels);



    let { GetParameterLabel, AssignManagerActionPlan, DynaObj, getQuestionData,getQuestionReportData, tempList, loading, getQuestionOverallData } = this.props;


    let ManagerName1 = ''
    if(CurrentManagerName){
      ManagerName1 = CurrentManagerName
    }
    else{
      ManagerName1 = DefaultManagerName
    }
    



    let selectedManagerName = '';
    let selectedManagerId = '';

    // if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
    //   if(crrManagerName && crrMangerId){
    //     selectedManagerName = crrManagerName;
    //     selectedManagerId = crrMangerId;
    //   }
    //   else{
    //     selectedManagerName = getDemographicIndexForManagerData[0].name;
    //     selectedManagerId = getDemographicIndexForManagerData[0].id;
    //   }
    //   //------
    // }
    let { showMultiple } = this.state;


    let getDemographicDriverData = []
    if(optionTypeM===1){
      getDemographicDriverData = ManagerIndexDriverData;
    }
    else if(optionTypeM===2){
      getDemographicDriverData = ManagerIndexDriverData;
    }
    else if(optionTypeM===4){
      getDemographicDriverData = ManagerIndexDriverData;
    }
    else if(optionTypeM===5){
      getDemographicDriverData = ManagerIndexDriverData;
    }
    // console.log('zzzzzzzzzzz - getDemographicDriverData: ',getDemographicDriverData)

    let DemographicDriverManagerData = [];
    let temShowMultiple =false;
    if(crrManagersList && crrManagersList.length>0){
    if(ManagerIndexDriverData && ManagerIndexDriverData.length>0){
      ManagerIndexDriverData.forEach((item)=>{
        crrManagersList.forEach((mngr)=>{
          if((item.name).toString() === (mngr.id).toString()){
            let tempObj = {
              ...item,
              id:(mngr.id).toString()
            }
            tempObj["name"] = mngr.name;
            DemographicDriverManagerData.push(tempObj);
          }
        })
      });
    }
    temShowMultiple=true;
  }
  else{
    temShowMultiple=true;
  }

  // console.log('zzzzzzzzzzzzz = crrManagersList: ',crrManagersList)
  // console.log('zzzzzzzzzzzzz = ManagerIndexDriverData: ',ManagerIndexDriverData)
  // console.log('zzzzzzzzzzzzz = DemographicDriverManagerData: ',DemographicDriverManagerData)




  // console.log('DemographicDriverManagerData: ',DemographicDriverManagerData);
  
  console.log('//////////////////crrManagersList: ',crrManagersList)
  console.log('//////////////////ManagerIndexDriverData: ',ManagerIndexDriverData)
  console.log('//////////////////DemographicDriverManagerData: ',DemographicDriverManagerData)



  // console.log('-------------------------------------------->: ');

  // console.log('ManagerListDasboardDataObj: ',ManagerListDasboardDataObj);
  // console.log('ManagerListQuestionDataObj: ',ManagerListQuestionDataObj);

  // console.log('-------------------------------------------->: ');


  let NEW_ManagerListDasboardDataObj = [];
  let NEW_ManagerListQuestionDataObj = [];

  if(ManagerListDasboardDataObj && ManagerListDasboardDataObj.length>0){
    ManagerListDasboardDataObj.forEach((item1)=>{
      let getIndex = crrManagersList.findIndex(prev=>prev.id === item1.ManagerPID);
      if(getIndex!==-1){
        NEW_ManagerListDasboardDataObj.push(item1);
      }
    });
  }

  if(ManagerListQuestionDataObj && ManagerListQuestionDataObj.length>0){
    ManagerListQuestionDataObj.forEach((item1)=>{
      let getIndex = crrManagersList.findIndex(prev=>prev.id === item1.ManagerPID);
      if(getIndex!==-1){
        NEW_ManagerListQuestionDataObj.push(item1);
      }
    });
  }


  // console.log('-------------------------------------------->: ');

  // console.log('NEW_ManagerListDasboardDataObj: ',NEW_ManagerListDasboardDataObj);

  // console.log('-------------------------------------------->: ');
  let DemographicIndexForManagerData = []
  if(getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0){
    getDemographicIndexForManagerData.forEach((item)=>{
      DemographicIndexForManagerData.push(item);
    });
  }

  function SortList(list){
    if(list && list.length>0){
      // return list.sort((b,a)=>(a.name-b.name))
      return list.sort((a, b) => a.name.localeCompare(b.name))

    }
    else{
      return [];
    }
  }

  // console.log('getDemographicIndexForManagerData=>',getDemographicIndexForManagerData)
  // console.log('DemographicIndexForManagerData=>',DemographicIndexForManagerData)
  let { validateIndex } = this.props;
  let { DimensionList} = this.state;


  let validateList = [];
  let validObj = []
  if(validateIndex){
    validObj = Object.keys(validateIndex).map((key)=>[key,validateIndex[key]])
  }
  console.log('validObj: ',validObj);
  if(validObj && validObj.length>0){
    validObj.forEach((item)=>{
      if(item[1]){
        let getIndex = DimensionList.findIndex(prev=>prev.index===parseInt(item[0]));
        if(getIndex!==-1?DimensionList[getIndex].name:""){
          validateList.push({
            "name":getIndex!==-1?DimensionList[getIndex].name:"",
            "template":parseInt(item[0])
          });
        }

      }
    })
  }


  let { IndexDimension } = this.props;

  console.log('crrManagersList: ',crrManagersList)

    return (
      <>
  <main className="flex-1 overflow-x-hidden overflow-y bg-gray-100">

      <div className="px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b h-10">
        <div className="cursor-pointer text-xs font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden">
          <span onClick={()=>this.handleOption(101,managerPID,managerData, ManagerName1)} className={(optionTypeM===101)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Action Plan Details</span>
          <span onClick={()=>this.handleOption(102,managerPID,managerData, ManagerName1)} className={(optionTypeM===102)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Dashboard Summary</span>

          {validateList&&validateList.length>0?
          validateList.map((item)=>
            (item.template!==5)?
              <span onClick={()=>this.handleOption(item.template,managerPID,managerData, ManagerName1)} 
              className={(optionTypeM===item.template)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>{item.name} Action</span>
            :null
          ):null}

          <span onClick={()=>this.handleOption(5,managerPID,managerData, ManagerName1)} className={(optionTypeM===5)?"py-3 block px-4 text-blue-600 border-b-2 border-blue-500":"py-3 block px-4"}>Manager Action</span>
        </div>
      </div>





        {getDemographicIndexForManagerData?

    <div class=" pb-8 ">
    {optionTypeM!==101 && optionTypeM!==102?
        <div className="bg-white px-4 flex w-full justify-between items-center  border-b py-3">
          <div className="flex items-center border py-2 px-4 w-full relative">
            
            {/* <input onClick={()=>this.openDropdown(showViewBy)} type="text" name placeholder="Select Managers" value={
              crrManagersList && crrManagersList.length>0?
                crrManagersList.map((element)=>{return element.name}).join(", "):
              getDemographicIndexForManagerData && getDemographicIndexForManagerData.length>0?getDemographicIndexForManagerData[0].name:""
            
            } className="w-full text-xs focus:outline-none font-bold cursor-pointer" />  */}

            <div className="w-full text-xs focus:outline-none font-bold cursor-pointer ">
            {crrManagersList && crrManagersList.length>0?
              crrManagersList.map((element,index)=>
              <>
              <span >{element.name}<span onClick={()=>this.handleManagerSelection(element)} className="text-red-500 ">{" (x)"}</span></span>
              {index+1===crrManagersList.length?<span></span>:<span className="mr-2">{", "}</span>}
              </>
              )

            :
            <div className="text-xs">
                <span >{"Select Managers"}</span>
            </div>
            }
            </div>
          {/*             
            {showViewBy?
              <span onClick={()=>this.openDropdown(showViewBy)} className="cursor-pointer material-icons text-gray-500">expand_less</span>
            :
              <span onClick={()=>this.openDropdown(showViewBy)} className="cursor-pointer material-icons text-gray-500">expand_more</span>
            } */}
            {/* drop down */}
            <div className="absolute left-0 top-10 bg-white shadow-lg   font-normal font-xs w-full  " style={{ zIndex:103 }}>
              
              {showViewBy?
              <>
              <div className="flex">
                    <div className="p-3 w-10/12">
                      <input type="text" value={managerSearchName} name="managerName" onChange={this.handleSearchManager} placeholder="Search" 
                      className="w-full border p-1.5 rounded-sm focus:outline-none text-xs text-gray-500 mb-2 focus:ring-blue-500 focus:ring-1" />
                    </div>

                    <div className="p-3 w-2/12">
                      {/* <button onClick={()=>this.openDropdown(showViewBy)} className="text-sm  text-red-500  py-2 px-10 ml-2 focus:outline-none">Close</button> */}
                    </div>
              </div>

                <div className="h-72 overflow-y-auto px-3" id="customscroll3"> 

                {SortList(DemographicIndexForManagerData) && DemographicIndexForManagerData && DemographicIndexForManagerData.length>0?
                  SortList(DemographicIndexForManagerData).map((mngr, index)=>
                  mngr && mngr.name.match(new RegExp(`${managerSearchName}`, 'gi'))?
                    <div key={index} className="py-2 m-1 flex items-center">
                      <input type="checkbox" checked={crrManagersList.some(prev=>prev.emp_id===mngr.emp_id)?true:false} 
                      onClick={()=>this.handleManagerSelection(mngr)}
                      onChange={()=>{}}/><label onClick={()=>this.handleManagerSelection(mngr)} 
                      className="cursor-pointer ml-2 text-md hover:text-blue-600 text-gray-600 font-semibold ">{mngr.name}</label>   
                    </div>
                  :null):null}

                </div> 
                </>
              :null}

            </div>
            

          </div>
          {showViewBy?
              <span onClick={()=>this.openDropdown(showViewBy)} className="cursor-pointer py-2 px-2 material-icons text-red-500 font-bold">close</span>
              :
              <span onClick={()=>this.openDropdown(showViewBy)} className="cursor-pointer py-2 px-2 material-icons text-blue-500 font-bold">arrow_downward</span>
          }
          <button onClick={()=>this.handleManagersCall(optionTypeM)} className="text-xs bg-blue-500 text-white  py-2 px-10 ml-2 focus:outline-none">Apply</button>
        </div>
:null}

        
        





        <div onClick={()=>this.openDropdown(true)}>


        {optionTypeM===101?
        <>
        <div>
          <DetailedView  EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} GetParameterLabel={GetParameterLabel} getAllManagerActionPlanData={getAllManagerActionPlanData} getDemographicIndexForManagerData={getDemographicIndexForManagerData}/>
        </div>
        </>
        :null}

        
        {optionTypeM===102?
        <>
        <div>
          <SummaryView  EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} GetParameterLabel={GetParameterLabel} getAllManagerActionPlanData={getAllManagerActionPlanData} getDemographicIndexForManagerData={getDemographicIndexForManagerData}/>
        </div>
        </>
        :null}


      {optionTypeM!==101 && optionTypeM!==102?
        <>
          <IndexComponent
          getAllManagerActionPlanData={getAllManagerActionPlanData}
          
          indexType={indexType} optionType={optionTypeM} getCommentData={getCommentData}
             getQuestionData={getQuestionData}
             Name={
               (optionTypeM===1)?"Happiness":
               (optionTypeM===2)?"Engagement":
               (optionTypeM===4)?"Stress":
               (optionTypeM===5)?"Manager":
               (optionTypeM===8)?"Organization":
               ""}
             handleManagerDriver={this.handleManagerDriver}
             ManagerIndexDriverData={ManagerIndexDriverData}
             CurrentManagerId={CurrentManagerId}
             optionTypeM={optionTypeM}
             ManagerDynaObj={ManagerDynaObj}
             EmpFilterData={EmpFilterData}
             CurrentManagerData={CurrentManagerData}

             

             QuesDynaObj={QuesDynaObj}

             handleManagerQuestion={this.handleManagerQuestion}

             ManagerIndexQuestionData={ManagerIndexQuestionData}


             ManagerName={ManagerName1}

             CurrentManagerName={CurrentManagerName}

             loading={loading}
             
             ManagerListQuestionDataObj={NEW_ManagerListQuestionDataObj}

             showMultiple={true}

             DemographicDriverManagerData={DemographicDriverManagerData}


             temShowMultiple={true}

             ParaLabelList = {
              (optionTypeM===1)?IndexDimension.Happiness:
              (optionTypeM===2)?IndexDimension.Engagement:
              (optionTypeM===4)?IndexDimension.Stress:
              (optionTypeM===5)?IndexDimension.Manager:{}
             }




             getQuestionOverallData={getQuestionOverallData}

             AssignManagerActionPlan={AssignManagerActionPlan}

             CollectDataManagerForActionPlan={this.CollectDataManagerForActionPlan}
             managerCollectionList={managerCollectionList}
             GetParameterLabel={GetParameterLabel}
             DemographicIndexForManagerData={DemographicIndexForManagerData}
          /> 
        </>
        :null}



        </div>
       



          </div>
   
        :null}
      </main>
    
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(ManagerList);
