import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions } from '../../../_actions';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProfileBox:false,


      showDropdown:false
    }
  }
  componentDidMount() {
  }

  openProfile=(check)=>{
    this.setState({showProfileBox:!check})

    setTimeout(()=>{
      this.setState({showProfileBox:false})
    },4000)
  }
  handleLogout=()=>{
    this.props.dispatch(userActions.logout());
  }

  render() {
    let { ClientProfileData ,SurveyList, ChooseSurvey} = this.props;
    let { showProfileBox } = this.state;



    function firstWord(ClientProfileData){
      if(ClientProfileData && ClientProfileData.name){
        var firstWords = [];
        var firstWords2 = [];
        var codelines = ClientProfileData.name;

        for (var i=0;i<codelines.length;i++)
        {
          var words = codelines[i].split(" ");
  
          firstWords.push(words[0]);
        }
        if(firstWords && firstWords.length>0){
          firstWords.every((name,index)=>{
            if(name ===""){
              return false;
            }
            else{
              firstWords2.push(name);
              return true;
            }
          }
          
          )
        }


        // var Username = firstWords2.join("");
        var Username = codelines;

  
        return Username
  
        }
        return 'User'
      }
      
      let ProfileName = firstWord(ClientProfileData);

      let ProfileGender = ''
      if(ClientProfileData && ClientProfileData.name){
        ProfileGender = ClientProfileData.Gender;
      }
      

    return (
      <>
  
  <div className="fixed lg:block hidden w-16 left-0 h-full z-30  transition duration-300 transform bg-white overflow-y-auto  overflow-hidden overflow-x-hidden border-r h-increse" id="customscroll">
    <div className="flex items-center border-b">
      <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-16 justify-center">
        <span className="material-icons text-gray-500 rounded hover:bg-gray-100 p-2">menu</span>
      </div>
      <span className="font-bold ml-6 text-2xl tracking-widest text-blue-500">Plus</span>
    </div>
    <div className="py-4">

      <div className="flex items-center">
        <div className="flex items-center px-4 py-4 w-16  text-blue-500 h-12 justify-center">
          <span className="material-icons text-gray-500 rounded hover:bg-blue-50 p-2">event_available</span>
        </div>
        <span className="font-medium text-xs text-gray-500 w-full">Actions Plan</span>
        <span className="material-icons text-gray-500 mr-4" style={{fontSize: '18px'}}>expand_more</span>
      </div>



    </div>
  </div>


      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { user , dashboard } = state;
  return {
    loggingIn,
    user
  };
}
export default connect(mapStateToProps)(Header);