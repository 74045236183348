import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";


class PieChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dimensionValueList:[],
      EmpIndexA:0,
      EmpIndexB:4
    }
  }
  componentDidMount() {

  }

  render() {
    let { AnswerObj , name, size, questionName} = this.props;
            
    let DataList = [];
    let total = 0;
    if(AnswerObj){
      Object.keys(AnswerObj).forEach(key =>{
        let score = parseInt(AnswerObj[key]);
        total+=score;
        DataList.push({
          name: key,
          value: score
        });
      });
    }

    // console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaAnswerObj: ',AnswerObj)
    // console.log('DataList: ',DataList)





    DataList.sort((a,b)=>{
      if(a.name.slice(0,-2) && b.name.slice(0,-2)){
       return (a.name.slice(5,6) - b.name.slice(5,6))
     }
   });


    let List = [];
    let MaxScore = 0;
    let MaxObj = {}
    if(DataList&&DataList.length>0){DataList.forEach((item)=>{

      let temp = {
        "label":item.name,
        "value":((parseFloat(item.value)*100)/total).toFixed(2),
        "itemStyle":{color:'#9933ff'}
      }

      let scorePerc = parseFloat(item.value);
      if(scorePerc>MaxScore){
        MaxScore = scorePerc;
        MaxObj = temp;
      }



      List.push(temp);



    })}





  function isFloat(n){
    return Number(n) % 1 != 0;
  }
  let {stage,index} = this.props;
    return (
      <>
          {/* <div className="flex justify-between">
          {stage!==3?
            <div><h1 className="text-xl font-medium">Single Choice</h1>
              <p className="text-sm  py-1">  
                {"1. "+questionName}</p></div>
          :null}
            <div className="whitespace-nowrap ml-4 cursor-pointer"><span className="text-blue-500 text-center hover:text-blue-700 ">{stage==2?"View Details":(stage==3?"":"View More")}</span></div>
          </div>
 */}


          {/* <div className="flex justify-between pb-2">
                            <h1 className="text-lg font-medium">Single Choice</h1>
                            <div className="whitespace-nowrap ml-4 cursor-pointer"><span className="text-white text-center text-xs px-4 py-2 rounded-md  bg-blue-500 hover:bg-blue-600 ">{stage==2?"View Details":(stage==3?"":"View More")}</span></div>
                        </div> 


                        <p className="text-sm text-gray-800 py-1 truncate">
                                {stage==2?
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                }'
                        {questionName}</p> */}


<div className="flex justify-between pb-2">
                            <h1 className="text-lg font-medium truncate">
                              {/* {stage==2?
                                <b>{"Q"+(index+1)+"."}</b>
                                :
                                <b>Q1.</b>
                                } */}
                        {questionName}</h1>
                            <div className="whitespace-nowrap ml-4 cursor-pointer">
                              <span className="text-white text-center text-xs px-4 py-2 rounded-md  bg-blue-500 hover:bg-blue-600 ">
                                {stage==2?"View Details":(stage==3?"":"View More")}</span></div>
                        </div> 



          <div className="mb-8 mt-4">
            <h1 className="text-2xl text-center ">{MaxObj && MaxObj["value"]?MaxObj["value"]+"%":"0%"}</h1>
            <div className="my-1  flex justify-center">
              <span className="text-gray-500">{MaxObj && MaxObj["label"]?MaxObj["label"]+" with the question":""}</span> 
            </div>
          </div>


{/*           

          <div className="mb-8">

          </div> */}
          {/* progress */}
          <div className="space-y-2">
            
            
            {List && List.length>0?
            List.map((item)=>
              <div className="flex items-center ">
                <p className="whitespace-nowrap text-sm w-40 font-semibold">{item.label}</p>
                <div className="h-6 bg-gray-200 w-full ml-1">
                  <div  style={{width:item.value+'%'}} 
                    className="w-3/6 bg-gradient-to-r from-blue-400 to-blue-500 h-6  relative " >
                    <div className="absolute right-2 text-sm font-normal text-white p-0.5">{item.value+'%'}</div>
                  </div>
                </div>
              </div>
            ):null}



          </div>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(PieChart);
