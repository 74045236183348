import React, { Component } from 'react';
import { connect } from 'react-redux';

class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }



  componentDidMount() {
  }



    render(){
        return(
            <>
                    <div className="bg-white rounded shadow-md p-4 md:w-5/12 w-full md:mx-1 md:mt-0 mt-2">
                        {/* <h1 className="text-sm font-bold">Comments</h1>
                        <p className="text-xs text-gray-500 py-2 border-b leading-loose">Lorem Ipsum is simply dummy text of the
                        printing and typesetting industry. Lorem
                        Ipsum has been the industry's standard
                        dummy text ever since the 1500s, when an
                        unknown printer took a galley of type and</p>
                        <div className="pt-4">
                        <h1 className="text-sm font-bold">Disclaimer</h1>
                        <p className="text-xs text-gray-500 py-2  leading-loose">Lorem Ipsum is simply dummy text of the
                            printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard
                            dummy text ever since the 1500s, when an
                            unknown printer took a galley of type and
                            scrambled it to make a type specimen
                            book. It has survived not only five centuries,
                            but also the leap into electronic
                            typesetting, remaining essentially
                            unchanged. It was popularised in the 1960s
                            with the release of Letraset sheets
                            containing Lorem Ipsum passages, and
                            more recently with desktop publishing
                            software like Aldus PageMaker includi</p>
                        </div> */}
                    </div>
            </>
        );
    }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  // const { users, dashboard } = state;
  return {
    loggingIn
  };
}
export default connect(mapStateToProps)(Comments);
