import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuestionStack from './Charts/QuestionStack';
import Commentary from '../Commentary/Commentary';
import Filter from './Filter/Filter';

class QuestionAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currFilterValue: 'none',
      currFilterName: 'none',


      currentSelectValue: '',
      FilterValues: [],
      colorTheme: 1,
      showFilter: false,
      showValue: ''
    }
  }
  componentDidMount() {

  }

  closeFilter = (check1, check2) => {
    this.setState({ showFilter: check1, showValue: check2 });
  }

  handleMultifilterIndexScore2 = (finalFilter) => {
    let { handleMultiFilterGetQuestion } = this.props;
    this.setState({ FilterValues: finalFilter });
    handleMultiFilterGetQuestion(finalFilter);

  }


  changeColorTheme = (value) => {
    this.setState({ colorTheme: value });
  }



  render() {
    let { selectedFilterValue, loading,
      indexType, optionType, getCommentData, CountRestrict, 

      getQuestionData, XAxis, getQuestionFilterData, handleQuestionFilter, EmpFilterData, getIndexFilterData

    } = this.props;

    let { colorTheme, FilterValues } = this.state;


    let QuestionData = []
    if (this.state.currFilterValue === 'none') {
      QuestionData = getQuestionData;
    }
    else {
      QuestionData = getQuestionFilterData;
    }





    let EmpFilterData2 = []
    if (EmpFilterData && EmpFilterData.length > 0) {
      EmpFilterData.forEach((emp) => {
        if (emp.name !== 'ManagerPID') {
          EmpFilterData2.push(emp);
        }
      })
    }

    let isDisable = true;
    //  let DemographicDataScore = [];
    //  let DemographicDataDistribution = [];

    if (FilterValues && FilterValues.length > 0) {

      if (isDisable) {
        FilterValues.forEach((item) => {
          if (item.value === 'All') {
            isDisable = false
          }
        });
      }


      //  DemographicDataScore=getDemographicIndexMultiFilterData;
      //  DemographicDataDistribution=getIndexDistributionData;

    }

    return (
      <>
        <div className=" px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b py-3 h-10">
          <Filter
            showFilter={this.state.showFilter}
            showValue={this.state.showValue}
            closeFilter={this.closeFilter}

            EmpFilterData2={EmpFilterData2}
            getIndexFilterData={getIndexFilterData}
            handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
          />
          <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        </div>


        {true ?
          <>

            <div className="xl:flex lg:flex md:flex justify-start items-center">
              {/* 
        <Commentary indexType={indexType} optionType={optionType} getCommentData={getCommentData} />

        <Filter 
          EmpFilterData2={EmpFilterData2}
          getIndexFilterData={getIndexFilterData}
          handleMultifilterIndexScore={this.handleMultifilterIndexScore2}
        /> */}



            </div>

            {FilterValues && FilterValues.length > 0 ?
              <div className=" px-4 xl:flex lg:flex md:flex justify-left items-center bg-white border-b py-3 h-10 ">
                {FilterValues && FilterValues.length > 0 ? FilterValues.map((element) =>
                  <p className="text-sm mx-2 my-4  flex"><p className="text-blue-500 mr-1">{element.level + ": "}</p><p className="text-gray-500 ">{element.value}</p></p>
                ) : null}</div>
              : null}



            <div onClick={() => this.closeFilter(false)} className=" " >
              <div className="flex w-full" >


                <div className="bg-white rounded w-full shadow-lg px-4 m-4">
                  <div className="flex justify-between">
                    <div className="text-lg font-semibold py-4">Detailed Distribution</div>
                    <div className="text-lg font-semibold py-4 flex px-2">

                      {/* <div className={colorTheme===1?"cursor-pointer flex mx-2 border border-gray-400  px-1 pt-1.5":"cursor-pointer flex mx-2 border border-white px-1 pt-1.5"}  onClick={()=>this.changeColorTheme(1)}>
            <div style={{width:"12px",height:"12px",background:"#e60000"}}/>
            <div style={{width:"12px",height:"12px",background:"#33cc33"}}/>
          </div>  
          <div className={colorTheme===2?"cursor-pointer flex mx-2 border border-gray-400 px-1 pt-1.5":"cursor-pointer flex mx-2 border border-white px-1 pt-1.5"} onClick={()=>this.changeColorTheme(2)}>
            <div style={{width:"12px",height:"12px",background:"#ff6600"}}/>
            <div style={{width:"12px",height:"12px",background:"#336699"}}/>
          </div> */}

                    </div>
                  </div>
                  <div class=" whitespace-nowrap w-full   mb-4" >
                    <table class="w-full  ">
                      <thead>
                        <tr className=" text-xs   text-gray-800">
                          <th className="py-2 px-4 font-bold"><div class="flex items-center justify-center">Question</div></th>
                          <th className="py-2 px-4 font-bold"><div class="flex items-center justify-center">Distribution (%)</div></th>
                        </tr>
                      </thead>
                      <tbody>
                        {QuestionData && QuestionData.length > 0 ?
                          QuestionData.map((question, index) =>
                            <tr key={index} className="bg-white  mb-10 lg:mb-0 text-xs text-gray-800">
                              <td className="tooltip-response-question1 px-2  w-2/12">

                                <div className="cursor-pointer whitespace-pre-line w-96">{question.name}</div>
                                {/* <div className="tooltip-response-question2">
                              {question.name}
                            </div> */}
                              </td>
                              <td className="  w-10/12" >
                                <div className="tooltip-response1">
                                  {CountRestrict(question.count)?
                                  <div className="text-purple-500 font-semibold text-center p-3">NaN</div>
                                  :
                                  <QuestionStack colorTheme={colorTheme} question={question} currFilterValue={this.state.currFilterValue} currFilterName={this.state.currFilterName} selectedFilterValue={selectedFilterValue} getQuestionFilterData={getQuestionFilterData} />
                                  }
                                </div>
                              </td>

                            </tr>
                          )
                          : null}

                      </tbody>
                    </table>
                  </div>
                  <div className="py-4 text-" style={{color:"#3366ff"}}>* We are not showing data where employee count is less than 5</div>
                </div>
              </div>
            </div>
          </>
          :
          <>
            {!loading ?
              <div className="text-center text-3xl text-gray-400 pt-40">We have not calculated this section!</div>
              : null}
          </>
        }
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(QuestionAnalysis);
