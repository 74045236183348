import React, { Component } from 'react';
import { connect } from 'react-redux';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Select2  from 'react-select';
import { SketchPicker } from 'react-color';

class WelcomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }
  componentDidMount() {
  }





 
  render() {
    let { SurveyTemplateId,welcomeFormData,imageWebLinkLogo,imageWebLink,
    parameterListOptions, parameterDefault, orgListOptions, orgDefault,
    handleWelcomeTextInput,handleInstructionTextInput,handleThankyouTextInput,
    handleSurveyParameterList,handleSurveyorgList,handleChangetextColor,handleChangebgColor,handlebgColorImage,
    handleFile, handleUpload, handleImageRemove, handleFileLogo,handleUploadLogo, handleLogoRemove,handlebgType,
    saveWelcomePage,handleChangeColorValue,handleInstructionCheck, instructionCheck,

    handleColorCode, ColorCodeList, selectColorType
    } = this.props;


    console.log('welcomeFormData.logo: ',imageWebLinkLogo, welcomeFormData.logo)

 



    return (
      <>
<main className="xl:px-6 lg:px-6 md:px-6 px-2 bg-gray-100">
  {/* <div className="cursor-pointer item-end" onClick={()=>saveWelcomePage()}>save</div> */}
    <div className="container py-12 xl:w-2/3 xl:px-12 lg:w-2/3 lg:px-8  md:w-2/3 md:px-0 px-2 w-full mx-auto">
      {/*  */}




      <div className="my-2">
        {/* <input type="text" name placeholder="Title here.." className="py-4 px-4 w-full border border-gray-300 outline-none" /> */}
      </div>
      {/* <div className="border border-gray-300 flex justify-center py-20 bg-gray-300 my-2">
        <div className="text-center text-gray-700">
          <i className="fas fa-image text-3xl" />
          <p className="text-sm">Click to add Image</p>
          <p className="text-xs py-1">jpg, png and svg</p>
        </div>
      </div> */}
            <div className="my-10"/>

      <div className="my-4 ">
        {/* <div className="py-1.5 px-4 bg-gray-200 flex border-gray-300  border-b">
          <span className="fa fa-bold text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Bold text" />
          <span className="fa fa-italic text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Italic text" />
          <span className="fa fa-underline text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Underline text" />
          <div className="border-l border-gray-300 mx-2" /> 
          <span className="fas fa-list-ul text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Point bullets" />
          <span className="fa fa-list-ol text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Numbers bullets" />
          <div className="border-l border-gray-300 mx-2" /> 
          <span className="fa fa-link text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Insert/edit link" />
        </div> */}
        {/* <textarea type="text" placeholder="Survey dicrption" className=" w-full h-60 px-4 py-4 outline-none resize-none" defaultValue={""} />  */}
        <div className="text-black font-semibold text-md">Welcome Text</div>
        <ReactQuill className="bg-white" theme="snow" value={welcomeFormData.welcomeText} onChange={handleWelcomeTextInput} placeholder="Survey description"/>

      </div>

      <div className="my-4">
        <div className="text-black font-semibold text-md">Instruction Text
        <input className="mx-2 mt-2" type="checkbox" checked={welcomeFormData.isInstruction}  onClick={()=>handleInstructionCheck(welcomeFormData.isInstruction)} onChange={()=>{}}/>
        </div>
        <ReactQuill className="bg-white" theme="snow" value={welcomeFormData.instructionText} onChange={handleInstructionTextInput}/>
      </div>

      <div className="my-4">
        <div className="text-black font-semibold text-md">Thankyou Text</div>
        <ReactQuill className="bg-white" theme="snow" value={welcomeFormData.thankyouText} onChange={handleThankyouTextInput}/>
      </div>

      <div className="my-10"/>


      

{/* 
      {SurveyTemplateId!==2?
      
        <>

      <div className="my-4">
                      <div className="text-black font-semibold text-sm">Selected Index</div>
                              <Select2
                                  id="parameterList"
                                  isMulti
                                  name="parameterList"
                                  isDisabled={true}
                                  defaultValue={(parameterDefault)?parameterDefault.map((val)=>{return val}):""}
                                  onChange={handleSurveyParameterList}
                                  options={parameterListOptions}
                                  className="basic-multi-select text-sm"
                                  classNamePrefix="Activities"
                                />
      </div>



      


      <div className="my-4">
                      <div className="text-black font-semibold text-sm">Selected Organization Core Drivers</div>
                      <Select2
                                  id="orgList"
                                  isMulti
                                  name="orgList"
                                  isDisabled={true}
                                  defaultValue={(orgDefault)?orgDefault.map((val)=>{return val}):""}
                                  onChange={handleSurveyorgList}
                                  options={orgListOptions}
                                  className="basic-multi-select text-sm"
                                  classNamePrefix="Activities"
                                  
                                />
      </div>
      </>
      :null} */}




      {/* <div className="mt-4 text-black font-semibold text-sm">Select Background Color/Image</div>
      <div className="bg-white pr-2 border xl:block lg:block md:block block">


            <select onChange={(e)=>handlebgType(e.target.value)} className="w-full py-2 text-xs text-black outline-none font-normal pl-2">
              <option value={1} className="text-xs py-2" style={{color:welcomeFormData.bgType===1?'blue':'black'}}>Background Color</option>
              <option value={2} className="text-xs py-2" style={{color:welcomeFormData.bgType===2?'blue':'black'}}>Background Image</option>
            </select>
            
      </div> */}


      <div className="my-10"/>




      {/* <div className="my-2"><input type="text" name placeholder="Button title.." className="py-4 px-4 w-full border border-gray-300 outline-none" /></div> */}
      {/* <div className="border border-gray-300 flex justify-center py-4 bg-gray-300 my-2">
        <div className="text-center text-gray-700">
          <i className="fas fa-image text-3xl" />
          <p className="text-sm">Click to add Logo</p>
        </div>
      </div> */}
      {/* <div className="border border-gray-300 my-2">
        <div className="py-1.5 px-4 bg-gray-200 flex border-gray-300  border-b">
          <span className="fa fa-bold text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Bold text" />
          <span className="fa fa-italic text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Italic text" />
          <span className="fa fa-underline text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Underline text" />
          <div className="border-l border-gray-300 mx-2" /> 
          <span className="fas fa-list-ul text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Point bullets" />
          <span className="fa fa-list-ol text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Numbers bullets" />
          <div className="border-l border-gray-300 mx-2" /> 
          <span className="fa fa-link text-xs p-1  mx-1 border border-transparent hover:bg-gray-100 hover:border-gray-300" title="Insert/edit link" />
        </div>
        <textarea type="text" placeholder="Text here.." className=" w-full h-24 px-4 py-4 outline-none resize-none" defaultValue={""} /> 
      </div> */}
    </div>
  </main>
      </>
    );
  }
}
function mapStateToProps(state) {
  console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey } = state;
  return {
    loggingIn,
    survey
  };
}
export default connect(mapStateToProps)(WelcomePage);
