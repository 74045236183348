import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class StackChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  openChartList=()=>{
    if(this.state.showChartList){
      this.setState({showChartList:false})
    }
    else{
      this.setState({showChartList:true})
    }
  }

  handleChartType=(item)=>{
    this.setState({chartTypeName:item})
  }

  render() {
    let { CountRestrict, getDemographicIndexData, currFilterName, getIndexScore ,indexType} = this.props;

    let nameList=[]
    let scoreList=[]

    let isDataAvailable = false;

    if(getIndexScore && getIndexScore[indexType]){
      nameList.push({
        value:"Overall Score",
        textStyle: {fontWeight:'bold'}
      });
      scoreList.push({
        value:getIndexScore[indexType].toFixed(2),
        itemStyle: {color: '#00cc66'}
      });

    }

    let tempSortData = []
    if(getDemographicIndexData && getDemographicIndexData.length>0){
      getDemographicIndexData.forEach((data)=>{
          tempSortData.push(data)
      })
    }
    tempSortData.sort((a,b)=>a.name.charCodeAt(0) - b.name.charCodeAt(0));


    if(tempSortData && tempSortData.length>0){
      tempSortData.forEach((data)=>{

        if(data && data[indexType]){
          let dataScore = CountRestrict(data.count)?"NaN":data[indexType].toFixed(2);

          nameList.push({
            value:data.name,
            textStyle:{fontWeight:'bold'}
          });
          scoreList.push({
            value:dataScore,
            itemStyle: {color: '#8f34eb'}
          });
          isDataAvailable =  true;

        }
        else{
          isDataAvailable =  false;

        }


      });
    }

    console.log('isDataAvailable: ',isDataAvailable)


    return (
      <>
         {/* <div className="stack-title">
            {currFilterName} 
          </div> */}
          <div className="stack-body">
              {isDataAvailable?
              <>
                            <ReactEcharts
                            style={{height:(nameList.length<4)?'200px':(nameList.length<5)?'300px':(nameList.length>20)?(nameList.length*60).toString()+'px':(nameList.length*50).toString()+'px',
                            marginTop:'-60px'
                          }}
                            option={{
                              tooltip: {
                                  trigger: 'axis',
                                  axisPointer: {     
                                      type: 'shadow'        
                                  },
                                  formatter: function(a,b,c,d){
                                    return (a && a[0] && a[0].name)?a[0].name+' = '+a[0].value+'':''
                                  } 


                              },
                              legend: {
                                data:[]
                              },
                              grid: {
                                  left: '3%',
                                  right: '4%',
                                  bottom: '3%',
                                  containLabel: true
                              },
                              xAxis: {
                                  type: 'value',
                                  splitLine: {
                                    lineStyle: {
                                        color: '#f2f2f2'
                                    }
                                },
                                axisLine: {
                                  lineStyle: {
                                    color:'#e6e6e6'
                                  }
                                },
                                max:100                            
                              },
                              yAxis: {
                                  type: 'category',
                                  data: nameList.reverse(),
                                  axisLine: {
                                    lineStyle: {
                                      color:'#e6e6e6'
                                    }
                                  }
                              },
                              axisLabel: {
                                color: "#000",
                                interval: 0,
                                formatter: function(value) {
                                  if (value.length > 15) {
                                    return value.substring(0, 15) + "..";
                                  } else {
                                    return value;
                                  }
                                }
                              },
                              series: [
                                  {
                                      name: 'Score',
                                      type: 'bar',
                                      barWidth: '20px',
                                      stack: '总量',
                                      label: {
                                          show: true,
                                          position: 'insideRight'
                                      },
                                      data: scoreList.reverse()
                                      
                                  },
                                 
                              ]
                          }}
                          />
                </>
              :
              <div className="text-center text-gray-300 text-xl">
                Data Not Available!
              </div>
              }
          </div>
     

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(StackChart);
