import React, { Component } from 'react';
import { connect } from 'react-redux';
import SummaryData from './Components/SummaryData/SummaryData';
import Behaviour from './Components/Behaviour/Behaviour';
import ResponseDistribution from '../Components/ResponseDistribution/ResponseDistribution';
import DimensionPriorities from './Components/DimensionPriorities/DimensionPriorities';
import DriverComparision from './Components/DriverComparision/DriverComparision';

class IndexMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }

  onSwitchOptionScreen = () => {
    let { name, XAxis, GetParameterLabel,CountRestrict,getIndexQuantileCount,
      indexType, loading, optionType, EmpFilterData, getIndexFilterData, cronbatchAlphaVarianceData, getCommentData, handleMultifilterIndexScore, getDemographicIndexMultiFilterData,

      getAllBenchMarkData, getRespondantDetailsData, getIndexScore, handleSummaryFilter, handleSummaryCard, getDemographicIndexData, getIndexData, getIndexDistributionData,
      getQuestionData, getQuestionFilterData, handleQuestionFilter,
      getDistributionIndexData, handleDistributionFilter, getIndexDriverData, getIndexQuantileData, handleQuantileFilter,



      handleMultifilterBehaviour, handleMultiFilterGetQuestion, handleMultiFilterQuantile

    } = this.props;

    switch (optionType) {

      case 1: return <SummaryData CountRestrict={CountRestrict} name={name} loading={loading} handleMultifilterIndexScore={handleMultifilterIndexScore} getDemographicIndexMultiFilterData={getDemographicIndexMultiFilterData}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}

        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData} cronbatchAlphaVarianceData={cronbatchAlphaVarianceData}
        getAllBenchMarkData={getAllBenchMarkData} getRespondantDetailsData={getRespondantDetailsData} getIndexScore={getIndexScore} getIndexDistributionData={getIndexDistributionData} handleSummaryFilter={handleSummaryFilter} handleSummaryCard={handleSummaryCard} getDemographicIndexData={getDemographicIndexData} getIndexData={getIndexData} />

      case 2: return <Behaviour CountRestrict={CountRestrict} GetParameterLabel={GetParameterLabel} name={name} loading={loading} handleMultifilterBehaviour={handleMultifilterBehaviour}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getIndexDriverData={getIndexDriverData} getDistributionIndexData={getDistributionIndexData} handleDistributionFilter={handleDistributionFilter}
      />

      case 3: return <DimensionPriorities CountRestrict={CountRestrict} name={name} GetParameterLabel={GetParameterLabel} loading={loading} handleMultiFilterGetQuestion={handleMultiFilterGetQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}

        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter} />

      case 4: return <ResponseDistribution CountRestrict={CountRestrict} XAxis={XAxis} loading={loading} handleMultiFilterGetQuestion={handleMultiFilterGetQuestion}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} getQuestionFilterData={getQuestionFilterData} handleQuestionFilter={handleQuestionFilter} />

      case 5: return <DriverComparision CountRestrict={CountRestrict} name={name} GetParameterLabel={GetParameterLabel} XAxis={XAxis} loading={loading} handleMultiFilterQuantile={handleMultiFilterQuantile}
        indexType={indexType} optionType={optionType} getCommentData={getCommentData}
        EmpFilterData={EmpFilterData} getIndexFilterData={getIndexFilterData}
        getQuestionData={getQuestionData} handleQuantileFilter={handleQuantileFilter} handleQuestionFilter={handleQuestionFilter}


        getIndexQuantileData={getIndexQuantileData}
        getIndexQuantileCount={getIndexQuantileCount}


      />



      default: return <div>Default Option</div>
    }
  }

  render() {
    let { onOptionType, optionType, CurrentSurveyId, indexType, name } = this.props;
    return (
      <>
        <main className="flex-1 w-full bg-gray-100  overflow-hidden overflow-y-auto ">
          <div className="px-4 xl:flex lg:flex md:flex  justify-between items-center bg-white border-b h-10">
            <div className="cursor-pointer text-xs font-medium flex text-gray-600 whitespace-nowrap overflow-x-auto overflow-hidden">
              <span onClick={() => onOptionType(CurrentSurveyId, indexType, 1)} className={(optionType === 1) ? "py-3 block px-4 text-blue-600 border-b-2 border-blue-500" : "py-3 block px-4"}>{name} Summary</span>
              <span onClick={() => onOptionType(CurrentSurveyId, indexType, 2)} className={(optionType === 2) ? "py-3 block px-4 text-blue-600 border-b-2 border-blue-500" : "py-3 block px-4"}>{name} Behaviour</span>
              <span onClick={() => onOptionType(CurrentSurveyId, indexType, 3)} className={(optionType === 3) ? "py-3 block px-4 text-blue-600 border-b-2 border-blue-500" : "py-3 block px-4"}>{name} Behaviour-Items</span>
              <span onClick={() => onOptionType(CurrentSurveyId, indexType, 4)} className={(optionType === 4) ? "py-3 block px-4 text-blue-600 border-b-2 border-blue-500" : "py-3 block px-4"}>Detailed Distribution</span>
              <span onClick={() => onOptionType(CurrentSurveyId, indexType, 5)} className={(optionType === 5) ? "py-3 block px-4 text-blue-600 border-b-2 border-blue-500" : "py-3 block px-4"}>{name} Behaviour-Range</span>
            </div>
          </div>
          {this.onSwitchOptionScreen()}
        </main>


      </>
    );
  }
}
function mapStateToProps(state) {
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexMain);
