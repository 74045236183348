import React from "react";
import moment from "moment";

export default function Item(props) {
let { CurrentManagerData, getSurveyData  } = props;

  return (
    <>
      <section className=" space-y-2 gap-5 px-10 pt-12">



<header className="bg-white ">
    <div className="flex justify-between capitalize w-12/12 mx-auto py-6 pt-3 space-y-1">
      
      
      <h1 className="text-xl text-white font-bold">
      {getSurveyData && getSurveyData.logo?
        <img src={getSurveyData && getSurveyData.logo?getSurveyData.logo:""}  style={{height:'90px'}}/>
        :null}
      </h1>

      <h1 className="text-xl text-white font-bold">
        <img src={"img/logo/happypluslogo.png"}  style={{height:'60px'}}/>
      </h1>

      
    </div>
  </header>


  
  <main className="text-left mx-auto w-full pb-4 ">
    <h1 className="text-left text-3xl text-gray-700 font-normal pb-2 ">Action Planning Process</h1>
    <div className="flex items-center py-4 space-x-8">
      <div> <h1 className="text-2xl text-indigo-700">Effective Action Planning &amp; Execution</h1>
      </div>
    </div>
    <div className="flex space-x-12">
      <div className="space-y-6 w-3/6">
        <div className="h-40">
          <h1 className="text-sm font-bold pb-4">REFLECT</h1>
          <ul className="list-disc px-4 text-gray-600 text-sm">
            <li> Are these results surprising for me?</li>
            <li> What have I done to impact the
              engagement of my team? </li>
            <li> What do these results mean for our
              performance?</li>
          </ul>
        </div>
        <div className>
          <h1 className="text-sm font-bold pb-4">REVIEW</h1>
          <ul className="list-disc px-4 text-gray-600 text-sm">
            <li> Track the progress made on
              agreed intervals</li>
            <li> Club tracking processes with
              existing business reviews to
              ensure adequate rigor </li>
          </ul>
        </div>
      </div>
      <div className="space-y-6 w-3/6">
        <div className="h-40">
          <h1 className="text-sm font-bold pb-4">PLAN</h1>
          <ul className="list-disc px-4 text-gray-600 text-sm">
            <li> Actions I will take to improve the
              engagement of my team</li>
            <li>Sharing the findings, identifying
              root cause and outlining plans
              for improvements with the team </li>
            <li> Fix accountabilities</li>
          </ul>
        </div>
        <div>
          <h1 className="text-sm font-bold pb-4">ACT</h1>
          <ul className="list-disc px-4 text-gray-600 text-sm">
            <li> Leveraging team strengths</li>
            <li> Working on identified action
              items as per the agreed plan</li>
          </ul>
        </div>
      </div>
      <div className="space-y-6 w-full">
        <div className="h-40">
          <h1 className="text-sm font-bold pb-4">Action planning process approach:</h1>
          <ul className="list-decimal px-4 text-gray-600 text-sm leading-loose">
            <li> Reflect</li>
            <li> Plan</li>
            <li>Act</li>
            <li>Review</li>
          </ul>
        </div>
        <div>
          <h1 className="text-sm font-bold pb-4">REFLECT</h1>
          <p className=" text-gray-600 text-sm">
            Start with Reflect by understanding the employee engagement result for
            your team and especially, for you as a manger. Next step is to Plan for the
            actions that you would take for your team and how would you share the
            findings and fix accountabilities. Act accordingly by leveraging team
            strengths and working on the identified actions and finally, Review and track
            your progress on action plans.
          </p>
        </div>
      </div>
    </div>
  </main>
</section>


    </>
  );
}







