import React, { Component } from 'react';
import { connect } from 'react-redux';
// import ReactEcharts from "echarts-for-react";
import StackChart from "./Chart/StackChart";

class IndexMeanOverall extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount() {

  }


  render() {
    let { OverviewList,cardType, getMeanScoreData,ItemSaveData, currFilterName, getMeanScoreQwiseDataUpdate } = this.props;

    console.log('getMeanScoreQwiseDataUpdate: ',getMeanScoreQwiseDataUpdate);

    let DriverList = []
  
    if(getMeanScoreQwiseDataUpdate && getMeanScoreQwiseDataUpdate.length>0){
      getMeanScoreQwiseDataUpdate.forEach((item,index)=>{
        let getIndex2 = DriverList.findIndex(prev=>prev.driverName===item.parameterDimensionName);

        if(getIndex2===-1){
          let temp = {
            "driverName":item && item.parameterDimensionName?item.parameterDimensionName:"Driver "+index.toString(),
            "driverMean":item && item.score?item.score:0
          }
          DriverList.push(temp);
        }


      })
    }


    console.log('DriverList: ',DriverList)
    // console.log('ItemSaveData: ',ItemSaveData)
    // console.log('currFilterName: ',currFilterName)

    let indexName = '';
    if(ItemSaveData && ItemSaveData.name){
      indexName = ItemSaveData.name;
    }

    return (
      <>
                    <div className="bg-white rounded w-full shadow-lg px-4 justify-center">
                      <div className="px-1 py-4 text-base text-gray-700 font-semibold flex justify-between items-center">
                        <h1 className="py-4">
                        <p className="text-base text-gray-700 font-semibold">Overall {cardType==="overall"?"Index":indexName==='OrganizationCore'?"Driver":"Dimension"} Wise Mean <span className="text-blue-500">{(currFilterName!=='none')?"( "+currFilterName+" wise )":""}</span></p> 
                        </h1> 
                      </div>

                      <StackChart 
                        OverviewList={OverviewList}

                        DriverList={DriverList}

                        ItemSaveData ={ItemSaveData}
                        getMeanScoreData={getMeanScoreData}
                        currFilterName={currFilterName}
                      />

                    </div>

      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { users, dashboard } = state;
  return {
    loggingIn,
    users,
    dashboard
  };
}
export default connect(mapStateToProps)(IndexMeanOverall);
