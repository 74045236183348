import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactEcharts from "echarts-for-react";

class DriverRadar extends Component {
    constructor(props) {
        super(props);
        this.state = {
    
      
        }
      }
    
      componentDidMount() {
    
      }
    
    
      render() {
        let { IndexDriverData, getQuestionData, Name, ManagerDynaObj, EmpFilterData, CurrentManagerData, ManagerIndexDriverData,DemographicDriverManagerData} = this.props;
        
        



        // let ManagerDimensionList = []
        
        // if(EmpFilterData && EmpFilterData.length>0){
        //     EmpFilterData.forEach((filter)=>{
        //         ManagerDimensionList.push({
        //             "filterName":filter.name,
        //             "filterValue":CurrentManagerData[filter.name]
        //         })
        //     })
        // }

        // let FieldList = []
        // if(ManagerDimensionList && ManagerDimensionList.length>0){
        //     ManagerDimensionList.forEach((value)=>{
        //         if(ManagerDynaObj && ManagerDynaObj.length>0){
        //             ManagerDynaObj.forEach((obj)=>{

        //                 if(obj.name === value.filterValue){
        //                     let data ={
        //                         ...obj,
        //                         level:value.filterName
        //                     }
        //                     FieldList.push(data)
        //                 }
        //             })
        //         }
        //     })
        // }
    

    
        // let paraList = []
    
        // let count = 0;
        // let rowSpan = 0;
        // let NewParaList=[]
    
        // if(getQuestionData && getQuestionData.length>0){
    
        //   getQuestionData.forEach((ques)=>{
        //     if(paraList.findIndex(prev=>prev===ques.parameterDimensionName)===-1){
        //       paraList.push(ques.parameterDimensionName);
        //       count+=1
        //     }
        //     else{
        //     }
        //   })
    
        //   let obj = {}
        //   paraList.forEach((para)=>{
        //     obj[para]=0
        //     getQuestionData.forEach((ques)=>{
        //       if(para===ques.parameterDimensionName){
        //         obj[para]+=1
        //       }
        //     })
        //   })
    
    
        //   NewParaList = Object.keys(obj).map((key) => [key, obj[key]])
        // //   console.log('data NewParaList: ',NewParaList)
    
        //   rowSpan=(getQuestionData.length/count)
    
        //   // console.log('Your Engagement paraList: ',NewParaList,count,rowSpan,getQuestionData.length)
    
        // }
        

        // console.log('>>>>NewParaList; ',NewParaList)
        // console.log('>>>>FieldList; ',FieldList)
        console.log('>>>>DemographicDriverManagerData; ',DemographicDriverManagerData)

        let ParameterObj = {}
        if(DemographicDriverManagerData&& DemographicDriverManagerData.length>0){
          ParameterObj = DemographicDriverManagerData[0]
        }
        let ParameterList = [] 
        if(Object.keys(ParameterObj) && Object.keys(ParameterObj).length>0){
          Object.keys(ParameterObj).forEach((item)=>{
            if(item!=='name' && item!='id'){
              var newStr = item.slice(0, item.length - 3)
              ParameterList.push(newStr);
            }
          });
        }

        console.log('>>->>,><<.---->>ParameterList; ',ParameterList)




        let RadarValueList = []

        let TempData = {
          value: [],
          name: "Overall"
        }
        if(IndexDriverData){
          let DriverScoreList = Object.keys(IndexDriverData);
          if(DriverScoreList && DriverScoreList.length>0){
            DriverScoreList.forEach((para)=>{
              const driverName = para.slice(0,-5);
              if(driverName){
 
               if(IndexDriverData&&IndexDriverData[para]){
                 TempData["value"].push(IndexDriverData && IndexDriverData[para]?IndexDriverData[para].toFixed(2):0)
               }
               else{
                 TempData["value"].push(0)
               }
 
              }
            });
          }
         }
 
         RadarValueList.push(TempData)





        if(DemographicDriverManagerData && DemographicDriverManagerData.length>0){
          DemographicDriverManagerData.forEach((item, itemIndex)=>{
              let data = {
                value: [],
                name: item.name
              }
              if(ParameterList && ParameterList.length>0){
                ParameterList.forEach((driver, driverIndex)=>{
                  if(item[driver+"Avg"]){
                    data["value"].push(item[driver+"Avg"].toFixed(2))
                    // console.log(': ',item.name+"Avg"+" > "+driver+"Avg"+" > "+item[driver+"Avg"])
                  }
                  else{
                    data["value"].push("NaN")
                    // console.log(': ',item.name+"Avg"+" > "+driver+"Avg"+" > "+item[driver+"Avg"])
                  }
                })
              }
              // console.log('===================================')

              RadarValueList.push(data)
              console.log('===================================',data)

          })
        }

        // console.log('===================================RadarValueList: ',RadarValueList)


        let ParaNameList = []
        if(ParameterList && ParameterList.length>0){
          ParameterList.forEach((driver, driverIndex)=>{
            ParaNameList.push({
              text: driver, 
              max: 100
            })
          })
        }
    


        return (
          <>    
          <div className="py-4 pb-8 ">
          <ReactEcharts
            style={{height:"500px"}}
            option={{
              aria: {
                show: true
              },
              tooltip: {
                show: true,

                  trigger: 'item',
                  // formatter: function(a) {
                  //   console.log('a:',a)
                  //       return a.name + ;
                    
                  // }
              },
              radar: [
                  {
                      indicator: (function (a){
                          var res = ParaNameList;
                          return res;
                      })(),
                      // radius: 140
                  }
              ],
              series: [
                  {
                      type: 'radar',
                      tooltip: {
                          trigger: 'item'
                      },
                      label: {
                        show: false,
                        position: 'insideRight',
                        formatter: function(d) {
                          console.log('d:',d)

                              return ParaNameList[d.dimensionIndex];
                          
                        }
                      },
                      // areaStyle: {normal: {}},
                      data: RadarValueList
                  }
              ]
          }}
                            
          />
          </div>
          </>
        );
      }
    }
    function mapStateToProps(state) {
      // console.log("state  ", state);
      const { loggingIn } = state.authentication;
      const { users, dashboard } = state;
      return {
        loggingIn,
        users,
        dashboard
      };
    }
export default connect(mapStateToProps)(DriverRadar);
