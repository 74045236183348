import React, { Component } from 'react';
import { connect } from 'react-redux';
import { userActions, surveyActions, questionsActions, dashboardActions } from '../../../_actions';
import { confirmAlert } from 'react-confirm-alert';
import { accessModule } from '../../../_helpers';
import Select2  from 'react-select';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


import Header from '../Header/Header';
import moment from 'moment';


import WelcomePage from './Components/WelcomePage/WelcomePage';
import LogicPage from './Components/LogicPage/LogicPage';
import DashboardDesign from './Components/DashboardDesign/DasboardDesign';
import DesignPage from './Components/DesignPage/DesignPage';

import SingleChoice from './Components/QuestionType/SingleChoice';
import TextBox from './Components/QuestionType/TextBox';
import MultilineText from './Components/QuestionType/MultilineText';
import RatingScale from './Components/QuestionType/RatingScale';
import NPS from './Components/QuestionType/NPS';
import NPSV2 from './Components/QuestionType/NPSv2';
import Email from './Components/QuestionType/Email';
import Number from './Components/QuestionType/Number';
import Date from './Components/QuestionType/Date';
import YesNo from './Components/QuestionType/YesNo';
import Dropdown from './Components/QuestionType/Dropdown';
import Statement from './Components/QuestionType/Statement';
import Matrix from './Components/QuestionType/Matrix';
import GroupQuestions from './Components/QuestionType/GroupQuestions';
import LocationQuestions from './Components/QuestionType/Location';
import MultipleChoice from './Components/QuestionType/MultipleChoice';



import Preview from './Components/Preview/Preview';
import LoadingOverlay from 'react-loading-overlay';
import DeleteSurveyConfirm from './Components/Modal/DeleteQuestionConfirm';

import Sidebar from '../Survey/Components/Sidebar/Sidebar';

import QuestionRestrictionModal from './Components/Modal/QuestionRestrictionModal';
import CustomQueLibrary from './Components/CustomQueLibrary/CustomQueLibrary';


class AddQuestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showAddQuestion: true,
      showWelcomePage: false,
      showPreviewPage: false,
      showTranslatePage: false,
      showDesignPage: false,
      showLogicPage: false,
      showDashboardDesignPage: false,

      showDeleteQueModal: false,
      CurrentQuestionData: '',
      CurrentQuestionName: '',
      currQuesTranslEditId: '',
      showPleaseSaveQuesModal: "",

      showIndexFilter: false,
      selectedIndexFilterValue: "",
      ShowTranslWelcome:false,

      showTranslAttributes:false,

      showOptionHeading:false,

      translQuestion:{},
      translSubQuestionsMatrix:{},

      translSurvey:{},


      currQuesEditId: '',
      welcomeFormData: {
        "bgType": 1,
        "designTemplate":"v2"
      },

      showBucketDropdown: false,
      showDimensionDropdown: false,

      paraBucketName: '',
      paraDimensionName: '',

      questionTypeTemplate: '',

      showQuestionTypeList: false,

      instructionCheck: true,

      expiryformData: {
        "id": "",
        "isExpire": "",
        "expireTime": moment().format("YYYY-MM-DD")
      },
      showParameterModal: false,

      showEditSurveyName: false,
      surveyUpdateFormData: '',
      imageWebLinkLogo: '',
      imageWebLinkLogo2: '',
      dashQuesTypeRank:{},

      ColorCodeList: [
        {
          "name": 'Question and Survey Text',
          "value": 'textColor',
          "no": 1
        },

        {
          "name": 'Answer Background',
          "value": 'OptionColor1',
          "no": 3
        },
        {
          "name": 'Answer Text',
          "value": 'OptionColor2',
          "no": 4
        },
        {
          "name": 'Selected Answer',
          "value": 'OptionColor3',
          "no": 5
        },
        {
          "name": 'Button Background',
          "value": 'buttonColor1',
          "no": 6
        },
        {
          "name": 'Button Text',
          "value": 'buttonColor2',
          "no": 7
        },
        {
          "name": 'Survey Background',
          "value": 'bgColor',
          "no": 2
        }
      ],
      selectColorType: 'textColor',

      showSidebar: false,

      CrrRoute: "survey",

      crrSurvey: "",
      tempSaveQues: {},



      CurrentLanguage: 'en',
      CurrentLanguageName: 'English',


      language: 'hi',
      CurrTranslShortName:'',


      showLanguageModal: false,
      showQuestionLibraryModal: false,
      QuestionLibraryFinalList: [],
      questionTypeTemplateGroup:'',
      logicQuesId:"", 
      logicOptions:[]


    }
  }
  componentDidMount() {
    this.props.dispatch(dashboardActions.getClientProfile());


    let dataX = {
      "keyWord": "",
      "pageNo": 1,
      "size": 500
    }
    this.props.dispatch(dashboardActions.getAllParameterList(dataX));


    let data = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(surveyActions.getSurveyById(data));
    this.props.dispatch(surveyActions.getQuestionTypeList());
    this.props.dispatch(questionsActions.getQuestionListBySurveyID(data));

    let data1 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 150
    }
    this.props.dispatch(surveyActions.getParameterList(data1));


    let data2 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }

    this.props.dispatch(surveyActions.getLanguageLabel(data2));
    this.props.dispatch(surveyActions.getLanguage(data2));


    let data5 = {
      "keyWord": "",
      "pageNo": 1,
      "size": 100
    }
    this.props.dispatch(surveyActions.getQuestionLibraryList(data5));


  }
  static getDerivedStateFromProps(nextProps, prevState) {
    console.log("nextProps: ",nextProps)
    if (nextProps.user.logoUploadSuccess) {
      return {
        ...nextProps,
        imageWebLinkLogo: nextProps.user.filesDetailsLogo.imageUrl
      }
    }
    if (nextProps.user.logo2UploadSuccess) {
      return {
        ...nextProps,
        imageWebLinkLogo2: nextProps.user.filesDetailsLogo2.imageUrl
      }
    }
    if (nextProps.user.imageUploadSuccess) {

      return {
        ...nextProps,
        imageWebLink: nextProps.user.filesDetails.imageUrl
      }

    } else {
      return {
        ...nextProps
      }
    }

  }

  // onAddQuestion=(check)=>{
  //   this.setState({
  //       showAddQuestion:!check,
  //       showWelcomePage:false
  //   })
  // }

  handleShowQuestionType = (check) => {
    console.log('check:', check)
    this.setState({ showQuestionTypeList: !check })
  }

  handleQuestionType = (quesType) => {
    this.setState({
      questionTypeTemplate: quesType,
      showQuestionTypeList: false
    })
  }

  handleQuestionTypeGroup = (quesType) => {
    this.setState({
      questionTypeTemplateGroup: quesType
    })
  }


  handleAddQuestion = (isGroupQuestion, GroupMainId, SubQuesTemplate) => {

    console.log('working', 'W')
    let { questions } = this.props;
    let { questionsList } = questions;
    let { questionTypeTemplate } = this.state;


    let QuesTypeTemplate = questionTypeTemplate.templateId;
    let QuesTypeID = questionTypeTemplate.id;
    let ParentId = null
    
    if(isGroupQuestion){
      QuesTypeTemplate = SubQuesTemplate.templateId;
      QuesTypeID = SubQuesTemplate.id;
      ParentId = GroupMainId;
    }


    if (QuesTypeTemplate && QuesTypeID) {



      let rank = 0;
      if (questionsList && questionsList.length > 0) {
        rank = parseInt(questionsList[questionsList.length-1].rank) + 1;
      }

      console.log("rank========>",rank)

      let Ques = {};
      if (QuesTypeTemplate === 1 ) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId:ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          rank: rank,
          options: [
            { weightage: 6, name: "Strongly Agree" },
            { weightage: 5, name: "Agree" },
            { weightage: 4, name: "Slightly Agree" },
            { weightage: 3, name: "Slightly Disagree" },
            { weightage: 2, name: "Disagree" },
            { weightage: 1, name: "Strongly Disagree" },
          ]
        }
      }
      else if (QuesTypeTemplate === 2 || QuesTypeTemplate === 9) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId:ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          rank: rank,
          options: [
            { weightage: 1, name: "Option A" },
            { weightage: 2, name: "Option B" },
            { weightage: 3, name: "Option C" },
            { weightage: 4, name: "Option D" }
          ]
        }
      }
      else if (QuesTypeTemplate === 3 || QuesTypeTemplate === 4 || QuesTypeTemplate === 14 || QuesTypeTemplate === 15 ) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId:ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          rank: rank,
          options: []
        }
      }
      else if (QuesTypeTemplate === 5 ) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId:ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          rank: rank,
          options: [
            { weightage: 1, name: "Star 1" },
            { weightage: 2, name: "Star 2" },
            { weightage: 3, name: "Star 3" },
            { weightage: 4, name: "Star 4" },
            { weightage: 5, name: "Star 5" }
          ]
        }
      }
      else if (QuesTypeTemplate === 10 ) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId:ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "This is a Statement!",
          rank: rank,
          options: []
        }
      }
      else if (QuesTypeTemplate === 11 ) {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId:ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          rank: rank,
          options: [
            { weightage: 1, name: "Yes" },
            { weightage: 0, name: "No" }
          ]
        }
      }
      else {
        Ques = {
          questionTypeId: QuesTypeID,
          templateId: QuesTypeTemplate,
          parentId:ParentId,
          isMandatory: false,
          surveyId: this.props.match.params.id,
          name: "Type your question here...",
          rank: rank,
          options: [{ weightage: 1, name: "Option" }]
        }
      }
      console.log('Question Created! ', Ques)
      this.props.dispatch(questionsActions.createQuestion(Ques));



    }


  }

  onPreviewPage = () => {
    let { currQuesEditId } = this.state;
    if (currQuesEditId) {
      this.setState({ showPleaseSaveQuesModal: true });
    }
    else {
      this.setState({
        showWelcomePage: false,
        showAddQuestion: false,
        showPreviewPage: true,
        showTranslatePage: false,
        showDesignPage: false,
        showLogicPage: false,
        showDashboardDesignPage:false

      });
    }
  }


  onTranslatePage = () => {
    this.setState({
      showWelcomePage: false,
      showAddQuestion: false,
      showPreviewPage: false,
      showTranslatePage: true,
      showDesignPage: false,
      showLogicPage: false,
      showDashboardDesignPage:false

    })
  }


  onQuestionPage = () => {
    this.setState({
      showWelcomePage: false,
      showPreviewPage: false,
      showAddQuestion: true,
      showTranslatePage: false,
      showDesignPage: false,
      showLogicPage: false,
      showDashboardDesignPage:false

    })
  }



  onWelcomePage = (screen) => {
    let { survey } = this.props;
    let { getSurveyData } = survey;
    let { currQuesEditId } = this.state;
    let data2 = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(surveyActions.getSurveyById(data2));

    if (currQuesEditId) {
      this.setState({ showPleaseSaveQuesModal: true });
    }
    else {

      if (screen === "welcome") {
        this.setState({
          showAddQuestion: false,
          showPreviewPage: false,
          showWelcomePage: true,
          showTranslatePage: false,
          showDesignPage: false,
          showLogicPage: false,
          showDashboardDesignPage:false
        });
      }
      else if (screen === "design") {

        this.setState({
          showAddQuestion: false,
          showPreviewPage: false,
          showWelcomePage: false,
          showTranslatePage: false,
          showDesignPage: true,
          showLogicPage: false,
          showDashboardDesignPage:false
        });
      }
      else if (screen === "logic") {

        this.setState({
          showAddQuestion: false,
          showPreviewPage: false,
          showWelcomePage: false,
          showTranslatePage: false,
          showDesignPage: false,
          showLogicPage: true,
          showDashboardDesignPage:false
        });
      }
      else if (screen === "translate") {

        this.setState({
          showAddQuestion: false,
          showPreviewPage: false,
          showWelcomePage: false,
          showTranslatePage: true,
          showDesignPage: false,
          showLogicPage: false,
          showDashboardDesignPage:false
        });
      }
      else if (screen === "dashboardDesign") {

        this.setState({
          showAddQuestion: false,
          showPreviewPage: false,
          showWelcomePage: false,
          showTranslatePage: false,
          showDesignPage: false,
          showLogicPage: false,
          showDashboardDesignPage:true
        });

        let { dashQuesTypeRank } = this.state;
        let { getQuestionTypeListData } = this.props.survey;

        if(getSurveyData && getSurveyData.designObj && getSurveyData.designObj.componentRank){
          dashQuesTypeRank = getSurveyData.designObj.componentRank;
          if(getQuestionTypeListData && getQuestionTypeListData.length>0){
            getQuestionTypeListData.forEach((item, index)=>{
              if(dashQuesTypeRank && dashQuesTypeRank[item.templateId]){
                dashQuesTypeRank[item.templateId] = dashQuesTypeRank[item.templateId];
              }
              else{
                dashQuesTypeRank[item.templateId] = 1;
              }
            });
          }
        }
        else{
          if(getQuestionTypeListData && getQuestionTypeListData.length>0){
            getQuestionTypeListData.forEach((item, index)=>{
              dashQuesTypeRank[item.templateId] = parseInt(index+1);
            });
          }
        }
        this.setState({dashQuesTypeRank});


      }

      let data = {
        "id": getSurveyData.id,
        "welcomeText": (getSurveyData.welcomeText) ? getSurveyData.welcomeText : "",
        "thankyouText": (getSurveyData.thankyouText) ? getSurveyData.thankyouText : "",
        "instructionText": (getSurveyData.instructionText) ? getSurveyData.instructionText : "",
        "isInstruction": (getSurveyData.isInstruction) ? getSurveyData.isInstruction : false,

        "textColor": (getSurveyData.textColor) ? getSurveyData.textColor : "#262626",
        "bgColor": (getSurveyData.bgColor) ? getSurveyData.bgColor : "#ffffff",
        "OptionColor1": (getSurveyData.OptionColor1) ? getSurveyData.OptionColor1 : "#3399ff",
        "OptionColor2": (getSurveyData.OptionColor2) ? getSurveyData.OptionColor2 : "#ffffff",
        "OptionColor3": (getSurveyData.OptionColor3) ? getSurveyData.OptionColor3 : "#33cc33",
        "buttonColor1": (getSurveyData.buttonColor1) ? getSurveyData.buttonColor1 : "#4d4d4d",
        "buttonColor2": (getSurveyData.buttonColor2) ? getSurveyData.buttonColor2 : "#ffffff",

        "bgType": (getSurveyData.bgType) ? getSurveyData.bgType : 1,

        "designTemplate": (getSurveyData.designTemplate) ? getSurveyData.designTemplate : "v2",


        "bgImage": (getSurveyData.bgImage) ? getSurveyData.bgImage : "",
        "logo": (getSurveyData.logo) ? getSurveyData.logo : "",
        "logo2": (getSurveyData.logo2) ? getSurveyData.logo2 : "",
        "parameterList": (getSurveyData && getSurveyData.parameterList) ? getSurveyData.parameterList : [],
        "orgList": (getSurveyData && getSurveyData.orgList) ? getSurveyData.orgList : [],
        "langList":(getSurveyData && getSurveyData.langList)?getSurveyData.langList:[],
        "designObj":(getSurveyData && getSurveyData.designObj)?getSurveyData.designObj:{},
        "parameterListUpdate": [],
        "orgListUpdate": [],
        "langListUpdate":[]
      }


      console.log('====>getSurveyData', data)


      if (getSurveyData && getSurveyData.parameterList && getSurveyData.parameterList.length > 0) {
        getSurveyData.parameterList.forEach((item) => {
          data["parameterListUpdate"].push(item.id)
        })
      }

      if (getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length > 0) {
        getSurveyData.orgList.forEach((item) => {
          data["orgListUpdate"].push(item.id)
        })
      }


      if (getSurveyData && getSurveyData.langList && getSurveyData.langList.length > 0) {
        getSurveyData.langList.forEach((item) => {
          data["langListUpdate"].push(item.id)
        })
      }

      this.setState({ welcomeFormData: data })
      // console.log(" xx-x xxx>>>: ",data)

      if (this.state.welcomeScreenModal) {
        this.setState({ welcomeScreenModal: false })
      } else {
        this.setState({ welcomeScreenModal: true })
      }


    }
  }


  saveAutoParameter = (isDeleteQ, Ques) => {
    let { questions, survey } = this.props;
    let { questionsList } = questions;
    let { getSurveyData } = survey;

    let saveParameterList = [];
    let saveOrgList = [];
    if (questionsList && questionsList.length > 0) {
      questionsList.forEach((item) => {
        if (item && item.parameterBucketId && item.parameterBucketId.id) {
          if (item.parameterBucketId.name === "OrganizationCore") {
            if (item && item.parameterDimensionId && item.parameterDimensionId.id) {
              let getIndex2 = saveOrgList.findIndex(prev => prev === item.parameterDimensionId.id);
              if (getIndex2 === -1) {
                saveOrgList.push(item.parameterDimensionId.id);
              }
            }
          }
          else {
            let getIndex = saveParameterList.findIndex(prev => prev === item.parameterBucketId.id);
            if (getIndex === -1) {
              saveParameterList.push(item.parameterBucketId.id);
            }
          }

        }
      });
    }
    if (isDeleteQ) {
      if (Ques && Ques.parameterBucketId && Ques.parameterBucketId.id) {
        if (Ques.parameterBucketId.name === "OrganizationCore") {
          if (Ques && Ques.parameterDimensionId && Ques.parameterDimensionId.id) {
            let getIndex3 = saveOrgList.findIndex(prev => prev === Ques.parameterDimensionId.id);
            saveOrgList.splice(getIndex3, 1);
          }
        }
        else {
          let getIndex4 = saveParameterList.findIndex(prev => prev === Ques.parameterBucketId.id);
          saveParameterList.splice(getIndex4, 1);
        }
      }
    }
    console.log("----------->saveParameterList---------------->", saveParameterList);


    let data = {
      "id": getSurveyData.id,
      "welcomeText": (getSurveyData.welcomeText) ? getSurveyData.welcomeText : "",
      "thankyouText": (getSurveyData.thankyouText) ? getSurveyData.thankyouText : "",
      "instructionText": (getSurveyData.instructionText) ? getSurveyData.instructionText : "",
      "isInstruction": (getSurveyData.isInstruction) ? getSurveyData.isInstruction : false,

      "textColor": (getSurveyData.textColor) ? getSurveyData.textColor : "#262626",
      "bgColor": (getSurveyData.bgColor) ? getSurveyData.bgColor : "#ffffff",
      "OptionColor1": (getSurveyData.OptionColor1) ? getSurveyData.OptionColor1 : "#3399ff",
      "OptionColor2": (getSurveyData.OptionColor2) ? getSurveyData.OptionColor2 : "#ffffff",
      "OptionColor3": (getSurveyData.OptionColor3) ? getSurveyData.OptionColor3 : "#33cc33",
      "buttonColor1": (getSurveyData.buttonColor1) ? getSurveyData.buttonColor1 : "#4d4d4d",
      "buttonColor2": (getSurveyData.buttonColor2) ? getSurveyData.buttonColor2 : "#ffffff",

      "bgType": (getSurveyData.bgType) ? getSurveyData.bgType : 1,

      "designTemplate": (getSurveyData.designTemplate) ? getSurveyData.designTemplate:"v2",

      "designObj": (getSurveyData && getSurveyData.designObj) ? getSurveyData.designObj:{},


      "bgImage": (getSurveyData.bgImage) ? getSurveyData.bgImage : "",
      "logo": (getSurveyData.logo) ? getSurveyData.logo : "",
      "logo2": (getSurveyData.logo2) ? getSurveyData.logo2 : "",
      "parameterList": saveParameterList,
      "orgList": saveOrgList,
      "langList":getSurveyData.langList
    }
    let temp = {
      surveyId: this.props.match.params.id
    }
    console.log("saveWelcomePage>>> ", data);
    this.props.dispatch(surveyActions.updateSurveyWelcome(data, this.props, temp));
    this.props.dispatch(surveyActions.getSurveyById(temp));
  }



  onSaveQues = (question) => {


    // "parameterList":welcomeFormData.parameterListUpdate,
    // "orgList":welcomeFormData.orgListUpdate


    this.props.dispatch(questionsActions.updateQuestion(question, false));
    console.log('question',question);
    // this.saveAutoParameter(false);

    this.onTranslateQuestionSave(true, "customNPS");




    // this.setState({ welcomeFormData:data  })




    localStorage.removeItem('tempQuestion');
    this.setState({
      currQuesEditId: '',

      paraBucketName: '',
      paraDimensionName: ''

    });
  }

  onEditQues = (question) => {
    let { questions } = this.props;
    let { questionsList } = questions;
    localStorage.setItem('tempQuestion', JSON.stringify(question));

    this.setState({ currQuesEditId: question.id, showParameterModal: false });


    let {translQuestion} = this.state;
    if(true){
      translQuestion['id'] = question.id;
      translQuestion['transl'] = {};






      if(question.templateId===6 || question.templateId===16){

                    if(question && question.transl){
                      translQuestion['transl'] = question.transl;
              
                      if(!(question && question.transl && question.transl["customNPS"])){
                        translQuestion['transl']["customNPS"] = {};
                      }
              
              
                      let data = question && question.transl && question.transl["customNPS"]? question.transl["customNPS"] : " ";
                
                        translQuestion['transl']["customNPS"]['Ratehere'] = data && data.Ratehere ?data.Ratehere:"";
                        translQuestion['transl']["customNPS"]['Extremelydissatisfied'] = data && data.Extremelydissatisfied ?data.Extremelydissatisfied:"";
                        translQuestion['transl']["customNPS"]['Dissatisfied'] = data && data.Dissatisfied ?data.Dissatisfied:"";
                        translQuestion['transl']["customNPS"]['Neither'] = data && data.Neither ?data.Neither:"";
                        translQuestion['transl']["customNPS"]['Satisfied'] = data && data.Satisfied ?data.Satisfied:"";
                        translQuestion['transl']["customNPS"]['Extremelysatisfied'] = data && data.Extremelysatisfied ?data.Extremelysatisfied:"";
              
                    }
                    else{
                      translQuestion['transl'] = {};
                      translQuestion['transl']["customNPS"] = {
                        "Ratehere":"",
                        "Extremelydissatisfied":"",
                        "Dissatisfied":"",
                        "Neither":"",
                        "Satisfied":"",
                        "Extremelysatisfied":"",
                      }
                    }

      }
      if(question.templateId===15){

        if(question && question.transl){
          translQuestion['transl'] = question.transl;
  
          if(!(question && question.transl && question.transl["customNPS"])){
            translQuestion['transl']["customNPS"] = {};
          }
  
  
          let data = question && question.transl && question.transl["customNPS"]? question.transl["customNPS"] : " ";
    
            translQuestion['transl']["customNPS"]['state'] = data && data.state ?data.state:"";
            translQuestion['transl']["customNPS"]['city'] = data && data.city ?data.city:"";
  
        }
        else{
          translQuestion['transl'] = {};
          translQuestion['transl']["customNPS"] = {
            "state":"",
            "city":""
          }
        }

}
      else if(question.templateId===15){

                if(question && question.transl){
                  translQuestion['transl'] = question.transl;
          
                  if(!(question && question.transl && question.transl["customLocation"])){
                    translQuestion['transl']["customLocation"] = {};
                  }
          
          
                  let data = question && question.transl && question.transl["customLocation"]? question.transl["customLocation"] : " ";
            
                  translQuestion['transl']["customLocation"]['type'] = data && data.type ?data.type:"";
          
                }
                else{
                  translQuestion['transl'] = {};
                  translQuestion['transl']["customLocation"] = {
                    "type":""
                  }
                }

      }
      else if(question.templateId===1){

              if(question && question.transl){
                translQuestion['transl'] = question.transl;
        
                if(!(question && question.transl && question.transl["questionLogic"])){
                  translQuestion['transl']["questionLogic"] = {};
                }
        
        
                let data = question && question.transl && question.transl["questionLogic"]? question.transl["questionLogic"] : " ";
          

                if(question && question.transl && question.transl["questionLogic"] && question.transl["questionLogic"]["id"]){
                  translQuestion['transl']["questionLogic"] = data;

                }
                else{
                  translQuestion['transl'] = {};
                  translQuestion['transl']["questionLogic"] = {
                    "enable":false,"id":"","answers":[]
                  };
                }
        
              }
              else{
                translQuestion['transl'] = {};
                translQuestion['transl']["questionLogic"] = {
                  "enable":false,"id":"","answers":[]
                };
              }

      }






    }






    //-------------------- parameter loading ------------------------------

    if (question && question.parameterBucketId && question.parameterBucketId.id) {
      this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, question.id, question.parameterBucketId, 1));
    }
    if (question && question.parameterDimensionId && question.parameterDimensionId.id) {
      this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, question.id, question.parameterDimensionId, 2));
    }

    if (question && question.parameterBucketId && question.parameterBucketId.id) {
      this.props.dispatch(surveyActions.getParameterListbyParentId({ parentId: question.parameterBucketId.id }));
    }
  }

  onSaveTranslateWelcome=(isWelcome)=>{
    if(isWelcome){
      this.setState({ ShowTranslWelcome:false})
    }
    else{
      this.setState({ showTranslAttributes:false})
    }
    let { translSurvey } = this.state;


    let tempdate = {
      surveyId: this.props.match.params.id
    }

    console.log('------>translSurvey: ',translSurvey)
  
    this.props.dispatch(questionsActions.translateSurvey(translSurvey,tempdate));

  }

  onTranslateWelcomePage=(surveyData, isWelcome,translShortName)=>{

    let { translSurvey } = this.state;

    if(translShortName){
      translSurvey['id'] = surveyData.id;
      translSurvey["transl"] = {}

      if(surveyData && surveyData.transl){
          translSurvey["transl"] = surveyData.transl;

          if(!(surveyData && surveyData.transl && surveyData.transl[translShortName])){
            translSurvey['transl'][translShortName] = {};
          }
          let data = surveyData && surveyData.transl && surveyData.transl[translShortName]? surveyData.transl[translShortName] : " ";
  
          translSurvey["transl"][translShortName]['name'] = data && data.name ?data.name:"";
          translSurvey["transl"][translShortName]['desc'] = data && data.desc ?data.desc:"";

          translSurvey["transl"][translShortName]['welcomeText'] = data && data.welcomeText ?data.welcomeText:"";
          translSurvey["transl"][translShortName]['instructionText'] = data && data.instructionText ?data.instructionText:"";
          translSurvey["transl"][translShortName]['thankyouText'] = data && data.thankyouText ?data.thankyouText:"";

          translSurvey["transl"][translShortName]['StartButtonText'] = data && data.StartButtonText ?data.StartButtonText:"";
          translSurvey["transl"][translShortName]['ContinueButtonText'] = data && data.ContinueButtonText ?data.ContinueButtonText:"";
          translSurvey["transl"][translShortName]['SubmitButtonText'] = data && data.SubmitButtonText ?data.SubmitButtonText:"";
          translSurvey["transl"][translShortName]['okButtonText'] = data && data.okButtonText ?data.okButtonText:"";
          translSurvey["transl"][translShortName]['WriteHereText'] = data && data.WriteHereText ?data.WriteHereText:"";
          translSurvey["transl"][translShortName]['ExpiryText'] = data && data.ExpiryText ?data.ExpiryText:"";
          translSurvey["transl"][translShortName]['AlreadyText'] = data && data.AlreadyText ?data.AlreadyText:"";
          translSurvey["transl"][translShortName]['mandatoryText'] = data && data.mandatoryText ?data.mandatoryText:"";
          translSurvey["transl"][translShortName]['allmandatoryText'] = data && data.allmandatoryText ?data.allmandatoryText:"";
          translSurvey["transl"][translShortName]['completedText'] = data && data.completedText ?data.completedText:"";
          translSurvey["transl"][translShortName]['instructionLabel'] = data && data.instructionLabel ?data.instructionLabel:"";

      }
      else{
        translSurvey["transl"] = {}
        translSurvey['transl'][translShortName] = {};
        translSurvey["transl"][translShortName]['name'] = "";
        translSurvey["transl"][translShortName]['desc'] = "";

        translSurvey["transl"][translShortName]['welcomeText'] = "";
        translSurvey["transl"][translShortName]['instructionText'] = "";
        translSurvey["transl"][translShortName]['thankyouText'] = "";

        translSurvey["transl"][translShortName]['StartButtonText'] = "";
        translSurvey["transl"][translShortName]['ContinueButtonText'] = "";
        translSurvey["transl"][translShortName]['SubmitButtonText'] = "";
        translSurvey["transl"][translShortName]['okButtonText'] = "";
        translSurvey["transl"][translShortName]['WriteHereText'] = "";
        translSurvey["transl"][translShortName]['ExpiryText'] = "";
        translSurvey["transl"][translShortName]['AlreadyText'] = "";

        translSurvey["transl"][translShortName]['mandatoryText'] = "";
        translSurvey["transl"][translShortName]['allmandatoryText'] = "";
        translSurvey["transl"][translShortName]['completedText'] = "";
        translSurvey["transl"][translShortName]['instructionLabel'] = "";
      }

      this.setState({translSurvey})

      if(isWelcome){
        this.setState({ShowTranslWelcome:true, showTranslAttributes:false})
      }
      else{
        this.setState({showTranslAttributes:true, ShowTranslWelcome:false})
      }

    }



  }




  onTranslateQues = (question, translShortName) => {


    let { translQuestion, translSubQuestionsMatrix } = this.state;

    console.log('question: ',question)

    if(translShortName){
        translQuestion['id'] = question.id;
        translQuestion['transl'] = {};

        if(question && question.transl){
          translQuestion['transl'] = question.transl;

          if(!(question && question.transl && question.transl[translShortName])){
            translQuestion['transl'][translShortName] = {};
          }


          let data = question && question.transl && question.transl[translShortName]? question.transl[translShortName] : " ";
    
            translQuestion['transl'][translShortName]['name'] = data && data.name ?data.name:"";
            translQuestion['transl'][translShortName]['optionsList'] = data && data.optionsList?data.optionsList:{};
            translQuestion['transl'][translShortName]['headingList'] = data && data.headingList?data.headingList:{};

        }
        else{
          translQuestion['transl'] = {};
          translQuestion['transl'][translShortName] = {
            "name":"",optionsList:{},headingList:{}
          }
        }


        // for matrix ------------------------------------------------------------------------
        if(question.templateId===13 && (!question.parentId)){
          if(question && question.SubQuestionList && question.SubQuestionList.length>0){
            question.SubQuestionList.forEach((quesSub,index)=>{

                    translSubQuestionsMatrix[quesSub.id]={};

                    translSubQuestionsMatrix[quesSub.id]['id'] = quesSub.id;

                    translSubQuestionsMatrix[quesSub.id]['transl'] = {};
            
                    if(quesSub && quesSub.transl){
                      translSubQuestionsMatrix[quesSub.id]['transl'] = quesSub.transl;
            
                      if(!(quesSub && quesSub.transl && quesSub.transl[translShortName])){
                        translSubQuestionsMatrix[quesSub.id]['transl'][translShortName] = {};
                      }
            
            
                      let data = quesSub && quesSub.transl && quesSub.transl[translShortName]? quesSub.transl[translShortName] : " ";
                
                        translSubQuestionsMatrix[quesSub.id]['transl'][translShortName]['name'] = data && data.name ?data.name:"";
                      
                    }
                    else{
                      translSubQuestionsMatrix[quesSub.id]['transl'] = {};
                      translSubQuestionsMatrix[quesSub.id]['transl'][translShortName] = {
                        "name":""
                      }
                    }
            
            });
          }


        }



        //------------------------------------------------------------------------
        this.setState({ currQuesEditId: question.id, translQuestion, translSubQuestionsMatrix });

        console.log('translQuestion: ',translQuestion)
        console.log('x--------->translSubQuestionsMatrix: ',translSubQuestionsMatrix)
        console.log('x--------->translSubQuestionsMatrix: ',translSubQuestionsMatrix)

    }





  }






  innerEdit = (question, SurveyTemplateId) => {
    if (SurveyTemplateId !== 2) {
      this.onEditQues(question);
    }
  }


  onEditTranslateQues = (question) => {
    this.setState({ currQuesTranslEditId: question.id });
  }

  onDeleteConfirm = (question) => {
    console.log('question: ', question)
    let data = {
      id: question.id,
      surveyId: question.surveyId
    }

    this.setState({ CurrentQuestionData: data, CurrentQuestionName: question.name });

  }


  onDeleteQues = () => {
    let { CurrentQuestionData } = this.state;
    // this.saveAutoParameter(true,CurrentQuestionData)
    this.handleConfirmDeleteQuesClose();
    this.props.dispatch(questionsActions.deleteQuestion(CurrentQuestionData))

  }

  handleConfirmDeleteQuesClose = () => {
    this.setState({ CurrentQuestionData: '', CurrentQuestionName: '' });
  }



  onClickQuesUp = (ques1, index, surveyId) => {
    let { questions } = this.props;
    let { questionsList } = questions;
    // console.log("working:onClickUp: ",ques1,index,'==>',surveyId)

    let ques2 = ''
    if (questionsList && questionsList.length > 0 && index) {
      ques2 = questionsList[index - 1].id;
    }

    if (ques1 && ques2 && surveyId) {
      let data = {
        idA: ques1,
        idB: ques2
      }
      console.log('data idA, IdB: ', data, surveyId)
      this.props.dispatch(questionsActions.updateQuestionRenk(data, surveyId));
    }

  }

  onClickQuesDown = (ques1, index, surveyId) => {
    let { questions } = this.props;
    let { questionsList } = questions;

    let ques2 = ''
    if (questionsList && questionsList.length > 0 && (index !== questionsList.length - 1)) {
      ques2 = questionsList[index + 1].id;
    }


    if (ques1 && ques2 && surveyId) {
      let data = {
        idA: ques1,
        idB: ques2
      }
      this.props.dispatch(questionsActions.updateQuestionRenk(data, surveyId));
    }

  }




  handleQuestionInput = (value, quesId) => {
    let { questions } = this.props;
    let { questionsList } = questions;
    this.props.dispatch(questionsActions.handleNameQuestion(questionsList, quesId, value));
  }


  onQuesMandatory = (isMandatory, quesId) => {
    let { questions } = this.props;
    let { questionsList } = questions;
    this.props.dispatch(questionsActions.handleMandatoryQuestion(questionsList, quesId, !isMandatory));
  }

  handleOptionInput = (value, quesId, optionId) => {
    let { questions } = this.props;
    let { questionsList } = questions;

    this.props.dispatch(questionsActions.handleNameOption(questionsList, quesId, optionId, value));

  }

  handleOptionHeadingInput = (value, quesId, optionId) => {
    let { questions } = this.props;
    let { questionsList } = questions;

    this.props.dispatch(questionsActions.handleNameOptionHeading(questionsList, quesId, optionId, value));

  }





  handleAddOptionHeading = (check) => {
    this.setState({showOptionHeading:check})
  }


  handleWeightageInput = (event, quesId, optionId) => {
    let { questions } = this.props;
    let { questionsList } = questions;

    this.props.dispatch(questionsActions.handleNameWeightage(questionsList, quesId, optionId, event.target.value));

  }

  onAddNewOption = (ques) => {
    let { questions } = this.props;
    let { questionsList } = questions;

    this.props.dispatch(questionsActions.addOption(questionsList, ques));

  }

  onDeleteOption = (quesId, optionId) => {

    console.log('onDeleteOption: ', quesId)


    let { questions } = this.props;
    let { questionsList } = questions;
    this.props.dispatch(questionsActions.deleteOption(questionsList, quesId, optionId));
  }


  handleWelcomeTextInput = (value) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['welcomeText'] = value;
    this.setState({ welcomeFormData });
  }
  handleInstructionTextInput = (value) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['instructionText'] = value;
    this.setState({ welcomeFormData });
  }
  handleThankyouTextInput = (value) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['thankyouText'] = value;
    this.setState({ welcomeFormData });
  }


  handleSurveyTemplate = (value) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['designTemplate'] = value;
    this.setState({ welcomeFormData });
  }


  handleLogoRemove = (type) => {
    let { welcomeFormData } = this.state;
    if(type===2){
      welcomeFormData['logo2'] = "";
      this.setState({ welcomeFormData, imageWebLinkLogo2: "" });
    }
    else{
      welcomeFormData['logo'] = "";
      this.setState({ welcomeFormData, imageWebLinkLogo: "" });
    }
  }
  

  handleImageRemove = () => {
    let { welcomeFormData } = this.state;
    welcomeFormData['bgImage'] = "";
    welcomeFormData['bgType'] = 1;
    this.setState({ welcomeFormData, imageWebLink: "" });
  }


  handleSurveyParameterList = (event, val) => {
    let { welcomeFormData } = this.state;

    if (val.option) {
      welcomeFormData.parameterListUpdate.push(val.option.id);
      this.setState({ welcomeFormData });
    }
    else if (val.removedValue) {
      welcomeFormData.parameterListUpdate.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          welcomeFormData.parameterListUpdate.splice(delIndex, 1);
        }
      })


      this.setState({ welcomeFormData });
    }
  }


  handleSurveyorgList = (event, val) => {
    let { welcomeFormData } = this.state;

    if (val.option) {
      welcomeFormData.orgListUpdate.push(val.option.id);
      this.setState({ welcomeFormData });
    }
    else if (val.removedValue) {
      welcomeFormData.orgListUpdate.forEach((deleteId, delIndex) => {
        if (deleteId === val.removedValue.id) {
          welcomeFormData.orgListUpdate.splice(delIndex, 1);
        }
      })


      this.setState({ welcomeFormData });
    }
  }



  handleSurveyLangList = (event, val) => {
    let { welcomeFormData } = this.state;

    if(val.option){
      welcomeFormData.langListUpdate.push(val.option.id);
      this.setState({ welcomeFormData });
    }
    else if(val.removedValue){
      welcomeFormData.langListUpdate.forEach((deleteId,delIndex)=>{
        if(deleteId === val.removedValue.id){
          welcomeFormData.langListUpdate.splice(delIndex,1);
        }
      })

      
      this.setState({ welcomeFormData });
    }
  }


  handleChangetextColor = (color) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['textColor'] = color.hex;
    this.setState({ welcomeFormData });
  };

  handleChangebgColor = (color) => {
    let { welcomeFormData } = this.state;
    welcomeFormData['bgColor'] = color.hex;
    this.setState({ welcomeFormData });
  };

  handlebgType = (data) => {
    console.log('handlebgColorImage: ', data)
    let { welcomeFormData } = this.state;
    welcomeFormData['bgType'] = parseInt(data);
    this.setState({ welcomeFormData });
  }


  handleColorCode = (item) => {
    this.setState({ selectColorType: item.value });
  }


  handleChangeColorValue = (color) => {
    let { selectColorType, welcomeFormData } = this.state;
    welcomeFormData[selectColorType] = color.hex;
    this.setState({ welcomeFormData });
  }



  // -----------------BACKGROUND IMAGE-------------------------------------------

  handleFile = (event) => {

    this.setState({ selectedFile: event.target.files[0] });
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImage(event.target.files[0]));
      this.setState({ selectedFile: null });
    }

    let { welcomeFormData } = this.state;
    welcomeFormData['bgType'] = 2;
    this.setState({ welcomeFormData });



  }

  // handleUpload=()=>{

  //   if(this.state.selectedFile){
  //    this.props.dispatch(userActions.uploadImage(this.state.selectedFile));
  //    this.setState({ selectedFile: null });
  //   }


  //  }

  handleImageRemove = () => {
    let { welcomeFormData } = this.state;
    welcomeFormData['bgImage'] = "";
    this.setState({ welcomeFormData, imageWebLink: "" });
  }


  // -----------------LOGO-------------------------------------------


  handleFileLogo = (event) => {

    this.setState({ selectedFileLogo: event.target.files[0] });
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImageLogo(event.target.files[0]));
      this.setState({ selectedFileLogo: null });
    }
  }

  handleFileLogo2 = (event) => {

    this.setState({ selectedFileLogo2: event.target.files[0] });
    if (event.target.files[0]) {
      this.props.dispatch(userActions.uploadImageLogo2(event.target.files[0]));
      this.setState({ selectedFileLogo2: null });
    }
  }

  // handleUploadLogo=()=>{

  //  if(this.state.selectedFileLogo){
  //   this.props.dispatch(userActions.uploadImageLogo(this.state.selectedFileLogo));
  //   this.setState({ selectedFileLogo: null });
  //  }

  // }

  // handleLogoRemove=()=>{
  //   let { welcomeFormData } = this.state;
  //   welcomeFormData['logo'] = "";
  //   this.setState({ welcomeFormData, imageWebLinkLogo:""  }); 
  // }


  // ------------------------------------------------------------

  saveWelcomePage = () => {
    let { welcomeFormData, showDashboardDesignPage, dashQuesTypeRank } = this.state;

      if(showDashboardDesignPage){
        let data = {
          "id":this.props.match.params.id,
          "designObj":{
            "componentRank":dashQuesTypeRank
          }
        }
        let temp = {
          surveyId: this.props.match.params.id
        }
        this.props.dispatch(surveyActions.updateSurveyDesign(data, temp));
      }






          let data = {
            "id": welcomeFormData.id,
            "welcomeText": welcomeFormData.welcomeText,
            "thankyouText": welcomeFormData.thankyouText,
            "instructionText": welcomeFormData.instructionText,
            "isInstruction": welcomeFormData.isInstruction,
      
            "textColor": welcomeFormData.textColor,
            "bgColor": welcomeFormData.bgColor,
            "OptionColor1": welcomeFormData.OptionColor1,
            "OptionColor2": welcomeFormData.OptionColor2,
            "OptionColor3": welcomeFormData.OptionColor3,
            "buttonColor1": welcomeFormData.buttonColor1,
            "buttonColor2": welcomeFormData.buttonColor2,
      
            "designTemplate": welcomeFormData.designTemplate,
      
      
            "bgType": welcomeFormData.bgType,
            "bgImage": (this.state.imageWebLink) ? this.state.imageWebLink : welcomeFormData.bgImage,
            "logo": (this.state.imageWebLinkLogo) ? this.state.imageWebLinkLogo : welcomeFormData.logo,
            "logo2": (this.state.imageWebLinkLogo2) ? this.state.imageWebLinkLogo2 : welcomeFormData.logo2,
            "parameterList": welcomeFormData.parameterListUpdate,
            "orgList": welcomeFormData.orgListUpdate,
            // "designObj": welcomeFormData.designObj,
            "langList": welcomeFormData.langListUpdate
          }
          let temp = {
            surveyId: this.props.match.params.id
          }
          console.log("saveWelcomePage>>> ", data);
          this.props.dispatch(surveyActions.updateSurveyWelcome(data, this.props, temp));
    




  }



  handleParameterBucket = (check) => {
    this.setState({
      showBucketDropdown: !check,
      showDimensionDropdown: false
    })
  }

  handleParameterDimension = (check) => {
    this.setState({
      showDimensionDropdown: !check,
      showBucketDropdown: false
    })
  }

  handleParameterQuestion = (para, quesId, isEmptyDimen) => {

    let { questions } = this.props;
    let { questionsList } = questions;



    if (isEmptyDimen === 1 || isEmptyDimen === 2) {
      //RefreshDimension
      let tempparameter = {
        "id": "",
        "name": ""
      }
      if (isEmptyDimen === 1) {
        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, tempparameter, 1));
        this.setState({ paraBucketName: "" });
      }
      else if (isEmptyDimen === 2) {

        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, tempparameter, 1));
        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, tempparameter, 2));
        this.setState({ paraBucketName: "", paraDimensionName: "" });
      }


    }
    else {

      this.setState({
        showBucketDropdown: false,
        showDimensionDropdown: false
      })


      if (para.parameterTypeLevel === 1) {
        let parameter = {
          "id": para.id,
          "name": para.name
        }
        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, parameter, 1));
        this.setState({ paraBucketName: para.name });
        this.props.dispatch(surveyActions.getParameterListbyParentId({ parentId: para.id }));

        //RefreshDimension
        let tempparameter = {
          "id": "",
          "name": ""
        }
        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, tempparameter, 2));
        this.setState({ paraDimensionName: "" });

      }
      else if (para.parameterTypeLevel === 2) {
        let parameter = {
          "id": para.id,
          "name": para.name
        }
        this.props.dispatch(questionsActions.handleParameterQuestion(questionsList, quesId, parameter, 2));
        this.setState({ paraDimensionName: para.name });
      }

    }



  }

  onPublishSurvey = () => {
    let { currQuesEditId } = this.state;
    if (currQuesEditId) {
      this.setState({ showPleaseSaveQuesModal: true });
    }
    else {
      let data = {
        surveyId: this.props.match.params.id
      }
      this.props.history.push(`/app/publish/${data.surveyId}`);
    }
  }


  handleExpiryTime = (e, SurveyisExpire, SurveyexpireTime) => {
    let { expiryformData } = this.state;
    expiryformData[e.target.name] = e.target.value;
    this.setState({ expiryformData })

    let surveyId = this.props.match.params.id;

    let data = {}

    if (e.target.value) {
      data = {
        "id": surveyId,
        "isExpire": SurveyisExpire,
        "expireTime": e.target.value
      };
    }
    else {
      data = {
        "id": surveyId,
        "isExpire": SurveyisExpire,
        "expireTime": moment.unix(SurveyexpireTime / 1000).format("YYYY-MM-DD")
      };
    }

    this.props.dispatch(surveyActions.setExpireSurvey(data, surveyId));
  }

  isExpired = (check, SurveyexpireTime) => {
    let { expiryformData } = this.state;
    expiryformData["isExpire"] = !check
    this.setState({ expiryformData })

    let surveyId = this.props.match.params.id;
    let data = {
      "id": surveyId,
      "isExpire": !check,
      "expireTime": SurveyexpireTime
    };
    console.log('SurveyexpireTime: ', SurveyexpireTime)
    this.props.dispatch(surveyActions.setExpireSurvey(data, surveyId));


  }



  submitExpiry = () => {
    let { expiryformData } = this.state;
    let surveyId = this.props.match.params.id;
    let data = {
      "id": surveyId,
      "isExpire": expiryformData.isExpire,
      "expireTime": expiryformData.expireTime
    };
    this.props.dispatch(surveyActions.setExpireSurvey(data));
  }


  updateSurvey = () => {
    let { surveyUpdateFormData } = this.state;
    console.log('-------------------------------->', surveyUpdateFormData)

    let data = {
      id: surveyUpdateFormData.id,
      name: surveyUpdateFormData.name,
      desc: surveyUpdateFormData.desc,
      templateId: surveyUpdateFormData.templateId,


      // companyId:"5f9aaf951be7e064d18f6e6a"
    }
    let tempdata = {
      surveyId: this.props.match.params.id
    }
    this.props.dispatch(surveyActions.updateSurvey(data, tempdata));
    this.setState({ showEditSurveyName: false })
  }

  handleUpdateSurveyNameInput = (e) => {
    let { surveyUpdateFormData } = this.state;
    surveyUpdateFormData["name"] = e.target.value;
    this.setState({ surveyUpdateFormData });

    console.log('surveyUpdateFormData', e.target.value)
  }

  handleEditSurveyName = (getSurveyData) => {
    let { currQuesEditId } = this.state;
    if (currQuesEditId) {
      this.setState({ showPleaseSaveQuesModal: true });
    }
    else {
      console.log('getSurveyData', getSurveyData)
      let data = {
        id: getSurveyData.id,
        name: getSurveyData.name,
        desc: getSurveyData.desc,
        templateId: getSurveyData.templateId,
      }
      this.setState({ surveyUpdateFormData: data, showEditSurveyName: true })
    }
  }

  handleInstructionCheck = (check) => {
    let { welcomeFormData } = this.state;
    welcomeFormData["isInstruction"] = !check;
    this.setState({ welcomeFormData });
  }

  onParamaterModal = (value) => {
    this.setState({ showParameterModal: value, showDimensionDropdown: false, showBucketDropdown: false });
  }

  handleQuesCancel = (value) => {
    let tempSaveQues = JSON.parse(localStorage.getItem('tempQuestion'));

    if (tempSaveQues) {
      this.onSaveQues(tempSaveQues);
    }
    this.setState({ currQuesEditId: "" });
  }


  handleOpenIndexFilter = (check) => {
    this.setState({ showIndexFilter: check });
  }

  handleSelectIndexFilter = (para, isempty) => {
    if(isempty){
      this.setState({ selectedIndexFilterValue: "" });
    }
    else{
      if (this.state.selectedIndexFilterValue && this.state.selectedIndexFilterValue.id === para.id) {
        this.setState({ selectedIndexFilterValue: "" });
      }
      else {
        this.setState({ selectedIndexFilterValue: para });
      }
    }

  }

  QuestionComponent = (question, quesindex, currQuesEditId, SurveyTemplateId, GetParameterLabel,translShortName ) => {
    
    
    let { showBucketDropdown, showDimensionDropdown, paraBucketName, paraDimensionName, showTranslatePage } = this.state;
    let { survey, questions } = this.props;
    let { questionsList } = questions;
    let { parameterList, getParameterListbyParentData } = survey;





    switch (question.templateId) {
      case 1: return (
        <SingleChoice
          question={question} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}

showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          questionsList={questionsList}
          handleLogicQuestionOrOption={this.handleLogicQuestionOrOption}


        />)
    case 2: return (
      <MultipleChoice
        question={question} quesindex={quesindex}
        currQuesEditId={currQuesEditId}
        handleQuestionInput={this.handleQuestionInput}
        handleOptionInput={this.handleOptionInput}
        handleWeightageInput={this.handleWeightageInput}
        onDeleteOption={this.onDeleteOption}
        onAddNewOption={this.onAddNewOption}
        onQuesMandatory={this.onQuesMandatory}
        onSaveQues={this.onSaveQues}
        handleParameterBucket={this.handleParameterBucket}
        showBucketDropdown={showBucketDropdown}
        showDimensionDropdown={showDimensionDropdown}
        parameterList={parameterList}
        paraBucketName={paraBucketName}
        handleParameterQuestion={this.handleParameterQuestion}
        handleParameterDimension={this.handleParameterDimension}
        getParameterListbyParentData={getParameterListbyParentData}
        paraDimensionName={paraDimensionName}

        showParameterModal={this.state.showParameterModal}
        onParamaterModal={this.onParamaterModal}
        innerEdit={this.innerEdit}
        SurveyTemplateId={SurveyTemplateId}
        handleQuesCancel={this.handleQuesCancel}
        GetParameterLabel={GetParameterLabel}
        showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}


      />)
      case 3: return (
        <TextBox
          question={question} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}



        />)
      case 4: return (
        <MultilineText
          question={question} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}



        />)
        case 5: return (
          <RatingScale
            question={question} quesindex={quesindex}
            currQuesEditId={currQuesEditId}
            handleQuestionInput={this.handleQuestionInput}
            handleOptionInput={this.handleOptionInput}
            handleWeightageInput={this.handleWeightageInput}
            onDeleteOption={this.onDeleteOption}
            onAddNewOption={this.onAddNewOption}
            onQuesMandatory={this.onQuesMandatory}
            onSaveQues={this.onSaveQues}
            handleParameterBucket={this.handleParameterBucket}
            showBucketDropdown={showBucketDropdown}
            showDimensionDropdown={showDimensionDropdown}
            parameterList={parameterList}
            paraBucketName={paraBucketName}
            handleParameterQuestion={this.handleParameterQuestion}
            handleParameterDimension={this.handleParameterDimension}
            getParameterListbyParentData={getParameterListbyParentData}
            paraDimensionName={paraDimensionName}
  
            showParameterModal={this.state.showParameterModal}
            onParamaterModal={this.onParamaterModal}
            innerEdit={this.innerEdit}
            SurveyTemplateId={SurveyTemplateId}
            handleQuesCancel={this.handleQuesCancel}
            GetParameterLabel={GetParameterLabel}
            showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}

          />)
      case 6: return (
        <NPS
          question={question} quesindex={quesindex}
          currQuesEditId={currQuesEditId}
          handleQuestionInput={this.handleQuestionInput}
          handleOptionInput={this.handleOptionInput}
          handleWeightageInput={this.handleWeightageInput}
          onDeleteOption={this.onDeleteOption}
          onAddNewOption={this.onAddNewOption}
          onQuesMandatory={this.onQuesMandatory}
          onSaveQues={this.onSaveQues}
          handleParameterBucket={this.handleParameterBucket}
          showBucketDropdown={showBucketDropdown}
          showDimensionDropdown={showDimensionDropdown}
          parameterList={parameterList}
          paraBucketName={paraBucketName}
          handleParameterQuestion={this.handleParameterQuestion}
          handleParameterDimension={this.handleParameterDimension}
          getParameterListbyParentData={getParameterListbyParentData}
          paraDimensionName={paraDimensionName}

          showParameterModal={this.state.showParameterModal}
          onParamaterModal={this.onParamaterModal}
          innerEdit={this.innerEdit}
          SurveyTemplateId={SurveyTemplateId}
          handleQuesCancel={this.handleQuesCancel}
          GetParameterLabel={GetParameterLabel}
          showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}
          handleNPSLable={this.handleNPSLable}

        />)

        case 16: return (
          <NPSV2
            question={question} quesindex={quesindex}
            currQuesEditId={currQuesEditId}
            handleQuestionInput={this.handleQuestionInput}
            handleOptionInput={this.handleOptionInput}
            handleWeightageInput={this.handleWeightageInput}
            onDeleteOption={this.onDeleteOption}
            onAddNewOption={this.onAddNewOption}
            onQuesMandatory={this.onQuesMandatory}
            onSaveQues={this.onSaveQues}
            handleParameterBucket={this.handleParameterBucket}
            showBucketDropdown={showBucketDropdown}
            showDimensionDropdown={showDimensionDropdown}
            parameterList={parameterList}
            paraBucketName={paraBucketName}
            handleParameterQuestion={this.handleParameterQuestion}
            handleParameterDimension={this.handleParameterDimension}
            getParameterListbyParentData={getParameterListbyParentData}
            paraDimensionName={paraDimensionName}
  
            showParameterModal={this.state.showParameterModal}
            onParamaterModal={this.onParamaterModal}
            innerEdit={this.innerEdit}
            SurveyTemplateId={SurveyTemplateId}
            handleQuesCancel={this.handleQuesCancel}
            GetParameterLabel={GetParameterLabel}
            showTranslatePage={showTranslatePage}
            translQuestion={this.state.translQuestion}
            handleTranslateQuestion={this.handleTranslateQuestion}
            handleTranslateOption={this.handleTranslateOption}
            onTranslateQuestionSave={this.onTranslateQuestionSave}
            translShortName={translShortName}
            handleNPSLable={this.handleNPSLable}
  
          />)


        case 7: return (
          <Email
            question={question} quesindex={quesindex}
            currQuesEditId={currQuesEditId}
            handleQuestionInput={this.handleQuestionInput}
            handleOptionInput={this.handleOptionInput}
            handleWeightageInput={this.handleWeightageInput}
            onDeleteOption={this.onDeleteOption}
            onAddNewOption={this.onAddNewOption}
            onQuesMandatory={this.onQuesMandatory}
            onSaveQues={this.onSaveQues}
            handleParameterBucket={this.handleParameterBucket}
            showBucketDropdown={showBucketDropdown}
            showDimensionDropdown={showDimensionDropdown}
            parameterList={parameterList}
            paraBucketName={paraBucketName}
            handleParameterQuestion={this.handleParameterQuestion}
            handleParameterDimension={this.handleParameterDimension}
            getParameterListbyParentData={getParameterListbyParentData}
            paraDimensionName={paraDimensionName}
  
            showParameterModal={this.state.showParameterModal}
            onParamaterModal={this.onParamaterModal}
            innerEdit={this.innerEdit}
            SurveyTemplateId={SurveyTemplateId}
            handleQuesCancel={this.handleQuesCancel}
            GetParameterLabel={GetParameterLabel}
            showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}

  
          />)
          case 8: return (
            <Number
              question={question} quesindex={quesindex}
              currQuesEditId={currQuesEditId}
              handleQuestionInput={this.handleQuestionInput}
              handleOptionInput={this.handleOptionInput}
              handleWeightageInput={this.handleWeightageInput}
              onDeleteOption={this.onDeleteOption}
              onAddNewOption={this.onAddNewOption}
              onQuesMandatory={this.onQuesMandatory}
              onSaveQues={this.onSaveQues}
              handleParameterBucket={this.handleParameterBucket}
              showBucketDropdown={showBucketDropdown}
              showDimensionDropdown={showDimensionDropdown}
              parameterList={parameterList}
              paraBucketName={paraBucketName}
              handleParameterQuestion={this.handleParameterQuestion}
              handleParameterDimension={this.handleParameterDimension}
              getParameterListbyParentData={getParameterListbyParentData}
              paraDimensionName={paraDimensionName}
    
              showParameterModal={this.state.showParameterModal}
              onParamaterModal={this.onParamaterModal}
              innerEdit={this.innerEdit}
              SurveyTemplateId={SurveyTemplateId}
              handleQuesCancel={this.handleQuesCancel}
              GetParameterLabel={GetParameterLabel}
              showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}

    
            />)
            case 9: return (
              <Dropdown
                question={question} quesindex={quesindex}
                currQuesEditId={currQuesEditId}
                handleQuestionInput={this.handleQuestionInput}
                handleOptionInput={this.handleOptionInput}
                handleWeightageInput={this.handleWeightageInput}
                onDeleteOption={this.onDeleteOption}
                onAddNewOption={this.onAddNewOption}
                onQuesMandatory={this.onQuesMandatory}
                onSaveQues={this.onSaveQues}
                handleParameterBucket={this.handleParameterBucket}
                showBucketDropdown={showBucketDropdown}
                showDimensionDropdown={showDimensionDropdown}
                parameterList={parameterList}
                paraBucketName={paraBucketName}
                handleParameterQuestion={this.handleParameterQuestion}
                handleParameterDimension={this.handleParameterDimension}
                getParameterListbyParentData={getParameterListbyParentData}
                paraDimensionName={paraDimensionName}
      
                showParameterModal={this.state.showParameterModal}
                onParamaterModal={this.onParamaterModal}
                innerEdit={this.innerEdit}
                SurveyTemplateId={SurveyTemplateId}
                handleQuesCancel={this.handleQuesCancel}
                GetParameterLabel={GetParameterLabel}
                showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}

              />)
          case 10: return (
            <Statement
              question={question} quesindex={quesindex}
              currQuesEditId={currQuesEditId}
              handleQuestionInput={this.handleQuestionInput}
              handleOptionInput={this.handleOptionInput}
              handleWeightageInput={this.handleWeightageInput}
              onDeleteOption={this.onDeleteOption}
              onAddNewOption={this.onAddNewOption}
              onQuesMandatory={this.onQuesMandatory}
              onSaveQues={this.onSaveQues}
              handleParameterBucket={this.handleParameterBucket}
              showBucketDropdown={showBucketDropdown}
              showDimensionDropdown={showDimensionDropdown}
              parameterList={parameterList}
              paraBucketName={paraBucketName}
              handleParameterQuestion={this.handleParameterQuestion}
              handleParameterDimension={this.handleParameterDimension}
              getParameterListbyParentData={getParameterListbyParentData}
              paraDimensionName={paraDimensionName}
    
              showParameterModal={this.state.showParameterModal}
              onParamaterModal={this.onParamaterModal}
              innerEdit={this.innerEdit}
              SurveyTemplateId={SurveyTemplateId}
              handleQuesCancel={this.handleQuesCancel}
              GetParameterLabel={GetParameterLabel}
              showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}

    
    
            />)
            case 11: return (
              <YesNo
                question={question} quesindex={quesindex}
                currQuesEditId={currQuesEditId}
                handleQuestionInput={this.handleQuestionInput}
                handleOptionInput={this.handleOptionInput}
                handleWeightageInput={this.handleWeightageInput}
                onDeleteOption={this.onDeleteOption}
                onAddNewOption={this.onAddNewOption}
                onQuesMandatory={this.onQuesMandatory}
                onSaveQues={this.onSaveQues}
                handleParameterBucket={this.handleParameterBucket}
                showBucketDropdown={showBucketDropdown}
                showDimensionDropdown={showDimensionDropdown}
                parameterList={parameterList}
                paraBucketName={paraBucketName}
                handleParameterQuestion={this.handleParameterQuestion}
                handleParameterDimension={this.handleParameterDimension}
                getParameterListbyParentData={getParameterListbyParentData}
                paraDimensionName={paraDimensionName}
      
                showParameterModal={this.state.showParameterModal}
                onParamaterModal={this.onParamaterModal}
                innerEdit={this.innerEdit}
                SurveyTemplateId={SurveyTemplateId}
                handleQuesCancel={this.handleQuesCancel}
                GetParameterLabel={GetParameterLabel}
                showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}

              />)
          case 12: return (
            <Date
              question={question} quesindex={quesindex}
              currQuesEditId={currQuesEditId}
              handleQuestionInput={this.handleQuestionInput}
              handleOptionInput={this.handleOptionInput}
              handleWeightageInput={this.handleWeightageInput}
              onDeleteOption={this.onDeleteOption}
              onAddNewOption={this.onAddNewOption}
              onQuesMandatory={this.onQuesMandatory}
              onSaveQues={this.onSaveQues}
              handleParameterBucket={this.handleParameterBucket}
              showBucketDropdown={showBucketDropdown}
              showDimensionDropdown={showDimensionDropdown}
              parameterList={parameterList}
              paraBucketName={paraBucketName}
              handleParameterQuestion={this.handleParameterQuestion}
              handleParameterDimension={this.handleParameterDimension}
              getParameterListbyParentData={getParameterListbyParentData}
              paraDimensionName={paraDimensionName}
    
              showParameterModal={this.state.showParameterModal}
              onParamaterModal={this.onParamaterModal}
              innerEdit={this.innerEdit}
              SurveyTemplateId={SurveyTemplateId}
              handleQuesCancel={this.handleQuesCancel}
              GetParameterLabel={GetParameterLabel}
              showTranslatePage={showTranslatePage}
          translQuestion={this.state.translQuestion}
          handleTranslateQuestion={this.handleTranslateQuestion}
          handleTranslateOption={this.handleTranslateOption}
          onTranslateQuestionSave={this.onTranslateQuestionSave}
          translShortName={translShortName}

    
            />)
          case 13: return (
            <Matrix
              question={question} quesindex={quesindex}
              currQuesEditId={currQuesEditId}
              handleQuestionInput={this.handleQuestionInput}
              handleOptionInput={this.handleOptionInput}
              handleWeightageInput={this.handleWeightageInput}
              onDeleteOption={this.onDeleteOption}
              onAddNewOption={this.onAddNewOption}
              onQuesMandatory={this.onQuesMandatory}
              onSaveQues={this.onSaveQues}
              handleParameterBucket={this.handleParameterBucket}
              showBucketDropdown={showBucketDropdown}
              showDimensionDropdown={showDimensionDropdown}
              parameterList={parameterList}
              paraBucketName={paraBucketName}
              handleParameterQuestion={this.handleParameterQuestion}
              handleParameterDimension={this.handleParameterDimension}
              getParameterListbyParentData={getParameterListbyParentData}
              paraDimensionName={paraDimensionName}
    
              showParameterModal={this.state.showParameterModal}
              onParamaterModal={this.onParamaterModal}
              innerEdit={this.innerEdit}
              SurveyTemplateId={SurveyTemplateId}
              handleQuesCancel={this.handleQuesCancel}
              GetParameterLabel={GetParameterLabel}
    
              showTranslatePage={showTranslatePage}
              translQuestion={this.state.translQuestion}
              translSubQuestionsMatrix={this.state.translSubQuestionsMatrix}
              handleTranslateQuestion={this.handleTranslateQuestion}
              handleTranslateOption={this.handleTranslateOption}
              onTranslateQuestionSave={this.onTranslateQuestionSave}
              translShortName={translShortName}
              onDeleteConfirm={this.onDeleteConfirm}
              handleInputMatrixQues={this.handleInputMatrixQues}
              handleInputTranslateMatrixQues={this.handleInputTranslateMatrixQues}
              addMatrixQFn={this.addMatrixQFn}

              handleAddOptionHeading={this.handleAddOptionHeading}
              showOptionHeading={this.state.showOptionHeading}
              handleOptionHeadingInput={this.handleOptionHeadingInput}
              handleTranslateOptionHeadingInput={this.handleTranslateOptionHeadingInput}

            />)

            case 14: return (
              <GroupQuestions
                question={question} quesindex={quesindex}
                currQuesEditId={currQuesEditId}
                handleQuestionInput={this.handleQuestionInput}
                handleOptionInput={this.handleOptionInput}
                handleWeightageInput={this.handleWeightageInput}
                onDeleteOption={this.onDeleteOption}
                onAddNewOption={this.onAddNewOption}
                onQuesMandatory={this.onQuesMandatory}
                onSaveQues={this.onSaveQues}
                handleParameterBucket={this.handleParameterBucket}
                showBucketDropdown={showBucketDropdown}
                showDimensionDropdown={showDimensionDropdown}
                parameterList={parameterList}
                paraBucketName={paraBucketName}
                handleParameterQuestion={this.handleParameterQuestion}
                handleParameterDimension={this.handleParameterDimension}
                getParameterListbyParentData={getParameterListbyParentData}
                paraDimensionName={paraDimensionName}
      
                showParameterModal={this.state.showParameterModal}
                onParamaterModal={this.onParamaterModal}
                innerEdit={this.innerEdit}
                SurveyTemplateId={SurveyTemplateId}
                handleQuesCancel={this.handleQuesCancel}
                GetParameterLabel={GetParameterLabel}
      
                showTranslatePage={showTranslatePage}
                translQuestion={this.state.translQuestion}
                translSubQuestionsMatrix={this.state.translSubQuestionsMatrix}
                handleTranslateQuestion={this.handleTranslateQuestion}
                handleTranslateOption={this.handleTranslateOption}
                onTranslateQuestionSave={this.onTranslateQuestionSave}
                translShortName={translShortName}
                onDeleteConfirm={this.onDeleteConfirm}
                handleInputMatrixQues={this.handleInputMatrixQues}
                handleInputTranslateMatrixQues={this.handleInputTranslateMatrixQues}
                addMatrixQFn={this.addMatrixQFn}
  
                handleAddOptionHeading={this.handleAddOptionHeading}
                showOptionHeading={this.state.showOptionHeading}
                handleOptionHeadingInput={this.handleOptionHeadingInput}
                handleTranslateOptionHeadingInput={this.handleTranslateOptionHeadingInput}
              
                handleQuestionTypeGroup={this.handleQuestionTypeGroup}
                handleAddQuestion={this.handleAddQuestion}
                questionTypeTemplateGroup={this.state.questionTypeTemplateGroup}


              />)

              case 15: return (
                <LocationQuestions
                  question={question} quesindex={quesindex}
                  currQuesEditId={currQuesEditId}
                  handleQuestionInput={this.handleQuestionInput}
                  handleOptionInput={this.handleOptionInput}
                  handleWeightageInput={this.handleWeightageInput}
                  onDeleteOption={this.onDeleteOption}
                  onAddNewOption={this.onAddNewOption}
                  onQuesMandatory={this.onQuesMandatory}
                  onSaveQues={this.onSaveQues}
                  handleParameterBucket={this.handleParameterBucket}
                  showBucketDropdown={showBucketDropdown}
                  showDimensionDropdown={showDimensionDropdown}
                  parameterList={parameterList}
                  paraBucketName={paraBucketName}
                  handleParameterQuestion={this.handleParameterQuestion}
                  handleParameterDimension={this.handleParameterDimension}
                  getParameterListbyParentData={getParameterListbyParentData}
                  paraDimensionName={paraDimensionName}
        
                  showParameterModal={this.state.showParameterModal}
                  onParamaterModal={this.onParamaterModal}
                  innerEdit={this.innerEdit}
                  SurveyTemplateId={SurveyTemplateId}
                  handleQuesCancel={this.handleQuesCancel}
                  GetParameterLabel={GetParameterLabel}
                  showTranslatePage={showTranslatePage}
                  translQuestion={this.state.translQuestion}
                  handleTranslateQuestion={this.handleTranslateQuestion}
                  handleTranslateOption={this.handleTranslateOption}
                  onTranslateQuestionSave={this.onTranslateQuestionSave}
                  translShortName={translShortName}
                  handleLocationLable={this.handleLocationLable}
                />)


    }
  }


  sidebarToggle = () => {
    this.setState({ showSidebar: !this.state.showSidebar });

  }
  routeLink = (link) => {
    this.props.history.push('/app/' + link);
    this.setState({ CrrRoute: link });
  }

  onPreviewFullPage = () => {
    let surveyId = this.props.match.params.id;
    if (surveyId) {
      this.props.history.push(`/app/preview/${surveyId}`)
    }
  }



  onLogout = () => {
    console.log('logout')
    this.props.dispatch(userActions.logout());
  }


  handleLanguage = (value) => {

    if (!this.state.editId) {
      if (this.state.language === value) {
        this.setState({ language: '' });
      }
      else {
        this.setState({ language: value });
      }
    }

  }



  handleLanguage = (item) => {
    this.setState({ CurrentLanguage: item.shortName, CurrentLanguageName: item.name, showLanguageModal: false });
  }


  openLanguageModal = (check) => {
    this.setState({ showLanguageModal: !check });
    setTimeout(() => {
      this.setState({ showLanguageModal: false });
    }, 7000);
  }


  closeQuestionRestrictionModal = () => {
    this.setState({ showPleaseSaveQuesModal: false });
  }




  onQuestionLibrary = () => {

    this.setState({ showQuestionLibraryModal: true });
  }

  handleQuestionLibraryModalClose = () => {
    this.setState({ showQuestionLibraryModal: false, QuestionLibraryFinalList: [] });
  }


  onSelectQLQue = (question) => {
    let { QuestionLibraryFinalList } = this.state;

    let getIndex = QuestionLibraryFinalList.findIndex(prev => prev === question.id);
    if (getIndex === -1) {
      QuestionLibraryFinalList.push(question.id)
    }
    else {
      QuestionLibraryFinalList.splice(getIndex, 1);
    }

    this.setState({ QuestionLibraryFinalList });
  }

  SubmitOfQuesLibList = () => {
    let { QuestionLibraryFinalList } = this.state;
    let data = {
      surveyId: this.props.match.params.id,
      questionList: QuestionLibraryFinalList
    }
    this.props.dispatch(questionsActions.copyFromQuestionLibrary(data));
    this.setState({ showQuestionLibraryModal: false, QuestionLibraryFinalList: [], selectedIndexFilterValue: "" });

  }

  
  handleTranlLanguage=(value, translShortName)=>{
    if(!this.state.currQuesEditId && !this.state.ShowTranslWelcome){
      if(translShortName === value){
        this.setState({ CurrTranslShortName:'' });
      }
      else{
        this.setState({ CurrTranslShortName:value });
      }
    }

  }


  handleTranslateQuestion=(translQuesNames,quesId, translShortName)=>{
    let { translQuestion} = this.state;
    translQuestion['id'] = quesId;
    translQuestion['transl'][translShortName]['name'] = translQuesNames;
    this.setState({translQuestion});
  }

  handleInputTranslateMatrixQues=(translQuesNames,quesId,SubQues, translShortName)=>{
    let { translSubQuestionsMatrix} = this.state;
    // translSubQuestionsMatrix[SubQues.id]['id'] = quesId;
    translSubQuestionsMatrix[SubQues.id]['transl'][translShortName]['name'] = translQuesNames;
    this.setState({translSubQuestionsMatrix});
  }

  handleLocationLable=(translOptionName,quesId, optId,translShortName)=>{
    let { translQuestion } = this.state;
    translQuestion['id'] = quesId;
    translQuestion['transl'][translShortName][optId] = translOptionName;
    this.setState({translQuestion});
  }
  

  handleNPSLable=(translOptionName,quesId, optId,translShortName)=>{
    let { translQuestion } = this.state;
    translQuestion['id'] = quesId;
    translQuestion['transl'][translShortName][optId] = translOptionName;
    this.setState({translQuestion});
  }



  handleTranslateOption=(translOptionName,quesId, optId,translShortName)=>{
    let { translQuestion } = this.state;
    translQuestion['id'] = quesId;
    translQuestion['transl'][translShortName]['optionsList'][optId] = translOptionName;
    this.setState({translQuestion});
  }

  handleTranslateOptionHeadingInput=(translOptionHeadName,quesId, optId, translShortName)=>{
    let { translQuestion } = this.state;
    translQuestion['id'] = quesId;
    translQuestion['transl'][translShortName]['headingList'][optId] = translOptionHeadName;
    this.setState({translQuestion});
    console.log('<--- handleTranslateOptionHeadingInput: --->',translQuestion)
  }


  onTranslateQuestionSave=(isSave, translShortName)=>{
    let { translQuestion, translSubQuestionsMatrix } = this.state;

    if(isSave){
      if(translShortName){
        let data = {
          "id":translQuestion.id,
          "transl": translQuestion.transl
        }


        let tempdate = {
          surveyId: this.props.match.params.id
        }


          let TranslationSubList = []
          if(translSubQuestionsMatrix){
            let questionIdList = Object.keys(translSubQuestionsMatrix);
            if(questionIdList && questionIdList.length>0){
              questionIdList.forEach((QuesId)=>{
                if(translSubQuestionsMatrix && translSubQuestionsMatrix[QuesId] ){
                  TranslationSubList.push(translSubQuestionsMatrix[QuesId])
                }
              });
            }
          }

        this.props.dispatch(questionsActions.translateQuestion(data,tempdate, TranslationSubList));
      }



      let clearData = {
        "id":"",
        "transl":{}
      }
      this.setState({ translQuestion:clearData, currQuesEditId:'', translSubQuestionsMatrix });

    }
    else{
      let clearData = {
        "id":"",
        "transl":{}
      }
      this.setState({ translQuestion:clearData, currQuesEditId:'', translSubQuestionsMatrix:clearData });
    }



  }

  handleWelcomeTextTranslate=(value, translShortName)=>{
    let { translSurvey } = this.state;
    translSurvey['id'] = this.props.match.params.id;
    translSurvey['transl'][translShortName]['welcomeText'] = value;
    this.setState({translSurvey});
  } 
  handleInstructionTextTranslate=(value, translShortName)=>{
    let { translSurvey } = this.state;
    translSurvey['id'] = this.props.match.params.id;
    translSurvey['transl'][translShortName]['instructionText'] = value;
    this.setState({translSurvey});
  } 
  handleThankyouTextTranslate=(value, translShortName)=>{
    let { translSurvey } = this.state;
    translSurvey['id'] = this.props.match.params.id;
    translSurvey['transl'][translShortName]['thankyouText'] = value;
    this.setState({translSurvey});
  } 


  handleTranslSurveyName=(e,translShortName)=>{
    let { translSurvey } = this.state;
    translSurvey['transl'][translShortName]['name'] = e.target.value;
    this.setState({translSurvey});

    console.log('translSurvey; ',translSurvey)
  }

  handleTranslSurveyInput=(value,name, translShortName)=>{
    let { translSurvey } = this.state;
    translSurvey['transl'][translShortName][name] = value;
    this.setState({translSurvey});

    console.log('translSurvey; ',translSurvey)
  }

  handleTranslSurveyDesc=(e, translShortName)=>{
    let { translSurvey } = this.state;
    translSurvey['transl'][translShortName]['desc'] = e.target.value;;
    this.setState({translSurvey});
  }



  
  addMatrixQFn=(question, comp)=>{

    let { questions } = this.props; 
    let { questionsList } = questions; 
    
    if(comp==="row"){



      
    let SubQues ={
      questionTypeId: question.questionTypeId,
      templateId: question.templateId,

      parentId: question.id,

      isMandatory:false,

      surveyId: this.props.match.params.id,
      name: "Question",
      rank: 1,
      options: []
    }


    console.log('SubQues>.........................',SubQues)
  
    let tempcall = {
      surveyId: this.props.match.params.id

    }
    
    this.props.dispatch(questionsActions.createQuestion(SubQues,tempcall));


    }

    if(comp==="col"){

      this.props.dispatch(questionsActions.addOption(questionsList, question));
    }



  }

  handleInputMatrixQues=(Value,Que,SubQues)=>{

    let { questions } = this.props;
    let { questionsList } = questions;
  
    this.props.dispatch(questionsActions.handleSubQuestionName(questionsList, Que.id, SubQues.id, Value));


  }


  handleRankDash=(templateNo, value)=>{
    let { dashQuesTypeRank } = this.state;
    dashQuesTypeRank[templateNo]=parseInt(value);
    this.setState({dashQuesTypeRank})
  }


  handleLogicQuestionOrOption=(value, type)=>{
    let { translQuestion, logicQuesId } = this.state;
    if(type==="QuestionId"){
      translQuestion['transl']["questionLogic"]["id"]=value
      // translQuestion['transl']["questionLogic"]["answers"] = [];

      this.setState({logicQuesId: value });
    } 
    else if(type==="Options" && (translQuestion && translQuestion['transl'] && translQuestion['transl']["questionLogic"] && translQuestion['transl']["questionLogic"]["id"])) {
      // this.setState({logicOptions: value},()=>{
      // });

      let optionsSelected = translQuestion['transl']["questionLogic"]["answers"];

      let getIndex = optionsSelected.findIndex(prev=>prev===value);
      if(getIndex===-1){
        translQuestion['transl']["questionLogic"]["answers"].push(value);
      }
      else{
        translQuestion['transl']["questionLogic"]["answers"].splice(getIndex,1);
      }



    }
    else if(type==="EnableLogic") {
      translQuestion['transl']["questionLogic"]["enable"]=value;
    }
    this.setState({translQuestion});

  }

  

  render() {
    console.log("==>translQuestion: ",this.state.translQuestion);

    //ROLE MANAGEMENT ----------------------------------------------

    let moduleAccess = {
      "isAdmin": false,
      "access": {}
    };
    if (accessModule()) {
      moduleAccess["access"] = accessModule();
    }
    console.log('moduleAccess: ', moduleAccess)


    let SHOW_ELEMENT = false;
    let clientData = this.props.dashboard.ClientProfileData;
    if (clientData && clientData.role) {

      if (clientData.role === "ADMIN") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = true;
      }
      else if (clientData.role === "MNGR") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "CROLE") {
        SHOW_ELEMENT = true;
        moduleAccess["isAdmin"] = false;
      }
      else if (clientData.role === "EMP") {
        SHOW_ELEMENT = false;
        moduleAccess["isAdmin"] = false;
      }
    }
    function accessAllow(item) {
      return (moduleAccess.isAdmin ? true : (moduleAccess && moduleAccess.access && moduleAccess.access[item] ? true : false))
    }

    //END -------------------------------------------------------



    let { survey, questions } = this.props;
    let { getQuestionLibraryListData, getLanguageLabelData, getLanguageData, getQuestionTypeListData, getSurveyData, parameterList, getParameterListbyParentData } = survey;
    let { questionsList } = questions;
    let { dashQuesTypeRank, showTranslAttributes,ShowTranslWelcome, translSurvey, CurrTranslShortName, translQuestion,showQuestionLibraryModal, showDesignPage, showPleaseSaveQuesModal, showLanguageModal, CurrentLanguageName, CurrentLanguage, crrSurvey, CrrRoute, showSidebar, showDeleteQueModal, showTranslatePage, language } = this.state;

    let loading = survey.loading || questions.loading;


    let { logicQuesId, logicOptions, showDashboardDesignPage, showLogicPage, showAddQuestion, showWelcomePage, showPreviewPage, welcomeFormData, currQuesEditId, currQuesTranslEditId, imageWebLinkLogo,imageWebLinkLogo2, imageWebLink,imageWebLink2,
      showBucketDropdown, showDimensionDropdown, paraBucketName, paraDimensionName, showQuestionTypeList, questionTypeTemplate, expiryformData, showEditSurveyName, surveyUpdateFormData, translSubQuestionsMatrix } = this.state;

    let { getAllParameterListData } = this.props.dashboard;
    // console.log('getAllParameterListData V : ', getAllParameterListData);


    // console.log('%%% logicQuesId, logicOptions Lang List: ',logicQuesId, logicOptions);






    function GetParameterLabel(name) {
      let AllParameterObj = {};
      if (getAllParameterListData && getAllParameterListData.length > 0) {
        getAllParameterListData.forEach((item) => {
          AllParameterObj[item.name] = item && item.label ? item.label : item.name;
        });
      }
      return (AllParameterObj && AllParameterObj[name] ? AllParameterObj[name] : name);
    }



    let parameterListOptions = []
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((item) => {
        if (item.parameterTypeLevel === 1) {
          parameterListOptions.push(
            {
              value: GetParameterLabel(item.name),
              label: GetParameterLabel(item.name),
              id: item.id,
            }
          )
        }
      })
    }

    let parameterDefault = []
    if (getSurveyData && getSurveyData.parameterList && getSurveyData.parameterList.length > 0) {

      getSurveyData.parameterList.forEach((item) => {
        parameterDefault.push(
          {
            value: GetParameterLabel(item.name),
            label: GetParameterLabel(item.name),
            id: item.id,
          }
        )
      })
    }






    let orgListOptions = []
    if (parameterList && parameterList.length > 0) {
      parameterList.forEach((item) => {
        if (item && item.parameterTypeLevel === 2 && item.parentId && item.parentId.name === "OrganizationCore") {
          orgListOptions.push(
            {
              value: GetParameterLabel(item.name),
              label: GetParameterLabel(item.name),
              id: item.id,
            }
          )
        }
      })
    }

    let orgDefault = []
    if (getSurveyData && getSurveyData.orgList && getSurveyData.orgList.length > 0) {

      getSurveyData.orgList.forEach((item) => {
        orgDefault.push(
          {
            value: GetParameterLabel(item.name),
            label: GetParameterLabel(item.name),
            id: item.id,
          }
        )
      })
    }








    

//---------------------------------------------------------------------------------------




let langListOptions=[]
if(getLanguageData && getLanguageData.length>0){
  getLanguageData.forEach((item)=>{
      langListOptions.push(
        {
          value:item.name,
          label:item.name,
          id:item.id,
        }
      )
  });
}

let LangDefault=[]
if(getSurveyData && getSurveyData.langList && getSurveyData.langList.length>0){
      
  getSurveyData.langList.forEach((item)=>{
    LangDefault.push(
           {
             value:item.name,
             label:item.name,
             id:item.id,
           }
         )
       });
}




//---------------------------------------------------------------------------------------








    if (questionsList && questionsList.length > 0) {
      questionsList.sort((a, b) => a.rank - b.rank);
      // console.log('questionsList V : ', questionsList)
    }


    let SurveyName = '';

    let SurveyisExpire = '';
    let SurveyexpireTime = '';

    if (getSurveyData) {
      SurveyName = getSurveyData.name;
      SurveyisExpire = getSurveyData.isExpire ? true : (moment(moment.unix(getSurveyData.expireTime / 1000).format("YYYY-MM-DD")).isBefore(moment().format("YYYY-MM-DD")) ? true : false);
      SurveyexpireTime = (getSurveyData && getSurveyData.expireTime) ? moment.unix(getSurveyData.expireTime / 1000).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
    }


    let SurveyId = this.props.match.params.id;


    // console.log('getSurveyData V : ', getSurveyData)

    let SurveyTemplateId = 0;
    if (getSurveyData && getSurveyData.templateId) {
      SurveyTemplateId = getSurveyData.templateId;
    }


    let LanguageObj = {};
    if (getLanguageLabelData && getLanguageLabelData[CurrentLanguage]) {
      LanguageObj = getLanguageLabelData[CurrentLanguage];
    }

    // console.log('LanguageObj V : ', LanguageObj)

    let QuestionTypeListData = [];
    if (getQuestionTypeListData && getQuestionTypeListData.length > 0) {
      for (let i = getQuestionTypeListData.length - 1; i >= 0; i--) {
        QuestionTypeListData.push(getQuestionTypeListData[i]);
      }
    }






    // console.log('translSurvey =========> : ', translSurvey)
    // console.log('translQuestion =========> : ', translQuestion)

    let LanguageTranslValueList = []
    if(welcomeFormData && welcomeFormData.langListUpdate && welcomeFormData.langListUpdate.length>0){
      welcomeFormData.langListUpdate.forEach((langId)=>{
        if(getLanguageData && getLanguageData.length>0){
          getLanguageData.forEach((item)=>{
            if(item.id === langId){
              LanguageTranslValueList.push(item)
            }
          });
        }

      });
    }


    function LabelTransl (short){
      if(getLanguageData && getLanguageData.length>0){
        let getIndex = getLanguageData.findIndex(prev=>prev.shortName===short);


        if(getIndex!==-1){
          return getLanguageData[getIndex].name;
        }
        else{
          return ""
        }
      }
      else{
        return ""
      }

    }
    // console.log('getLanguageData =========> : ', getLanguageData)
    // console.log('translShortName =========> : ', translShortName)

    // console.log('questionsList =========> : ', questionsList)

    // console.log('translSubQuestionsMatrix =========> : ', translSubQuestionsMatrix)


    




    let translShortName = '';
    if(LanguageTranslValueList && LanguageTranslValueList.length>0){
      if(CurrTranslShortName){
        translShortName = CurrTranslShortName
      }
      else{
        translShortName = LanguageTranslValueList[0].shortName;
      }
    }

    // console.log('translShortName =========> : ', translShortName)



    return (
      <>
        <LoadingOverlay
          active={loading}
          // active={false}

          spinner
          text='Loading Data...'
          className="loader"
        >
          <div>
            <Sidebar sidebarToggle={this.sidebarToggle} accessAllow={accessAllow} showSidebar={showSidebar} routeLink={this.routeLink} CrrRoute={CrrRoute}
              LanguageLabel={this.LanguageLabel}

              LanguageObj={LanguageObj}
              CurrentLanguage={CurrentLanguage}

            />



            {/* side bar end */}
            <main className="lg:ml-16 h-screen ">
              {/* component */}
              <Header onLogout={this.onLogout}

                CurrentLanguageName={CurrentLanguageName}
                getLanguageData={getLanguageData}
                handleLanguage={this.handleLanguage}
                openLanguageModal={this.openLanguageModal}
                showLanguageModal={showLanguageModal}


              />

              {/* header end */}
              {accessAllow("createSurvey") ?
                <>
                  <div className=" h-full overflow-hidden overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
                    {/*sub nav bar 1 */}
                    <div className="px-4 xl:flex lg:flex  justify-between items-center bg-white border-b py-4">



                      {!showEditSurveyName ?
                        <div className="inline-flex xl:pb-0 lg:pb-0 pb-4 xl:w-auto lg:w-auto md:w-auto w-full justify-center items-center">
                          <span onClick={() => this.props.history.goBack()} className="cursor-pointer material-icons mr-4">keyboard_backspace</span>
                          <h2 className="text-lg text-gray-700 truncate font-semibold cursor-default">{SurveyName}</h2>

                          <div className="float-left px-2 cursor-pointer" onClick={() => this.handleEditSurveyName(getSurveyData)}><i className="fa fa-pen text-blue-500 hover:text-blue-500 hover:bg-blue-100 rounded-full p-2"></i></div>

                        </div>
                        :
                        <div className="">
                          <div className="float-left"><input type="text" name="name" value={surveyUpdateFormData.name} onChange={this.handleUpdateSurveyNameInput} placeholder="Survey title here..." className="py-1 px-2 w-full border border-gray-300 outline-none rounded-md" /></div>
                          <div className="float-left px-3 py-1 cursor-pointer" onClick={() => this.updateSurvey()}><i className="fa fa-check text-green-500"></i></div>
                          <div className="float-left px-3 py-1 cursor-pointer" onClick={() => { this.setState({ showEditSurveyName: false }) }}><i className="fa fa-times text-red-500"></i></div>
                        </div>
                      }
                      <div className="inline-flex items-center">
                        <div>

                          {/* <p className="text-sm mr-2 cursor-default">{LanguageObj && LanguageObj["mark_as_Expired_label"+"_"+CurrentLanguage]? LanguageObj["mark_as_Expired_label"+"_"+CurrentLanguage]:"Mark as Expired"}</p> */}

                        </div>
                        <input
                          id="expireTime"
                          type="date"
                          className=
                          {SurveyisExpire ?
                            "cursor-pointer px-2 border-2 border-red-500 rounded-md text-sm" :
                            "cursor-pointer px-2 border-2 border-blue-500 rounded-md text-sm"
                          }
                          name="expireTime"
                          value={SurveyexpireTime}
                          onChange={(e) => this.handleExpiryTime(e, SurveyisExpire, SurveyexpireTime)}
                        />
                        <div className="flex items-center space-x-2 ml-4">
                          <input onClick={() => this.isExpired(SurveyisExpire, SurveyexpireTime)} checked={SurveyisExpire} type="checkbox" id="remember" className="cursor-pointer w-4 h-4 transition duration-300 rounded focus:ring-2 focus:ring-offset-0 focus:outline-none focus:ring-blue-200" />
                          {/* <label htmlFor="remember" className="text-xs text-gray-500">{LanguageObj && LanguageObj["my_surveys_label"+"_"+CurrentLanguage]? LanguageObj["my_surveys_label"+"_"+CurrentLanguage]:"My Surveys"}</label> */}
                          <label htmlFor="remember" className={SurveyisExpire ? "text-xs text-red-500" : "text-xs text-gray-500"}>{LanguageObj && LanguageObj["mark_as_Expired_label" + "_" + CurrentLanguage] ? LanguageObj["mark_as_Expired_label" + "_" + CurrentLanguage] : "Mark As Expired"}</label>

                        </div>
                      </div>
                    </div>
                    {/* end */}
                    {/*sub nav bar 2 */}
                    <div className="px-4 xl:flex lg:flex  justify-between items-center bg-white border-b ">
                      <div className="flex xl:pt-0 lg:pt-0 pt-4 xl:w-auto lg:w-auto md:w-auto w-full justify-between items-center">
                        <h2 className="cursor-default text-lg text-gray-700 truncate font-semibold invisible ">Edit Survey </h2>

                        <div className="grid grid-cols-2 gap-2 items-center justify-center xl:hidden lg:hidden block">
                          {true ? <button onClick={() => this.onPreviewFullPage()} className="px-2 cursor-pointer w-full border border-green-500 text-green-500 rounded py-1.5  text-xs ">PREVIEW</button> : null}
                          {showAddQuestion ?
                            <button onClick={() => this.saveAutoParameter(false)} className="cursor-pointer w-full border border-blue-500 text-blue-500 rounded py-1.5  text-xs ">SAVE</button>
                            :
                            <button onClick={() => this.saveWelcomePage()} className="cursor-pointer w-full border border-blue-500 text-blue-500 rounded py-1.5  text-xs ">SAVE</button>
                          }
                          <button onClick={() => this.onPublishSurvey()} className="cursor-pointer w-full border border-blue-500 bg-blue-500 text-white rounded py-1.5  text-xs  px-4">
                            {LanguageObj && LanguageObj["publish_label" + "_" + CurrentLanguage] ? LanguageObj["publish_label" + "_" + CurrentLanguage] : "Publish"}
                          </button>
                        </div>
                      </div>

                      <nav className="scroll pt-2 overflow-x-auto overflow-hidden">
                        <div className="flex justify-center select-none">

                          {/* <span className="o-underline text-grey-dark border-b-2 hover:border-blue-500 border-transparent uppercase tracking-wide font-bold text-xs py-4 mr-8" href=" ">INSTRUCTIONS</span> */}

                          <span onClick={() => this.onQuestionPage()} className={showAddQuestion ? "cursor-pointer no-underline text-grey-dark border-b-2 hover:border-blue-500 border-blue-500 uppercase tracking-wide font-bold text-sm py-3 mr-8  n" : "cursor-pointer o-underline text-grey-dark border-b-2 hover:border-blue-500 border-transparent uppercase tracking-wide font-bold text-xs py-4 mr-8"} href>
                            {LanguageObj && LanguageObj["question_label" + "_" + CurrentLanguage] ? LanguageObj["question_label" + "_" + CurrentLanguage] : "Question"}
                          </span>

                          <span onClick={() => this.onWelcomePage("welcome")} className={showWelcomePage ? "cursor-pointer no-underline text-grey-dark border-b-2 hover:border-blue-500 border-blue-500 uppercase tracking-wide font-bold text-sm py-3 mr-8  n" : "cursor-pointer o-underline text-grey-dark border-b-2 hover:border-blue-500 border-transparent uppercase tracking-wide font-bold text-xs py-4 mr-8"} href>
                            {LanguageObj && LanguageObj["design_label" + "_" + CurrentLanguage] ? LanguageObj["design_label" + "_" + CurrentLanguage] : "Greetings"}
                          </span>

                          <span onClick={() => this.onWelcomePage("design")} className={showDesignPage ? "cursor-pointer no-underline text-grey-dark border-b-2 hover:border-blue-500 border-blue-500 uppercase tracking-wide font-bold text-sm py-3 mr-8  n" : "cursor-pointer o-underline text-grey-dark border-b-2 hover:border-blue-500 border-transparent uppercase tracking-wide font-bold text-xs py-4 mr-8"} href>
                            {LanguageObj && LanguageObj["design_label" + "_" + CurrentLanguage] ? LanguageObj["design_label" + "_" + CurrentLanguage] : "Design"}
                          </span>

                          <span onClick={() => this.onWelcomePage("dashboardDesign")} className={showDashboardDesignPage ? "cursor-pointer no-underline text-grey-dark border-b-2 hover:border-blue-500 border-blue-500 uppercase tracking-wide font-bold text-sm py-3 mr-8  n" : "cursor-pointer o-underline text-grey-dark border-b-2 hover:border-blue-500 border-transparent uppercase tracking-wide font-bold text-xs py-4 mr-8"} href>
                            {LanguageObj && LanguageObj["dashboardDesign_label" + "_" + CurrentLanguage] ? LanguageObj["dashboardDesign_label" + "_" + CurrentLanguage] : "Dashboard Design"}
                          </span>


                          {/* <span onClick={() => this.onWelcomePage("logic")} className={showLogicPage ? "cursor-pointer no-underline text-grey-dark border-b-2 hover:border-blue-500 border-blue-500 uppercase tracking-wide font-bold text-sm py-3 mr-8  n" : "cursor-pointer o-underline text-grey-dark border-b-2 hover:border-blue-500 border-transparent uppercase tracking-wide font-bold text-xs py-4 mr-8"} href>
                            {LanguageObj && LanguageObj["logic_label" + "_" + CurrentLanguage] ? LanguageObj["logic_label" + "_" + CurrentLanguage] : "logic"}
                          </span> */}

                          <span onClick={() => this.onWelcomePage("translate",translShortName)} className={showTranslatePage ? "cursor-pointer no-underline text-grey-dark border-b-2 hover:border-blue-500 border-blue-500 uppercase tracking-wide font-bold text-sm py-3 mr-8  n" : "cursor-pointer o-underline text-grey-dark border-b-2 hover:border-blue-500 border-transparent uppercase tracking-wide font-bold text-xs py-4 mr-8"} href>
                            {LanguageObj && LanguageObj["translate_label" + "_" + CurrentLanguage] ? LanguageObj["translate_label" + "_" + CurrentLanguage] : "Survey Translation"}
                          </span>

                        </div>
                      </nav>

                      <div className="xl:block lg:block hidden ">
                        <div className="grid grid-cols-3 gap-2 items-center justify-center">
                          {showAddQuestion ?
                            <button onClick={() => this.saveAutoParameter(false)} className="w-full border border-blue-500 text-blue-500 rounded py-1.5  text-xs ">{"SAVE"}</button>
                            :
                            <button onClick={() => this.saveWelcomePage()} className="w-full border border-blue-500 text-blue-500 rounded py-1.5  text-xs ">{"SAVE"}</button>
                          }
                          <button onClick={() => this.onPreviewFullPage()} className={"px-2 w-full border border-green-500 text-green-500 rounded py-1.5  text-xs "}>{"PREVIEW"}</button>
                          {questionsList && questionsList.length > 0 ?
                            <button onClick={() => this.onPublishSurvey()} className="w-full border border-blue-500 bg-blue-500 text-white rounded py-1.5  text-xs  px-4">
                              {LanguageObj && LanguageObj["publish_label" + "_" + CurrentLanguage] ? LanguageObj["publish_label" + "_" + CurrentLanguage] : "Publish"}
                            </button>
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-100 h-screen">

                      {showWelcomePage ?
                        <>
                          <WelcomePage
                            welcomeFormData={welcomeFormData}
                            parameterListOptions={parameterListOptions}
                            parameterDefault={parameterDefault}
                            orgListOptions={orgListOptions}
                            orgDefault={orgDefault}

                            handleWelcomeTextInput={this.handleWelcomeTextInput}
                            handleInstructionTextInput={this.handleInstructionTextInput}
                            handleThankyouTextInput={this.handleThankyouTextInput}

                            handleSurveyParameterList={this.handleSurveyParameterList}
                            handleSurveyorgList={this.handleSurveyorgList}

                            handleChangetextColor={this.handleChangetextColor}
                            handleChangebgColor={this.handleChangebgColor}

                            handleFile={this.handleFile}
                            handleUpload={this.handleUpload}
                            handleImageRemove={this.handleImageRemove}
                            imageWebLinkLogo={imageWebLinkLogo}

                            handleFileLogo={this.handleFileLogo}
                            handleUploadLogo={this.handleUploadLogo}
                            handleLogoRemove={this.handleLogoRemove}
                            imageWebLink={imageWebLink}

                            handlebgType={this.handlebgType}

                            ColorCodeList={this.state.ColorCodeList}

                            handleColorCode={this.handleColorCode}

                            selectColorType={this.state.selectColorType}


                            handleChangeColorValue={this.handleChangeColorValue}

                            handleInstructionCheck={this.handleInstructionCheck}

                            instructionCheck={this.state.instructionCheck}

                            SurveyTemplateId={SurveyTemplateId}
                          // saveWelcomePage={this.saveWelcomePage}
                          />
                        </>
                        : null}


                      {showDesignPage ?
                        <>
                          <DesignPage
                            welcomeFormData={welcomeFormData}
                            parameterListOptions={parameterListOptions}
                            parameterDefault={parameterDefault}
                            orgListOptions={orgListOptions}
                            orgDefault={orgDefault}

                            handleWelcomeTextInput={this.handleWelcomeTextInput}
                            handleInstructionTextInput={this.handleInstructionTextInput}
                            handleThankyouTextInput={this.handleThankyouTextInput}

                            handleSurveyParameterList={this.handleSurveyParameterList}
                            handleSurveyorgList={this.handleSurveyorgList}

                            handleChangetextColor={this.handleChangetextColor}
                            handleChangebgColor={this.handleChangebgColor}

                            handleFile={this.handleFile}
                            handleUpload={this.handleUpload}
                            handleImageRemove={this.handleImageRemove}
                            imageWebLinkLogo={imageWebLinkLogo}
                            imageWebLinkLogo2={imageWebLinkLogo2}

                            handleFileLogo={this.handleFileLogo}
                            handleFileLogo2={this.handleFileLogo2}
                            handleUploadLogo={this.handleUploadLogo}
                            handleLogoRemove={this.handleLogoRemove}
                            imageWebLink={imageWebLink}
                            imageWebLink2={imageWebLink2}

                            handlebgType={this.handlebgType}

                            ColorCodeList={this.state.ColorCodeList}

                            handleColorCode={this.handleColorCode}

                            selectColorType={this.state.selectColorType}


                            handleChangeColorValue={this.handleChangeColorValue}

                            handleInstructionCheck={this.handleInstructionCheck}

                            instructionCheck={this.state.instructionCheck}

                            SurveyTemplateId={SurveyTemplateId}
                          // saveWelcomePage={this.saveWelcomePage}

                            handleSurveyTemplate={this.handleSurveyTemplate}
                          />
                        </>
                        : null}




                      {showDashboardDesignPage ?
                        <>
                          <DashboardDesign
                            welcomeFormData={welcomeFormData}
                            dashQuesTypeRank={dashQuesTypeRank}
                            getQuestionTypeListData={getQuestionTypeListData}
                            handleRankDash={this.handleRankDash}
                          />
                        </>
                        : null}




                      {showLogicPage ?
                        <>
                          <LogicPage
                            welcomeFormData={welcomeFormData}
                            parameterListOptions={parameterListOptions}
                            parameterDefault={parameterDefault}
                            orgListOptions={orgListOptions}
                            orgDefault={orgDefault}

                            handleWelcomeTextInput={this.handleWelcomeTextInput}
                            handleInstructionTextInput={this.handleInstructionTextInput}
                            handleThankyouTextInput={this.handleThankyouTextInput}

                            handleSurveyParameterList={this.handleSurveyParameterList}
                            handleSurveyorgList={this.handleSurveyorgList}

                            handleChangetextColor={this.handleChangetextColor}
                            handleChangebgColor={this.handleChangebgColor}

                            handleFile={this.handleFile}
                            handleUpload={this.handleUpload}
                            handleImageRemove={this.handleImageRemove}
                            imageWebLinkLogo={imageWebLinkLogo}

                            handleFileLogo={this.handleFileLogo}
                            handleUploadLogo={this.handleUploadLogo}
                            handleLogoRemove={this.handleLogoRemove}
                            imageWebLink={imageWebLink}

                            handlebgType={this.handlebgType}

                            ColorCodeList={this.state.ColorCodeList}

                            handleColorCode={this.handleColorCode}

                            selectColorType={this.state.selectColorType}


                            handleChangeColorValue={this.handleChangeColorValue}

                            handleInstructionCheck={this.handleInstructionCheck}

                            instructionCheck={this.state.instructionCheck}

                            SurveyTemplateId={SurveyTemplateId}
                            // saveWelcomePage={this.saveWelcomePage}

                            GetParameterLabel={GetParameterLabel}

                            LangDefault = {LangDefault}
                            handleSurveyLangList = {this.handleSurveyLangList}
                            langListOptions = {langListOptions}


                          />
                        </>
                        : null}


                      {showAddQuestion ?

                        <main className="xl:px-6 lg:px-6 md:px-6 px-2 bg-gray-100">
                          <div className="container py-12 xl:w-2/3 xl:px-12 lg:w-2/3 lg:px-8 md:w-2/3 md:px-0 px-2 w-full mx-auto">


                            {questionsList && questionsList.length > 0 ?
                              questionsList.map((question, quesindex) =>
                                <div onClick={() => this.setState({ showQuestionTypeList: false })} 
                                className={question.parentId || question.templateId === 14?
                                  "border border-purple-300  py-6 px-1  bg-white  relative"
                                  :"border border-gray-300 py-6 px-4 bg-white mb-2 mt-3 relative"
                                }>
                                  <><div className={question.parentId || question.templateId === 14?"pt-6 px-6":"pt-6 px-4"}> 

                                    {/* Single Choice */}
                                    {question.templateId===1?
                                    <>
                                        <SingleChoice
                                          question={question} quesindex={quesindex}
                                          currQuesEditId={currQuesEditId}
                                          handleQuestionInput={this.handleQuestionInput}
                                          handleOptionInput={this.handleOptionInput}
                                          handleWeightageInput={this.handleWeightageInput}
                                          onDeleteOption={this.onDeleteOption}
                                          onAddNewOption={this.onAddNewOption}
                                          onQuesMandatory={this.onQuesMandatory}
                                          onSaveQues={this.onSaveQues}
                                          handleParameterBucket={this.handleParameterBucket}
                                          showBucketDropdown={showBucketDropdown}
                                          showDimensionDropdown={showDimensionDropdown}
                                          parameterList={parameterList}
                                          paraBucketName={paraBucketName}
                                          handleParameterQuestion={this.handleParameterQuestion}
                                          handleParameterDimension={this.handleParameterDimension}
                                          getParameterListbyParentData={getParameterListbyParentData}
                                          paraDimensionName={paraDimensionName}

                                          showParameterModal={this.state.showParameterModal}
                                          onParamaterModal={this.onParamaterModal}
                                          innerEdit={this.innerEdit}
                                          SurveyTemplateId={SurveyTemplateId}
                                          handleQuesCancel={this.handleQuesCancel}
                                          GetParameterLabel={GetParameterLabel}

                                          showTranslatePage={showTranslatePage}
                                          translQuestion={this.state.translQuestion}
                                          handleTranslateQuestion={this.handleTranslateQuestion}
                                          handleTranslateOption={this.handleTranslateOption}
                                          onTranslateQuestionSave={this.onTranslateQuestionSave}
                                          translShortName={translShortName}
                                          questionsList={questionsList}
                                          handleLogicQuestionOrOption={this.handleLogicQuestionOrOption}

                                        />
                                    </>
                                    :null}

                                    {/* Multiple Choice */}
                                    {question.templateId===2?
                                    <>
                                        <MultipleChoice
                                          question={question} quesindex={quesindex}
                                          currQuesEditId={currQuesEditId}
                                          handleQuestionInput={this.handleQuestionInput}
                                          handleOptionInput={this.handleOptionInput}
                                          handleWeightageInput={this.handleWeightageInput}
                                          onDeleteOption={this.onDeleteOption}
                                          onAddNewOption={this.onAddNewOption}
                                          onQuesMandatory={this.onQuesMandatory}
                                          onSaveQues={this.onSaveQues}
                                          handleParameterBucket={this.handleParameterBucket}
                                          showBucketDropdown={showBucketDropdown}
                                          showDimensionDropdown={showDimensionDropdown}
                                          parameterList={parameterList}
                                          paraBucketName={paraBucketName}
                                          handleParameterQuestion={this.handleParameterQuestion}
                                          handleParameterDimension={this.handleParameterDimension}
                                          getParameterListbyParentData={getParameterListbyParentData}
                                          paraDimensionName={paraDimensionName}

                                          showParameterModal={this.state.showParameterModal}
                                          onParamaterModal={this.onParamaterModal}
                                          innerEdit={this.innerEdit}
                                          SurveyTemplateId={SurveyTemplateId}
                                          handleQuesCancel={this.handleQuesCancel}
                                          GetParameterLabel={GetParameterLabel}
                                          showTranslatePage={showTranslatePage}
                                          translQuestion={this.state.translQuestion}
                                          handleTranslateQuestion={this.handleTranslateQuestion}
                                          handleTranslateOption={this.handleTranslateOption}
                                          onTranslateQuestionSave={this.onTranslateQuestionSave}
                                          translShortName={translShortName}

                                        />
                                    </>
                                    :null}

                                    {/* TextBox */}
                                    {question.templateId===3?
                                    <>
                                            <TextBox
                                                question={question} quesindex={quesindex}
                                                currQuesEditId={currQuesEditId}
                                                handleQuestionInput={this.handleQuestionInput}
                                                handleOptionInput={this.handleOptionInput}
                                                handleWeightageInput={this.handleWeightageInput}
                                                onDeleteOption={this.onDeleteOption}
                                                onAddNewOption={this.onAddNewOption}
                                                onQuesMandatory={this.onQuesMandatory}
                                                onSaveQues={this.onSaveQues}
                                                handleParameterBucket={this.handleParameterBucket}
                                                showBucketDropdown={showBucketDropdown}
                                                showDimensionDropdown={showDimensionDropdown}
                                                parameterList={parameterList}
                                                paraBucketName={paraBucketName}
                                                handleParameterQuestion={this.handleParameterQuestion}
                                                handleParameterDimension={this.handleParameterDimension}
                                                getParameterListbyParentData={getParameterListbyParentData}
                                                paraDimensionName={paraDimensionName}

                                                showParameterModal={this.state.showParameterModal}
                                                onParamaterModal={this.onParamaterModal}
                                                innerEdit={this.innerEdit}
                                                SurveyTemplateId={SurveyTemplateId}
                                                handleQuesCancel={this.handleQuesCancel}
                                                GetParameterLabel={GetParameterLabel}
                                                showTranslatePage={showTranslatePage}
                                                translQuestion={this.state.translQuestion}
                                                handleTranslateQuestion={this.handleTranslateQuestion}
                                                handleTranslateOption={this.handleTranslateOption}
                                                onTranslateQuestionSave={this.onTranslateQuestionSave}
                                                translShortName={translShortName}

                                              />
                                    </>
                                    :null}


                                    {/* Multiline Box */}
                                    {question.templateId===4?
                                    <>
                                      <MultilineText
                                          question={question} quesindex={quesindex}
                                          currQuesEditId={currQuesEditId}
                                          handleQuestionInput={this.handleQuestionInput}
                                          handleOptionInput={this.handleOptionInput}
                                          handleWeightageInput={this.handleWeightageInput}
                                          onDeleteOption={this.onDeleteOption}
                                          onAddNewOption={this.onAddNewOption}
                                          onQuesMandatory={this.onQuesMandatory}
                                          onSaveQues={this.onSaveQues}
                                          handleParameterBucket={this.handleParameterBucket}
                                          showBucketDropdown={showBucketDropdown}
                                          showDimensionDropdown={showDimensionDropdown}
                                          parameterList={parameterList}
                                          paraBucketName={paraBucketName}
                                          handleParameterQuestion={this.handleParameterQuestion}
                                          handleParameterDimension={this.handleParameterDimension}
                                          getParameterListbyParentData={getParameterListbyParentData}
                                          paraDimensionName={paraDimensionName}

                                          showParameterModal={this.state.showParameterModal}
                                          onParamaterModal={this.onParamaterModal}
                                          innerEdit={this.innerEdit}
                                          SurveyTemplateId={SurveyTemplateId}
                                          handleQuesCancel={this.handleQuesCancel}
                                          GetParameterLabel={GetParameterLabel}
                                          showTranslatePage={showTranslatePage}
                                          translQuestion={this.state.translQuestion}
                                          handleTranslateQuestion={this.handleTranslateQuestion}
                                          handleTranslateOption={this.handleTranslateOption}
                                          onTranslateQuestionSave={this.onTranslateQuestionSave}
                                          translShortName={translShortName}

                                        />
                                    </>
                                    :null}


                                    {/* Rating Scale */}
                                    {question.templateId===5?
                                    <>
                                          <RatingScale
                                            question={question} quesindex={quesindex}
                                            currQuesEditId={currQuesEditId}
                                            handleQuestionInput={this.handleQuestionInput}
                                            handleOptionInput={this.handleOptionInput}
                                            handleWeightageInput={this.handleWeightageInput}
                                            onDeleteOption={this.onDeleteOption}
                                            onAddNewOption={this.onAddNewOption}
                                            onQuesMandatory={this.onQuesMandatory}
                                            onSaveQues={this.onSaveQues}
                                            handleParameterBucket={this.handleParameterBucket}
                                            showBucketDropdown={showBucketDropdown}
                                            showDimensionDropdown={showDimensionDropdown}
                                            parameterList={parameterList}
                                            paraBucketName={paraBucketName}
                                            handleParameterQuestion={this.handleParameterQuestion}
                                            handleParameterDimension={this.handleParameterDimension}
                                            getParameterListbyParentData={getParameterListbyParentData}
                                            paraDimensionName={paraDimensionName}
                                  
                                            showParameterModal={this.state.showParameterModal}
                                            onParamaterModal={this.onParamaterModal}
                                            innerEdit={this.innerEdit}
                                            SurveyTemplateId={SurveyTemplateId}
                                            handleQuesCancel={this.handleQuesCancel}
                                            GetParameterLabel={GetParameterLabel}
                                            showTranslatePage={showTranslatePage}
                                            translQuestion={this.state.translQuestion}
                                            handleTranslateQuestion={this.handleTranslateQuestion}
                                            handleTranslateOption={this.handleTranslateOption}
                                            onTranslateQuestionSave={this.onTranslateQuestionSave}
                                            translShortName={translShortName}

                                          />
                                    </>
                                    :null}


                                    {/* NPS 1-10 scale*/}
                                    {question.templateId===6?
                                    <>
                                      <NPS
                                        question={question} quesindex={quesindex}
                                        currQuesEditId={currQuesEditId}
                                        handleQuestionInput={this.handleQuestionInput}
                                        handleOptionInput={this.handleOptionInput}
                                        handleWeightageInput={this.handleWeightageInput}
                                        onDeleteOption={this.onDeleteOption}
                                        onAddNewOption={this.onAddNewOption}
                                        onQuesMandatory={this.onQuesMandatory}
                                        onSaveQues={this.onSaveQues}
                                        handleParameterBucket={this.handleParameterBucket}
                                        showBucketDropdown={showBucketDropdown}
                                        showDimensionDropdown={showDimensionDropdown}
                                        parameterList={parameterList}
                                        paraBucketName={paraBucketName}
                                        handleParameterQuestion={this.handleParameterQuestion}
                                        handleParameterDimension={this.handleParameterDimension}
                                        getParameterListbyParentData={getParameterListbyParentData}
                                        paraDimensionName={paraDimensionName}

                                        showParameterModal={this.state.showParameterModal}
                                        onParamaterModal={this.onParamaterModal}
                                        innerEdit={this.innerEdit}
                                        SurveyTemplateId={SurveyTemplateId}
                                        handleQuesCancel={this.handleQuesCancel}
                                        GetParameterLabel={GetParameterLabel}
                                        showTranslatePage={showTranslatePage}
                                        translQuestion={this.state.translQuestion}
                                        handleTranslateQuestion={this.handleTranslateQuestion}
                                        handleTranslateOption={this.handleTranslateOption}
                                        onTranslateQuestionSave={this.onTranslateQuestionSave}
                                        translShortName={translShortName}
                                        handleNPSLable={this.handleNPSLable}


                                      />
                                    </>
                                    :null}

                                    {/* NPS 0-10 scale*/}
                                    {question.templateId===16?
                                    <>
                                      <NPSV2
                                        question={question} quesindex={quesindex}
                                        currQuesEditId={currQuesEditId}
                                        handleQuestionInput={this.handleQuestionInput}
                                        handleOptionInput={this.handleOptionInput}
                                        handleWeightageInput={this.handleWeightageInput}
                                        onDeleteOption={this.onDeleteOption}
                                        onAddNewOption={this.onAddNewOption}
                                        onQuesMandatory={this.onQuesMandatory}
                                        onSaveQues={this.onSaveQues}
                                        handleParameterBucket={this.handleParameterBucket}
                                        showBucketDropdown={showBucketDropdown}
                                        showDimensionDropdown={showDimensionDropdown}
                                        parameterList={parameterList}
                                        paraBucketName={paraBucketName}
                                        handleParameterQuestion={this.handleParameterQuestion}
                                        handleParameterDimension={this.handleParameterDimension}
                                        getParameterListbyParentData={getParameterListbyParentData}
                                        paraDimensionName={paraDimensionName}

                                        showParameterModal={this.state.showParameterModal}
                                        onParamaterModal={this.onParamaterModal}
                                        innerEdit={this.innerEdit}
                                        SurveyTemplateId={SurveyTemplateId}
                                        handleQuesCancel={this.handleQuesCancel}
                                        GetParameterLabel={GetParameterLabel}
                                        showTranslatePage={showTranslatePage}
                                        translQuestion={this.state.translQuestion}
                                        handleTranslateQuestion={this.handleTranslateQuestion}
                                        handleTranslateOption={this.handleTranslateOption}
                                        onTranslateQuestionSave={this.onTranslateQuestionSave}
                                        translShortName={translShortName}
                                        handleNPSLable={this.handleNPSLable}


                                      />
                                    </>
                                    :null}

                                    {question.templateId===7?
                                    <>
                                        <Email
                                          question={question} quesindex={quesindex}
                                          currQuesEditId={currQuesEditId}
                                          handleQuestionInput={this.handleQuestionInput}
                                          handleOptionInput={this.handleOptionInput}
                                          handleWeightageInput={this.handleWeightageInput}
                                          onDeleteOption={this.onDeleteOption}
                                          onAddNewOption={this.onAddNewOption}
                                          onQuesMandatory={this.onQuesMandatory}
                                          onSaveQues={this.onSaveQues}
                                          handleParameterBucket={this.handleParameterBucket}
                                          showBucketDropdown={showBucketDropdown}
                                          showDimensionDropdown={showDimensionDropdown}
                                          parameterList={parameterList}
                                          paraBucketName={paraBucketName}
                                          handleParameterQuestion={this.handleParameterQuestion}
                                          handleParameterDimension={this.handleParameterDimension}
                                          getParameterListbyParentData={getParameterListbyParentData}
                                          paraDimensionName={paraDimensionName}
                                
                                          showParameterModal={this.state.showParameterModal}
                                          onParamaterModal={this.onParamaterModal}
                                          innerEdit={this.innerEdit}
                                          SurveyTemplateId={SurveyTemplateId}
                                          handleQuesCancel={this.handleQuesCancel}
                                          GetParameterLabel={GetParameterLabel}
                                          showTranslatePage={showTranslatePage}
                                          translQuestion={this.state.translQuestion}
                                          handleTranslateQuestion={this.handleTranslateQuestion}
                                          handleTranslateOption={this.handleTranslateOption}
                                          onTranslateQuestionSave={this.onTranslateQuestionSave}
                                          translShortName={translShortName}

                                
                                        />
                                    </>
                                    :null}

                                    {question.templateId===8?
                                    <>
                                    <Number
                                      question={question} quesindex={quesindex}
                                      currQuesEditId={currQuesEditId}
                                      handleQuestionInput={this.handleQuestionInput}
                                      handleOptionInput={this.handleOptionInput}
                                      handleWeightageInput={this.handleWeightageInput}
                                      onDeleteOption={this.onDeleteOption}
                                      onAddNewOption={this.onAddNewOption}
                                      onQuesMandatory={this.onQuesMandatory}
                                      onSaveQues={this.onSaveQues}
                                      handleParameterBucket={this.handleParameterBucket}
                                      showBucketDropdown={showBucketDropdown}
                                      showDimensionDropdown={showDimensionDropdown}
                                      parameterList={parameterList}
                                      paraBucketName={paraBucketName}
                                      handleParameterQuestion={this.handleParameterQuestion}
                                      handleParameterDimension={this.handleParameterDimension}
                                      getParameterListbyParentData={getParameterListbyParentData}
                                      paraDimensionName={paraDimensionName}
                            
                                      showParameterModal={this.state.showParameterModal}
                                      onParamaterModal={this.onParamaterModal}
                                      innerEdit={this.innerEdit}
                                      SurveyTemplateId={SurveyTemplateId}
                                      handleQuesCancel={this.handleQuesCancel}
                                      GetParameterLabel={GetParameterLabel}
                                      showTranslatePage={showTranslatePage}
                                      translQuestion={this.state.translQuestion}
                                      handleTranslateQuestion={this.handleTranslateQuestion}
                                      handleTranslateOption={this.handleTranslateOption}
                                      onTranslateQuestionSave={this.onTranslateQuestionSave}
                                      translShortName={translShortName}

                                          />
                                    </>
                                    :null}


                                    {question.templateId===9?
                                    <>
                                    <Dropdown
                                        question={question} quesindex={quesindex}
                                        currQuesEditId={currQuesEditId}
                                        handleQuestionInput={this.handleQuestionInput}
                                        handleOptionInput={this.handleOptionInput}
                                        handleWeightageInput={this.handleWeightageInput}
                                        onDeleteOption={this.onDeleteOption}
                                        onAddNewOption={this.onAddNewOption}
                                        onQuesMandatory={this.onQuesMandatory}
                                        onSaveQues={this.onSaveQues}
                                        handleParameterBucket={this.handleParameterBucket}
                                        showBucketDropdown={showBucketDropdown}
                                        showDimensionDropdown={showDimensionDropdown}
                                        parameterList={parameterList}
                                        paraBucketName={paraBucketName}
                                        handleParameterQuestion={this.handleParameterQuestion}
                                        handleParameterDimension={this.handleParameterDimension}
                                        getParameterListbyParentData={getParameterListbyParentData}
                                        paraDimensionName={paraDimensionName}
                              
                                        showParameterModal={this.state.showParameterModal}
                                        onParamaterModal={this.onParamaterModal}
                                        innerEdit={this.innerEdit}
                                        SurveyTemplateId={SurveyTemplateId}
                                        handleQuesCancel={this.handleQuesCancel}
                                        GetParameterLabel={GetParameterLabel}
                                        showTranslatePage={showTranslatePage}
                                        translQuestion={this.state.translQuestion}
                                        handleTranslateQuestion={this.handleTranslateQuestion}
                                        handleTranslateOption={this.handleTranslateOption}
                                        onTranslateQuestionSave={this.onTranslateQuestionSave}
                                        translShortName={translShortName}

                                      />
                                    </>
                                    :null}

                                    {question.templateId===10?
                                    <>
                                      <Statement
                                          question={question} quesindex={quesindex}
                                          currQuesEditId={currQuesEditId}
                                          handleQuestionInput={this.handleQuestionInput}
                                          handleOptionInput={this.handleOptionInput}
                                          handleWeightageInput={this.handleWeightageInput}
                                          onDeleteOption={this.onDeleteOption}
                                          onAddNewOption={this.onAddNewOption}
                                          onQuesMandatory={this.onQuesMandatory}
                                          onSaveQues={this.onSaveQues}
                                          handleParameterBucket={this.handleParameterBucket}
                                          showBucketDropdown={showBucketDropdown}
                                          showDimensionDropdown={showDimensionDropdown}
                                          parameterList={parameterList}
                                          paraBucketName={paraBucketName}
                                          handleParameterQuestion={this.handleParameterQuestion}
                                          handleParameterDimension={this.handleParameterDimension}
                                          getParameterListbyParentData={getParameterListbyParentData}
                                          paraDimensionName={paraDimensionName}
                                
                                          showParameterModal={this.state.showParameterModal}
                                          onParamaterModal={this.onParamaterModal}
                                          innerEdit={this.innerEdit}
                                          SurveyTemplateId={SurveyTemplateId}
                                          handleQuesCancel={this.handleQuesCancel}
                                          GetParameterLabel={GetParameterLabel}
                                          showTranslatePage={showTranslatePage}
                                          translQuestion={this.state.translQuestion}
                                          handleTranslateQuestion={this.handleTranslateQuestion}
                                          handleTranslateOption={this.handleTranslateOption}
                                          onTranslateQuestionSave={this.onTranslateQuestionSave}
                                          translShortName={translShortName}

                                
                                
                                        />
                                    </>
                                    :null}


                                    {question.templateId===11?
                                    <>
                                    <YesNo
                                        question={question} quesindex={quesindex}
                                        currQuesEditId={currQuesEditId}
                                        handleQuestionInput={this.handleQuestionInput}
                                        handleOptionInput={this.handleOptionInput}
                                        handleWeightageInput={this.handleWeightageInput}
                                        onDeleteOption={this.onDeleteOption}
                                        onAddNewOption={this.onAddNewOption}
                                        onQuesMandatory={this.onQuesMandatory}
                                        onSaveQues={this.onSaveQues}
                                        handleParameterBucket={this.handleParameterBucket}
                                        showBucketDropdown={showBucketDropdown}
                                        showDimensionDropdown={showDimensionDropdown}
                                        parameterList={parameterList}
                                        paraBucketName={paraBucketName}
                                        handleParameterQuestion={this.handleParameterQuestion}
                                        handleParameterDimension={this.handleParameterDimension}
                                        getParameterListbyParentData={getParameterListbyParentData}
                                        paraDimensionName={paraDimensionName}
                              
                                        showParameterModal={this.state.showParameterModal}
                                        onParamaterModal={this.onParamaterModal}
                                        innerEdit={this.innerEdit}
                                        SurveyTemplateId={SurveyTemplateId}
                                        handleQuesCancel={this.handleQuesCancel}
                                        GetParameterLabel={GetParameterLabel}
                                        showTranslatePage={showTranslatePage}
                                        translQuestion={this.state.translQuestion}
                                        handleTranslateQuestion={this.handleTranslateQuestion}
                                        handleTranslateOption={this.handleTranslateOption}
                                        onTranslateQuestionSave={this.onTranslateQuestionSave}
                                        translShortName={translShortName}

                                      />
                                    </>
                                    :null}

                                    {question.templateId===12?
                                    <>
                                        <Date
                                          question={question} quesindex={quesindex}
                                          currQuesEditId={currQuesEditId}
                                          handleQuestionInput={this.handleQuestionInput}
                                          handleOptionInput={this.handleOptionInput}
                                          handleWeightageInput={this.handleWeightageInput}
                                          onDeleteOption={this.onDeleteOption}
                                          onAddNewOption={this.onAddNewOption}
                                          onQuesMandatory={this.onQuesMandatory}
                                          onSaveQues={this.onSaveQues}
                                          handleParameterBucket={this.handleParameterBucket}
                                          showBucketDropdown={showBucketDropdown}
                                          showDimensionDropdown={showDimensionDropdown}
                                          parameterList={parameterList}
                                          paraBucketName={paraBucketName}
                                          handleParameterQuestion={this.handleParameterQuestion}
                                          handleParameterDimension={this.handleParameterDimension}
                                          getParameterListbyParentData={getParameterListbyParentData}
                                          paraDimensionName={paraDimensionName}
                                
                                          showParameterModal={this.state.showParameterModal}
                                          onParamaterModal={this.onParamaterModal}
                                          innerEdit={this.innerEdit}
                                          SurveyTemplateId={SurveyTemplateId}
                                          handleQuesCancel={this.handleQuesCancel}
                                          GetParameterLabel={GetParameterLabel}
                                          showTranslatePage={showTranslatePage}
                                          translQuestion={this.state.translQuestion}
                                          handleTranslateQuestion={this.handleTranslateQuestion}
                                          handleTranslateOption={this.handleTranslateOption}
                                          onTranslateQuestionSave={this.onTranslateQuestionSave}
                                          translShortName={translShortName}

                                
                                        />
                                    </>
                                    :null}

                                    {question.templateId===13?
                                    <>
                                        <Matrix
                                            question={question} quesindex={quesindex}
                                            currQuesEditId={currQuesEditId}
                                            handleQuestionInput={this.handleQuestionInput}
                                            handleOptionInput={this.handleOptionInput}
                                            handleWeightageInput={this.handleWeightageInput}
                                            onDeleteOption={this.onDeleteOption}
                                            onAddNewOption={this.onAddNewOption}
                                            onQuesMandatory={this.onQuesMandatory}
                                            onSaveQues={this.onSaveQues}
                                            handleParameterBucket={this.handleParameterBucket}
                                            showBucketDropdown={showBucketDropdown}
                                            showDimensionDropdown={showDimensionDropdown}
                                            parameterList={parameterList}
                                            paraBucketName={paraBucketName}
                                            handleParameterQuestion={this.handleParameterQuestion}
                                            handleParameterDimension={this.handleParameterDimension}
                                            getParameterListbyParentData={getParameterListbyParentData}
                                            paraDimensionName={paraDimensionName}
                                  
                                            showParameterModal={this.state.showParameterModal}
                                            onParamaterModal={this.onParamaterModal}
                                            innerEdit={this.innerEdit}
                                            SurveyTemplateId={SurveyTemplateId}
                                            handleQuesCancel={this.handleQuesCancel}
                                            GetParameterLabel={GetParameterLabel}
                                  
                                            showTranslatePage={showTranslatePage}
                                            translQuestion={this.state.translQuestion}
                                            translSubQuestionsMatrix={this.state.translSubQuestionsMatrix}
                                            handleTranslateQuestion={this.handleTranslateQuestion}
                                            handleTranslateOption={this.handleTranslateOption}
                                            onTranslateQuestionSave={this.onTranslateQuestionSave}
                                            translShortName={translShortName}
                                            onDeleteConfirm={this.onDeleteConfirm}
                                            handleInputMatrixQues={this.handleInputMatrixQues}
                                            handleInputTranslateMatrixQues={this.handleInputTranslateMatrixQues}
                                            addMatrixQFn={this.addMatrixQFn}

                                            handleAddOptionHeading={this.handleAddOptionHeading}
                                            showOptionHeading={this.state.showOptionHeading}
                                            handleOptionHeadingInput={this.handleOptionHeadingInput}
                                            handleTranslateOptionHeadingInput={this.handleTranslateOptionHeadingInput}

                                          />
                                    </>
                                    :null}

                                    {question.templateId===14?
                                    <>
                                        <GroupQuestions
                                          question={question} quesindex={quesindex}
                                          currQuesEditId={currQuesEditId}
                                          handleQuestionInput={this.handleQuestionInput}
                                          handleOptionInput={this.handleOptionInput}
                                          handleWeightageInput={this.handleWeightageInput}
                                          onDeleteOption={this.onDeleteOption}
                                          onAddNewOption={this.onAddNewOption}
                                          onQuesMandatory={this.onQuesMandatory}
                                          onSaveQues={this.onSaveQues}
                                          handleParameterBucket={this.handleParameterBucket}
                                          showBucketDropdown={showBucketDropdown}
                                          showDimensionDropdown={showDimensionDropdown}
                                          parameterList={parameterList}
                                          paraBucketName={paraBucketName}
                                          handleParameterQuestion={this.handleParameterQuestion}
                                          handleParameterDimension={this.handleParameterDimension}
                                          getParameterListbyParentData={getParameterListbyParentData}
                                          paraDimensionName={paraDimensionName}
                                
                                          showParameterModal={this.state.showParameterModal}
                                          onParamaterModal={this.onParamaterModal}
                                          innerEdit={this.innerEdit}
                                          SurveyTemplateId={SurveyTemplateId}
                                          handleQuesCancel={this.handleQuesCancel}
                                          GetParameterLabel={GetParameterLabel}
                                
                                          showTranslatePage={showTranslatePage}
                                          translQuestion={this.state.translQuestion}
                                          translSubQuestionsMatrix={this.state.translSubQuestionsMatrix}
                                          handleTranslateQuestion={this.handleTranslateQuestion}
                                          handleTranslateOption={this.handleTranslateOption}
                                          onTranslateQuestionSave={this.onTranslateQuestionSave}
                                          translShortName={translShortName}
                                          onDeleteConfirm={this.onDeleteConfirm}
                                          handleInputMatrixQues={this.handleInputMatrixQues}
                                          handleInputTranslateMatrixQues={this.handleInputTranslateMatrixQues}
                                          addMatrixQFn={this.addMatrixQFn}
                            
                                          handleAddOptionHeading={this.handleAddOptionHeading}
                                          showOptionHeading={this.state.showOptionHeading}
                                          handleOptionHeadingInput={this.handleOptionHeadingInput}
                                          handleTranslateOptionHeadingInput={this.handleTranslateOptionHeadingInput}
                                        
                                          questionTypeTemplate={questionTypeTemplate}
                                          CurrentLanguage={CurrentLanguage}
                                          handleQuestionTypeGroup={this.handleQuestionTypeGroup}
                                          handleAddQuestion={this.handleAddQuestion}
                                          LanguageObj={LanguageObj}
                                          QuestionTypeListData={QuestionTypeListData}
                                          questionTypeTemplateGroup={this.state.questionTypeTemplateGroup}


                                        />



      


                                    </>
                                    :null}

                                    {question.templateId===15?
                                    <>
                                        <LocationQuestions
                                        question={question} quesindex={quesindex}
                                        currQuesEditId={currQuesEditId}
                                        handleQuestionInput={this.handleQuestionInput}
                                        handleOptionInput={this.handleOptionInput}
                                        handleWeightageInput={this.handleWeightageInput}
                                        onDeleteOption={this.onDeleteOption}
                                        onAddNewOption={this.onAddNewOption}
                                        onQuesMandatory={this.onQuesMandatory}
                                        onSaveQues={this.onSaveQues}
                                        handleParameterBucket={this.handleParameterBucket}
                                        showBucketDropdown={showBucketDropdown}
                                        showDimensionDropdown={showDimensionDropdown}
                                        parameterList={parameterList}
                                        paraBucketName={paraBucketName}
                                        handleParameterQuestion={this.handleParameterQuestion}
                                        handleParameterDimension={this.handleParameterDimension}
                                        getParameterListbyParentData={getParameterListbyParentData}
                                        paraDimensionName={paraDimensionName}
                              
                                        showParameterModal={this.state.showParameterModal}
                                        onParamaterModal={this.onParamaterModal}
                                        innerEdit={this.innerEdit}
                                        SurveyTemplateId={SurveyTemplateId}
                                        handleQuesCancel={this.handleQuesCancel}
                                        GetParameterLabel={GetParameterLabel}
                                        showTranslatePage={showTranslatePage}
                                        translQuestion={this.state.translQuestion}
                                        handleTranslateQuestion={this.handleTranslateQuestion}
                                        handleTranslateOption={this.handleTranslateOption}
                                        onTranslateQuestionSave={this.onTranslateQuestionSave}
                                        translShortName={translShortName}
                                        handleLocationLable={this.handleLocationLable}
                        
                                      />
                                    </>
                                    :null}

                                    

                                    
                                    
                                    </div> </>

                                  {SurveyTemplateId !== 2 ?

                                    <>
                                      {!(currQuesEditId === question.id) ?

                                        <div className="absolute top-3 right-4">
                                          <span title="Edit" className="cursor-pointer border rounded-full w-8 h-8 inline-flex items-center justify-center text-gray-500 mr-1 hover:text-blue-500 hover:bg-blue-100 rounded-full" onClick={() => this.onEditQues(question)}><span className="material-icons text-sm">edit</span></span>
                                          
                                          <span title="Delete" className="cursor-pointer border rounded-full w-8 h-8 inline-flex items-center justify-center text-gray-500 mr-1 hover:text-blue-500 hover:bg-blue-100 rounded-full" onClick={() => this.onDeleteConfirm(question)}><span className="material-icons text-sm">delete_outline</span></span>

                                          <span title="Move Up" className="cursor-pointer border rounded-full w-8 h-8 inline-flex items-center justify-center text-gray-500 mr-1 hover:text-blue-500 hover:bg-blue-100 rounded-full" onClick={() => this.onClickQuesUp(question.id, quesindex, question.surveyId)}><span className="material-icons text-sm">keyboard_arrow_up</span></span>
                                          
                                          <span title="Move Down" className="cursor-pointer border rounded-full w-8 h-8 inline-flex items-center justify-center text-gray-500 mr-1 hover:text-blue-500 hover:bg-blue-100 rounded-full" onClick={() => this.onClickQuesDown(question.id, quesindex, question.surveyId)}><span className="material-icons text-sm">keyboard_arrow_down</span></span>
                                        </div>

                                        : null}
                                    </>

                                    : null}

                                </div>
                              )
                              : null}


                            {SurveyTemplateId !== 2 ?
                              <div className="border mt-4 border-gray-300 py-6 xl:px-4 px-2 bg-white mb-20 flex justify-center">

                                <div className="mx-1.5 w-full border text-black flex justify-between px-4 items-center relative">

                                  <span className=" text-black text-xs font-semibold cursor-pointer w-full" onClick={() => this.handleShowQuestionType(showQuestionTypeList)}>
                                    {questionTypeTemplate.templateId ?
                                      QuestionTypeListData && QuestionTypeListData.length > 0 ?
                                        QuestionTypeListData.map((item, index) =>
                                          (item.templateId === questionTypeTemplate.templateId) ?
                                            (item.name)
                                            : null
                                        ) : null
                                      :
                                      (LanguageObj && LanguageObj["choose_question_type_label" + "_" + CurrentLanguage] ? LanguageObj["choose_question_type_label" + "_" + CurrentLanguage] : "Choose Question Type")
                                    }
                                  </span>

                                  <span className="material-icons text-gray-500 select-none cursor-pointer" onClick={() => this.handleShowQuestionType(showQuestionTypeList)}>expand_more</span>

                                  {showQuestionTypeList ?
                                    <div className="absolute top-10 left-0 bg-white shadow-md rounded-md w-full text-gray-500">
                                      {QuestionTypeListData && QuestionTypeListData.length > 0 ?
                                        QuestionTypeListData.map((item, index) =>
                                          (item.templateId === questionTypeTemplate.templateId) ?
                                            <span key={index} onClick={() => this.handleQuestionType(item)} className="cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b bg-gray-100"><i className="fa fa-list pr-4" />
                                              {item.name}</span>
                                            :
                                            <span key={index} onClick={() => this.handleQuestionType(item)} className="cursor-pointer inline-flex items-center py-3 px-4 text-xs w-full border-b hover:bg-gray-100"><i className="fa fa-list pr-4" />
                                              {item.name}</span>

                                        ) : null}
                                    </div>
                                    : null}

                                </div>

                                <span onClick={() => this.handleAddQuestion()} className="cursor-pointer mx-1.5 w-full bg-blue-500 text-white py-2 text-center rounded inline-flex items-center justify-center hover:bg-blue-600 text-sm">
                                  <span className="material-icons mr-1 -mt-0.5" >
                                    add
                                  </span>
                                  {LanguageObj && LanguageObj["add_question_label" + "_" + CurrentLanguage] ? LanguageObj["add_question_label" + "_" + CurrentLanguage] : "Add Question"}
                                </span>
                                <span onClick={() => this.onQuestionLibrary()} className="cursor-pointer mx-1.5 w-full bg-green-500 text-white py-2 text-center rounded inline-flex items-center justify-center hover:bg-green-600 text-sm">
                                  {/* <span className="material-icons mr-1 -mt-0.5" >
                                add
                              </span> */}
                                  {LanguageObj && LanguageObj["question_library_label" + "_" + CurrentLanguage] ? LanguageObj["question_library_label" + "_" + CurrentLanguage] : "Question Library"}
                                </span>

                              </div>
                              : null}

                          </div>
                        </main>

                      : null}










                      {showPreviewPage ?
                        <Preview
                          SurveyId={SurveyId}
                        />
                        : null}


                      {showPleaseSaveQuesModal ?
                        <QuestionRestrictionModal
                          currQuesEditId={currQuesEditId}
                          closeQuestionRestrictionModal={this.closeQuestionRestrictionModal}
                        />
                        : null}


                      {this.state.CurrentQuestionData ?
                        <DeleteSurveyConfirm
                          CurrentSurveyName={this.state.CurrentQuestionName}
                          handleSendSingleMail={this.onDeleteQues}
                          handleConfirmMailClose={this.handleConfirmDeleteQuesClose}
                        />
                        : null}


                      {showQuestionLibraryModal ?
                        <CustomQueLibrary
                          handleQuestionLibraryModalClose={this.handleQuestionLibraryModalClose}
                          getQuestionLibraryListData={getQuestionLibraryListData}
                          onSelectQLQue={this.onSelectQLQue}
                          QuestionLibraryFinalList={this.state.QuestionLibraryFinalList}
                          SubmitOfQuesLibList={this.SubmitOfQuesLibList}

                          handleOpenIndexFilter={this.handleOpenIndexFilter}
                          handleSelectIndexFilter={this.handleSelectIndexFilter}
                          showIndexFilter={this.state.showIndexFilter}
                          selectedIndexFilterValue={this.state.selectedIndexFilterValue}
                          GetParameterLabel={GetParameterLabel}
                        />
                        : null}


                      {showTranslatePage ?

                        <main className="xl:px-6 lg:px-6 md:px-6 px-2 bg-gray-100">
                       
                          
                          <div className="container py-12 xl:w-2/3 xl:px-12 lg:w-2/3 lg:px-8 md:w-2/3 md:px-0 px-2 w-full mx-auto">

                            <div className="mb-4">
                              <div className="text-black font-semibold text-sm">Language Options</div>
                              <Select2
                                id="langList"
                                isMulti
                                name="langList"
                                defaultValue={(LangDefault)?LangDefault.map((val)=>{return val}):""}
                                onChange={this.handleSurveyLangList}
                                options={langListOptions}
                                className="basic-multi-select"
                                classNamePrefix="Activities"
                              />
                            </div>
                          
                            {/* <div className="text-black font-semibold text-sm">Translate Language</div> */}
                            <div onClick={() => this.setState({ showQuestionTypeList: false })} className="flex mt-2 -ml-2">
                            {LanguageTranslValueList && LanguageTranslValueList.length>0?
                              LanguageTranslValueList.map((item,index)=>
                                <div 
                                onClick={()=>this.handleTranlLanguage(item.shortName, translShortName)} 
                                className={item.shortName===translShortName?
                                  "cursor-pointer  py-1 block px-4 text-blue-600 border-b-2 border-blue-500":
                                  "cursor-pointer  py-1 block px-4"}
                                >{item.name}</div>
                            ):null}
                            </div>


                            <div className="text-black font-semibold text-sm mt-6">Translate Greetings {LabelTransl(translShortName)?'in '+'"'+LabelTransl(translShortName)+'"':""}</div>
                            
                            
                            
                            {ShowTranslWelcome?
                            <>
                              <div className="border border-gray-300 py-6 px-4 bg-white mb-2 relative">
                            
                              <div className="absolute top-3 right-4">
                                <span title="Translate" className="cursor-pointer border border-green-500 text-white py-1 pb-2 px-2 rounded-sm" 
                                onClick={() => this.onSaveTranslateWelcome(true)}><span className=" text-sm text-green-500">Save</span>
                                </span>
                                
                              </div>


                              <div className="p-4 ">
                              <span className="text-blue-500">Survey Name: </span>

                              <input 
                                type="text" 
                                value={getSurveyData && getSurveyData.name?getSurveyData.name:""}
                                onChange={()=>{}}
                                disabled={true}
                                className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                              />
                              <input 
                                type="text" 
                                value={
                                  translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['name']?
                                  translSurvey['transl'][translShortName]['name']:""
                                }
                                onChange={(e)=>this.handleTranslSurveyName(e,translShortName)}
                                placeholder="Translate here..."
                                className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                              />

                              </div>


                              {getSurveyData && getSurveyData.desc?
                              <div className="p-4 ">
                              <span className="text-blue-500">Survey Desc: </span>

                              <input 
                                type="text" 
                                value={getSurveyData && getSurveyData.desc?getSurveyData.desc:""}
                                onChange={()=>{}}
                                disabled={true}
                                className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                              />
                              <input 
                                type="text" 
                                value={
                                  translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['desc']?
                                  translSurvey['transl'][translShortName]['desc']:""
                                }
                                placeholder="Translate here..."
                                onChange={(e)=>this.handleTranslSurveyDesc(e,translShortName)}
                                className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                              />


                              </div>
                              :null}



                              <div className="p-4 ">
                              <span className="text-blue-500">Welcome Text: </span>
                              <div className="text-gray-400 mb-1 text-sm border-2 border-gray-500 p-2" dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.welcomeText?getSurveyData.welcomeText:""}} />
                              
                              {/* <ReactQuill className="bg-white" theme="snow" value={getSurveyData && getSurveyData.welcomeText?getSurveyData.welcomeText:""} 
                              onChange={{}} placeholder="Welcome Text" disabled={true}/> */}


                              <ReactQuill className="bg-white border-2 border-purple-500" theme="snow" value={
                                translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['welcomeText']?
                                translSurvey['transl'][translShortName]['welcomeText']:""
                              } onChange={(val)=>this.handleWelcomeTextTranslate(val,translShortName)} placeholder="Translate here..."/>

                              </div>

                              {getSurveyData && getSurveyData.isInstruction?
                              <div className="p-4 ">
                              <span className="text-blue-500">Instruction Text: </span>
                              <div  className="text-gray-400 mb-1 text-sm border-2 border-gray-500 p-2"  dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.instructionText?getSurveyData.instructionText:""}} />
                              <ReactQuill className="bg-white border-2 border-purple-500" theme="snow" value={
                                translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['instructionText']?
                                translSurvey['transl'][translShortName]['instructionText']:""
                              } onChange={(val)=>this.handleInstructionTextTranslate(val,translShortName)} placeholder="Translate here..."/>
                              </div>
                              :null}



                              <div className="p-4 ">
                              <span className="text-blue-500">Thankyou Text: </span>
                              <div  className="text-gray-400 mb-1 text-sm border-2 border-gray-500 p-2"  dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.thankyouText?getSurveyData.thankyouText:""}} />
                              <ReactQuill className="bg-white border-2 border-purple-500" theme="snow" value={
                                translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['thankyouText']?
                                translSurvey['transl'][translShortName]['thankyouText']:""
                              } onChange={(val)=>this.handleThankyouTextTranslate(val,translShortName)} placeholder="Translate here..."/>
                              </div>


                              </div>
                            </>
                            :
                            <>
                              <div className="border border-gray-300 py-6 px-4 bg-white mb-2 relative">

                            <div className="absolute top-3 right-4">
                              <span title="Translate" className="cursor-pointer bg-purple-500 text-white py-1 pb-2 px-2 rounded-sm" 
                              onClick={() => this.onTranslateWelcomePage(getSurveyData, true,translShortName)}><span className=" text-sm">Translate</span>
                              </span>
                              
                            </div>


                            <div className="p-4 ">
                            <span className="text-blue-500">Survey Name: </span>
                            <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.name?getSurveyData.name:""}</span>
                            </div>
                            {getSurveyData && getSurveyData.desc?
                            <div className="p-4 ">
                            <span className="text-blue-500">Survey Desc: </span>
                            <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.desc?getSurveyData.desc:""}</span>
                            </div>
                            :null}
                            <div className="p-4 ">
                            <span className="text-blue-500">Welcome Text: </span>
                            <div dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.welcomeText?getSurveyData.welcomeText:""}} />
                            </div>
                            {getSurveyData && getSurveyData.isInstruction?
                              <div className="p-4 ">
                              <span className="text-blue-500">Instruction Text: </span>
                              <div dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.instructionText?getSurveyData.instructionText:""}} />
                              </div>
                            :null}
                            <div className="p-4 ">
                            <span className="text-blue-500">Thankyou Text: </span>
                            <div dangerouslySetInnerHTML={{ __html: getSurveyData && getSurveyData.thankyouText?getSurveyData.thankyouText:""}} />
                            </div>


                            </div>
                            </>}


                          <div className="text-black font-semibold text-sm mt-6">Translate Attributes {LabelTransl(translShortName)?'in '+'"'+LabelTransl(translShortName)+'"':""}</div>

                          {showTranslAttributes?
                            <>
                              <div className="border border-gray-300 py-6 px-4 bg-white mb-2 relative">
                            
                                <div className="absolute top-3 right-4">
                                  <span title="Translate" className="cursor-pointer border border-green-500 text-white py-1 pb-2 px-2 rounded-sm" 
                                  onClick={() => this.onSaveTranslateWelcome(false)}><span className=" text-sm text-green-500">Save</span>
                                  </span>
                                  
                                </div>


                                <div className="p-4 ">
                                <span className="text-blue-500">StartButtonText: </span>

                                <input 
                                  type="text" 
                                  value={getSurveyData && getSurveyData.StartButtonText?getSurveyData.StartButtonText:""}
                                  onChange={()=>{}}
                                  disabled={true}
                                  className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                                />
                                <input 
                                  type="text" 
                                  value={
                                    translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['StartButtonText']?
                                    translSurvey['transl'][translShortName]['StartButtonText']:""
                                  }
                                  onChange={(e)=>this.handleTranslSurveyInput(e.target.value,"StartButtonText",translShortName)}
                                  placeholder="Translate here..."
                                  className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                                />

                                </div> 
                                {getSurveyData && getSurveyData.isInstruction?

                                <div className="p-4 ">
                                <span className="text-blue-500">ContinueButtonText: </span>

                                <input 
                                  type="text" 
                                  value={getSurveyData && getSurveyData.ContinueButtonText?getSurveyData.ContinueButtonText:""}
                                  onChange={()=>{}}
                                  disabled={true}
                                  className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                                />
                                <input 
                                  type="text" 
                                  value={
                                    translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['ContinueButtonText']?
                                    translSurvey['transl'][translShortName]['ContinueButtonText']:""
                                  }
                                  onChange={(e)=>this.handleTranslSurveyInput(e.target.value,"ContinueButtonText",translShortName)}
                                  placeholder="Translate here..."
                                  className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                                />

                                </div> 
                                :null}

                                <div className="p-4 ">
                                <span className="text-blue-500">SubmitButtonText: </span>

                                <input 
                                  type="text" 
                                  value={getSurveyData && getSurveyData.SubmitButtonText?getSurveyData.SubmitButtonText:""}
                                  onChange={()=>{}}
                                  disabled={true}
                                  className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                                />
                                <input 
                                  type="text" 
                                  value={
                                    translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['SubmitButtonText']?
                                    translSurvey['transl'][translShortName]['SubmitButtonText']:""
                                  }
                                  onChange={(e)=>this.handleTranslSurveyInput(e.target.value,"SubmitButtonText",translShortName)}
                                  placeholder="Translate here..."
                                  className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                                />

                                </div> 

                                <div className="p-4 ">
                                <span className="text-blue-500">okButtonText: </span>

                                <input 
                                  type="text" 
                                  value={getSurveyData && getSurveyData.okButtonText?getSurveyData.okButtonText:""}
                                  onChange={()=>{}}
                                  disabled={true}
                                  className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                                />
                                <input 
                                  type="text" 
                                  value={
                                    translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['okButtonText']?
                                    translSurvey['transl'][translShortName]['okButtonText']:""
                                  }
                                  onChange={(e)=>this.handleTranslSurveyInput(e.target.value,"okButtonText",translShortName)}
                                  placeholder="Translate here..."
                                  className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                                />

                                </div> 

                                <div className="p-4 ">
                                <span className="text-blue-500">WriteHereText: </span>

                                <input 
                                  type="text" 
                                  value={getSurveyData && getSurveyData.WriteHereText?getSurveyData.WriteHereText:""}
                                  onChange={()=>{}}
                                  disabled={true}
                                  className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                                />
                                <input 
                                  type="text" 
                                  value={
                                    translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['WriteHereText']?
                                    translSurvey['transl'][translShortName]['WriteHereText']:""
                                  }
                                  onChange={(e)=>this.handleTranslSurveyInput(e.target.value,"WriteHereText",translShortName)}
                                  placeholder="Translate here..."
                                  className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                                />

                                </div> 

                                <div className="p-4 ">
                                <span className="text-blue-500">ExpiryText: </span>

                                <input 
                                  type="text" 
                                  value={getSurveyData && getSurveyData.ExpiryText?getSurveyData.ExpiryText:""}
                                  onChange={()=>{}}
                                  disabled={true}
                                  className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                                />
                                <input 
                                  type="text" 
                                  value={
                                    translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['ExpiryText']?
                                    translSurvey['transl'][translShortName]['ExpiryText']:""
                                  }
                                  onChange={(e)=>this.handleTranslSurveyInput(e.target.value,"ExpiryText",translShortName)}
                                  placeholder="Translate here..."
                                  className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                                />

                                </div> 


                                <div className="p-4 ">
                                <span className="text-blue-500">AlreadyText: </span>

                                <input 
                                  type="text" 
                                  value={getSurveyData && getSurveyData.AlreadyText?getSurveyData.AlreadyText:""}
                                  onChange={()=>{}}
                                  disabled={true}
                                  className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                                />
                                <input 
                                  type="text" 
                                  value={
                                    translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['AlreadyText']?
                                    translSurvey['transl'][translShortName]['AlreadyText']:""
                                  }
                                  onChange={(e)=>this.handleTranslSurveyInput(e.target.value,"AlreadyText",translShortName)}
                                  placeholder="Translate here..."
                                  className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                                />

                                </div> 

                                <div className="p-4 ">
                                <span className="text-blue-500">mandatoryText: </span>

                                <input 
                                  type="text" 
                                  value={getSurveyData && getSurveyData.mandatoryText?getSurveyData.mandatoryText:"This question is mandatory"}
                                  onChange={()=>{}}
                                  disabled={true}
                                  className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                                />
                                <input 
                                  type="text" 
                                  value={
                                    translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['mandatoryText']?
                                    translSurvey['transl'][translShortName]['mandatoryText']:""
                                  }
                                  onChange={(e)=>this.handleTranslSurveyInput(e.target.value,"mandatoryText",translShortName)}
                                  placeholder="Translate here..."
                                  className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                                />

                                </div> 

                                <div className="p-4 ">
                                <span className="text-blue-500">allmandatoryText: </span>

                                <input 
                                  type="text" 
                                  value={getSurveyData && getSurveyData.allmandatoryText?getSurveyData.allmandatoryText:"All questions are mandatory"}
                                  onChange={()=>{}}
                                  disabled={true}
                                  className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                                />
                                <input 
                                  type="text" 
                                  value={
                                    translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['allmandatoryText']?
                                    translSurvey['transl'][translShortName]['allmandatoryText']:""
                                  }
                                  onChange={(e)=>this.handleTranslSurveyInput(e.target.value,"allmandatoryText",translShortName)}
                                  placeholder="Translate here..."
                                  className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                                />

                                </div> 

                                <div className="p-4 ">
                                <span className="text-blue-500">completedText: </span>

                                <input 
                                  type="text" 
                                  value={getSurveyData && getSurveyData.completedText?getSurveyData.completedText:"completed"}
                                  onChange={()=>{}}
                                  disabled={true}
                                  className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                                />
                                <input 
                                  type="text" 
                                  value={
                                    translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['completedText']?
                                    translSurvey['transl'][translShortName]['completedText']:""
                                  }
                                  onChange={(e)=>this.handleTranslSurveyInput(e.target.value,"completedText",translShortName)}
                                  placeholder="Translate here..."
                                  className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                                />

                                </div> 


                                <div className="p-4 ">
                                <span className="text-blue-500">instructionLabel: </span>

                                <input 
                                  type="text" 
                                  value={getSurveyData && getSurveyData.instructionLabel?getSurveyData.instructionLabel:"I N S T R U C T I O N"}
                                  onChange={()=>{}}
                                  disabled={true}
                                  className="py-2 px-4 border-2 border-gray-500 outline-none w-full mt-2" 
                                />
                                <input 
                                  type="text" 
                                  value={
                                    translSurvey && translSurvey['transl'] && translSurvey['transl'][translShortName] && translSurvey['transl'][translShortName]['instructionLabel']?
                                    translSurvey['transl'][translShortName]['instructionLabel']:""
                                  }
                                  onChange={(e)=>this.handleTranslSurveyInput(e.target.value,"instructionLabel",translShortName)}
                                  placeholder="Translate here..."
                                  className="py-2 px-4 border-2 border-purple-500 outline-none w-full mt-2" 
                                />

                                </div> 

                            </div>
                            </>
                              :
                            <>



                              <div className="border border-gray-300 py-6 px-4 bg-white mb-2 relative">

                              <div className="absolute top-3 right-4">
                                <span title="Translate" className="cursor-pointer bg-purple-500 text-white py-1 pb-2 px-2 rounded-sm" 
                                onClick={() => this.onTranslateWelcomePage(getSurveyData,  false,translShortName)}><span className=" text-sm">Translate</span>
                                </span>
                                
                              </div>


                              <div className="p-4 ">
                              <span className="text-blue-500">StartButtonText: </span>
                              <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.StartButtonText?getSurveyData.StartButtonText:""}</span>
                              </div>
                              {getSurveyData && getSurveyData.isInstruction?
                              <div className="p-4 ">
                              <span className="text-blue-500">ContinueButtonText: </span>
                              <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.ContinueButtonText?getSurveyData.ContinueButtonText:""}</span>
                              </div>
                              :null}

                              <div className="p-4 ">
                              <span className="text-blue-500">SubmitButtonText: </span>
                              <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.SubmitButtonText?getSurveyData.SubmitButtonText:""}</span>
                              </div>

                              <div className="p-4 ">
                              <span className="text-blue-500">okButtonText: </span>
                              <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.okButtonText?getSurveyData.okButtonText:""}</span>
                              </div>

                              <div className="p-4 ">
                              <span className="text-blue-500">WriteHereText: </span>
                              <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.WriteHereText?getSurveyData.WriteHereText:""}</span>
                              </div>

                              <div className="p-4 ">
                              <span className="text-blue-500">ExpiryText: </span>
                              <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.ExpiryText?getSurveyData.ExpiryText:""}</span>
                              </div>

                              <div className="p-4 ">
                              <span className="text-blue-500">AlreadyText: </span>
                              <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.AlreadyText?getSurveyData.AlreadyText:""}</span>
                              </div>

                              <div className="p-4 ">
                              <span className="text-blue-500">mandatoryText: </span>
                              <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.mandatoryText?getSurveyData.mandatoryText:"This question is mandatory"}</span>
                              </div>

                              <div className="p-4 ">
                              <span className="text-blue-500">allmandatoryText: </span>
                              <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.allmandatoryText?getSurveyData.allmandatoryText:"All questions are mandatory"}</span>
                              </div>

                              <div className="p-4 ">
                              <span className="text-blue-500">completedText: </span>
                              <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.completedText?getSurveyData.completedText:"completed"}</span>
                              </div>

                              <div className="p-4 ">
                              <span className="text-blue-500">instructionLabel: </span>
                              <span className="px-1 text-gray-500 font-bold">{getSurveyData && getSurveyData.instructionLabel?getSurveyData.instructionLabel:"instructionLabel"}</span>
                              </div>

                              </div>
                            </>
                              }




                            <div className="text-black font-semibold text-sm">Questions</div>
                            {questionsList && questionsList.length > 0 ?
                              questionsList.map((question, quesindex) =>
                                <div onClick={() => this.setState({ showQuestionTypeList: false })} className="border border-gray-300 py-6 px-4 bg-white mb-2 relative">
                                  <><div className="pt-6"> {this.QuestionComponent(question, quesindex, currQuesEditId, SurveyTemplateId, GetParameterLabel,translShortName)} </div> </>


                                      {!(currQuesEditId === question.id) ?
                                      <>
                                        <div className="absolute top-3 right-4">
                                          <span title="Translate" className="cursor-pointer bg-purple-500 text-white py-1 pb-2 px-2 rounded-sm" 
                                          onClick={() => this.onTranslateQues(question, translShortName)}><span className=" text-sm">Translate</span>
                                          </span>
                                          
                                        </div>
                                      </>
                                      : null}

                                </div>
                              )
                              : null}
                              
                          </div>
                        </main>

                      : null}


                    </div>
                  </div>
                </>
                :
                <>
                  {!loading ?
                    <div className="container py-12 mx-auto px-4 h-full overflow-hidden overflow-y-auto" style={{ height: 'calc(100% - 4rem)' }}>
                      <div className="w-full h-full flex justify-center items-center">
                        {/*  text start*/}
                        <div className="text-center">
                          <img src="img/empty_survey.png" className="mb-4" />
                          <h1 className="text-gray-500 text-xl  font-semibold cursor-default">You have no access <br /> to this module!</h1>
                        </div>
                      </div>
                    </div>
                    : null}
                </>
              }
            </main>
          </div>

        </LoadingOverlay>
      </>
    );
  }
}
function mapStateToProps(state) {
  // console.log("state  ", state);
  const { loggingIn } = state.authentication;
  const { survey, questions, user, dashboard } = state;
  return {
    loggingIn,
    survey,
    questions,
    user,
    dashboard
  };
}
export default connect(mapStateToProps)(AddQuestion);
